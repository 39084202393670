import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

declare const spot$1: any;

export interface AlertEvent {
    isBusy: boolean;
    busyMsg?: string
}

@Injectable()
export class PCCAlertService {

    public busySubject = new BehaviorSubject<AlertEvent>({ isBusy: false, busyMsg: null });

    public busy = false;

    public busyMsg = "Searching...";

    public constructor(
    ) {
    }

    public setBusy(isBusy: boolean, busyMsg = ""): void {
        console.log(`setBusy: ${isBusy} ${busyMsg}`);

        this.busy = isBusy;
        this.busyMsg = busyMsg;
        this.busySubject.next({ isBusy, busyMsg });
    }

    public isBusy(): boolean {
        return this.busy;
    }

    public showError(title: string, msg = ""): void {
        spot$1.Message.toast({
            // "display": "inline",
            "dismissible": true,
            "priority": "medium",
            "alertLevel": "danger",
            title,
            "html": msg,
            "progressBar": true,
            "icon": "alert-notification",
            "iconPath": "/assets/spot-library/dist/symbols/spot_symbols.svg",
            "animationIn": "bounceInRight",
            "animationOut": "bounceOutRight",
            "position": "top-right",
            "timer": 10000
        });
    }

    public showToast(title: string, msg: string = "", icon: string = "checkmark", onDismissFunc: any = null): void {
        spot$1.Message.toast({
            // "display": "inline",
            "dismissible": true,
            "priority": "medium",
            "alertLevel": "success",
            title,
            "html": msg,
            "progressBar": true,
            "icon": icon,
            "iconPath": "/assets/spot-library/dist/symbols/spot_symbols.svg",
            "animationIn": "bounceInRight",
            "animationOut": "bounceOutRight",
            "position": "top-right",
            "timer": 5000,
            "onDismiss": onDismissFunc
            // ,
            // "onDismiss": function() {
            //     alert("hey you closed me!");
            // }
        });
    }
}
