import { Component, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { Language, LocalizedString, LocalizedKey } from "@shared/model/language";

@Component({
    selector: 'pcc-localized-text',
    templateUrl: './localized-text.component.html',
    styleUrls: ['./localized-text.component.scss']
})
export class LocalizedTextComponent implements OnChanges {

    @Input() public languages: Language[] = [];

    @Input() public label: string;

    @Input() public key: string;

    @Input() public editMode = false;

    @Input() public localizedKey: LocalizedKey;

    @Input() public required = false;

    @Input() public useEditButton = false;

    @Input() public hasRichText = false;

    @Output() public textChanged = new EventEmitter<LocalizedString>();

    public ngOnChanges(): void {
        if (!this.localizedKey) {
            this.localizedKey = { key: this.key };
        }
    }

    public fieldTextChanged(locale: string, updatedValue: string): void {
        console.log("fieldTextChanged: ", locale, updatedValue);

        this.localizedKey[locale] = updatedValue;

        this.textChanged.emit({ locale: locale, key: this.key, value: updatedValue });
    }

    public editClicked() {
        this.editMode = !this.editMode;
    }
}
