import { Component, OnInit, AfterViewInit, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";

import { AdminFacade } from "../../../facade/admin.facade";
import { ErrorUtils } from "@shared/model/error/error-utils";
import { PCCAlertService } from "../../../service/alert.service";

import { IBuyingGroup } from "@shared/model/buying-group";
import { ISystemSettings } from "@shared/model/system-settings";

@Component({
    selector: "pcc-buying-groups",
    templateUrl: "./buying-groups.component.html",
    styleUrls: [
        "./buying-groups.component.scss"
    ]
})
export class BuyingGroupsComponent implements OnInit, AfterViewInit {
    @ViewChild("bgForm", {
        static: false
    }) public bgForm: NgForm;

    public systemSettings: ISystemSettings;

    public buyingGroups: IBuyingGroup[];

    public bgCount = 0;

    public selectedBuyingGroup: IBuyingGroup;

    public invalid = false;

    public saving = false;

    public constructor(
        private adminFacade: AdminFacade,
        private alertService: PCCAlertService
    ) {}

    public async ngOnInit(): Promise<void> {
        const systemSettings = await this.adminFacade.getSystemSettingsCached();
        this.buyingGroups = systemSettings.buyingGroups;
        this.bgCount = this.buyingGroups.length;

        this.updateButtons();
    }

    public ngAfterViewInit(): void {
        if (this.bgForm) {
            this.bgForm.statusChanges.subscribe((status): void => {
                console.log("bgForm status change: ", status);
                this.updateButtons();
            });

        } else {
            console.error("this.bgForm doesn't exist!");
        }

    }

    public selectBuyingGroup(ctg: IBuyingGroup): void {
        console.log("selectBuyingGroup: ", ctg);
        this.selectedBuyingGroup = ctg;

        this.updateButtons();
    }

    public addBuyingGroup(): void {
        console.log("addBuyingGroup");
        const newBg: IBuyingGroup = {
        };
        this.selectBuyingGroup(newBg);
    }

    public async save(): Promise<void> {
        console.log("save: ", this.selectedBuyingGroup);

        const success = await this.saveBuyingGroup(this.selectedBuyingGroup);
        console.log("saveSuccessful = ", success);

        if (success) {
            try {
                // Reload system settings.
                await this.adminFacade.getSystemSettings();
            } catch (err) {
                console.error("Error calling getSystemSettings: ", err);
            }
        }
    }

    public async saveBuyingGroup(bg: IBuyingGroup): Promise<boolean> {
        console.log("saveBuyingGroup: ", bg);

        this.alertService.setBusy(true, "Saving...");

        this.saving = true;
        const isNew = bg.buying_group_id == null;
        console.log("isNew=", isNew);

        if (!bg.developer_name) {
            bg.developer_name = this.genShortKey();
        }

        try {
            const resp = await this.adminFacade.saveBuyingGroup(bg);
            console.log("saveBuyingGroup resp: ", resp);
            this.saving = false;
            this.alertService.setBusy(false);
            if (resp && resp.success) {
                this.alertService.showToast("Save successful");

                if (isNew) {
                    const newBg = resp.buying_group;
                    this.buyingGroups.push(newBg);
                }
                return true;

            }
            console.error("Error saving buyingGroup: ", resp);
            this.alertService.showError("Error saving buyingGroup", `${ErrorUtils.getErrorMessage(resp.error)}`);
        } catch (err) {
            this.saving = false;
            this.alertService.setBusy(false);
            console.error("Error saving buyingGroup: ", err);
            this.alertService.showError(`Error saving buyingGroup: ${err}`);
        }

        return false;
    }

    public cancel(): void {
        console.log("cancel");
        this.selectBuyingGroup(null);
    }

    public isSelected(bg: IBuyingGroup): boolean {
        return this.selectedBuyingGroup === bg;
    }

    // Just generate a short unique key we can use to tie an account in a buying
    // group to an account settings record.  Needs to be alphanumeric to avoid
    // possible overlaps with real SAP numbers.
    // Will be used in the same way we're using 'US' and 'CA' as sap numbers
    // for those generic accounts.
    public genShortKey(): string {
        let key = `bg_${this.bgCount++}`;
        while (this.isDupKey(key)) {
            key = this.genShortKey();
        }
        return key;
    }

    public isDupKey(key: string): boolean {
        return this.buyingGroups.some((bg: IBuyingGroup): boolean => (bg.developer_name === key));
    }

    private updateButtons(): void {
        const valid = this.bgForm && this.bgForm.form.valid;
        this.invalid = !valid;
    }

    /**
     * Test to make sure customer group description expression is a valid regex.
     */
    public testRegex(): boolean {
        console.log("testRegex: ", this.selectedBuyingGroup);
        if (this.selectedBuyingGroup && this.selectedBuyingGroup.customer_group_description) {
            try {
                const result = new RegExp(this.selectedBuyingGroup.customer_group_description).test("abc123");
                console.log("result=", result);
                return true;
            } catch (err) {
                console.error("Invalid regex: ", this.selectedBuyingGroup.customer_group_description, err);
                return false;
            }
        }
        return true;
    }
}
