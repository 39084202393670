<ng-container *ngIf="activeState && !activeState.hideProgress">
  <a *ngIf="landingLink" [routerLink]="landingLink" [title]="'navigation.Launch' | translate" class="spot-link nav-landing-link" (click)="bookClick()">
    <svg class="pcc-spot-icon-large spot-icon" aria-labelledby="title">
      <title>Book</title>
      <use xlink:href="/assets/spot-library/dist/symbols/spot_symbols.svg#book"></use>
    </svg>
  </a>
  <div class="pcc-progress-bar">
    <ng-container *ngFor="let st of states; let last=last; let first=first">
      <div *ngIf="st.hideProgress !== true" [class]="'progress-item ' + (activeState === st ? ' current ' : '') + (st.enabled === true ? ' enabled' : ' disabled')">
        <ng-container *ngIf="st.enabled === true">
          <a [routerLink]="st.link" routerLinkActive="pcc-route-current" (click)="breadcrumbClick(st)">
            {{st.name | translate}}
          </a>
        </ng-container>
        <span *ngIf="st.enabled !== true" class="pcc-route-disabled">{{st.name | translate}}</span>

        <hr *ngIf="activeState === st">
      </div>
    </ng-container>
  </div>
</ng-container>
