import { Component, OnInit, OnChanges, ViewChild, ElementRef, Input } from "@angular/core";

import exprEval from "expr-eval";

import { CorpPricingAlgorithmService } from "../../../service/corp-pricing-algorithm.service";

import { IAccountSettings } from "@shared/model/account-settings";
import { ISpecies } from "@shared/model/species";
import { IProfileItem } from "@shared/model/profile-item";
import { ICategory } from "@shared/model/category";
import { AdminFacade } from "../../../facade/admin.facade";
import { IProfileTemplate } from "@shared/model/profile-template";
import { IProfileCategory } from "@shared/model/profile-category";
import { ISystemSettings } from "@shared/model/system-settings";

import { Profile } from "@shared/model/profile";

/**
 * Pricing algorithm tool
 *
 * Allows user to create/edit the pricing algorithm to be set by the corporate parent account.
 *
 * Variables:
 * v_CUSTOMER_PRICE -> Customer-specific price for matched Profile from Order Simulate.
 * v_LIST_PRICE -> List price for matched Profile from Order Simulate.
 *   NOTE: Will be called on behalf of the child account during normal UI usage,
 *   not on the parent corporate account.
 * s_FELINE, s_CANINE -> Determined by the species value of the profile template.
 *   1 (profile is for FELINE) or
 *   0 (profile is not for FELINE)
 * p_[profile_item_id] -> For any profile item, will be p_ followed by the profile item id.
 *
 * There are basically three formats used here for the formula:
 * PERSIST: machine-parsable version where all variables are surrounded by {}
 * DISPLAY_NAME: Converts any variables to a human-readable display name surrounded by {}
 * EVALUATE: Same as Persist, but strips the {} characters.
 *
 */

interface IVariable {
    displayName: string;
    name: string;
    value?: number;
    isInputValue?: boolean
}

interface IVarGroup {
    displayName: string;
    name: string;
    variables: IVariable[];
}

interface ISelectedVariable {
    displayName: string;
    name: string;
    selected: number;
}

@Component({
    selector: "pcc-account-pricing",
    templateUrl: "./account-pricing.component.html",
    styleUrls: [
        "./account-pricing.component.scss"
    ]
})
export class AccountPricingComponent implements OnInit, OnChanges {

    @ViewChild("pricingAlg", {
        static: false
    }) public algTF: ElementRef;

    @Input() public accountSettings: IAccountSettings;

    public alg: string;

    public displayAlgorithm: string;

    public simResult: number;

    public selectedVar: IVariable;

    public showSim = false;

    public showPriceOK = true;

    public errorMsg: string;

    public simPrice: string;

    public simVariables: Record<string, number> = {
    };

    public simVariables2: ISelectedVariable[] = [];

    public varValues: Record<string, any>;

    public profileItems: IProfileItem[] = [];

    public categories: ICategory[] = [];

    public accountTests: IProfileItem[] = [];

    public species: ISpecies[] = [];

    public algorithmVars: IVariable[];

    public varGroups: IVarGroup[];

    public knownVariables: Record<string, IVariable>;

    public knownVariables2: IVariable[];

    public simProfile: Profile;

    public systemSettings: ISystemSettings;

    public constructor(
        public adminFacade: AdminFacade,
        public corpPricingAlgorithmService: CorpPricingAlgorithmService
    ) {
    }

    public setCategories(ctgList: ICategory[]): void {
        this.categories = ctgList;

        this.initVariables();
    }

    public async ngOnInit(): Promise<void> {

        try {
            const ss = await this.adminFacade.getSystemSettingsCached();
            if (ss) {
                this.systemSettings = ss;
                this.species = this.systemSettings.species;
                this.setTests(this.systemSettings.profileItems);

                this.setCategories(this.systemSettings.categories);

                this.updateView();
            } else {
                console.error("Error retrieving system settings: ", ss);
            }
        } catch (err) {
            console.error("Error getting system settings: ", err);
        }

    }

    public ngOnChanges(changes: any): void {
        console.log("Account pricing component changes: ", changes);
        this.updateView();
    }

    public updateView(): void {
        this.alg = this.accountSettings.pricing_algorithm;

        const currentVars = this.getVariables(this.alg);

        const availTests: Record<string, IProfileItem> = this.getAvailableTests();
        this.accountTests = Object.values(availTests);

        if (this.alg) {
            this.displayAlgorithm = this.corpPricingAlgorithmService.convertToDisplayNameVersion(this.alg,
                this.species,
                this.accountTests || this.profileItems,
                this.systemSettings);
        }

        const knownVars = this.initVariables();

        this.validateVars(knownVars, currentVars);
        this.validateFormula();
    }

    private getAvailableTests(): Record<string, IProfileItem> {
        const availTests: Record<string, IProfileItem> = {};
        if (!this.accountSettings || !this.accountSettings.profileTemplates) {
            return availTests;
        }

        this.accountSettings.profileTemplates.forEach((pt: IProfileTemplate): void => {
            pt.categories.forEach((ctg: IProfileCategory): void => {
                if (ctg.profileItems) {
                    for (const ctest of ctg.profileItems) {
                        availTests[ctest.developer_name] = ctest;
                    }
                }
            });
        });

        return availTests;
    }

    public formulaChanged(): void {
        if (this.validateFormula() === true) {
            this.accountSettings.pricing_algorithm = this.alg;
        }
    }

    public validateVars(knownVars: Record<string, IVariable>, currentVars: string[]): void {
        for (const cv of currentVars) {
            if (!knownVars[cv]) {
                console.log("Invalid variable: ", cv);
            }
        }
    }

    public setTests(tests: IProfileItem[]): void {
        this.profileItems = tests;

        this.initVariables();

    }

    public initVariables(): Record<string, IVariable> {
        // Load known tests allowed for this corp org.
        // Parse into {devName1:1, devName2:1} object

        this.knownVariables = {
        };
        const groups: Record<string, IVarGroup> = {
        };
        let key: string;

        groups.Price = {
            displayName: "General",
            name: "General",
            variables: []
        };

        key = "v_CUSTOMER_PRICE";
        let newVar: IVariable = {
            displayName: "Customer Price",
            name: key,
            isInputValue: true
        };
        groups.Price.variables.push(newVar);
        this.knownVariables[key] = newVar;

        key = "v_LIST_PRICE";
        newVar = {
            displayName: "List Price",
            name: key,
            isInputValue: true
        };
        groups.Price.variables.push(newVar);
        this.knownVariables[key] = newVar;

        for (const sp of this.species) {
            const sKey = `s_${sp.developer_name}`;
            newVar = {
                displayName: sp.display_name,
                name: sKey
            };
            this.knownVariables[sKey] = newVar;
            groups.Price.variables.push(newVar);
        }

        const knownTests = this.accountTests || this.profileItems;
        if (this.accountTests) {
            console.log("Using account tests for known variables...");
        } else if (this.profileItems) {
            console.log("Using all tests for known variables...");
        }
        for (const t of knownTests) {
            const ctg = t.category;
            if (!groups[ctg.displayName]) {

                const ctgInfo = ctg;
                groups[ctg.displayName] = {
                    displayName: ctgInfo.displayName,
                    name: ctgInfo.developerName,
                    variables: []
                };
            }

            key = `p_${t.profile_item_id}`;

            newVar = {
                displayName: this.adminFacade.translate(t.displayNameKey),
                name: key
            };
            this.knownVariables[key] = newVar;
            groups[ctg.displayName].variables.push(newVar);
        }

        this.varGroups = Object.values(groups);

        this.knownVariables2 = Object.values(this.knownVariables);

        return this.knownVariables;
    }

    public toggleSimulate(): void {
        this.showSim = !this.showSim;

        if (this.showSim) {
            this.initSimulation();
        }
    }

    public initSimulation(): void {
        this.simProfile = this.simProfile || new Profile();
        this.simProfile.customerPrice = 12.95;
        this.simProfile.listPrice = 13.95;

        const varNames = this.getVariables(this.alg);

        const algVars: IVariable[] = [];
        for (const kv of Object.keys(this.knownVariables)) {
            if (varNames.indexOf(kv) !== -1) {
                algVars.push(this.knownVariables[kv]);
            }
        }

        this.algorithmVars = algVars;

        this.varValues = this.getVariableValues(this.simProfile, varNames);
    }

    /**
     * Given a populated profile (containing customer price, species, and selected
     *  tests) and a list of variable names (parsed from algorithm), return an
     * object with all variables populated with either a value (for price) or 1|0
     * indicating the presence of that value.
     */
    public getVariableValues(profile: Profile, varNames: string[]): Record<string, any> {
        const formVars: Record<string, exprEval.Value> = {
        };

        // Initialize all variables to zero value
        varNames.forEach((varName: string): void => {
            formVars[varName] = 0;
        });

        for (const tVar of varNames) {
            // Some variables are hard-coded here.  Ideally could be driven from the formula itself...
            if (tVar === "v_CUSTOMER_PRICE") {
                formVars.CUSTOMER_PRICE = this.simProfile.customerPrice || 0;
                continue;
            }

            if (tVar === "v_LIST_PRICE") {
                formVars.LIST_PRICE = this.simProfile.listPrice || 0;
                continue;
            }

            for (const sp of this.species) {
                if (this.simProfile.species === sp) {
                    formVars[tVar] = 1;
                }
            }

            if (tVar.startsWith("p_")) {
                // Look for selected tests in the profile.
                const pid: number = Number.parseInt(tVar.substring(2), 10);
                const foundTest = profile.profileItems.find((st: IProfileItem): boolean => (st.profile_item_id === pid));
                if (foundTest) {
                    formVars[tVar] = 1;
                }
            }
        }

        return formVars;
    }

    public simFormula(): void {
        console.log("simFormula");
        try {

            this.simResult = this.adminFacade.runPriceCalc(this.alg, this.varValues);
            console.log("this.simResult = ", this.simResult, typeof this.simResult);

            this.showPriceOK = true;

        } catch (e) {
            console.error("Error parsing expression: ", e);

            this.showPriceOK = false;
            this.errorMsg = "Invalid";
        }

    }

    public addVar(): void {
        const element = this.algTF.nativeElement;
        const value = element.value;
        const startPos = element.selectionStart;
        const endPos = element.selectionEnd;
        const newVar = ` {${this.selectedVar.name}} `;

        this.alg = value.slice(0, startPos) + newVar + value.slice(endPos);
        this.displayAlgorithm = this.corpPricingAlgorithmService.convertToDisplayNameVersion(this.alg, this.species, this.accountTests || this.profileItems, this.systemSettings);

        element.value = this.alg;
        const newSel = startPos + newVar.length;
        element.selectionStart = newSel;
        element.selectionEnd = newSel;

        this.validateFormula();
    }

    /**
     * Validates that the free-text formula is readable.
     * Parses out the presence of any variables in the formula for use in the
     * simulate functionality.
     */
    public validateFormula(): boolean {
        let algTxt = this.alg;

        // Called when user has changed text manually as well.  So need to convert
        // display algorithm back to variable form...
        this.alg = this.convertToVarVersion(this.displayAlgorithm);
        algTxt = this.alg;

        algTxt = algTxt.replace(/[{}]/g, "");

        let expression: exprEval.Expression;
        try {
            const parser = new exprEval.Parser();
            expression = parser.parse(algTxt);

            const formVars: Record<string, exprEval.Value> = {
            };
            Object.values(this.knownVariables).forEach((ivar: IVariable): void => {
                formVars[ivar.name] = 1;
            });

            console.log("Using variables to evaluate formula: ", formVars);
            const evalResult = expression.evaluate(formVars);
            console.log("evalResult=", evalResult);

            this.showPriceOK = true;

            const vars: string[] = expression.variables();
            const newSimVariables: Record<string, number> = {
            };
            const newSimVariables2 = [];
            vars.forEach((v: string): void => {
                newSimVariables[v] = this.simVariables[v] || 0;
                newSimVariables2.push({
                    name: v, selected: this.simVariables[v] || 0
                });
            });
            this.simVariables = newSimVariables;
            this.simVariables2 = newSimVariables2;

            this.initSimulation();

            if (this.showSim === true) {
                this.simFormula();
            }

            return true;

        } catch (e) {
            console.error("Invalid formula: ", e);

            let msg = "Invalid formula";

            if (e.message) {
                if (e.message.startsWith("undefined variable")) {
                    console.log("Missing variable message here: ", e.message);

                    const split = e.message.split(":");
                    if (split[0] === "undefined variable") {
                        console.log("Missing variable message here: ", split[1]);
                    }

                    msg = `Invalid variable: ${split[1]}`;
                } else {
                    console.log("Other error message here: ", e.message);
                }
            }

            console.error(`error message=${msg}`);
            this.showPriceOK = false;
            this.errorMsg = msg;

            console.log("showPriceOK=", this.showPriceOK);

            return false;
        }

    }

    public getVariables(algTxt: string): string[] {
        let vars: string[];

        algTxt = algTxt.replace(/[{}]/g, "");

        let expression: exprEval.Expression;
        try {
            const parser = new exprEval.Parser();
            expression = parser.parse(algTxt);

            vars = expression.variables();
        } catch (e) {
            console.error("Invalid formula: ", e);
        }

        return vars;

    }

    public cleanTestName(testName: string): string {
        let cleanName = testName.replace(/([a-z\xE0-\xFF])([A-Z\xC0\xDF])/g, "$1 $2");
        // convert to upper case.
        cleanName = cleanName.toUpperCase();
        // Replace all non-alphanumeric with space.
        cleanName = cleanName.replace(/[^a-z0-9\xE0-\xFF]/gi, " ");
        cleanName = cleanName.trim();
        cleanName = cleanName.replace(/ /g, "_");
        return cleanName;
    }

    public compareVar(v1: IVariable, v2: IVariable): boolean {
        return v1 && v2 ? v1.name === v2.name : v1 === v2;
    }

    /**
       * Given a human-readable formatted pricing algorithm, convert to machine-parsible format.
       * For example:
       * input: "{Customer price} + 1.34* ({CHEM 25 w/ SDMA})"
       * output: "v_CUSTOMER_PRICE + 1.35 * (p_123)"
     */
    public convertToVarVersion(alg: string): string {
        let varAlg = "";
        const delimiterPattern = /({[^}]*})/g;
        const pieces = alg.split(delimiterPattern);
        for (const p of pieces) {
            if (p === "") {
                continue;
            }
            if (p.startsWith("{")) {
                const varDisp = p.replace(/[{}]/g, "");
                const v = this.convertDispToVar(varDisp);
                varAlg += `{${v}}`;
            } else {
                varAlg += p;
            }
        }

        return varAlg;
    }

    public convertDispToVar(varDisp: string): string {
        console.log(`convertDispToVar: ${varDisp}`);
        varDisp = varDisp.trim();
        if (varDisp === "Customer Price") {
            return "v_CUSTOMER_PRICE";
        }
        if (varDisp === "List Price") {
            return "v_LIST_PRICE";
        }

        for (const sp of this.species) {
            if (varDisp === sp.display_name) {
                return `s_${sp.developer_name}`;
            }
        }

        const knownTests = this.accountTests || this.profileItems;
        for (const t of knownTests) {
            if (varDisp === this.adminFacade.translate(t.displayNameKey).trim()) {
                return `p_${t.profile_item_id}`;
            }
        }

        console.error(`Unknown variable: '${varDisp}'`);
        return varDisp;
    }
}
