<div class="pcc-edit-panel">

  <div class="pcc-edit-panel__header profile-item-header" [class]="enrollInfo?.marked_for_deletion ? 'bg-danger' : ''">
    <span class="spot-typography__heading--level-3">Edit Enrollment</span>
    <span style="margin-left: auto;">
      <a #downloadButton type="button" class="spot-button spot-button--secondary" style="box-shadow:none;" (click)="getPDF()" title="Download PDF">
        <svg class="spot-icon spot-button__icon spot-button__icon--left" aria-labelledby="title" style="width:24px;height:24px;">
          <title>Download</title>
          <use xlink:href="/assets/spot-library/dist/symbols/spot_symbols.svg#download"></use>
        </svg>
      </a>
      &nbsp;&nbsp;&nbsp;
      <button type="button" class="spot-button spot-button--secondary spot-button--icon-only spot-button--with-icon spot-button--sales" style="box-shadow:none;" data-toggle="button" aria-pressed="false" (click)="markDelete()" title="Mark for Deletion">
        <svg class="spot-icon spot-button__icon spot-button__icon--left" aria-labelledby="title" style="width:24px;height:24px;">
          <title>Delete</title>
          <use xlink:href="/assets/spot-library/dist/symbols/spot_symbols.svg#delete"></use>
        </svg>
      </button>
    </span>
  </div>
  <div class="pcc-edit-panel__content" style="padding: 1em;">

    <form #editForm="ngForm" name="editForm" style="flex:1;flex-direction:column;display:flex;" id="editForm">
      <!-- Need the form here on startup so validation can work.  But don't want it showing -->
      <ng-container *ngIf="enrollInfo">
        <div class="pcc-form-row">
          <div class="spot-form__field-group" style="flex: 1;">
            <label class="spot-form__label">Practice name</label>
            <div class="form-readonly-value">{{enrollInfo?.practiceName}}</div>
          </div>
          <div class="spot-form__field-group" style="flex: 1;">
            <label class="spot-form__label">Practice champion</label>
            <div class="form-readonly-value">
              {{enrollInfo?.championSalutation}} {{enrollInfo?.championFirstName}} {{enrollInfo?.championLastName}}
            </div>
          </div>
          <div class="spot-form__field-group" style="flex: 1;">
            <label class="spot-form__label">IDEXX VDC</label>
            <div class="form-readonly-value">{{enrollInfo?.vdcUser?.name}}</div>
          </div>
        </div>
        <div class="pcc-form-row">
          <div class="spot-form__field-group" style="flex: 1;">
            <label class="spot-form__label">SAP ID</label>
            <div class="form-readonly-value">{{enrollInfo?.sap_id}}</div>
          </div>
          <div class="spot-form__field-group" style="flex: 1;">
            <label class="spot-form__label">Practice champion email</label>
            <input type="text" class="spot-form__input" name="championEmail" [(ngModel)]="enrollInfo.championEmail" required/>
          </div>
          <div class="spot-form__field-group" style="flex: 1;">
            <label class="spot-form__label">VDC email</label>
            <div class="form-readonly-value">{{enrollInfo?.vdcUser?.email}}></div>
          </div>
        </div>
        <div class="pcc-form-row">
          <div class="spot-form__field-group" style="flex: 1;">
            <label class="spot-form__label">Practice location</label>
            <div class="form-readonly-value">{{enrollInfo?.practiceCity+", "+enrollInfo?.practiceStateRegion}}</div>
          </div>
          <div class="spot-form__field-group" style="flex:1;">
          </div>
          <div class="spot-form__field-group" style="flex: 1;">
            <label class="spot-form__label">VDC phone</label>
            <div class="form-readonly-value">{{enrollInfo?.vdcUser?.phone}}</div>
          </div>
        </div>

        <div class="pcc-form-row">
          <div class="spot-form__field-group" style="flex: 1;">
            <label class="spot-form__label">Practice integration appointment</label>
            <div class="form-readonly-value">{{enrollInfo?.integrationDate}}</div>
          </div>
          <div class="spot-form__field-group" style="flex:1;">
            <label class="spot-form__label">Launch date</label>
            <calendar-widget
              [(date)]="enrollInfo.launchDate"
              (dateChange)="launchDateChanged()"
              />
          </div>
          <div class="spot-form__field-group" style="flex: 1;">
            <label class="spot-form__label">Enrollment end date </label>
            <calendar-widget
              [(date)]="enrollInfo.enrollmentEndDate"
              />
          </div>
        </div>

        <section class="pch-enroll">
          <pcc-enroll-pet-care-heroes style="flex:2;" [enrollInfo]="enrollInfo" [sessionMode]="sessionMode" [hidden]="!accountSettings?.flags.pch_enabled"></pcc-enroll-pet-care-heroes>

          <div style="flex: 1;"></div>

        </section>

        <span class="pcc-level-5-10">Profiles</span>
        <table class="table table-hover" aria-label="Profiles">
          <thead class="thead-light">
            <tr>
              <th scope="col">Profile</th>
              <th scope="col">Component</th>
              <th scope="col" *ngIf="!isCorp" class="price-column">Suggested Practice Price</th>
              <th scope="col" *ngIf="isCorp" class="price-column">Suggested Pet Owner Price</th>
              <th *ngIf="!isCorp" scope="col">Special Price</th>
              <th *ngIf="isCorp" scope="col">Pet Owner Price</th>
              <th *ngIf="!isCorp" scope="col" class="price-column">Panel Price</th>
              <th scope="col">Goal Quantity</th>
              <th scope="col" style="text-align:center">Price Check</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let prof of profiles" [class]="prof.marked_for_deletion === true ? 'deleted' : ''">
              <td>
                <input type="text" class="spot-form__input" [name]="prof.enrollment_profile_id + '.display_name'" [(ngModel)]="prof.display_name" size=30/>
              </td>
              <td>
                {{prof.profile_test_code}}
              </td>
              <td *ngIf="!isCorp" class="price-column">
                {{prof.recommendedPracticePrice | currency : session.currency : 'symbol-narrow' : '1.2-2' : session.locale}}
              </td>
              <td *ngIf="isCorp" class="price-column">
                {{ (prof.recommendedPetOwnerPrice | currency : session.currency : 'symbol-narrow' : '1.2-2' : session.locale) || 'n/a'}}
              </td>
              <td *ngIf="!isCorp">
                <input type="text" class="spot-form__input" currencyMask size=10 [options]="session?.currencyMaskOptions" [name]="prof.enrollment_profile_id + '.special_price'" id="specialPrice" [(ngModel)]="prof.specialPrice" (ngModelChange)="specialPriceChanged(prof)" style="text-align:center;"/>
              </td>
              <td *ngIf="isCorp">
                <input type="text" class="spot-form__input" currencyMask size=10 [options]="session?.currencyMaskOptions" [name]="prof.enrollment_profile_id + '.accepted_pet_owner_price'" id="acceptedPetOwnerPrice" [(ngModel)]="prof.acceptedPetOwnerPrice" (ngModelChange)="acceptedPetOwnerPriceChanged(prof)" style="text-align:center;"/>
              </td>
              <td *ngIf="!isCorp" class="price-column">
                {{(prof.acceptedPracticePrice | currency : session.currency : 'symbol-narrow' : '1.2-2' : session.locale) || 'n/a'}}
              </td>
              <td>
                <input type="number" class="spot-form__input" [name]="prof.enrollment_profile_id + '.goal_quantity'" id="goalQuantity" [(ngModel)]="prof.goalQuantity" style="text-align:center;width:80px" size=4/>
              </td>
              <td align="center">
                <input type="checkbox" class="form-check-input" name="price_check" id="price_check" [checked]="prof.price_check" (change)="priceCheckClicked(prof)">
              </td>
              <td>
                <button class="spot-button spot-button--secondary spot-button--icon-only spot-button--with-icon spot-button--sales" style="box-shadow:none;" (click)="markProfileDelete(prof)" title="Mark for Deletion">
                  <svg class="spot-icon spot-button__icon spot-button__icon--left" aria-labelledby="title">
                    <title>Delete</title>
                    <use xlink:href="/assets/spot-library/dist/symbols/spot_symbols.svg#delete"></use>
                  </svg>

                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </ng-container>
    </form>
  </div>

  <ng-container *ngIf="enrollInfo">
    <div class="pcc-edit-panel__footer">
      <button type="cancel" class="pcc-edit-panel__footer-cancel-button" (click)="cancel()">Cancel</button>
      <button type="submit" class="spot-button spot-button--primary" [disabled]="saving" (click)="save()">Save</button>
    </div>

  </ng-container>

</div>
