<div class="spot-modal">

  <pcc-loading [isBusy]="isLoading" [busyMsg]="loadingMessage"></pcc-loading>

  <div class="spot-modal__header">
    <span class="spot-typography__heading--level-3">
      Managed File
    </span>
    <button class="spot-modal__header-cancel-button" (click)="cancel()">
      <svg class="spot-icon spot-modal__header-cancel-button-icon" aria-labelledby="title">
        <title>cancel</title>
        <use xlink:href="/assets/spot-library/dist/symbols/spot_symbols.svg#cancel"></use>
      </svg>
    </button>
  </div>
  <div class="spot-modal__content">

    <div class="custom-file">
	  <form class="spot-form file-form" id="fileForm" #fileForm="ngForm" name="fileForm">
        <div style="display: flex;">
          <section class="file-preview" *ngIf="imagePreview" >
		    <img [src]="imagePreview" width=150 [alt]="selectedFile?.name">
		  </section>

          <section class="file-form">
            <div style="display:flex;align-items:center;margin-bottom:1em;">
              <pcc-language-radio-group
                [(selectedLocale)]="selectedFile.locale"
                label="Assign Language"
                [isRequired]="true"
                [languages]="languages"
                (selectedLanguageChange)="onLanguageSelected($event)">
              </pcc-language-radio-group>

		      <pcc-confirm-action symbol="delete" confirmPosition="spot-popover--bottom-left" (okClick)="deleteFileConfirmed(selectedFile)" class="delete-button" confirmMsg="manage-files.confirm_delete"></pcc-confirm-action>
            </div>
            <div class="file-select">
		      <div class="form-group" style="flex:1">
		        <input type="file" name="imageFileField" id="imageFileField" accept="image/*" [(ngModel)]="imageFileName" (change)="onImageFileSelect($event)"/>
		      </div>
	        </div>
	        <div class="spot-form__field-group">
		      <label class="spot-form__label tight-label" title="Used to display text along with image"> Title </label>
		      <pcc-edit-text-locale [locale]="selectedFile.locale" [(editText)]="selectedFile.title" [editEnabledEmpty]="true" (editTextChanged)="validate()" [required]="true" [editMode]="true"></pcc-edit-text-locale>
	        </div>

	        <div class="spot-form__field-group">
		      <label class="spot-form__label" for="description" title="Used to display text along with image"> Description </label>
              <pcc-edit-text-locale [locale]="selectedFile.locale" [(editText)]="selectedFile.description" [editEnabledEmpty]="true"></pcc-edit-text-locale>
	        </div>

            <div class="spot-form__field-group">
		      <label class="spot-form__label" > File name </label>
              {{ selectedFile.name }}
            </div>

	        <div class="file-location">
		      <span class="label">S3 Bucket</span>
		      <span class="data"> {{ selectedFile.location }} </span>
	        </div>

          </section>
        </div>
	  </form>
    </div>

  </div>
  <div class="spot-modal__footer">
    <button type="cancel" class="spot-modal__footer-cancel-button" (click)="cancel()">Cancel</button>
    <button type="submit" class="spot-button spot-button--primary" (click)="saveClicked()">Save</button>
  </div>
</div>
