<div class="account-header">
  <span class="spot-typography__heading--level-3">{{ account?.name }} ({{ account.sap_id }}) </span>

  <input type="checkbox" class="spot-toggle" id="acctSettingsVisible" name="acctSettingsVisible" [(ngModel)]="accountSettings.visible" (change)="accountSettingsVisibleChange()">
  <label class="spot-toggle-label" for="acctSettingsVisible">Account visibility</label>

  <a class="spot-link" style="margin-left: 50px;" routerLink="/admin/config/search">
    Go to a different group or country
  </a>
</div>

<pcc-account-nav [activeItem]="activeView" [account]="account" [accountSettings]="accountSettings" (navClick)="navClicked($event)" *ngIf="activeView"></pcc-account-nav>

<router-outlet></router-outlet>
