import { IUser } from "./user";
import { ISpecies } from "./species";
import { ICategory } from "./category";
import { IProduct } from "./product";
import { IModality, ModalityEnum } from "./modality";
import { LocalizedResource, LocalizedContent } from "./language";

export type ProfileItemType = "STANDARD"
    | "ADD_ON"
    | "OVERRIDE"
    | "STATIC";

export const PROFILE_ITEM_TYPES: { [key: string]: ProfileItemType } = {
    STANDARD: "STANDARD",
    ADD_ON: "ADD_ON",
    OVERRIDE: "OVERRIDE",
    STATIC: "STATIC"
};

export interface IProfileItem extends LocalizedResource {
    profile_item_id?: number;
    test_type?: ProfileItemType;

    // Used when saving only.  Not persisted!
    accountSettingsId?: number;

    // Used when consumed either by the client or in the admin build-template screen to pull translations from "active" locale
    displayNameKey?: string;

    // Only populated to order profile items inside a template.
    display_order?: number;

    developer_name?: string;

    category: ICategory;
    is_default?: boolean;
    isSelected?: boolean;

    countryCd: string;

    price?: number;

    active?: boolean;

    speciesList?: ISpecies[];
    modality: IModality; // modality_id

    // List of actual test components sourced from mdos/salesforce.
    // Contains test_id which is used to match against profile
    // Contains sap_material_num which is used for pricing (on the final matched profile, not the individual product
    products?: IProduct[];
    replacementRules?: IReplaceRule[];

    // Used to contain localized display text for this object
    localizedKeys: LocalizedContent;

    created_on?: Date;
    createdBy?: IUser;

    updated_on?: Date;
    updatedBy?: IUser;

}

export interface IModalityTests {
    modality: string;
    tests?: IProfileItem[];
}

export const ItemUtils = {
    isAddOn(profileItem: IProfileItem): boolean {
        return profileItem && profileItem.test_type === PROFILE_ITEM_TYPES.ADD_ON;
    },

    isStandard(profileItem: IProfileItem): boolean {
        return profileItem && profileItem.test_type === PROFILE_ITEM_TYPES.STANDARD;
    },

    isOverride(profileItem: IProfileItem): boolean {
        return profileItem && profileItem.test_type === PROFILE_ITEM_TYPES.OVERRIDE;
    },

    isStatic(profileItem: IProfileItem): boolean {
        return profileItem && profileItem.test_type === PROFILE_ITEM_TYPES.STATIC;
    },

    getProductIds(pItem: IProfileItem): string[] {
        return Array.isArray(pItem.products) ? pItem.products.map((product: IProduct): string => product.test_code) : [];
    },

    createProfileItem(accountSettingsId: number, countryCd: string, mod: IModality, testType: string): IProfileItem {
        return {
            accountSettingsId,
            countryCd,
            modality: mod,
            test_type: testType,
            active: true,
            products: [],
            speciesList: [],
            replacementRules: [],
            localizedKeys: {}
        } as IProfileItem;
    },

    getTestsByModality(profileItems: IProfileItem[]): IModalityTests[] {
        const map = {
            SNAP: [] as IProfileItem[],
            REFERENCE_LAB: [] as IProfileItem[],
            REF_LAB_IHD: [] as IProfileItem[],
            IHD: [] as IProfileItem[]
        };
        if (profileItems) {
            for (const pi of profileItems) {
                if (ModalityEnum.SNAP.equals(pi.modality)) {
                    map.SNAP.push(pi);
                } else if (ModalityEnum.IHD.equals(pi.modality)) {
                    map.IHD.push(pi);
                } else if (ModalityEnum.REF_LAB_IHD.equals(pi.modality)) {
                    map.REF_LAB_IHD.push(pi);
                } else {
                    map.REFERENCE_LAB.push(pi);
                }
            }
        }
        const result = [];
        if (map.REFERENCE_LAB.length) {
            result.push({
                modality: ModalityEnum.REF_LAB.value,
                tests: map.REFERENCE_LAB
            });
        }
        if (map.SNAP.length) {
            result.push({
                modality: ModalityEnum.SNAP.value,
                tests: map.SNAP
            });
        }
        if (map.IHD.length) {
            result.push({
                modality: ModalityEnum.IHD.value,
                tests: map.IHD
            });
        }
        if (map.REF_LAB_IHD.length) {
            result.push({
                modality: ModalityEnum.REF_LAB_IHD.value,
                tests: map.REF_LAB_IHD
            });
        }
        return result;
    },

    equalsProducts(p1: IProduct, p2: IProduct): boolean {
        if (!p1 && !p2) {
            return true;
        }
        if (!p1 || !p2) {
            return false;
        }
        if (p1.product_id || p2.product_id) {
            return p1.product_id === p2.product_id;
        }
        if (p1.test_code || p2.test_code) {
            return p1.test_code === p2.test_code;
        }
        if (p1.sap_material_number || p2.sap_material_number) {
            return p1.sap_material_number === p2.sap_material_number;
        }
        return false;
    },

    // If the specified profile item contains only a single product, return the test code from that product.
    // If more than one product is defined, then return null
    getSingleProductTestCode(profileItem: IProfileItem): string {
        if (profileItem && profileItem.products && profileItem.products.length === 1) {
            return profileItem.products[0].test_code;
        }
        return null;
    }
};

export interface IReplaceRule {
    replacement_rule_id?: number;
    profile_item_id?: number;
    from_product: IProduct;
    to_product: IProduct;
}
