import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

import { IProfileItem, PROFILE_ITEM_TYPES } from "@shared/model/profile-item";

@Component({
    selector: "pcc-test-comp",
    templateUrl: "./test-comp.component.html",
    styleUrls: [
        "./test-comp.component.scss"
    ]
})
export class TestComponent implements OnInit {

    @Input() public testComp: IProfileItem;

    @Input() public enableAddOn = false;

    @Input() public isSelected = false;

    @Input() public allowNone = true;

    @Output() public testClicked = new EventEmitter<{ test: IProfileItem, selected: boolean }>();

    public testStyle = "";

    public isAddOn = false;

    public ngOnInit(): void {
        if (this.testComp && this.testComp.test_type === PROFILE_ITEM_TYPES.ADD_ON) {
            this.testStyle = "add-on";
            this.isAddOn = true;
        }
    }

    public testCompClicked(): void {
        this.testClicked.emit({ test: this.testComp, selected: !this.isSelected });
    }

    public addOnClicked(): void {
        if (this.enableAddOn) {
            // Only fire if add on is enabled.
            this.testClicked.emit({ test: this.testComp, selected: !this.isSelected });
        } else {
            console.warn("Ignoring click on add on...");
        }
    }
}
