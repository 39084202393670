import { Injectable } from "@angular/core";

import { HttpClient } from "@angular/common/http";
import { Observable, firstValueFrom } from "rxjs";

import { PCCAlertService } from "./alert.service";
import { IAPIResponseData } from "@shared/model/service/service";
import { IAccountResponse } from "@shared/model/account-response";
import { AccountSettingsCreateRequest } from "@shared/model/account-settings";
import { ICustomPage } from "@shared/model/custom-page";
import { FileInfo } from "@shared/model/file";
import { SeismicThumbnail } from "@shared/model/seismic";
import { SettingsFlags, SettingsKey } from "@shared/model/settings-flags";
import { Language } from "@shared/model/language";
import { ErrorUtils } from "@shared/model/error/error-utils";

const enum API {
    getAccountSettings = "/api/admin/account-settings/",
    saveAccountSettings = "/api/admin/account-settings",
    createAccountSettings = "/api/admin/account-settings",
    saveCustomPage = "/api/admin/custom-page",
    saveImageFile = "/api/admin/image-file",
    getCustomPages = "/api/admin/custom-pages/",
    getSeismicThumbnail = "/api/seismic/thumbnail/",
    findAccount = "/api/admin/account/",
    saveSettingsFlags = "/api/admin/settings-flags",
    saveAdminFlag = "/api/admin/flag",
    getAdminFlags = "/api/admin/flags",
    saveLanguage = "/api/admin/language",
    deleteLanguage = "/api/admin/language/",
    getLanguages = "/api/admin/languages",
    saveCustomText = "/api/admin/custom-page",
}

@Injectable()
export class AdminService {

    public constructor(
        private http: HttpClient,
        private alertService: PCCAlertService
    ) {}

    public getAccountSettings(acctSettingsId?: number): Observable<IAccountResponse> {
        console.log(`getAccountSettings: ${acctSettingsId}`);
        let url: string = API.getAccountSettings;
        url = `${url}?id=${acctSettingsId}`;
        return this.http.get<IAccountResponse>(url);
    }

    public saveAccountSettings(formData: FormData): Observable<IAccountResponse> {
        console.log("api.saveAccountSettings");
        return this.http.put<IAccountResponse>(API.saveAccountSettings, formData);
    }

    public async createAccountSettings(createRequest: AccountSettingsCreateRequest): Promise<IAccountResponse> {
        console.log("api.createAccountSettings");
        return await firstValueFrom(
            this.http.post<IAccountResponse>(API.saveAccountSettings, createRequest)
        );
    }

    public async saveCustomPage(page: ICustomPage): Promise<IAPIResponseData<ICustomPage>> {
        console.log("saveCustomPage", page);
        const formData = new FormData();
        formData.append("page", JSON.stringify(page));

        if (page.images && page.images.some((file: FileInfo): boolean => file.uploadData != null)) {
            page.images.forEach((file: FileInfo): void => {
                if (file.uploadData) {
                    formData.append("images", <File>file.uploadData);
                }
            });
        }

        return await firstValueFrom(
            this.http.post<IAPIResponseData<ICustomPage>>(API.saveCustomPage, formData)
        );
    }

    public getCustomPages(acctSettingsId?: number): Promise<IAPIResponseData<ICustomPage[]>> {
        console.log(`getCustomPages: ${acctSettingsId}`);
        const url = API.getCustomPages + acctSettingsId;
        return this.http.get<IAPIResponseData<ICustomPage[]>>(url).toPromise();
    }

    public getSeismicThumbnail(contentId: string): Promise<IAPIResponseData<SeismicThumbnail>> {
        console.log(`getSeismicThumbnail: ${contentId}`);
        const url = API.getSeismicThumbnail + contentId;
        return this.http.get<IAPIResponseData<SeismicThumbnail>>(url).toPromise();
    }

    public findAccount(acctSettingsId?: number): Observable<IAccountResponse> {
        console.log(`api.findAccount: ${acctSettingsId}`);
        return this.http.post<IAccountResponse>(API.findAccount,
            {
                accountSettingsId: acctSettingsId
            });
    }

    public getAllAccountSettings(): Observable<IAccountResponse> {
        console.log("api.getAllAccountSettings");
        return this.http.get<IAccountResponse>(API.getAccountSettings);
    }

    public async saveSettingsFlags(accountSettingsId: number, flags: SettingsFlags): Promise<IAPIResponseData<number[]>> {
        return await firstValueFrom(
            this.http.post<IAPIResponseData<number[]>>(API.saveSettingsFlags, { accountSettingsId, flags })
        );
    }

    public async updateFlag(accountSettingsId: number, flagName: SettingsKey, enabled: boolean): Promise<IAPIResponseData<number[]>> {
        const flags: SettingsFlags = {};
        flags[flagName] = enabled;

        try {
            this.alertService.setBusy(true, "Saving...");

            const resp = await this.saveSettingsFlags(accountSettingsId, flags);

            this.alertService.setBusy(false);

            if (resp) {
                this.alertService.showToast("Save sucessful");
                return resp;
            }

            this.alertService.showError("Error", "Error saving active status: ");

            return resp;
        } catch (err) {
            this.alertService.showError(ErrorUtils.getErrorMessage(err), "Error saving: ");
            throw err;
        }
    }

    public async saveLanguage(language: Language): Promise<IAPIResponseData<Language>> {
        console.log("saveLanguage: ", language);
        return await firstValueFrom(
            this.http.post<IAccountResponse>(API.saveLanguage, language)
        );
    }

    public async deleteLanguage(language: Language): Promise<IAPIResponseData<Language>> {
        console.log("deleteLanguage: ", language);

        // Set language to inactive rather than deleting.
        language.active = false;

        return await firstValueFrom(
            this.http.post<IAccountResponse>(API.saveLanguage, language)
        );
    }

    public async getLanguages(): Promise<IAPIResponseData<Language[]>> {
        return await firstValueFrom(
            this.http.get<IAccountResponse>(API.getLanguages)
        );
    }

    public async saveImageFile(file: FileInfo): Promise<IAPIResponseData<FileInfo>> {
        console.log("saveImageFile", file);
        const formData = new FormData();
        formData.append("file", JSON.stringify(file));

        if (file.uploadData != null) {
            formData.append("images", <File>file.uploadData);
        }

        return await firstValueFrom(
            this.http.post<IAPIResponseData<FileInfo>>(API.saveImageFile, formData)
        );
    }
}
