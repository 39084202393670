import { Component, OnInit, OnChanges } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

import { PCCClientError } from "@client/shared/model/pcc-client-error";
import { ModalityEnum } from "@shared/model/modality";
import { IAccountResponse } from "@shared/model/account-response";
import { IProfileTemplate, ProfileTemplate, TEMPLATE_TYPES, TemplateType } from "@shared/model/profile-template";
import { PricingMethods } from "@shared/model/price";
import { IAccountSettings } from "@shared/model/account-settings";
import { ISystemSettings } from "@shared/model/system-settings";
import { IAPIResponseData } from "@shared/model/service/service";

import { AdminFacade } from "@client/facade/admin.facade";
import { AppService } from "@client/service/app.service";
import { PCCTranslateService } from "../../../service/translate.service";

import { PCCAlertService } from "@client/service/alert.service";

@Component({
    selector: "pcc-account-templates",
    templateUrl: "./account-templates.component.html",
    styleUrls: [
        "./account-templates.component.scss"
    ]
})
export class AccountTemplatesComponent implements OnInit, OnChanges {

    public accountSettings: IAccountSettings;

    public templates: IProfileTemplate[] = [];

    public countryCd: string;

    public systemSettings: ISystemSettings;

    public speciesNames: string[];

    public selectedSpecies: string;

    public templateCards: Record<string, IProfileTemplate[]> = {
    };

    public selectedTemplate: IProfileTemplate;

    public constructor(
        public route: ActivatedRoute,
        private alertService: PCCAlertService,
        private adminFacade: AdminFacade,
        protected translateService: PCCTranslateService
    ) {}

    public async ngOnInit(): Promise<void> {
        console.log("account-template: ngOnInit");

        // Locally cached system settings is enough here.  We only need it for the latest version
        // of profile items.  If any changes have taken place there from the admin tool, the cached
        // system settings is getting the updated data.
        this.systemSettings = await this.adminFacade.getSystemSettingsCached();

        this.route.parent.data.subscribe((data): void => {
            console.log("Account resp data here: ", data);
            if (data.accountData) {
                const acctResp: IAccountResponse = data.accountData;
                if (acctResp.success === true) {
                    this.setAccountSettings(acctResp.accountSettings);
                }
            }
        });
    }

    public ngOnChanges(changes: any): void {
        console.log("ngOnChanges: ", changes);
        this.refreshTemplates();
    }

    private groupSpecies(): void {
        if (!this.templates) {
            return;
        }

        this.templateCards = {
        };

        this.templates.forEach((tpl: IProfileTemplate): void => {
            const speciesName = tpl.species.display_name;
            this.templateCards[speciesName] = this.templateCards[speciesName] || [];
            this.templateCards[speciesName].push(tpl);
        });

        this.sortCards();

        console.log("this.templateCards=", this.templateCards);
    }

    public configSlot(slot: IProfileTemplate): void {
        console.log("configSlot: ", slot);

        this.selectedTemplate = slot;

        slot.countryCd = this.countryCd;
    }

    public addNewTemplate(): void {
        console.log("addNewTemplate");

        this.selectedTemplate = this.genNewSlot();
    }

    public async deleteSlot(template: IProfileTemplate): Promise<void> {
        console.log("deleteSlot template=", template);

        try {
            const acctSettingsId = template.account_settings_id;

            const resp = await this.adminFacade.deleteProfileTemplate(template);
            console.log("deleteProfileTemplate results: ", resp);
            if (resp.success === true) {
                this.templates = this.templates.filter((listTemplate: IProfileTemplate): boolean =>
                    listTemplate.template_profile_id !== template.template_profile_id
                );

                if (acctSettingsId) {
                    console.log("Refreshing account settings...");
                    const session = this.adminFacade.getSession();
                    session.accountSettings = await this.adminFacade.getAccountSettings(acctSettingsId);
                }
                this.groupSpecies();
            }
        } catch (err) {
            console.error("Error deleting test: ", err);
        }
    }

    public slotCancelled(slot: IProfileTemplate): void {
        console.log("slotCancelled: ", slot);
        delete this.selectedTemplate;
    }

    public slotSaved(slot: IProfileTemplate): void {
        console.log("slotSaved: ", slot);
    }

    // How to optimize this so we don't have to go saving everything?
    // We don't have a parent object in some cases (system templates), so we'll need to make a call with all affected templates...
    public reorderTemplates(templates: IProfileTemplate[]): IProfileTemplate[] {
        let order = 0;
        const updatedTemplates: IProfileTemplate[] = [];
        templates.forEach((tpl: IProfileTemplate): void => {
            if (tpl.display_order !== order) {
                tpl.display_order = order;
                updatedTemplates.push(tpl);
            }

            order += 1;
        });
        return updatedTemplates;
    }

    private async saveTemplateOrder(tplList: IProfileTemplate[]): Promise<void> {
        try {
            this.alertService.setBusy(true, "Updating template order...");
            let hasError = false;

            const updatePromises: Promise<IAPIResponseData<IProfileTemplate>>[] = [];
            tplList.forEach((template: IProfileTemplate): void => {
                updatePromises.push(this.saveProfileTemplate(template));
            });

            const updateResults = await Promise.all(updatePromises);
            updateResults.forEach((result: IAPIResponseData<IProfileTemplate>): void => {
                console.log("saveCustomPageResp=", result);
                if (result.success !== true) {
                    console.error("Save failed: ", result);
                    this.alertService.showError("Save failed");
                    hasError = true;
                }
            });

            if (hasError === false) {
                this.alertService.showToast("Template order updated");
            }
        } catch (err) {
            console.error("Error saving template order: ", err);
        } finally {
            console.log("Done saving!");
            this.alertService.setBusy(false);
        }
    }

    private async saveProfileTemplate(tpl: IProfileTemplate): Promise<IAPIResponseData<IProfileTemplate>> {
        try {
            const resp = await this.adminFacade.saveProfileTemplate(tpl);
            if (!resp || resp.success !== true) {
                console.error("Save failed: ", resp);
            }
            return resp;
        } catch (err) {
            console.error("Error saving profile template: ", err);
            return {
                success: false,
                error: new PCCClientError("SYSTEM_ERROR", "Error thrown calling adminFacade.saveProfileTemplate", err)
            };
        }
    }

    public cardsReordered(newCards: IProfileTemplate[]): void {
        const templates = this.reorderTemplates(newCards);
        this.saveTemplateOrder(templates);
    }

    private genNewSlot(): IProfileTemplate {
        const newSlot = ProfileTemplate.newInstance();
        this.translateService.setLocalizedText(newSlot.localizedKeys, "displayName", "New Profile Template", this.accountSettings.supportedLocales);
        newSlot.countryCd = this.countryCd;
        newSlot.account_settings_id = this.accountSettings.account_settings_id;
        newSlot.modality = ModalityEnum.REF_LAB.getModality(this.systemSettings.modalities);
        if (this.accountSettings.pricingMethod === PricingMethods.STATIC) {
            newSlot.templateType = <TemplateType>TEMPLATE_TYPES.STATIC;
        }
        console.log("newSlot=", newSlot);
        return newSlot;
    }

    // Sort template cards by template_type, display_order and display_name;
    private sortCards(): void {
        Object.keys(this.templateCards).forEach((speciesName: string): void => {
            this.templateCards[speciesName].sort((t1: IProfileTemplate, t2: IProfileTemplate): number => {
                if (t1.templateType !== t2.templateType) {
                    // Preconfig templates should be first in list
                    return (t1.templateType === TEMPLATE_TYPES.PRECONFIG) ? -1 : 1;
                }
                if (t1.display_order !== t2.display_order) {
                    return t1.display_order - t2.display_order;
                }
                return 0;
            });
        });
    }

    private setAccountSettings(accountSettings: IAccountSettings): void {
        this.accountSettings = accountSettings;

        this.refreshTemplates();
    }

    private async refreshTemplates(): Promise<void> {
        this.templates = this.accountSettings.profileTemplates;
        this.countryCd = this.accountSettings.countryCd;

        const species = this.systemSettings.species;

        this.speciesNames = AppService.getSpeciesNames(species);
        this.speciesNames = this.speciesNames.sort();

        this.selectedSpecies = this.speciesNames[0];

        this.groupSpecies();
    }
}
