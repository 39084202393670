<div class="spot-modal">
  <div class="spot-modal__header">
    <div class="spot-modal__titles">
      <div class="pcc-confirmation-header">
	    {{title | translate}}
      </div>
    </div>
    <button type="button" *ngIf="closable" type="button" class="spot-modal__header-cancel-button" aria-label="Close" (click)="activeModal.dismiss('Close click')">
      <svg class="spot-icon spot-modal__header-cancel-button-icon" aria-labelledby="title">
        <title>cancel</title>
        <use xlink:href="/assets/spot-library/dist/symbols/spot_symbols.svg#cancel"></use>
      </svg>
    </button>
  </div>
  
  <div class="pcc-confirmation-body">{{body | translate:param}}</div>
  <div class="spot-modal__footer">
    <button *ngIf="cancelButton" class="spot-modal__footer-cancel-button" (click)="onButtonClick('cancelButton')">{{ cancelButton.text | translate }}</button>
    <button class="spot-button" [class]="button1.cls" (click)="onButtonClick('button1')">{{ button1.text | translate }}</button>
    <button *ngIf="button2" class="spot-button" [class]="button2.cls" (click)="onButtonClick('button2')">{{ button2.text | translate }}</button>
  </div>
</div>
