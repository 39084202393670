<pcc-popover>
  <a class="spot-link" spot-popover-id="addSettingsPopover" id="add-account-settings-button">
    <svg class="spot-icon spot-link__icon spot-link__icon--left" aria-labelledby="title">
      <title>add</title>
      <use xlink:href="/assets/spot-library/dist/symbols/spot_symbols.svg#add"></use>
    </svg>
    Add a group or country
  </a>
  <div #addSettingsPopover id="addSettingsPopover" class="spot-popover spot-popover--bottom-right" style="padding:0px;">
    <button class="pcc-cancel-modal-button" spot-popover-cancel (click)="cancel()">
      <svg class="spot-icon spot-modal__header-cancel-button-icon" aria-labelledby="title">
        <title>cancel</title>
        <use xlink:href="/assets/spot-library/dist/symbols/spot_symbols.svg#cancel"></use>
      </svg>
    </button>
    <div class="create-settings-body">
      <form #createSettingsForm="ngForm" class="create-settings-form">
        <div class="spot-form__field-group spot-form__field-group--horizontal">
          <fieldset class="spot-form__radio-group" role="radiogroup">
            <div class="spot-form__radio-group-inner">
              <label *ngFor="let acctSettingType of acctSettingTypes" class="spot-form__radio" [for]="'type_' + acctSettingType">
                <input [id]="'type_' + acctSettingType" name="selectedAccountSettingType" [value]="acctSettingType" type="radio" class="spot-form__radio-input" [(ngModel)]="selectedType" (change)="accountSettingTypeSelected(acctSettingType)"/>
                <span class="spot-form__radio-inner">
                  <span class="spot-form__radio-visual"></span>
                  <span class="spot-form__radio-label ">
                    {{acctSettingType}}
                  </span>
                </span>
              </label>
            </div>
          </fieldset>
        </div>

        <div class="create-forms">
          <section *ngIf="selectedType==='Country'">
            <div class="spot-form__field-group">
              <label class="spot-form__label" for="countryCd">Country <span class="field-required">*</span></label>
              <div class="spot-form__select">
                <select name="countryCd" class="spot-form__select-input" aria-label="Country Code" [(ngModel)]="selectedCountry" (change)="countrySelected()" required>
                  <option class="spot-form__select-option" value=""></option>
                  <option *ngFor="let country of countryList" [value]="country.code" class="spot-form__select-option">{{country.code}} - {{country.name}}</option>
                </select>
                <div class="spot-form__select-inner"></div>
                <span class="spot-form__select-open-indicator">
                  <svg class="spot-icon spot-form__select-open-icon" aria-labelledby="title">
                    <title>caret-down</title>
                    <use xlink:href="/assets/spot-library/dist/symbols/spot_symbols.svg#caret-down"></use>
                  </svg>
                </span>
              </div>
            </div>
          </section>
          <section *ngIf="selectedType==='Corporate Account'">
            <div class="spot-form__field-group">
              <label class="spot-form__label" for="corpSapId">Parent Corporate Account SAP Id</label>
              <input type="text" class="spot-form__input" name="corpSapId" [(ngModel)]="corpSapId" (change)="corpSapIdSelected()" />
            </div>

          </section>
          <section *ngIf="selectedType==='Buying Group'">
            <div class="spot-form__field-group">
              <label class="spot-form__label" for="buyingGroup">Buying Group</label>
              <div class="spot-form__select">
                <select name="buyingGroup" class="spot-form__select-input" id="buyingGroup" [(ngModel)]="selectedBuyingGroup" (change)="buyingGroupSelected()" >
                  <option class="spot-form__select-option" value=""></option>
                  <option class="spot-form__select-option" *ngFor="let bg of buyingGroups" [value]="bg">{{bg.name}}</option>
                </select>
                <div class="spot-form__select-inner"></div>
                <span class="spot-form__select-open-indicator">
                  <svg class="spot-icon spot-form__select-open-icon" aria-labelledby="title">
                    <title>caret-down</title>
                    <use xlink:href="/assets/spot-library/dist/symbols/spot_symbols.svg#caret-down"></use>
                  </svg>
                </span>
              </div>
            </div>
          </section>
        </div>

      </form>
      <div class="button-panel">
        <button type="cancel" class="spot-button spot-button--link" spot-popover-cancel (click)="cancel()">Cancel</button>
        <button type="submit" class="spot-button spot-button--primary" (click)="next()">Next</button>
      </div>
    </div>
  </div>
</pcc-popover>
