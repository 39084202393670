import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { SystemLanguagesComponent } from "./views/system-languages/system-languages.component";
import { ProfileItemsComponent } from "./views/profile-items/profile-items.component";
import { UserSettingsComponent } from "./views/user-settings/user-settings.component";
import { BuyingGroupsComponent } from "./views/buying-groups/buying-groups.component";

import { AccountSearchComponent } from "./views/account-search/account-search.component";
import { AccountSettingsComponent } from "./views/account-settings/account-settings.component";
import { AccountSettingsGeneralComponent } from "./views/account-settings-general/account-settings-general.component";
import { AccountTemplatesComponent } from "./views/account-templates/account-templates.component";
import { AccountSettingsEnrollmentPagesComponent } from "./views/account-settings-enrollment-pages/account-settings-enrollment-pages.component";
import { AccountSettingsLandingPagesComponent } from "./views/account-settings-landing-pages/account-settings-landing-pages.component";
import { EnrollmentSearchComponent } from "./views/enrollment-search/enrollment-search.component";
import { EnrollmentEditComponent } from "./views/enrollment-edit/enrollment-edit.component";

import { AccountGuard } from "./guard/account.guard";
import { RoleGuardService } from "../auth/role.guard";
import { accountSettingsResolver } from "../service/account-settings-resolver.service";
import { AdminLayoutComponent } from "./layout/admin-layout/admin-layout.component";
import { PricingOutputComponent } from "./views/pricing-output/pricing-output.component";

const data = {
    expectedRole: "PCC_ADMIN"
};

const routes: Routes = [
    {
        path: "admin",
        component: AdminLayoutComponent,
        data: {
            expectedRole: "PCC_ADMIN"
        },
        canActivate: [
            RoleGuardService
        ],
        children: [
            {
                path: "config/search",
                data,
                component: AccountSearchComponent
            },
            {
                path: "config/:id",
                component: AccountSettingsComponent,
                data,
                resolve: {
                    accountData: accountSettingsResolver
                },
                children: [
                    {
                        path: "general",
                        component: AccountSettingsGeneralComponent,
                        canActivate: [
                            AccountGuard
                        ]
                    },
                    {
                        path: "templates",
                        component: AccountTemplatesComponent,
                        canActivate: [
                            AccountGuard
                        ],
                    },
                    {
                        path: "enroll-pages",
                        component: AccountSettingsEnrollmentPagesComponent,
                        canActivate: [
                            AccountGuard
                        ]
                    },
                    {
                        path: "learning-pages",
                        component: AccountSettingsLandingPagesComponent,
                        canActivate: [
                            AccountGuard
                        ]
                    },
                    {
                        path: "profile-items",
                        component: ProfileItemsComponent,
                        canActivate: [
                            AccountGuard
                        ]
                    },
                ]
            },
            {
                path: "enrollment/search",
                data,
                component: EnrollmentSearchComponent
            },
            {
                path: "enrollment/:id",
                data,
                component: EnrollmentEditComponent
            },
            {
                path: "user",
                data,
                component: UserSettingsComponent
            },
            {
                path: "buying-groups",
                data,
                component: BuyingGroupsComponent
            },

            {
                path: "languages",
                data,
                component: SystemLanguagesComponent
            },
            {
                path: "pricing-output",
                data,
                component: PricingOutputComponent
            },
            {
                path: "",
                redirectTo: "config/search",
                pathMatch: "full"
            }
        ]
    }
];

@NgModule({
    imports: [
        RouterModule.forChild(routes)
    ],
    exports: [
        RouterModule
    ]
})
export class AdminRoutingModule {}
