import { Component } from "@angular/core";

@Component({
    selector: "pcc-home",
    templateUrl: "./home.component.html",
    styleUrls: [
        "./home.component.scss"
    ]
})
export class HomeComponent {
}
