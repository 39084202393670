import { Component, EventEmitter, Input, Output } from "@angular/core";
import { IProfileTemplate } from "@shared/model/profile-template";

@Component({
    selector: "pcc-account-template-list-item",
    templateUrl: "./account-template-list-item.component.html",
    styleUrls: [
        "./account-template-list-item.component.scss"
    ]
})
export class AccountTemplateListItemComponent {

    @Input() public slot: IProfileTemplate;

    @Input() public selected = false;

    @Output() public slotClick = new EventEmitter<IProfileTemplate>();

    public slotClicked(): void {
        this.slotClick.emit(this.slot);
    }

}
