import { NgModule } from "@angular/core";
import { HttpClientModule } from "@angular/common/http";

import { AppService } from "./service/app.service";

// export function init_app(appService: AppService) {
//     return () => {
//         return appService.getAppProperties();
//     }
// }

@NgModule({
    imports: [
        HttpClientModule
    ],
    providers: [
        AppService,
        // {
        //     provide: APP_INITIALIZER,
        //     useFactory: init_app,
        //     deps: [AppService],
        //     multi: true
        // }
    ]
})
export class AppLoadModule { }
