<div class="pcc-edit-panel" *ngIf="selectedPage">
  <div class="pcc-edit-panel__header">
    <span class="spot-typography__heading--level-3">Page: Test Mix</span>
    <input type="checkbox" class="spot-toggle" id="use_test_mix" name="use_test_mix" [(ngModel)]="accountSettings.flags.use_test_mix" (change)="flagToggled($event)">
    <label class="spot-toggle-label" for="use_test_mix">Enabled</label>
  </div>
  <div class="pcc-edit-panel__content" style="padding: 1em;">
    <div class="spot-form__field-group" style="display:flex;flex-direction:column;">
      <input type="checkbox" class="spot-toggle" id="test_mix_show_price" name="test_mix_show_price" [(ngModel)]="accountSettings.flags.test_mix_show_price" (change)="flagToggled($event)">
      <label class="spot-toggle-label" for="test_mix_show_price">Show Price</label>
    </div>
    <div class="spot-form__field-group" style="display:flex;flex-direction:column;">
      <input type="checkbox" class="spot-toggle" id="test_mix_show_special_price" name="test_mix_show_special_price" [(ngModel)]="accountSettings.flags.test_mix_show_special_price" (change)="flagToggled($event)">
      <label class="spot-toggle-label" for="test_mix_show_special_price">Show Special Price</label>
    </div>
  </div>
</div>
