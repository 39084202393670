import { AfterViewInit, Component, ElementRef, ViewChild, Input, Output, EventEmitter } from "@angular/core";

declare const spot$3: any; // eslint-disable-line @typescript-eslint/no-explicit-any

interface ISpotPopover {
    clickHandler: () => void;
    target: HTMLElement;
}

export interface IPopConfig {
    triggers?: HTMLElement[];
    defaultPositionClass?: string;
    autoPosition?: boolean;

    onOpen?: () => void;
    onClose?: () => void;

    autoPositionTopOffset?: number;
    autoPositionBottomOffset?: number;
    autoPositionLeftOffset?: number;
    autoPositionRightOffset?: number;
}

/**
 * Expanded version of Spot design system's popover component.
 * Popover from spot has trigger to open popover, but only closes on clicking outside popover.
 * Added cancel/close trigger.
 */
@Component({
    selector: "pcc-popover",
    templateUrl: "./popover.component.html",
    styleUrls: [
        "./popover.component.scss"
    ]
})
export class PopoverComponent implements AfterViewInit {
    @ViewChild("popoverWrapper", {
        static: false
    }) public popoverWrapper: ElementRef;

    @Input() public popConfig: IPopConfig;

    @Output() public closed = new EventEmitter<boolean>();

    private spotPopover: ISpotPopover;

    public ngAfterViewInit(): void {
        this.createSpotPopover();
    }

    // Create the popover via Spot
    private createSpotPopover(): void {
        const popConfig = this.popConfig || {
            triggers: this.popoverWrapper.nativeElement.querySelectorAll(`[spot-popover-id]`)
        };

        const popovers = this.popoverWrapper.nativeElement.getElementsByClassName("spot-popover");
        for (let i = 0; i < popovers.length; i++) {
            spot$3.Popover.create(popovers[i], popConfig);
        }

        this.initPopover();
    }

    private initPopover(): void {
        const pop = this.popoverWrapper.nativeElement.getElementsByClassName("spot-popover");
        if (!pop || pop.length === 0) {
            console.error("No spotPopover found", this.popoverWrapper);
            return;
        }

        this.spotPopover = pop[0].spotPopover;

        this.addCloseListener();
        this.addCancelListener();
    }

    private addCloseListener(): void {
        const closeTriggerList: NodeListOf<Element> = this.popoverWrapper.nativeElement.querySelectorAll("[spot-popover-close]");
        closeTriggerList.forEach((el: Element): void => {
            el.addEventListener("click", this.closeClicked.bind(this), false);
        });

    }

    private addCancelListener(): void {
        const cancelTriggerList: NodeListOf<Element> = this.popoverWrapper.nativeElement.querySelectorAll("[spot-popover-cancel]");
        cancelTriggerList.forEach((el: Element): void => {
            el.addEventListener("click", this.cancelClicked.bind(this), false);
        });

    }

    /**
     * Close popover if user clicks element with spot-popover-close attribute
     */
    public closeClicked(): void {
        console.log("closeClicked");

        this.spotPopover.clickHandler();

        this.closed.emit(true);
    }

    /**
     * Close popover if user clicks element with spot-popover-cancel attribute
     */
    public cancelClicked(): void {
        console.log("cancelClicked");

        this.spotPopover.clickHandler();

        this.closed.emit(false);
    }
}
