import { Component, Input, OnInit, OnChanges } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";

import { IAccountSettings } from "@shared/model/account-settings";

@Component({
    selector: "pcc-corp-logo",
    templateUrl: "./corp-logo.component.html",
    styleUrls: [
        "./corp-logo.component.scss"
    ]
})
export class CorpLogoComponent implements OnInit, OnChanges {
    @Input() public accountSettings: IAccountSettings;

    public logoImageUrl: any;

    public logoFilename: string;

    public fileData: File = null;

    public constructor(
        public domSanitizer: DomSanitizer
    ) {
    }

    public ngOnInit(): void {
        this.updateView();
    }

    public ngOnChanges(): void {
        this.updateView();
    }

    public onLogoSelect(fileInput: any): void {
        if (fileInput.target.files.length === 0) {
            console.warn("No logo selected");
            return;
        }

        this.fileData = <File>fileInput.target.files[0];
        console.log("fileData=", this.fileData);


        const reader = new FileReader();
        reader.onload = (): void => {
            // Convert file content to data URL
            const dataUrl: string = reader.result as string;
            // Sanitize the data URL
            this.logoImageUrl = this.domSanitizer.bypassSecurityTrustUrl(dataUrl);
        };
        reader.readAsDataURL(this.fileData);
    }

    public updateView(): void {
        if (this.accountSettings.logo) {
            try {
                const logoBuffer = this.accountSettings.logo;
                const typedArray = new Uint8Array(logoBuffer.data);
                const stringChar = String.fromCharCode.apply(null, typedArray);
                const base64String = btoa(stringChar);
                this.logoImageUrl = this.domSanitizer.bypassSecurityTrustUrl(`data:image/jpg;base64, ${base64String}`);
            } catch (err) {
                console.error("Error parsing logo image: ", err);
            }
        }
    }
}
