<div class="spot-modal training-mode-modal">
  <pcc-loading [isBusy]="isLoading" [busyMsg]="loadingMessage"></pcc-loading>

  <div class="spot-modal__header">
    <span class="spot-typography__heading--level-3">Training mode</span>
    <input type="checkbox" *ngIf="systemSettings" class="spot-toggle" name="trainingEnabled" id="trainingEnabled" [(ngModel)]="systemSettings.trainingEnabled" [readonly]="!allowTraining" (change)="saveTrainingEnabled()">
    <label class="spot-toggle-label" for="trainingEnabled">Enabled</label>
    <button class="spot-modal__header-cancel-button" (click)="cancel()">
      <svg class="spot-icon spot-modal__header-cancel-button-icon" aria-labelledby="title">
        <title>cancel</title>
        <use xlink:href="/assets/spot-library/dist/symbols/spot_symbols.svg#cancel"></use>
      </svg>
    </button>
  </div>
  <div class="spot-modal__content">

    <div style="display:flex;flex-direction:column;padding:10px;">
      <form #sysSettingsForm="ngForm" *ngIf="systemSettings" style="overflow:auto;flex:1;padding:5px;">
        <div *ngIf="!allowTraining">
          Training mode is only available in sub-Prod environments.
        </div>

        <div class="spot-form__field-group">
          <label class="spot-form__label" for="trainingSapIds" title=" Comma-separated list of SAP ids used to allow open access for all users in training. "> Training SAP IDs </label>
          <span class="spot-form__microcopy"  id="training-sap-help-text" >If multiple, separate by comma</span>
          <input type="text" class="spot-form__input" id="trainingSapIds" name="trainingSapIds" [(ngModel)]="trainingSapIds" (blur)="reformatSapList()" (input)="updateTrainingSapIds()" [readonly]="!allowTraining">
        </div>

      </form>
    </div>
  </div>
  <div class="spot-modal__footer">
    <button type="cancel" class="spot-modal__footer-cancel-button" (click)="cancel()">Cancel</button>
    <button type="submit" class="spot-button spot-button--primary" (click)="saveClicked()">Save</button>
  </div>
</div>
