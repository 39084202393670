import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

import { TrainingModeConfigComponent } from "../training-mode-config/training-mode-config.component";

export interface INavItem {
    displayName: string;
    key: string;
    url: string;
}

declare const spot$2: any;
declare const spot$3: any;
declare const spot$4: any;

@Component({
    selector: "pcc-admin-nav",
    templateUrl: "./admin-nav.component.html",
    styleUrls: [
        "./admin-nav.component.scss"
    ]
})
export class AdminNavComponent implements OnInit {

    public navItems: INavItem[] = [
        {
            key: "accountSearch",
            url: "/admin/config/search",
            displayName: "Group Configuration"
        },
        {
            key: "enrollments",
            url: "/admin/enrollment/search",
            displayName: "Enrollment Management"
        },
        {
            key: "languages",
            url: "/admin/languages",
            displayName: "Language and Translation"
        },
    ];

    public secondaryNavItems: INavItem[] = [
        {
            key: "userSettings",
            url: "/admin/user",
            displayName: "User Settings"
        },
        {
            key: "buyingGroups",
            url: "/admin/buying-groups",
            displayName: "Buying Groups"
        },
        {
            key: "pricing-output",
            url: "/admin/pricing-output",
            displayName: "Run pricing jobs"
        }
    ];

    @Input() public activeItem: string = "accountSearch";

    @Output() public navClick = new EventEmitter<INavItem>();

    public constructor(
        private modalService: NgbModal
    ) {}

    public ngOnInit(): void {
        spot$2.SplitButton.initialize();
        spot$3.Popover.initialize();
        spot$4.Panel.initialize();

        if (!this.activeItem && this.navItems.length) {
            this.activeItem = this.navItems[0].key;
        }
    }

    public navClicked(navItem: INavItem): void {
        this.activeItem = navItem.key;

        this.navClick.emit(navItem);
    }

    public trainingModeClicked(): void {
        console.log("showTrainingModeModal");
        this.modalService.open(TrainingModeConfigComponent, {
            size: "xl",
            windowClass: "modal-centered",
            backdrop: true,
            keyboard: true
        });
    }
}
