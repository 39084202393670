import { Component } from "@angular/core";
import { Router } from "@angular/router";

import { PCCAlertService } from "../../../service/alert.service";

import { Sort } from "@angular/material/sort";
import { AdminFacade } from "../../../facade/admin.facade";
import { IEnrollmentsRequest, IEnrollmentsResponse } from "@shared/model/service/enrollments-service";
import { IEnrollment } from "@shared/model/enrollment";
import { UtilService } from "@shared/service/util.service";

@Component({
    selector: "pcc-enrollment-search",
    templateUrl: "./enrollment-search.component.html",
    styleUrls: [
        "./enrollment-search.component.scss"
    ]
})
export class EnrollmentSearchComponent {
    public enrollments: IEnrollment[];

    public searchRequest: IEnrollmentsRequest = {
    };

    public sortedData: IEnrollment[] = [];

    private lastSort: Sort;

    public constructor(
        private router: Router,
        private adminFacade: AdminFacade,
        private alertService: PCCAlertService
    ) {
    }

    public enrollmentClicked(en: IEnrollment): void {
        console.log("enrollmentClicked: ", en);
        this.router.navigate([
            "/admin/enrollment/",
            en.enrollment_id
        ]);
    }

    public searchEnrollments(): void {
        console.log("searchEnrollments: ", this.searchRequest);

        this.alertService.setBusy(true, "Searching...");

        this.adminFacade.getEnrollments(this.searchRequest).subscribe(
            (enrollResp: IEnrollmentsResponse): void => {
                console.log("getEnrollments resp: ", enrollResp);
                this.alertService.setBusy(false);
                if (enrollResp.success) {
                    this.enrollments = enrollResp.enrollList || [];

                    if (this.enrollments.length === 1) {
                        this.enrollmentClicked(this.enrollments[0]);
                    } else {
                        this.sortedData = this.enrollments.slice();
                        this.resort();
                    }
                }
            },
            (err: Error): void => {
                console.log("Error calling getEnrollments: ", err);
                this.alertService.setBusy(false);
            });
    }

    private resort(): void {
        console.log("resort");
        if (this.lastSort) {
            this.sortData(this.lastSort);
        } else {
            this.sortData({
                active: "enrollmentDate", direction: "asc"
            });
        }
        console.log("lastSort not defined, not sorting...");
    }

    public sortData(sort: Sort): void {
        console.log("sortData: ", sort);
        const data = this.enrollments.slice();

        this.lastSort = sort;

        if (!sort.active || sort.direction === "") {
            this.sortedData = data;
            return;
        }

        this.sortedData = data.sort((a: IEnrollment, b: IEnrollment): number => {
            const isAsc = sort.direction === "asc";
            if (sort.active === "sapId") {
                return UtilService.compare(a.sap_id, b.sap_id, isAsc);
            }
            if (sort.active === "name") {
                return UtilService.compare(a.practiceName, b.practiceName, isAsc);
            }
            if (sort.active === "enrollmentDate") {
                return UtilService.compare(a.enrollmentDate.toString(), b.enrollmentDate.toString(), isAsc);
            }
            if (sort.active === "id") {
                return UtilService.compare(a.enrollment_id, b.enrollment_id, isAsc);
            }
            return 0;
        });
    }
}
