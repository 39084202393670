import { Component, OnInit, OnChanges } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { Router, ActivatedRoute } from "@angular/router";

import { ISystemSettings } from "@shared/model/system-settings";
import { IEnrollResponse } from "@shared/model/service/enroll-service";
import { PCCSession } from "@shared/model/pcc-session";
import { PCCAlertService } from "../../../service/alert.service";
import { IAccountResponse, ISessionCount } from "@shared/model/account-response";
import { Country } from "@shared/model/country";
import { IAccount } from "@shared/model/account";
import { IAccountSettings, StatusEnum } from "@shared/model/account-settings";
import { ConfirmSavePurge } from "../../../service/account.service";
import { ErrorUtils } from "@shared/model/error/error-utils";
import { AdminFacade } from "../../../facade/admin.facade";
import { PricingMethods } from "@shared/model/price";

@Component({
    selector: "pcc-account-settings-general",
    templateUrl: "./account-settings-general.component.html",
    styleUrls: [
        "./account-settings-general.component.scss"
    ]
})
export class AccountSettingsGeneralComponent implements OnInit, OnChanges {

    public accountSettings: IAccountSettings;

    public account: IAccount;

    public sessionCount: ISessionCount;

    public isCountrySettings: boolean = false;

    public countryList: Country[];

    public logoImageUrl: any;

    public logoFilename: string;

    public fileData: File = null;

    public pricingMethods: string[];

    public session: PCCSession;

    public formStyle: string;

    public words: string = "Hello there, this is a test";

    public systemSettings: ISystemSettings;

    public constructor(
        public router: Router,
        public route: ActivatedRoute,
        public adminFacade: AdminFacade,
        public domSanitizer: DomSanitizer,
        public alertService: PCCAlertService
    ) {
    }

    public async ngOnInit(): Promise<void> {
        console.log("ngOnInit");

        const session = this.adminFacade.getSession();
        this.session = session;

        this.systemSettings = await this.adminFacade.getSystemSettingsCached();

        this.route.parent.data.subscribe((data): void => {
            console.log("Account resp data here: ", data);
            if (data.accountData) {
                const acctResp: IAccountResponse = data.accountData;
                if (acctResp.success === true) {
                    this.setAccountData(acctResp.account, acctResp.accountSettings, acctResp.sessionCount);
                }
            }
        });

        // TODO: Need to allow "other" country code here when creating a new account settings.
        // This can be done either by explicitly adding the new country code to this list, or opening it way up and just display full list of all countries, not just ones we handle.
        this.countryList = this.systemSettings.countries;

        this.pricingMethods = Object.values(PricingMethods);

        this.updateView();
    }

    public onLogoSelect(fileInput: any): void {
        if (fileInput.target.files.length === 0) {
            console.warn("No logo selected");
            return;
        }

        this.fileData = <File>fileInput.target.files[0];
        console.log("fileData=", this.fileData);

    }

    public toggleShowPracticePrice(): void {
        console.log("toggleShowPracticePrice");
        this.accountSettings.flags.profile_show_practice_price = !this.accountSettings.flags.profile_show_practice_price;
    }

    public toggleShowPetOwnerPrice(): void {
        console.log("toggleShowPetOwnerPrice");
        this.accountSettings.flags.show_pet_owner_price = !this.accountSettings.flags.show_pet_owner_price;
    }

    public toggleShowFloorPrice(): void {
        console.log("toggleShowFloorPrice");
        this.accountSettings.flags.show_floor_price = !this.accountSettings.flags.show_floor_price;
    }

    public toggleAcctSettingsVisible(): void {
        console.log("toggleAcctSettingsVisible");
        this.accountSettings.visible = !this.accountSettings.visible;
    }

    public ngOnChanges(changes: any): void {
        console.log("Account general component changes: ", changes);

        if (!this.session || !this.accountSettings || !this.account) {
            console.log("All data not yet present, ignoring changes...");
            return;
        }
        this.updateView();
    }

    protected updateView(): void {
        console.log("updateView");

        if (this.accountSettings.status === "DRAFT") {
            this.formStyle = "draft";
        }

        this.isCountrySettings = !this.accountSettings.isCorp;

        if (this.accountSettings.logo) {
            try {
                const logoBuffer = this.accountSettings.logo;
                const typedArray = new Uint8Array(logoBuffer.data);
                const stringChar = String.fromCharCode.apply(null, typedArray);
                const base64String = btoa(stringChar);
                this.logoImageUrl = this.domSanitizer.bypassSecurityTrustUrl(`data:image/jpg;base64, ${base64String}`);
            } catch (err) {
                console.error("Error parsing logo image: ", err);
            }
        }


    }

    public advancedCollapsed: boolean = true;
    public advancedSettingsClicked(evt: any): void {
        console.log("advancedSettingsClicked: ", evt);
        this.advancedCollapsed = !this.advancedCollapsed;
    }

    protected getFormData(acctSettings: IAccountSettings, status?: StatusEnum): FormData {
        const formData = new FormData();
        formData.append("account", JSON.stringify(this.account));
        formData.append("accountSettings", JSON.stringify(acctSettings));
        if (status) {
            formData.append("status", status.value);
        }
        if (!this.isCountrySettings) {
            formData.append("file", this.fileData);
        }
        return formData;
    }

    public cancel(): void {
        console.log("close account settings for account: ", this.accountSettings.sap_id);
        this.adminFacade.clearSession();
        this.router.navigate([
            "/admin/config/search"
        ]);
    }

    public async onSubmit(): Promise<IAccountResponse> {
        console.log("onSubmit");

        const confirmSavePurge = await this.adminFacade.confirmSavePurge(this.accountSettings, this.sessionCount);

        if (confirmSavePurge === ConfirmSavePurge.CANCEL) {
            return null;
        }

        if (confirmSavePurge === ConfirmSavePurge.OK) {
            await this.purgePartialEnrollments();
        }

        const saveResp = await this.saveAccountSettings(this.accountSettings);
        console.log(`saveResp.success = ${saveResp.success}`);

        if (saveResp.success === true) {
            this.sessionCount = saveResp.sessionCount;
        }
        return saveResp;
    }

    public async saveAccountSettings(acctSettings: IAccountSettings, status?: StatusEnum): Promise<IAccountResponse> {
        console.log("saveAccountSettings: ", status, acctSettings);

        this.alertService.setBusy(true, "Saving...");

        const formData = this.getFormData(acctSettings, status);

        try {
            const saveResp: IAccountResponse = await this.adminFacade.saveAccountSettings(formData);
            console.log("saveAccountSettings saveResp: ", saveResp);

            this.alertService.setBusy(false);

            if (saveResp && saveResp.success === true) {
                this.alertService.showToast("Save successful");
                this.setAccountSettings(saveResp.accountSettings);
                this.sessionCount = saveResp.sessionCount;
            } else {
                const msg = `Error saving account settings: ${ErrorUtils.getErrorMessage(saveResp.error)}`;
                this.alertService.showError(msg);
            }
            return saveResp;
        } catch (err) {
            console.error("Error: ", err);
            const msg2 = `System Error saving account settings: ${ErrorUtils.getErrorMessage(err)}`;
            this.alertService.setBusy(false);
            this.alertService.showError(msg2);

            return {
                success: false, error: err
            };
        }
    }

    protected setAccountSettings(acctSettings: IAccountSettings): void {
        console.log("setAccountSettings: ", acctSettings);
        this.accountSettings = acctSettings;
    }

    public async promoteActive(): Promise<void> {
        console.log("promoteActive");

        const confirmSavePurge = await this.adminFacade.confirmSavePurge(this.accountSettings, this.sessionCount);
        if (confirmSavePurge === ConfirmSavePurge.OK) {
            await this.purgePartialEnrollments();
        }
        if (!confirmSavePurge
            || confirmSavePurge === ConfirmSavePurge.CANCEL) {
            console.log("Save canceled");
            return;
        }

        const saveResp = await this.saveAccountSettings(this.accountSettings, StatusEnum.ACTIVE);
        console.log("saveResp.success=", saveResp.success);

        if (saveResp.success === true) {
            this.sessionCount = saveResp.sessionCount;
        }
    }

    public async deleteDraft(): Promise<void> {
        console.log("deleteDraft");
        const saveResp = await this.saveAccountSettings(this.accountSettings, StatusEnum.INACTIVE);
        if (saveResp.success === true) {
            console.log("deleteDraft succeeded");
            this.cancel();
        } else {
            console.error("deleteDraft failed: ", saveResp);
        }
    }

    public async saveAsDraft(): Promise<void> {
        console.log("saveAsDraft");
        const saveResp = await this.saveAccountSettings(this.accountSettings, StatusEnum.DRAFT);
        if (saveResp.success === true) {
            console.log("saveAsDraft succeeded");
            this.router.navigate([
                "/admin/config"
            ]);
        } else {
            console.error("saveAsDraft failed: ", saveResp);
        }

    }

    protected setAccountData(acct: IAccount, acctSettings: IAccountSettings, sessionCount: ISessionCount): void {
        console.log("gen.setAccountData: ", acct, acctSettings, sessionCount);

        this.setAccountSettings(acctSettings);

        this.account = acct;
        this.sessionCount = sessionCount;

        if (this.accountSettings.status === "DRAFT") {
            this.formStyle = "draft";
        }

        this.isCountrySettings = !this.accountSettings.isCorp;
    }

    public async clearClicked(): Promise<void> {
        console.log("clearClicked");
        const confirmPurge = await this.adminFacade.confirmPurgeSessions();
        console.log(`confirmPurge = ${confirmPurge}`);
        if (confirmPurge === true) {
            await this.clearOldSessions();
        } else {
            console.log("Canceled");
        }
    }

    private async purgePartialEnrollments(): Promise<void> {
        let resp: IEnrollResponse;
        if (this.sessionCount.partial_session_count > 0) {
            resp = await this.clearOldSessions();
        }

        if (resp.success) {
            this.alertService.showToast("Partial sessions have been cleared.");
        } else {
            this.alertService.showError("Failed to clear sessions", ErrorUtils.getErrorMessage(resp.error));
        }
    }

    private async clearOldSessions(): Promise<IEnrollResponse> {
        console.log("clearOldSessions");
        this.alertService.setBusy(true, "Clearing sessions...");

        try {
            const resp = await this.adminFacade.clearOldSessions(this.accountSettings);
            console.log("clearOldSessions resp: ", resp);
            if (resp.success === true && resp.sessionCount) {
                this.sessionCount = resp.sessionCount;
                this.alertService.showToast("Session cleanup successful");
            } else {
                this.alertService.showError("Failed to clear sessions", ErrorUtils.getErrorMessage(resp.error));
            }
            this.alertService.setBusy(false);
            return resp;
        } catch (err) {
            console.error("Error clearing old sessions", err);
            this.alertService.setBusy(false);
            this.alertService.showError("Error clearing sessions", ErrorUtils.getErrorMessage(err));
            return {
                success: false,
                error: err
            };
        }
    }
}
