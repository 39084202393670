import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { AuthGuard } from "./auth/auth.guard";
import { RecentSearchesResolver } from "./resolver/recent-searches.resolver";
import { SAPSearchComponent } from "./client/views/sap-search/sap-search.component";

const routes: Routes = [
    {
        path: "search",
        component: SAPSearchComponent,
        canActivate: [
            AuthGuard
        ],
        resolve: {
            recentSearches: RecentSearchesResolver
        }
    },
    {
        path: "",
        redirectTo: "/search",
        pathMatch: "full"
    },
    {
        path: "home",
        redirectTo: "/search"
    }
];

const isIframe = window !== window.parent && !window.opener;

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            useHash: false,
            enableTracing: false,
            // Don't perform initial navigation in iframes
            initialNavigation: !isIframe ? "enabledBlocking" : "disabled"
        })
    ],
    exports: [
        RouterModule
    ]
})
export class AppRoutingModule { }
