import { Inject, Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { Subject } from "rxjs";
import { filter, takeUntil } from "rxjs/operators";
import { JwtHelperService } from "@auth0/angular-jwt";
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from "@azure/msal-angular";
import { AuthenticationResult, InteractionStatus, PopupRequest, RedirectRequest, InteractionRequiredAuthError, AccountInfo, InteractionType, EventMessage, BrowserAuthError } from "@azure/msal-browser";

import { envUtils } from "../globals/env";

import { IUser } from "@shared/model/user";
import { IAuthResp, IAuthInfo } from "@shared/model/service/auth-service";

import { NativeUtil } from "../utils/native";
import googleAnalytics from "../analytics/googleAnalytics";

const JWT_TOKEN = "jwt_token";
const USER_DATA = "user_data";

let DEFAULT_INTERACTION_TYPE = NativeUtil.getInteractionType();
console.log("DEFAULT_INTERACTION_TYPE=", DEFAULT_INTERACTION_TYPE);

@Injectable({
    providedIn: "root"
})
export class AuthService {

    /*
      jwtToken contains the salesforce refresh_token, access_token, instance_url that are needed to make back-end calls to Salesforce in addition to being used to confirm the user is logged in.
      The first time a user hits the site, they won't have the jwt.  Standard login process will be followed (oauth web app handshake with Salesforce for sso).
      As the user is using the app, the jwt will be updated to keep them logged in as long as they're using the app.  This is done by monitoring for 401 (unauthorized) errors, upon which it refreshes the jwt.
      When a user re-visits the site/refreshes the browser, the jwt will be passed to the backend in an attempt to re-login the user using the refresh_token from Salesforce.  If this works, they're returned an updated jwt and are considered logged in.
      If the refresh fails, the user is re-directed to the standard Salesforce SSO login page.
    */

    private jwtToken?: string; // jwt token

    private userData?: IUser;

    private permissions?: string[];

    public loggedIn = false;

    private readonly _destroying$ = new Subject<void>();

    public loginFailure?: Error;

    public constructor(
        @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
        private http: HttpClient,
        private router: Router,
        public authService: MsalService,
        private msalBroadcastService: MsalBroadcastService
    ) {
        console.log("AuthService constructor");

        window.addEventListener("storage", this.syncLogout);

        this.msalBroadcastService.msalSubject$
            .subscribe((result: EventMessage): void => {
                console.log("msal event message: ", result);

                if (result && result.error && result.error.name == "BrowserAuthError" && (<BrowserAuthError>result.error).errorCode == "popup_window_error") {
                    console.log("Login popup failed, possibly blocked.  Trying again via redirect...");
                    DEFAULT_INTERACTION_TYPE = InteractionType.Redirect;
                    msalGuardConfig.interactionType = DEFAULT_INTERACTION_TYPE;
                    this.loginWithRedirect();
                }
            });

    }

    public updateLoggedInStatus(): void {
        this.msalBroadcastService.inProgress$
            .pipe(
                filter((status: InteractionStatus): boolean => status === InteractionStatus.None),
                takeUntil(this._destroying$)
            )
            .subscribe((): void => {
                console.log("broadcast done here, accounts=", this.authService.instance.getAllAccounts(), ", activeAccount=", this.authService.instance.getActiveAccount());
                this.setLoggedIn();
                this.checkAndSetActiveAccount();
            });
    }

    public async login(): Promise<AuthenticationResult | null> {
        if (this.msalGuardConfig.interactionType == InteractionType.Popup) {
            return this.loginWithPopup();
        }
        this.loginWithRedirect();
        return null;

    }

    public getActiveAccount(): AccountInfo | null {
        return this.authService.instance.getActiveAccount();
    }

    public checkAndSetActiveAccount(): void {
        console.log("checkAndSetActiveAccount");
        /**
         * If no active account set but there are accounts signed in,
         * search for the correct account (using SSO_CLIENT_ID) and set
         * first account found to active account.
         */
        const accounts = this.authService.instance.getAllAccounts();
        let activeAccount = this.authService.instance.getActiveAccount();
        console.log("activeAccount=", activeAccount);

        if (!activeAccount && accounts.length > 0) {
            for (const acct of accounts) {
                const idTokenClaims: any = acct.idTokenClaims;
                if (idTokenClaims && idTokenClaims.aud == envUtils.SSO_CLIENT_ID) {
                    activeAccount = acct;
                    this.authService.instance.setActiveAccount(acct);
                    break;
                }
            }
            if (!activeAccount) {
                console.error("Didn't find correct account!");
                this.authService.instance.setActiveAccount(accounts[0]);
            }
        }
    }

    private setLoggedIn(): void {
        this.loggedIn = this.authService.instance.getAllAccounts().length > 0;
    }

    private async loginWithPopup(): Promise<AuthenticationResult> {
        console.log("loginWithPopup");
        let loginResp: AuthenticationResult;

        if (this.msalGuardConfig.authRequest) {
            loginResp = await this.authService.loginPopup({
                ...this.msalGuardConfig.authRequest
            } as PopupRequest).toPromise();
        } else {
            loginResp = await this.authService.loginPopup().toPromise();
        }

        this.authService.instance.setActiveAccount(loginResp.account);
        return loginResp;
    }

    private async loginWithRedirect(): Promise<boolean> {
        if (this.msalGuardConfig.authRequest) {
            await this.authService.loginRedirect({
                ...this.msalGuardConfig.authRequest
            } as RedirectRequest).toPromise();
        } else {
            await this.authService.loginRedirect().toPromise();
        }
        return true;
    }

    public logout(): void {
        this.authService.logout();
        this.doLogoutUser();
    }

    private doLogoutUser(): void {
        console.log("doLogoutUser");

        delete this.jwtToken;
        delete this.userData;
        this.removeTokens();

        window.localStorage.setItem("logout", Date.now().toString());

        this.router.navigate([
            "/home"
        ]);
    }

    private removeTokens(): void {
        localStorage.removeItem(JWT_TOKEN);
        localStorage.removeItem(USER_DATA);
    }

    public destroy(): void {
        this._destroying$.next(undefined);
        this._destroying$.complete();
    }

    /**
       * Similar to isLoggedIn, but only checks that the Azure authentication piece has completed.
       * Separate from the PCC authentication piec.
     */
    public hasAzureAccount(): boolean {
        let hasActiveAccount = false;
        if (this.authService.instance.getActiveAccount()) {
            hasActiveAccount = true;
        } else {
            console.log("Why no active account?", this.authService.instance.getAllAccounts());
        }

        return hasActiveAccount;
    }

    public isLoggedIn(): boolean {
        let isLoggedIn = false;

        const userData = this.userData;
        const jwtToken = this.getToken();

        if (userData == null) {
            console.log("No userData available, user is not logged in.");
            isLoggedIn = false;
        } else if (!userData.adUserId || !this.authService.instance.getActiveAccount()) {
            console.log("Azure user data not present, user is not logged in.");
            isLoggedIn = false;
        } else {
            const jwtHelper = new JwtHelperService();
            //console.log("Token expires: ", jwtHelper.getTokenExpirationDate(jwtToken));
            if (jwtHelper.isTokenExpired(jwtToken)) {
                console.log("jwt is expired, user is not logged in.");
                isLoggedIn = false;
            } else {
                //console.log("jwt is valid, user is logged in");
                isLoggedIn = true;
            }
        }

        //console.log("isLoggedIn:", isLoggedIn);
        return isLoggedIn;

    }

    public isLoggedOut(): boolean {
        return !this.isLoggedIn();
    }

    private setAuthSession(authResult: IAuthInfo): void {
        console.log("setAuthSession: ", authResult);

        const jwtToken = authResult.jwt_token,
            userData = authResult.user,
            perms = authResult.permissions,
            azureInfo = authResult.azureInfo;

        console.log("jwtToken=", jwtToken);
        console.log("azureInfo=", azureInfo);
        console.log("userData=", userData);

        localStorage.setItem(USER_DATA, JSON.stringify(userData));
        localStorage.setItem(JWT_TOKEN, JSON.stringify(jwtToken));
        this.jwtToken = jwtToken;
        this.userData = userData;

        this.permissions = perms;
    }

    public getToken(): string {
        let jToken = this.jwtToken;
        if (!this.jwtToken) {
            const token = localStorage.getItem(JWT_TOKEN);
            if (token && token != "undefined") { // TODO: Clean token
                //console.log("token=" + token);
                jToken = this.jwtToken = JSON.parse(token);
            }
        }
        return jToken;
    }

    public getUserDetails(): IUser {
        const userDataStr = localStorage.getItem(USER_DATA);
        return userDataStr ? JSON.parse(userDataStr) : null;
    }

    /*
     * User is currently not logged in.
     * If a refresh token is not available, show the standard login popup.
     * Else first try to login using the refresh token.
     * Failing that (refresh token is expired), fall back to standard login.
     */
    public async showLogin(targetUrl?: string): Promise<void> {
        console.log("showLogin: ", targetUrl);

        await this.msalLogin(targetUrl);
    }

    private async msalLogin(targetUrl?: string): Promise<void> {
        console.log("msalLogin", targetUrl);

        console.log("DEFAULT_INTERACTION_TYPE=", DEFAULT_INTERACTION_TYPE);

        const loginRequest = {
            scopes: [
                "User.Read"
            ]
            //scopes: ["User.Read", 'openid', 'profile']
        };
        if (this.hasAzureAccount()) {
            console.log("one");
            // If azure sso already taken care of, just silently acquire
            // the accessToken to be used elsewhere.
            const authResult = await this.acquireTokenSilent();
            if (authResult) {
                await this.handleAzureLoginResp(authResult, targetUrl);
            }
        } else if (DEFAULT_INTERACTION_TYPE == InteractionType.Popup) {
            console.log("two");
            await this.showAzureLogin(loginRequest, targetUrl);
        } else {
            console.log("three");
            await this.doLoginRedirect(loginRequest, targetUrl);
        }

    }

    public async showAzureLogin(loginRequest: any, targetUrl?: string): Promise<AuthenticationResult | null> {
        console.log("showAzureLogin", loginRequest);

        console.log("Show loginPopup...");

        try {
            const authResp: AuthenticationResult = await this.authService.loginPopup(loginRequest).toPromise();
            await this.handleAzureLoginResp(authResp, targetUrl);
            return authResp;
        } catch (error) {
            console.error("Error with login", error);
            return null;
        }
    }

    public async doLoginRedirect(loginRequest: any, targetUrl?: string): Promise<void> {
        console.log("doLoginRedirect", loginRequest);

        //TODO: Clean this up.
        if (this.msalGuardConfig.authRequest) {
            this.authService.loginRedirect({
                ...this.msalGuardConfig.authRequest
            } as RedirectRequest)
                .subscribe(
                    (): void => {
                        console.log("loginResp3=");

                    }, (err): void => {
                        console.error("Error redirect3: ", err);
                    });
            // console.log("loginResp from msal loginRedirect...", loginResponse);
            // await me.handleAzureLoginResp(loginResponse, targetUrl);
        } else {
            this.authService.loginRedirect().subscribe(
                (): void => {
                    console.log("loginResp2=");

                }, (err): void => {
                    console.error("Error redirect: ", err);
                }
            );
        }
    }

    /**
       * Once user has been authenticated with Azure, synch up with salesforce and PCC user data on backend.
     */
    public async handleAzureLoginResp(loginResponse: AuthenticationResult, targetUrl?: string): Promise<IAuthResp> {
        console.log("handleAzureLoginResp: ", loginResponse);
        console.log("targetUrl=", targetUrl);

        this.authService.instance.setActiveAccount(loginResponse.account);

        delete this.loginFailure;

        const authResp: IAuthResp = await this.http.post<IAuthResp>("/api/auth/azure", {
            accessToken: loginResponse.accessToken, idToken: loginResponse.idToken
        }).toPromise();
        console.log("authResp: ", authResp);
        if (authResp.success) {
            googleAnalytics.registerUser(authResp.authInfo.azureInfo.id);
            this.setAuthSession(authResp.authInfo);
            if (targetUrl) {
                console.log("refreshToken succeeded.  Redirecting to target url: ", targetUrl);
                this.router.navigate([
                    targetUrl
                ]);
            }
        } else {
            console.error("handleAzureLoginResp failed?", authResp.error);
            this.loginFailure = authResp.error;
            this.router.navigate([
                "login-failed"
            ]);
        }
        return authResp;
    }

    /*
     * User is currently not logged in.
     * If a refresh token is not available, show the standard login popup.
     * Else first try to login using the refresh token.
     * Failing that (refresh token is expired), fall back to standard login.
     */
    public async doLogin(targetUrl?: string): Promise<void> {
        console.log("doLogin: ", targetUrl);

        const jwtToken = this.getToken();

        const jwtHelper = new JwtHelperService();
        console.log("token decoded: ", jwtHelper.decodeToken(jwtToken));
        console.log("Token expires: ", jwtHelper.getTokenExpirationDate(jwtToken));
        if (!jwtToken) {
            console.warn("No jwtToken available, going standard login route...");
            await this.showLogin(targetUrl);
        } else if (!this.isLoggedIn()) {
            console.warn("User not logged in.  Show login prompt...");
            await this.showLogin(targetUrl);
        }
    }

    // Handles user logging out with multiple tabs open...
    private syncLogout(event: any): void {
        if (event.key === "logout") {
            console.log("logged out from storage!");
            //TODO: Router.push('/login')
        }
    }

    public hasAccess(permName: string): boolean {
        console.log(`hasAccess: ${permName}`);
        if (this.permissions
            && this.permissions.find((perm): boolean => perm == permName)) {
            console.log("hasAccess: ", permName, true);
            return true;
        }
        console.log("hasAccess: ", permName, false);
        return false;
    }

    public async acquireTokenSilent(): Promise<AuthenticationResult | void> {
        console.log("acquireTokenSilent");
        // MSAL.js v2 exposes several account APIs, logic to determine which account to use is the responsibility of the developer
        const account = this.authService.instance.getAllAccounts()[0];

        const accessTokenRequest = {
            scopes: [
                "User.Read"
            ],
            account
        };

        try {
            const accessTokenResponse: AuthenticationResult = await this.authService.instance.acquireTokenSilent(accessTokenRequest);
            console.log("accessTokenResponse=", accessTokenResponse);
            // Acquire token silent success
            //let accessToken = accessTokenResponse.accessToken;
            // Call your API with token
            return accessTokenResponse;
        } catch (error) {
            //Acquire token silent failure, and send an interactive request
            if (error instanceof InteractionRequiredAuthError) {
                if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
                    return this.acquireTokenSilentWithPopup(accessTokenRequest);
                }
                return this.acquireTokenSilentWithRedirect(accessTokenRequest);

            }
            console.error("Acquire token unknown error: ", error);
            throw error;

        }
    }

    public async acquireTokenSilentWithPopup(accessTokenRequest: any): Promise<AuthenticationResult> {
        try {
            const accessTokenResponse: AuthenticationResult = await this.authService.instance.acquireTokenPopup(accessTokenRequest);

            console.log("accessTokenResponse=", accessTokenResponse);
            return accessTokenResponse;
        } catch (error) {
            // Acquire token interactive failure
            console.error("Acquire token interactive failure: ", error);
            throw error;
        }
    }

    public acquireTokenSilentWithRedirect(accessTokenRequest: any): void {
        try {
            this.authService.instance.acquireTokenRedirect(accessTokenRequest);
        } catch (error) {
            // Acquire token interactive failure
            console.error("Acquire token interactive failure: ", error);
            throw error;
        }
    }
}
