import { Component, OnInit } from "@angular/core";

import { Sort } from "@angular/material/sort";
import { ErrorUtils } from "@shared/model/error/error-utils";
import { AdminFacade } from "../../../facade/admin.facade";
import { UtilService } from "@shared/service/util.service";

import { IUser } from "@shared/model/user";
import { IRole } from "@shared/model/role";
import { PCCAlertService } from "../../../service/alert.service";
import { IAdminSettingsResponse } from "@shared/model/service/admin-service";
import { SF_INSTANCE } from "@shared/model/salesforce";

@Component({
    selector: "pcc-user-settings",
    templateUrl: "./user-settings.component.html",
    styleUrls: [
        "./user-settings.component.scss"
    ]
})
export class UserSettingsComponent implements OnInit {

    public users: IUser[] = [];

    public roles: IRole[] = [];

    public sortedData: IUser[] = [];

    public lastSort: Sort;

    public selectedUser: IUser;

    public origUser: IUser;

    public userSfInstance: string;

    public saving: boolean;

    public constructor(
        private adminFacade: AdminFacade,
        private alertService: PCCAlertService
    ) {}

    public async ngOnInit(): Promise<void> {
        console.log("onInit...");
        try {
            const resp = await this.adminFacade.getUsers();
            console.log("Got users: ", resp);
            if (resp.success) {
                this.users = resp.users;

                this.sortedData = this.users.slice();
                this.resort();
            } else {
                console.error("Error retrieving users: ", resp);
            }
        } catch (err) {
            console.error("Error retrieving users2: ", err);
        }

        try {
            const roleResp = await this.adminFacade.getRoles();
            console.log("Got roles: ", roleResp);
            if (roleResp.success) {
                this.roles = roleResp.roles;
            } else {
                console.error("Error retrieving roles: ", roleResp);
            }
        } catch (err) {
            console.error("Error retrieving roles2: ", err);
        }

        console.log("onInit done.");
    }

    public configUser(user: IUser): void {
        console.log("configUser: ", user);
        this.origUser = user;
        this.selectedUser = JSON.parse(JSON.stringify(user));
        this.userSfInstance = (this.selectedUser) ? SF_INSTANCE[this.selectedUser.sfName] : "";
    }

    public isSelected(user: IUser): boolean {
        return (user && this.selectedUser && user.user_id === this.selectedUser.user_id);
    }

    public roleSelected(): void {
        console.log("roleSelected: ", this.selectedUser);
    }

    public compareRole(r1: IRole, r2: IRole): boolean {
        return r1 && r2 ? r1.role_id === r2.role_id : r1 === r2;
    }

    public async save(): Promise<void> {
        console.log("save user role", this.selectedUser);

        this.alertService.setBusy(true, "Saving...");

        this.saving = true;

        try {

            const saveResp: IAdminSettingsResponse = await this.adminFacade.saveUserRole(this.selectedUser);
            console.log("saveUserRole result: ", saveResp);

            this.alertService.setBusy(false);
            this.saving = false;

            if (saveResp.success && saveResp.user) {
                this.alertService.showToast("Save successful");
                this.origUser.role = saveResp.user.role;
            } else {
                this.alertService.showError("Error saving user", `${ErrorUtils.getErrorMessage(saveResp.error)}`);
            }
        } catch (err) {
            console.error("Error saving user: ", err);
            this.alertService.setBusy(false);
            this.alertService.showError("Error saving user", ErrorUtils.getErrorMessage(err));

            this.saving = false;
        }
    }

    public cancel(): void {
        // TODO: Edit on temp object.  After save, update local user.
        delete this.selectedUser;
    }

    public resort(): void {
        console.log("resort");
        if (this.lastSort) {
            this.sortData(this.lastSort);
        } else {
            this.sortData({
                active: "name", direction: "asc"
            });
        }
    }

    public sortData(sort: Sort): void {
        console.log("sortData: ", sort);
        const data = this.users.slice();

        this.lastSort = sort;

        if (!sort.active || sort.direction === "") {
            this.sortedData = data;
            return;
        }

        this.sortedData = data.sort((a: IUser, b: IUser): number => {
            const isAsc = sort.direction === "asc";
            try {
                if (sort.active === "name") {
                    return UtilService.compare(a.name, b.name, isAsc);
                }
                if (sort.active === "role") {
                    return UtilService.compare(this.getName(a, a.role), this.getName(b, b.role), isAsc);
                }
                if (sort.active === "sfUsername") {
                    return UtilService.compare(a.sfdcUsername, b.sfdcUsername, isAsc);
                }
                if (sort.active === "email") {
                    return UtilService.compare(a.email, b.email, isAsc);
                }
                if (sort.active === "profile") {
                    return UtilService.compare(this.getName(a, a.sfdcProfile), this.getName(b, b.sfdcProfile), isAsc);
                }
                return 0;
            } catch (err) {
                console.error("Error sorting: ", err);
                return 0;
            }
        });
    }

    private getName(obj: any, objChild: any): string {
        if (objChild) {
            return objChild.name;
        }
        console.warn("getName: Child is not defined: ", obj);
        return null;
    }
}
