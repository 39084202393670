import { Component, OnDestroy, OnInit } from "@angular/core";

import { Subscription } from "rxjs";

import { IPCCNavEvent, IProgressItem, NavService } from "../../../service/nav.service";
import googleAnalytics from "../../../analytics/googleAnalytics";

@Component({
    selector: "pcc-progress-bar",
    templateUrl: "./progress-bar.component.html",
    styleUrls: [
        "./progress-bar.component.scss"
    ]
})
export class ProgressBarComponent implements OnInit, OnDestroy {

    public activeState: IProgressItem;

    public states: IProgressItem[];

    private navStateSub: Subscription;

    public landingLink: string;

    public constructor(
        private navService: NavService
    ) {
    }

    public ngOnInit(): void {
        this.navStateSub = this.navService.getNavState().subscribe(
            (navEvent: IPCCNavEvent): void => {
                this.setNavInfo(navEvent);
            },
            (err: Error): void => {
                console.error("Error with nav state: ", err);
            }
        );
    }

    public ngOnDestroy(): void {
        console.log("ngOnDestroy");
        if (this.navStateSub) {
            this.navStateSub.unsubscribe();
        }
    }

    private setNavInfo(navEvent: IPCCNavEvent): void {
        console.log("progress-bar.setNavInfo: ", navEvent);

        if (!navEvent) {
            return;
        }

        if (navEvent.name === "session") {
            this.handleSessionEvent(navEvent);
        } else if (navEvent.name === "nextState") {
            this.handleNextStateEvent(navEvent);
        } else if (navEvent.currentState) {
            this.handleCurrentStateEvent(navEvent);
        }
    }

    private handleSessionEvent(navEvent: IPCCNavEvent): void {
        // New session started, clear out list of states for progress and start over.
        this.states = navEvent.states;
        this.landingLink = this.navService.landingLink;
        if (navEvent.currentState) {
            this.activeState = navEvent.currentState;
        }
    }

    private handleNextStateEvent(navEvent: IPCCNavEvent): void {
        const nextState = navEvent.nextState;
        if (nextState && this.states) {
            this.updateStateEnabledStatus(nextState);
        }

    }

    private handleCurrentStateEvent(navEvent: IPCCNavEvent): void {
        this.activeState = navEvent.currentState;
        if (navEvent.states) {
            this.states = navEvent.states;
            this.landingLink = this.navService.landingLink;
        }

    }

    private updateStateEnabledStatus(nextState: IProgressItem): void {
        const stateToUpdate = this.states.find((state: IProgressItem): boolean => state.link === nextState.link);

        if (stateToUpdate) {
            console.log("Setting state ", stateToUpdate, " enabled to: ", nextState.enabled);
            stateToUpdate.enabled = nextState.enabled;
        }
    }

    bookClick() {
        googleAnalytics.registerEvent("go_to_customer_engagement_page", "click", {
            current_page: this.navService.currentState.name
        });
    }

    breadcrumbClick(state: IProgressItem) {
        googleAnalytics.registerEvent("go_to_breadcrumb", "click", {
            current_page: this.navService.currentState.name,
            breadcrumb_link: state.name
        });
    }
}
