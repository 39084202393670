import { IPCCError, PCCError } from "@shared/model/error/";

export class PCCClientError extends PCCError implements IPCCError {
    public static readonly NAME: string = "PCCSystemError";

    constructor(code: string, msg?: string, err?: Error | null, context?: any | null) {
        super(code, msg, err, context, true);
        this.name = PCCClientError.NAME;
    }

    public toString(): string {
        return `PCCClientError(${this.code}, ${this.message}, ${this.name}, ${this.context}, ${this.sourceError}, ${this.stack})`;
    }
}
