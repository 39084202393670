import { Pipe, PipeTransform, ChangeDetectorRef, OnDestroy } from "@angular/core";
import { PCCTranslateService } from "../../service/translate.service";
import { Subscription } from "rxjs";

@Pipe({
    name: "customTranslate",
    pure: false // this is needed to update the value when the language changes
})
export class CustomTranslatePipe implements PipeTransform, OnDestroy {
    private lastKey: string;
    private onTranslationChange: Subscription;
    private onLangChange: Subscription;
    private onDefaultLangChange: Subscription;

    public constructor(
        private translateService: PCCTranslateService,
        private _ref: ChangeDetectorRef
    ) {
        this.subscribeToEvents();
    }

    transform(key: string, args?: any): any {
        const fallbackKey = args?.fallbackKey;
        const defaultValue = args?.defaultValue || "";
        const params = args?.params || {};

        const useKey = this.determineKey(key, fallbackKey);

        const translation = useKey ? this.getTranslation(useKey, params, defaultValue) : '';

        this.lastKey = useKey;

        return translation;
    }

    private determineKey(key: string, fallbackKey?: string): string {
        if ((!key || !this.hasTranslation(key)) && fallbackKey && this.hasTranslation(fallbackKey)) {
            return fallbackKey;
        }
        return key;
    }

    /**
     * Retrieves the translation of a given key.
     *
     * @param useKey - The key for which the translation is to be fetched.
     * @param params - The parameters to be passed for translation.
     * @param defaultValue - The default value to be used if the translation for the key doesn't exist.
     * @returns The translation of the given key if it exists, otherwise the default value.
     */
    private getTranslation(useKey: string, params: Object, defaultValue?: string): string {
        let translation: string;

        if (this.hasTranslation(useKey)) {
            translation = this.translateService.ngxTranslateService.instant(useKey, params);
        } else {
            // Call translate anyway to trigger missing translation message, but don't look at
            // returning value (which will match the key going in)
            this.translateService.ngxTranslateService.instant(useKey, params);
        }

        if (!translation && defaultValue) {
            translation = defaultValue;
        }

        return translation;
    }

    private markForCheck(): void {
        if (this.lastKey) {
            this._ref.markForCheck();
        }
    }

    private subscribeToEvents() {

        this.onTranslationChange = this.onTranslationChange || this.translateService.ngxTranslateService.onTranslationChange.subscribe(() => {
            this.markForCheck();
        });

        this.onLangChange = this.onLangChange || this.translateService.ngxTranslateService.onLangChange.subscribe(() => {
            this.markForCheck();
        });

        this.onDefaultLangChange = this.onDefaultLangChange || this.translateService.ngxTranslateService.onDefaultLangChange.subscribe(() => {
            this.markForCheck();
        });

    }

    public ngOnDestroy(): void {
        this._dispose();
    }

    private _dispose() {
        this.onTranslationChange?.unsubscribe();
        this.onLangChange?.unsubscribe();
        this.onDefaultLangChange?.unsubscribe();
    }

    private hasTranslation(key: string): boolean {
        return this.translateService.hasTranslation(key);
    }
}
