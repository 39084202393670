import { Component } from "@angular/core";

@Component({
    selector: "pcc-app-footer",
    templateUrl: "./app-footer.component.html",
    styleUrls: [
        "./app-footer.component.scss"
    ]
})
export class AppFooterComponent {

}
