<div class="mainLayout vbox">
  <div class="dog-background">
	<div class="sso-panel">

	  <div class="sapHeader">
		<div class="idexxLogo">
		    <img src="/assets/images/idexx_sap.png" class="headerLogo" alt="IDEXX"/>
		</div>
	  </div>
	  <div>
		<h1 style="font-size:12px;text-align:center;">IDEXX <strong>Preventive Care Simple Start</strong></h1>
	  </div>
	  <div class="sapInput">
		<h1>SSO Failed!</h1>
		User not logged in.<br/>

		<div *ngIf="errorMsg" class="alert alert-danger" role="alert" style="margin-top:20px;font-size:14px;">
		  <i class="fa-solid fa-exclamation-circle fa-lg" style="color:red;"></i> {{ errorMsg }}
		</div>

		<a href="/" class="spot-button spot-button--link">Retry</a>

	  </div>
	</div>
  </div>
</div>
