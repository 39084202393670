<div class="spot-modal corp-info2">
  <div class="spot-modal__header">
    <span class="corp-info-title">Account information</span>
    <button type="button" class="spot-modal__header-cancel-button" aria-label="Close" (click)="activeModal.dismiss('Close click')">
      <svg class="spot-icon spot-modal__header-cancel-button-icon" aria-labelledby="title">
        <title>cancel</title>
        <use xlink:href="/assets/spot-library/dist/symbols/spot_symbols.svg#cancel"></use>
      </svg>
    </button>
  </div>
  <div class="spot-modal__content" style="flex:1;display:flex;flex-direction:column;overflow:auto;margin:1em;">
    <div style="display:flex;flex-direction:row;">
      <span *ngIf="logoUrl" style="width:100px;height:100px;margin-right:10px;">
        <img [src]="logoUrl" [alt]="'account-info.logo_alt' | translate">
      </span>
      <div style="flex:1">
        <div *ngIf="accountInfo.corpParent">
          <strong>{{'account-info.corp_group_name' | translate}}</strong><br/>
          {{accountInfo.corpParent.name}}: SAP ID {{accountInfo.corpParent.sap_id}}<br/>
          {{accountInfo.corpParent.city}}, {{accountInfo.corpParent.state}}<br/>
          <br/><br/>
        </div>
        <div *ngIf="accountInfo.buyingGroup">
          <strong>{{'account-info.buying_group' | translate}}</strong><br/>
          {{accountInfo.buyingGroup.name}}
          <br/><br/>
        </div>
        <div>
          <strong>
            <span *ngIf="accountInfo.corpParent">
              {{'account-info.child_practice_name' | translate }}
            </span>
            <span *ngIf="!accountInfo.corpParent">
              {{'account-info.practice_name' | translate }}
            </span>
          </strong><br/>
          {{accountInfo.name}}: SAP ID {{accountInfo.sap_id}}<br/>
          {{accountInfo.city}}, {{accountInfo.state}}
        </div>
      </div>
    </div>

    <br/><br/>
    <span class="special-instructions-title">{{'account-info.special_handling_instructions' | translate }}</span>
    <div [innerHTML]="safeSpecialInstructions" class="special-instructions"></div>
  </div>

</div>
