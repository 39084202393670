import { IPCCError, PCCError } from "./pcc-error";

export class PCCSystemError<T = any> extends PCCError<T> implements IPCCError {
    public static readonly NAME: string = "PCCSystemError";

    constructor(code: string, msg?: string, err?: Error | null, context?: T | null) {
        super(code, msg, err, context, true);
        this.name = PCCSystemError.NAME;
    }
}
