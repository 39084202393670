import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from "@angular/core";

@Component({
    selector: "pcc-range-slider",
    templateUrl: "./range-slider.component.html",
    styleUrls: [
        "./range-slider.component.scss"
    ]
})
export class RangeSliderComponent implements OnInit, OnChanges {

    @Input() minValue: number = 0;

    @Input() maxValue: number = 10;

    @Input() stepValue: number = 1;

    @Input() lowValue: number = 0;

    @Input() midValue: number = 5;

    @Input() hiValue: number = 10;

    @Input() lowReadOnly: boolean = false;

    @Input() midReadOnly: boolean = false;

    @Input() hiReadOnly: boolean = false;

    @Output() hiValueChange = new EventEmitter<number>();

    @Output() midValueChange = new EventEmitter<number>();

    @Output() lowValueChange = new EventEmitter<number>();

    rangeMarginLeft: string = "0%";

    rangeColorWidth: string = "100%";

    ngOnInit(): void {
        console.log("ngOnInit");
        // console.log("this.minValue=", this.minValue);
        // console.log("this.maxValue=", this.maxValue);
        // console.log("this.stepValue=", this.stepValue);

        // console.log("this.lowValue=", this.lowValue);
        // console.log("this.midValue=", this.midValue);
        // console.log("this.hiValue=", this.hiValue);

        // console.log("this.lowReadOnly=", this.lowReadOnly);
        // console.log("this.midReadOnly=", this.midReadOnly);
        // console.log("this.hiReadOnly=", this.hiReadOnly);
    }

    ngOnChanges(changes: any) {
        console.log("ngOnChanges: ", changes);

        this.updateView();

    }

    updateView() {
        // console.log("this.lowValue=", this.lowValue);
        // console.log("this.hiValue=", this.hiValue);
        // console.log("this.maxValue=", this.maxValue);

        //Setting the margin left of the middle range color.
        //Taking the value of the lower value times 10 and then turning it into a percentage.
        this.rangeMarginLeft = `${(this.lowValue / this.maxValue) * 100}%`;
        //console.log("rangeMarginLeft=" + this.rangeMarginLeft);

        //Setting the width of the middle range color.
        //Taking the value of the upper value times 10 and subtracting the lower value times 10 and then turning it into a percentage.
        this.rangeColorWidth = `${((this.hiValue - this.lowValue) / this.maxValue) * 100}%`;
        //console.log("rangeColorWidth=" + this.rangeColorWidth);

    }


    //When the upper value slider is moved.
    hiValueChanged() {
        // console.log("hiValueChanged");
        // console.log("hiValue=", this.hiValue);
        // console.log("midValue=", this.midValue);

        if (this.hiValue < this.midValue) {
            //            console.log("Shifting!");
            this.midValue = this.hiValue;
        }

        //        console.log('upper value: ' + this.hiValue);

        this.hiValueChange.emit(this.hiValue);

        this.updateView();
    }

    //When the mid value slider is moved.
    midValueChanged() {
        //        console.log('mid value: ' + this.midValue);

        if (this.midValue < this.lowValue) {
            this.midValue = this.lowValue;
        }

        if (this.midValue > this.hiValue) {
            this.midValue = this.hiValue;
        }

        this.midValueChange.emit(this.midValue);

        this.updateView();
    }

    //When the lower value slider is moved.
    lowValueChanged() {
        // console.log("lowValueChanged");
        // console.log("lowValue=", this.lowValue);
        // console.log("midValue=", this.midValue);

        if (this.lowValue > this.midValue) {
            // console.log("Shifting 2!");
            this.midValue = this.lowValue;
        }

        this.updateView();

        // console.log('lower value: ' + this.lowValue);
        this.lowValueChange.emit(this.lowValue);

    }

}

// export class RangeModel {
//     private _minValue: number = 0;
//     get minValue() {
//         return this._minValue;
//     }
//     set minValue(val: number) {
//         this._minValue = val;
//         //TODO recalc
//     }

//     private _maxValue: number = 10;
//     get maxValue() {
//         return this._maxValue;
//     }
//     set maxValue(val: number) {
//         this._maxValue = val;
//         //TODO recalc
//     }

//     stepValue: number = 1;

//     private lowValue: number = 0;
//     private midValue: number = 5;
//     private hiValue: number = 10;

//     lowReadOnly: boolean = false;
//     midReadOnly: boolean = false;
//     hiReadOnly: boolean = false;
// }
