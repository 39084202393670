<div class="view-body" style="display:flex;flex-direction:column">
  <div class="enrolling" *ngIf="submitting || errorMsg">
    <div class="done-progress-container">
      <div class="done-progress-bar">
      </div>
      <div [ngClass]="getStepClass('send')" (click)="onClick('send')">
        <div class="center-background"></div>
        <div class="step step-background">
          <svg #sendIconsBG class="icon-background" viewBox="0 0 24 24">
            <g id="gear">
              <path d="M19.14,12.94c0.04-0.3,0.06-0.61,0.06-0.94c0-0.32-0.02-0.64-0.07-0.94l2.03-1.58c0.18-0.14,0.23-0.41,0.12-0.61 l-1.92-3.32c-0.12-0.22-0.37-0.29-0.59-0.22l-2.39,0.96c-0.5-0.38-1.03-0.7-1.62-0.94L14.4,2.81c-0.04-0.24-0.24-0.41-0.48-0.41 h-3.84c-0.24,0-0.43,0.17-0.47,0.41L9.25,5.35C8.66,5.59,8.12,5.92,7.63,6.29L5.24,5.33c-0.22-0.08-0.47,0-0.59,0.22L2.74,8.87 C2.62,9.08,2.66,9.34,2.86,9.48l2.03,1.58C4.84,11.36,4.8,11.69,4.8,12s0.02,0.64,0.07,0.94l-2.03,1.58 c-0.18,0.14-0.23,0.41-0.12,0.61l1.92,3.32c0.12,0.22,0.37,0.29,0.59,0.22l2.39-0.96c0.5,0.38,1.03,0.7,1.62,0.94l0.36,2.54 c0.05,0.24,0.24,0.41,0.48,0.41h3.84c0.24,0,0.44-0.17,0.47-0.41l0.36-2.54c0.59-0.24,1.13-0.56,1.62-0.94l2.39,0.96 c0.22,0.08,0.47,0,0.59-0.22l1.92-3.32c0.12-0.22,0.07-0.47-0.12-0.61L19.14,12.94z M12,15.6c-1.98,0-3.6-1.62-3.6-3.6 s1.62-3.6,3.6-3.6s3.6,1.62,3.6,3.6S13.98,15.6,12,15.6z"/>
            </g>
            <g id="circle" >
              <path d="M12,12m-0.05,0a0.05,0.05 0 1,0 0.10,0a0.05,0.05 0 1,0 -0.10,0"/>
            </g>
          </svg>
        </div>
        <svg #sendIcons class="icon-foreground" viewBox="0 0 24 24">
          <g id="start">
            <path d="M3.01 21L23 12 3.01 3 2 10l15 2-15 2z"/>
          </g>
          <g id="error">
            <path d="M12 5V1L7 6l5 5V7c3.31 0 6 2.69 6 6s-2.69 6-6 6-6-2.69-6-6H4c0 4.42 3.58 8 8 8s8-3.58 8-8-3.58-8-8-8z"/>
          </g>
          <g id="check">
            <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"/>
          </g>
          <g id="circle" >
            <path d="M12,12m-12,0a12,12 0 1,0 24,0a12,12 0 1,0 -24,0"/>
          </g>
        </svg>
      </div>
      <div [ngClass]="getStepClass('s3')" (click)="onClick('s3')">
        <div class="step step-background">
          <div class="center-background"></div>
          <svg #s3IconsBG class="icon-background" viewBox="0 0 24 24">
            <g id="gear">
              <path d="M19.14,12.94c0.04-0.3,0.06-0.61,0.06-0.94c0-0.32-0.02-0.64-0.07-0.94l2.03-1.58c0.18-0.14,0.23-0.41,0.12-0.61 l-1.92-3.32c-0.12-0.22-0.37-0.29-0.59-0.22l-2.39,0.96c-0.5-0.38-1.03-0.7-1.62-0.94L14.4,2.81c-0.04-0.24-0.24-0.41-0.48-0.41 h-3.84c-0.24,0-0.43,0.17-0.47,0.41L9.25,5.35C8.66,5.59,8.12,5.92,7.63,6.29L5.24,5.33c-0.22-0.08-0.47,0-0.59,0.22L2.74,8.87 C2.62,9.08,2.66,9.34,2.86,9.48l2.03,1.58C4.84,11.36,4.8,11.69,4.8,12s0.02,0.64,0.07,0.94l-2.03,1.58 c-0.18,0.14-0.23,0.41-0.12,0.61l1.92,3.32c0.12,0.22,0.37,0.29,0.59,0.22l2.39-0.96c0.5,0.38,1.03,0.7,1.62,0.94l0.36,2.54 c0.05,0.24,0.24,0.41,0.48,0.41h3.84c0.24,0,0.44-0.17,0.47-0.41l0.36-2.54c0.59-0.24,1.13-0.56,1.62-0.94l2.39,0.96 c0.22,0.08,0.47,0,0.59-0.22l1.92-3.32c0.12-0.22,0.07-0.47-0.12-0.61L19.14,12.94z M12,15.6c-1.98,0-3.6-1.62-3.6-3.6 s1.62-3.6,3.6-3.6s3.6,1.62,3.6,3.6S13.98,15.6,12,15.6z"/>
            </g>
            <g id="circle" >
              <path d="M12,12m-0.05,0a0.05,0.05 0 1,0 0.10,0a0.05,0.05 0 1,0 -0.10,0"/>
            </g>
          </svg>
        </div>
        <svg #s3Icons class="icon-foreground" viewBox="0 0 24 24">
          <g id="start">
            <path d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM14 13v4h-4v-4H7l5-5 5 5h-3z"/>
          </g>
          <g id="error">
            <path d="M12 5V1L7 6l5 5V7c3.31 0 6 2.69 6 6s-2.69 6-6 6-6-2.69-6-6H4c0 4.42 3.58 8 8 8s8-3.58 8-8-3.58-8-8-8z"/>
          </g>
          <g id="check">
            <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"/>
          </g>
          <g id="circle" >
            <path d="M12,12m-12,0a12,12 0 1,0 24,0a12,12 0 1,0 -24,0"/>
          </g>
        </svg>
      </div>
      <div [ngClass]="getStepClass('mail')" (click)="onClick('mail')">
        <div class="step step-background">
          <div class="center-background"></div>
          <svg #emailIconsBG class="icon-background" viewBox="0 0 24 24">
            <g id="gear">
              <path d="M19.14,12.94c0.04-0.3,0.06-0.61,0.06-0.94c0-0.32-0.02-0.64-0.07-0.94l2.03-1.58c0.18-0.14,0.23-0.41,0.12-0.61 l-1.92-3.32c-0.12-0.22-0.37-0.29-0.59-0.22l-2.39,0.96c-0.5-0.38-1.03-0.7-1.62-0.94L14.4,2.81c-0.04-0.24-0.24-0.41-0.48-0.41 h-3.84c-0.24,0-0.43,0.17-0.47,0.41L9.25,5.35C8.66,5.59,8.12,5.92,7.63,6.29L5.24,5.33c-0.22-0.08-0.47,0-0.59,0.22L2.74,8.87 C2.62,9.08,2.66,9.34,2.86,9.48l2.03,1.58C4.84,11.36,4.8,11.69,4.8,12s0.02,0.64,0.07,0.94l-2.03,1.58 c-0.18,0.14-0.23,0.41-0.12,0.61l1.92,3.32c0.12,0.22,0.37,0.29,0.59,0.22l2.39-0.96c0.5,0.38,1.03,0.7,1.62,0.94l0.36,2.54 c0.05,0.24,0.24,0.41,0.48,0.41h3.84c0.24,0,0.44-0.17,0.47-0.41l0.36-2.54c0.59-0.24,1.13-0.56,1.62-0.94l2.39,0.96 c0.22,0.08,0.47,0,0.59-0.22l1.92-3.32c0.12-0.22,0.07-0.47-0.12-0.61L19.14,12.94z M12,15.6c-1.98,0-3.6-1.62-3.6-3.6 s1.62-3.6,3.6-3.6s3.6,1.62,3.6,3.6S13.98,15.6,12,15.6z"/>
            </g>
            <g id="circle" >
              <path d="M12,12m-0.05,0a0.05,0.05 0 1,0 0.10,0a0.05,0.05 0 1,0 -0.10,0"/>
            </g>
          </svg>
        </div>
        <svg #emailIcons class="icon-foreground" viewBox="0 0 24 24">
          <g id="start">
            <path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z"/>
          </g>
          <g id="error">
            <path d="M12 5V1L7 6l5 5V7c3.31 0 6 2.69 6 6s-2.69 6-6 6-6-2.69-6-6H4c0 4.42 3.58 8 8 8s8-3.58 8-8-3.58-8-8-8z"/>
          </g>
          <g id="check">
            <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"/>
          </g>
          <g id="circle">
            <path d="M12,12m-12,0a12,12 0 1,0 24,0a12,12 0 1,0 -24,0"/>
          </g>
        </svg>
      </div>
    </div>
  </div>

  <div class="enrollment-complete" *ngIf="!submitting">
	<a (click)="homeClick()" [title]="'done.go-home' | translate" class="spot-link return-link">
      <svg class="pcc-spot-icon-large spot-icon" aria-labelledby="title">
        <title>Book</title>
        <use xlink:href="/assets/spot-library/dist/symbols/spot_symbols.svg#book"></use>
      </svg>
	  &nbsp;{{ 'done.return_home' | translate }}
	</a>
	<div class="complete-message">
	  <div *ngIf="!errorMsg" class="enroll-success">
		<div style="flex: 1;padding-right:1em;">
		  <div class="enrollment-header">
			<div class="pcc-level-2-10 enrollment-successful">{{'done.Enrollment_successful' | translate}}</div>
			<button #downloadButton type="button" class="spot-button spot-button--link" (click)="downloadClick()"><i class="fa-solid fa-file-pdf"></i> {{ 'done.view_summary' | translate }} </button>
		  </div>
		  <p class="confirmation-text spot-typography__heading--level-5">
			{{'done.confirmation_email_sent_to' | translate}} <span class="champ-email"> {{session?.enrollInfo.championEmail }} </span>
		  </p>
		  <p class="remember-text spot-typography__heading--level-5">
			{{'done.Please_remember_to_offer_assistance' | translate}}
		  </p>
		</div>
		<div style="display:flex;flex-direction:column;overflow:auto;margin-top:40px;">
		  <div class="pcc-level-3-10"> {{ 'review.summary' | translate }}</div>
		  <div style="display:flex; flex-direction: row;width:800px;"> <!-- top section -->
			<div style="flex:1;"> <!-- first column -->
			  <div class="pcc-level-5-10"> {{ 'review.champion_title' | translate }} </div>

			  <div class="form-group">
				<div class="form-readonly-value championname-value">{{enrollInfo?.championSalutation}} {{enrollInfo?.championFirstName}} {{enrollInfo?.championLastName}}</div>
				<div class="form-readonly-value championemail-value">{{enrollInfo?.championEmail}}</div>
			  </div>

			  <div class="pcc-level-5-10"> {{ 'review.program_dates' | translate }} </div>
			  <div class="form-group">
				<label class="spot-form__label" for="integrationDate">{{'review.Practice_integration_appointment' | translate}}</label>
				<div class="form-readonly-value integrationdate-value">{{formatDate(integrationAsDate)}}</div>
			  </div>
			  <div class="form-group" *ngIf="session?.accountSettings?.flags?.show_launch_calendar">
				<label class="spot-form__label" for="launchDate">{{'review.Launch_training_appointment' | translate}}</label>
				<div class="form-readonly-value launchdate-value">{{formatDate(launchAsDate)}}</div>
			  </div>
			  <div class="form-group" *ngIf="session?.accountSettings?.flags?.show_enrollment_end_date">
				<label class="spot-form__label" for="enrollmentEndDate">{{'review.Enrollment_end_date' | translate}}</label>
				<div class="form-readonly-value enrollmentenddate-value">{{formatDate(enrollmentEndAsDate)}}</div>
			  </div>
			</div>
			<div style="flex:1;"> <!-- second column -->
			  <div class="review-goal" *ngIf="session?.accountSettings?.flags?.page_enabled_goals">
				<div class="pcc-level-5"> {{ 'review.monthly_goal' | translate}} </div>
				<div class="review-goal-line"><span class="review-goal-quanty spot-typography__heading--level-1">{{ enrollInfo?.totalGoalQuantities }}</span> {{ 'review.target_runs_per_month' | translate }} </div>
			  </div>

			  <div class="pcc-level-5-10"> {{ 'review.idexx_partner' | translate }} </div>

			  <div class="form-group">
				<label class="spot-form__label" for="vdcName">{{'review.vdc' | translate}}</label>
				<div class="form-readonly-value vdcName-value">{{vdcUser.name}}</div>
			  </div>

			  <div class="form-group">
				<label class="spot-form__label" for="vdcEmail">{{'review.vdc_email' | translate}}</label>
				<div class="form-readonly-value vdcEmail-value">{{vdcUser.email}}</div>
			  </div>

			  <div class="form-group">
				<label class="spot-form__label" for="vdcPhone">{{'review.vdc_phone' | translate}}</label>
				<div class="form-readonly-value vdcPhone-value">{{vdcUser.phone}}</div>
			  </div>
			</div>
		  </div>

		  <div style="margin-top:4em;padding-left:0.15em;position:relative;flex:1;height:60%;">
			<pcc-profiles-tab-panel
			  [profiles]="profileCards"
			  [canSelect]="false"
			  [canEdit]="false"
			  >
			</pcc-profiles-tab-panel>
		  </div>
		</div>
		<div class="enroll-resources" *ngIf="hasResources">
		  <div class="spot-typography__heading--level-5">{{ 'done.sent_resources' | translate }}</div>
		  <ul>
			<li *ngFor="let doc of session?.enrollInfo.selectedDocuments"> {{ doc.desc }} </li>
		  </ul>
		</div>
	  </div>
	  <div *ngIf="errorMsg">
		<div class="enrollment-header">
		  <i class="fa-solid fa-exclamation-triangle" style="color:red;"></i>&nbsp;{{'done.Enrollment_failed' | translate}}
		</div>
		<div class="confirmation-text">
		  {{errorMsg}}
		</div>
		<div class="remember-text">
		  {{'done.error_notified' | translate}}
		</div>
	  </div>
	</div>
  </div>
</div>
