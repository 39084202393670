export class StatusEnum {
    private static ORDINAL_SEQUENCE = 0;

    public readonly ordinal: number;

    private constructor(public readonly displayName: string, public readonly value: string) {
        this.ordinal = StatusEnum.ORDINAL_SEQUENCE++;
    }

    public equals(status: string | StatusEnum): boolean {
        if (typeof status === "string") {
            return this.value === status;
        }
        if (status instanceof StatusEnum) {
            return status.value === this.value;
        }
        return false;
    }

    public toString(): string {
        return this.displayName;
    }

    public static ACTIVE = new StatusEnum("Active", "ACTIVE");

    public static DRAFT = new StatusEnum("Draft", "DRAFT");

    public static INACTIVE = new StatusEnum("Inactive", "INACTIVE");

    public static RETIRED = new StatusEnum("Retired", "RETIRED");

    // Static method to get an array of pseudo enum values
    public static getValues(): StatusEnum[] {
        return [
            StatusEnum.ACTIVE,
            StatusEnum.DRAFT,
            StatusEnum.INACTIVE,
            StatusEnum.RETIRED,
        ];
    }

    public static forString(value: string): StatusEnum | null {
        if (!value) {
            console.log("value is null, no match found");
            return null;
        }
        const found = StatusEnum.getValues().find((status: StatusEnum): boolean => status.equals(value));
        if (found) {
            return found;
        }

        console.warn("Invalid value type passed in.  Defaulting to ACTIVE Status...");
        return StatusEnum.ACTIVE;
    }
}

