import { MulterFile } from "./multer-file";

export const PARENT_TYPES = {
    ACCOUNT_SETTINGS: "ACCOUNT_SETTINGS",
    CUSTOM_PAGE: "CUSTOM_PAGE"
} as const;

export type ParentType = keyof typeof PARENT_TYPES;

export interface FileInfo {
    id?: number;

    clientId?: string;

    title?: string;

    description?: string;

    // file name
    name?: string;

    // s3 bucket name/key
    location?: string;

    // Signed url to file.
    url?: string;

    // file mime type
    contentType?: string;

    contentLength?: number;

    // Parent record of this file.
    parentType?: ParentType;

    // Parent record id
    parentId?: number

    // Used when multiple files for a parent need an order
    displayOrder?: number;

    locale?: string;

    // Used to locate an uploaded file by "tag name" rather than file name, which must be unique.
    developer_name?: string;

    active?: boolean;

    // Downloaded file data
    data?: string | Buffer;


    // Uploaded file data
    uploadData?: File | MulterFile;

    createdOn?: Date;
    createdBy?: number;

    updatedOn?: Date;
    updatedBy?: number;
}
