<div class="page-header">
  <div>
    <span class="spot-typography__heading--level-2">Group Configuration</span>
    <div>Select a group or country to manage specific actions and configurations related to it.</div>
  </div>
</div>

<div class="page-body">
  <div class="page-section" style="display:block;padding: 10px;overflow:hidden;">
    <div class="account-search">
      <div class="sapInput-wrapper">
        <form>
          <div style="display:flex;flex-direction:row;justify-content:space-between;width: 100%;">
            <div class="spot-form__field-group spot-form__field-group--horizontal">
              <fieldset class="spot-form__radio-group" role="radiogroup">
                <legend class="spot-form__radio-group-label">
                  Select Focus
                </legend>
                <div style="display:flex;align-items:center;padding: 10px;">
                  <div class="spot-form__radio-group-inner">
                    <label class="spot-form__radio" *ngFor="let settingType of settingTypes" [for]="settingType">
                      <input [id]="settingType" [name]="settingType" [value]="settingType" [(ngModel)]="selectedSettingsType" type="radio" class="spot-form__radio-input" (change)="settingsTypeChanged()"/>
                      <span class="spot-form__radio-inner">
                        <span class="spot-form__radio-visual"></span>
                        <span class="spot-form__radio-label ">
                          {{settingType}}
                        </span>
                      </span>
                    </label>


                  </div>
                  <pcc-create-account-settings (newAccountSettingsAdded)="newAccountSettingsAdded($event)"></pcc-create-account-settings>
                </div>

              </fieldset>
            </div>

          </div>

          <div *ngIf="errorMsg" class="alert alert-danger" role="alert" style="margin-top:20px;">
            <i class="fa-solid fa-exclamation-circle fa-2x" style="color:red;"></i> {{ errorMsg }}
          </div>
        </form>
      </div>

      <span *ngIf="showSelectConfig">Please select which config you wish to use:</span>
      <div class="account-list">
        <div class="spot-form__field-group">
          <input type="checkbox" class="spot-toggle" id="showDraft" name="showDraft" [(ngModel)]="showDraft" (change)="showDraftChanged()">
          <label class="spot-toggle-label" for="showDraft">show draft groups</label>
        </div>
        <table class="spot-data-table spot-data-table--clickable" style="width: 100%;"  aria-label="Group Config">
          <thead>
            <tr>
              <th style="text-align:left !important;" *ngIf="showCorpSettings">SAP</th>
              <th style="text-align:left !important;">Name</th>
              <th>Country</th>
              <th *ngIf="showDraft">Status</th>
              <th>Visible</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngIf="showCountrySettings">
              <tr *ngFor="let acct of sortedCountryData" (click)="editAccountSettings(acct)">
                <td>{{acct.accountSettings.customer_name}}</td>
                <td style="text-align:center;">{{acct.accountSettings.countryCd}}</td>
                <td *ngIf="showDraft" style="text-align:center;" [class]="acct.accountSettings.status == 'ACTIVE' ? 'account-active' : ''">{{ statusDisplayName(acct.accountSettings.status) }}</td>
                <td style="text-align:center">
                  <span *ngIf="acct.accountSettings.visible == true"><pcc-icon iconClass="pcc-spot-icon--green" symbol="checkmark"></pcc-icon></span>
                  <span *ngIf="acct.accountSettings.visible != true"></span>
                </td>
              </tr>
            </ng-container>
            <ng-container *ngIf="showBuyingGroupSettings">
              <tr *ngFor="let acct of sortedBuyingGroupData" (click)="editAccountSettings(acct)">
                <td>{{acct.accountSettings.customer_name}}</td>
                <td style="text-align:center;">{{acct.accountSettings.countryCd}}</td>
                <td *ngIf="showDraft" style="text-align:center;" [class]="acct.accountSettings.status == 'ACTIVE' ? 'account-active' : ''">{{ statusDisplayName(acct.accountSettings.status) }}</td>
                <td style="text-align:center">
                  <span *ngIf="acct.accountSettings.visible == true"><pcc-icon iconClass="pcc-spot-icon--primary" symbol="checkmark"></pcc-icon></span>
                  <span *ngIf="acct.accountSettings.visible != true"></span>
                </td>
              </tr>
            </ng-container>
            <ng-container *ngIf="showCorpSettings">
              <tr *ngFor="let acct of sortedCorpData" (click)="editAccountSettings(acct)">
                <td>{{acct.accountSettings.sap_id}}</td>
                <td>{{acct.accountSettings.customer_name}}</td>
                <td style="text-align:center;">{{acct.accountSettings.countryCd}}</td>
                <td *ngIf="showDraft" style="text-align:center;" [class]="acct.accountSettings.status == 'ACTIVE' ? 'account-active' : ''">{{ statusDisplayName(acct.accountSettings.status) }}</td>
                <td style="text-align:center">
                  <span *ngIf="acct.accountSettings.visible == true"><pcc-icon iconClass="pcc-spot-icon--primary" symbol="checkmark"></pcc-icon></span>
                  <span *ngIf="acct.accountSettings.visible != true"></span>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>

    </div>

  </div>
</div>
