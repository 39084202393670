import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";

import { NavService } from "../../service/nav.service";

import { AppFacade } from "../../facade/app.facade";
import { PCCSession } from "@shared/model/pcc-session";
import { IUser } from "@shared/model/user";
import { AuthService } from "../../auth/auth.service";

import { envUtils } from "../../globals/env";
import googleAnalytics from "../../analytics/googleAnalytics";

@Component({
    selector: "pcc-app-header",
    templateUrl: "./app-header.component.html",
    styleUrls: [
        "./app-header.component.scss"
    ]
})
export class AppHeaderComponent implements OnInit, OnDestroy {

    public session: PCCSession;

    private sessionSub: Subscription;

    public user: IUser;

    public hasAdminAccess: boolean;

    public showSettings = false;

    public showCanadaLeaf = false;

    public constructor(
        public appFacade: AppFacade,
        private navService: NavService,
        private authService: AuthService
    ) {
    }

    private setSession(session: PCCSession): void {
        this.session = session;

        this.updatePerms();
    }

    public ngOnInit(): void {

        this.user = this.appFacade.getUserDetails();
        console.log("user=", this.user);
        console.log("envUtils=", envUtils);

        this.sessionSub = this.appFacade.getCurrentSession().subscribe(
            (session: PCCSession): void => {
                this.setSession(session);
            }
        );

        this.hasAdminAccess = this.authService.hasAccess("ADMIN_TOOL");
        this.updatePerms();

        console.log("hasAdminAccess=", this.hasAdminAccess);
    }

    private updatePerms(): void {
        this.showSettings = this.hasAdminAccess;

        this.showCanadaLeaf = this.session?.countryCd === "CA";
    }

    public ngOnDestroy(): void {
        this.sessionSub.unsubscribe();
    }

    public adminClicked(): void {
        console.log("adminClicked");
        googleAnalytics.registerEvent("admin_clicked", "click");
        this.navService.showAdmin();
    }

    public goHome(): void {
        console.log("goHome");

        googleAnalytics.registerEvent("go_to_home_started", "linkEvent");
        this.navService.goHome();
    }
}
