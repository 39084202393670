import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

import { IProfileItem, ProfileItemType } from "@shared/model/profile-item";

@Component({
    selector: "pcc-slot-category-test",
    templateUrl: "./slot-category-test.component.html",
    styleUrls: [
        "./slot-category-test.component.scss"
    ]
})
export class SlotCategoryTestComponent implements OnInit {

    @Input() public testComp: IProfileItem;

    @Input() public enableDefault = true;

    @Input() public showLeft: boolean;

    @Input() public showRight: boolean;

    @Output() public testClick = new EventEmitter<boolean>();

    @Output() public defaultClick = new EventEmitter<boolean>();

    @Output() public moveLeft = new EventEmitter<IProfileItem>();

    @Output() public moveRight = new EventEmitter<IProfileItem>();

    public modality: string;

    public testType: ProfileItemType;

    public ngOnInit(): void {
        this.modality = this.testComp.modality.developer_name;
        this.testType = this.testComp.test_type;
    }

    public testClicked(): void {
        this.testClick.emit(this.testComp.isSelected);
    }

    public defaultClicked(): void {
        this.defaultClick.emit(this.testComp.is_default);
    }

    public moveLeftClicked(evt: Event): void {
        this.moveLeft.emit(this.testComp);
        evt.stopPropagation();
        evt.preventDefault();
    }

    public moveRightClicked(evt: Event): void {
        this.moveRight.emit(this.testComp);
        evt.stopPropagation();
        evt.preventDefault();
    }
}
