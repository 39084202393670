import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";

@Component({
    selector: "pcss-slider",
    templateUrl: "./pcss-slider.component.html",
    styleUrls: [
        "./pcss-slider.component.scss"
    ]
})
export class PcssSliderComponent implements AfterViewInit {
    @ViewChild('slider', { static: true })
    public slider: ElementRef;

    @ViewChild('output', { static: true })
    public output: ElementRef;

    @Input()
    public sliderValue;

    @Output()
    public sliderValueChange = new EventEmitter<number>();

    @Output()
    public sliderValueInput = new EventEmitter<number>();

    @Input()
    public step;

    @Input()
    public min;

    @Input()
    public max;

    public ticks: Number[];

    public async ngOnInit() {
        this.ticks = new Array(Math.round((this.max - this.min) / this.step) + 1);
    }

    public ngAfterViewInit(): void {
        this.updateStyles();
    }

    public updateStyles() {
        this.slider.nativeElement.style.setProperty('--value', `${this.calculateLeftPosition()}%`);
        this.updateOutputPosition();
    }

    public input() {
        this.updateStyles();
        this.sliderValueInput.emit(this.sliderValue);
    }

    public change() {
        this.updateStyles();
        this.sliderValueChange.emit(this.sliderValue);
    }

    public updateOutputPosition() {
        // thumbWidth * leftPosition / 100 - thumbWidth / 2
        const positionOffset = Math.round(10 * this.calculateLeftPosition() / 100) - 5;

        this.output.nativeElement.style.left = `calc(${this.calculateLeftPosition()}% - ${positionOffset}px)`;
    }

    private calculateLeftPosition() {
        const sliderElement = this.slider.nativeElement;
        return Number((this.sliderValue - sliderElement.min) * 100 / (sliderElement.max - sliderElement.min));
    }
}
