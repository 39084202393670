<div class="loading-overlay" *ngIf="isBusy">
  <div class="loading-indicator" id="loading-indicator">
	<div>
      <svg class="spot-icon spot-loading-spinner spot-loading-spinner--large" aria-labelledby="title" style="fill:teal">
		<title>spinner</title>
		<use xlink:href="/assets/images/spot_icons.svg#spinner"></use>
      </svg>
	</div>
	<div style="margin-top: 1em;color:#2d2e2f;font-size: larger;">
      <strong>{{busyMsg}}</strong>
	</div>
  </div>
</div>
