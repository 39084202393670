import { Component, OnInit } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";

import { PCCTranslateService } from "../../../service/translate.service";
import { NavService } from "../../../service/nav.service";
import { ICleanSessionsRequest } from "@shared/model/service/enroll-service";
import { IEnrollment, Enrollment, EnrollUtils } from "@shared/model/enrollment";
import { AppFacade } from "../../../facade/app.facade";
import { IAccountResponse } from "@shared/model/account-response";
import { PCCSession } from "@shared/model/pcc-session";
import { IAccount } from "@shared/model/account";
import { SessionModeEnum } from "@shared/model/session-mode-enum";
import { PCCAlertService } from "../../../service/alert.service";

import googleAnalytics from "../../../analytics/googleAnalytics";

const SESSION_MESSAGE_KEYS = {
    PARTIAL_ENROLLMENT_FOUND: "session-confirm.partial_enrollment_found",
    ACTIVE_ENROLLMENT_FOUND: "session-confirm.active_enrollment_found",
    EXPIRED_ENROLLMENT_FOUND: "session-confirm.expired_enrollment_found",
    CHOICE_PROMPT: "session-confirm.choice_prompt",
    PARTIAL_CONTINUE_DESC: "session-confirm.partial_continue_desc",
    PARTIAL_NEW_DESC: "session-confirm.partial_new_desc",
    ACTIVE_CONTINUE_DESC: "session-confirm.active_continue_desc",
    EXPIRED_CONTINUE_DESC: "session-confirm.expired_continue_desc",
    EXPIRED_NEW_DESC: "session-confirm.expired_new_desc",
    EXPIRED_PARTIAL_DESC: "session-confirm.partial_continue_desc"
}

/**
 * Using the account resp, run through the logic checking for active enrollment, expired enrollment, and partial enrollment.
 * Possible outcomes:
 * 1) Blank slate, start new session from scratch for this account.
 * 2) Retrieve partial enrollment and continue.
 * 3) Retrieve active/expired enrollment and continue with certain blocks in place for what user can change;
 * 4) Cancel search and return user to search screen.
 */

@Component({
    selector: "pcc-session-confirm",
    templateUrl: "./session-confirm.component.html",
    styleUrls: [
        "./session-confirm.component.scss"
    ]
})
export class SessionConfirmComponent implements OnInit {

    public accountSearchResponse: IAccountResponse;

    public sessionMsgKey: string;

    public showConfirmPartial = false;

    public showConfirmActiveEnrollment = false;

    public showConfirmExpiredEnrollment = false;

    public session: PCCSession;

    public locale: string;

    public accountInfo: IAccount;

    public handlingEmail: string;

    public continueDescMessage: SafeHtml;

    public newDescMessage: SafeHtml;

    public continuePartialDescMessage: SafeHtml;

    private continueDescKey: string;

    private newDescKey: string;

    private continuePartialDescKey: string;

    public constructor(
        private alertService: PCCAlertService,
        public appFacade: AppFacade,
        private navService: NavService,
        private translateService: PCCTranslateService,
        private sanitizer: DomSanitizer
    ) {}

    public async ngOnInit(): Promise<void> {

        // Hide next button
        this.navService.setNextState(null);

        const state = window.history.state;

        this.translateService.localeSubject.subscribe((locale: string): void => {
            this.setLocale(locale);

            this.continueDescMessage = this.getTranslatedSafeString(this.continueDescKey);
            this.newDescMessage = this.getTranslatedSafeString(this.newDescKey);
            this.continuePartialDescMessage = this.getTranslatedSafeString(this.continuePartialDescKey);
        });

        if (state.accountResp) {
            this.setAccountResponse(state.accountResp);
        } else {
            console.error("No account response present in routing state!", state);
        }
    }

    private setLocale(locale: string): void {
        console.log("setLocale: ", locale);
        this.locale = locale;
    }

    private async setAccountResponse(resp: IAccountResponse): Promise<void> {
        this.accountSearchResponse = resp;
        this.accountInfo = resp?.account;

        const accountSettings = resp?.accountSettings;
        this.handlingEmail = accountSettings?.handling_email;

        if (!resp.existEnroll && !resp.oldSession) {
            console.error("No existing or partial enrollment?");
            return;
        }

        this.continueDescKey = "";
        this.newDescKey = "";
        this.continuePartialDescKey = "";

        if (resp.existEnroll) {
            if (EnrollUtils.isEnrollmentExpired(resp.existEnroll)) {
                this.showConfirmExpiredEnrollment = true;
                this.continueDescKey = SESSION_MESSAGE_KEYS.EXPIRED_CONTINUE_DESC;
                this.sessionMsgKey = SESSION_MESSAGE_KEYS.EXPIRED_ENROLLMENT_FOUND;
                this.newDescKey = SESSION_MESSAGE_KEYS.EXPIRED_NEW_DESC;
                this.continuePartialDescKey = SESSION_MESSAGE_KEYS.EXPIRED_PARTIAL_DESC;
            } else {
                this.showConfirmActiveEnrollment = true;
                this.sessionMsgKey = SESSION_MESSAGE_KEYS.ACTIVE_ENROLLMENT_FOUND;
                this.continueDescKey = SESSION_MESSAGE_KEYS.ACTIVE_CONTINUE_DESC;
            }
        } else if (resp.oldSession) {
            this.showConfirmPartial = true;
            this.sessionMsgKey = SESSION_MESSAGE_KEYS.PARTIAL_ENROLLMENT_FOUND;
            this.continueDescKey = SESSION_MESSAGE_KEYS.PARTIAL_CONTINUE_DESC;
            this.newDescKey = SESSION_MESSAGE_KEYS.PARTIAL_NEW_DESC;
        }

        this.continueDescMessage = this.getTranslatedSafeString(this.continueDescKey);
        this.newDescMessage = this.getTranslatedSafeString(this.newDescKey);
        this.continuePartialDescMessage = this.getTranslatedSafeString(this.continuePartialDescKey);

        // Used just for client header until real session is worked out.
        this.session = await this.appFacade.createSession(resp?.account, resp?.accountSettings, this.locale, null, false, null);
    }

    private getTranslatedSafeString(key?: string): SafeHtml {
        console.log("getTranslatedSafeString: ", key);
        if (!key) {
            return "";
        }
        const msg = this.appFacade.translate(key || "", { email: this.handlingEmail });
        console.log("msg=", msg);
        console.log("Returning safe html: ", this.sanitizer.bypassSecurityTrustHtml(msg));
        return this.sanitizer.bypassSecurityTrustHtml(msg);
    }

    public gotoSearch(): void {
        this.navService.goHome();
    }

    public async partialSessionConfirmed(retrievePartial: boolean): Promise<void> {
        console.log("partialSessionConfirmed: ", retrievePartial);

        this.startSession(this.accountSearchResponse, retrievePartial, false, false);
    }

    // User has chosen to continue with an existing active submitted enrollment.
    // Enrollment data will be copied to a new enrollment record that references this old one.
    // If user submits new record, old one will be marked as deleted.
    public async activeSessionConfirmed(): Promise<void> {
        console.log("activeSessionConfirmed: true");

        this.startSession(this.accountSearchResponse, false, true, false);
    }

    // User has chosen to modify existing enrollment
    // TODO: This differs from editing an existing enrollment
    // - we don't want to mark anything as deleted, just edit the existing record in place.
    public async expiredSessionConfirmed(continueExpired: boolean): Promise<void> {
        console.log("expiredSessionConfirmed: ", continueExpired);

        this.startSession(this.accountSearchResponse, false, false, continueExpired);
    }

    public async startSession(resp: IAccountResponse, retrievePartial = false, retrieveActive = false, modifyExpired = false): Promise<void> {
        console.log("session-confirm.startSession", retrievePartial, retrieveActive, modifyExpired);

        let busyMsgKey: string;
        if (retrievePartial) {
            busyMsgKey = "sap-search.Reloading";
        } else {
            busyMsgKey = "sap-search.Loading";
        }
        this.alertService.setBusy(true, this.appFacade.translate(busyMsgKey));

        const oldSession = retrievePartial ? resp.oldSession : null;
        const currentEnroll = (retrieveActive || modifyExpired) ? resp.existEnroll : null;

        const accountSettings = resp.accountSettings;

        const locale = this.translateService.getClosestLanguage(accountSettings.languages, accountSettings.defaultLocale);
        this.setLocale(locale);
        console.log("Locale by country=", locale);

        const lastPCHDate = this.getLastPetCareHeroesEnrollment(resp.existEnroll);

        const enrollInfo = new Enrollment(resp.account, currentEnroll || oldSession, accountSettings);

        // Don't lose the date of the last pet care heroes enrollment, even if the last session was
        // closed and the user chooses to create a totally new enrollment.
        enrollInfo.petCareHeroesLastEnrollmentDate = enrollInfo.petCareHeroesLastEnrollmentDate || lastPCHDate;

        if (!retrievePartial && !retrieveActive && !modifyExpired) {
            // For a new session only, if any templates in the supplied account settings are marked as
            // selected by default, add them here.
            enrollInfo.autoAddProfilesIfNeeded(resp.accountSettings, locale);
        }

        const sessionMode = this.getSessionMode(modifyExpired, retrieveActive);

        const newSession = await this.appFacade.createSession(resp.account, resp.accountSettings, locale, enrollInfo, true, sessionMode);

        console.log("newSession=", newSession);

        this.session = newSession;

        // Clean up any old partial sessions if necessary
        this.cleanOldSessions(oldSession, newSession, resp.account.sap_id);

        if (EnrollUtils.isPricingExpired(newSession.enrollInfo, sessionMode)) {
            // If old session is more than 30 days old or from a past year,
            // re-retrieve pricing for any profiles contained in the session.

            this.alertService.setBusy(true, this.appFacade.translate("sap-search.Repricing"));

            // For an old partial enrollment, clear out any prices as well as reset dates based on today.
            // For both closed and active (submitted) enrollments, we need to retrieve any pricing changes from SAP and apply them, but don't lose anything set by the user otherwise...
            if (retrievePartial) {
                // Reset dates based on today rather than original dates from
                // retrieved session.
                EnrollUtils.resetDates(newSession.enrollInfo);

                // Clear out pricing data to force re-retrieve of pricing from
                // SAP and Test Mix...
                EnrollUtils.clearPricing(newSession.enrollInfo);
            }

            await this.appFacade.reloadPricing(newSession);

            await this.appFacade.saveEnrollment(newSession.enrollInfo);

            // TODO: Notify profiles page so next button can get re-enabled!

            this.alertService.setBusy(false);
        } else {
            this.alertService.setBusy(false);
        }


        await this.appFacade.startEnrollmentSession(newSession);
    }

    // Clean up old partial sessions when user chooses to ignore and start fresh.
    private cleanOldSessions(oldEnrollment: IEnrollment, newSession: PCCSession, sapId: string): void {
        console.log("cleanOldSessions()");

        // When training mode is enabled, save and retrieve and edit enrollment is disabled,
        // so multiple sessions are allowed to exist simultaneously.
        if (this.appFacade.isEventTrainingMode()) {
            return;
        }

        let ignoreEnrollId: number;
        if (oldEnrollment?.enrollment_id) {
            ignoreEnrollId = oldEnrollment.enrollment_id;
        } else if (newSession.enrollInfo?.enrollment_id) {
            ignoreEnrollId = newSession.enrollInfo.enrollment_id;
        }

        // Clear out old sessions.
        const cleanReq: ICleanSessionsRequest = {
            sapId,
            ignore_enrollment_id: ignoreEnrollId
        };
        console.log("Clearing out old sessions in background...", cleanReq);
        this.appFacade.cleanupSessions(cleanReq);
    }

    public viewPdfClicked(): void {
        const enrollId = this.accountSearchResponse.existEnroll.enrollment_id;

        googleAnalytics.registerEvent("session_pdf_download", "download", { enrollment_id: enrollId });

        this.appFacade.showPdf(enrollId);
    }

    public getSessionMode(modifyExpired: boolean, retrieveActive: boolean): SessionModeEnum {
        if (modifyExpired) {
            return SessionModeEnum.EXPIRED;
        }
        if (retrieveActive) {
            return SessionModeEnum.ACTIVE;
        }
        return SessionModeEnum.FRESH;
    }

    // Return the date of the last pet care heroes enrollment (if any) from the old enrollment (if
    // available).
    private getLastPetCareHeroesEnrollment(enrollInfo?: IEnrollment): Date {
        return enrollInfo?.petCareHeroesLastEnrollmentDate || enrollInfo?.petCareHeroesEnrollmentDate;
    }
}
