import { Component, Input, OnChanges, OnInit } from "@angular/core";
import { FileInfo } from "@shared/model/file";
import googleAnalytics from "../../../analytics/googleAnalytics";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CarouselWrapperComponent } from "../carousel-wrapper/carousel-wrapper.component";

@Component({
    selector: "pcc-carousel",
    templateUrl: "./carousel.component.html",
    styleUrls: [
        "./carousel.component.scss"
    ]
})
export class CarouselComponent implements OnChanges, OnInit {
    @Input() public images: FileInfo[] = [];

    @Input() public currentIndex = 0;

    @Input() public title: string;

    @Input() public canExpand = true;

    public constructor(
        private modalService: NgbModal
    ) {
    }

    private slideTimeStart: number;

    public ngOnChanges(): void {
        // Reset current index to 0 when images array changes.
        this.currentIndex = 0;
        this.slideTimeStart = Date.now();
    }

    public ngOnInit() {
        this.slideTimeStart = Date.now();
    }

    public prevSlide(): void {
        this.goToSlide((this.currentIndex === 0) ? this.images.length - 1 : this.currentIndex - 1);
    }

    public nextSlide(): void {
        this.goToSlide((this.currentIndex === this.images.length - 1) ? 0 : this.currentIndex + 1);
    }

    public goToSlide(index: number): void {
        this.googleAnalyticsMeasureSlideTime();
        this.currentIndex = index;
    }

    private getCurrentImage(): FileInfo {
        return this.images[this.currentIndex];
    }

    private googleAnalyticsMeasureSlideTime() {
        const timeOnSlide = Date.now() - this.slideTimeStart;
        googleAnalytics.registerEvent("slide_change", "change", {
            time_on_slide: timeOnSlide,
            slide_id: this.getCurrentImage().id,
            slide_title: this.getCurrentImage().title,
            slide_description: this.getCurrentImage().description
        });
        this.slideTimeStart = Date.now();
    }

    public expand(): void {
        const ref = this.modalService.open(CarouselWrapperComponent, {
            size: "xl",
            centered: false,
            backdrop: "static",
            keyboard: true,
            animation: true,
            modalDialogClass: "carousel-wrapper-modal"
        });
        ref.componentInstance.images = this.images;
        ref.componentInstance.title = this.title;
        ref.componentInstance.currentIndex = this.currentIndex;
        ref.componentInstance.canExpand = false;
    }
}
