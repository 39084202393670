<header class="app-header">
  <div class="app-title">
	<a (click)="goHome()" class="app-home-link">
	  <img src="/assets/images/simple_start_icon_blue.png" class="app_icon" alt="IDEXX Simple Start">
	  <span style="font-weight: bold;font-size:18px;color:#6a6b6d;" [innerHTML]="'progress-bar.PCC_A' | translate"></span>&nbsp;<span style="font-size: 18px;color:#6a6b6d;" [innerHTML]="'progress-bar.PCC_B' | translate"></span>
	</a>
  </div>
  <div class="pcc-idexx-logo">
	<img class="idexx-logo" src="/assets/images/IDEXX_logo_charcoal.png" alt="IDEXX"/>
	<img class="canada-leaf" src="/assets/images/canada_leaf.png" alt="Canada" *ngIf="showCanadaLeaf" alt="Canada">
  </div>

  <div class="app-controls">
	<pcc-language-toggle></pcc-language-toggle>
	<div class="app-menu">
	  <span class="user_name">{{ user?.name }}</span>
	  <div ngbDropdown class="app-menu-button d-inline-block" *ngIf="showSettings">
		<button class="spot-button spot-button--link spot-button--icon-only spot-button--with-icon spot-button--dropdown-toggle settings-button" style="background:transparent; box-shadow:none;" id="dropdownMenuButton" ngbDropdownToggle>
		  <svg class="settings-icon spot-icon spot-button__icon spot-button__icon--right" aria-labelledby="title">
			<title>Admin</title>
			<use xlink:href="/assets/spot-library/dist/symbols/spot_symbols.svg#settings"></use>
		  </svg>
		</button>
		<div ngbDropdownMenu aria-labelledby="dropdownMenuButton">
          <div class="spot-crossproduct__content" style="width:320px;max-width:320px;" >

			  <a ngbDropdownItem class="spot-crossproduct__content-item" (click)="adminClicked()">
				<svg class="spot-icon spot-button__icon spot-button__icon--left admin-button" aria-labelledby="title">
				  <title>Settings</title>
				  <use xlink:href="/assets/spot-library/dist/symbols/spot_symbols.svg#settings"></use>
				</svg>
				<label>Administration</label>
			  </a>
		  </div>
		</div>
	  </div>
	</div>
  </div>

</header>
