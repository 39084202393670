<div class="pcc-edit-panel">
  <div class="pcc-edit-panel__header">
    <span class="spot-typography__heading--level-3">Page: PDF</span>
  </div>
  <div class="pcc-edit-panel__content" style="padding: 1em;">
    <div class="spot-form__field-group" style="display:flex;flex-direction:column;">
      <input type="checkbox" class="spot-toggle" id="show_practice_price_pdf" name="show_practice_price_pdf" [(ngModel)]="accountSettings.flags.show_practice_price_pdf" (change)="flagToggled($event)">
      <label class="spot-toggle-label" for="show_practice_price_pdf">Show Practice Price (PDF)</label>
    </div>
  </div>
</div>
