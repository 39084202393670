<div class="pcc-edit-panel">
  <div class="pcc-edit-panel__header build-template-header">
    <!-- Template Display Name -->
    <pcc-localized-text label="Profile Template Name" [required]="true" [languages]="accountSettings?.languages" key="displayName" [localizedKey]="viewModel.localizedKeys.displayName" (textChanged)="templateNameChanged($event);" style="width: initial;flex:1;"></pcc-localized-text>
    <div *ngIf="showMatch" [ngClass]="'matched-profile ' + (viewModel.matchedProfile ? 'matched' : 'no-match')">
      <svg *ngIf="matching" class="spot-icon spot-loading-spinner" aria-labelledby="title">
        <title>spinner</title>
        <use xlink:href="/assets/spot-library/dist/symbols/spot_symbols.svg#spinner"></use>
      </svg>
      <div *ngIf="viewModel.matchedProfile">
        <svg class="spot-icon matched-profile-checkmark" aria-labelledby="title">
          <title>Match found</title>
          <use xlink:href="/assets/spot-library/dist/symbols/spot_symbols.svg#checkmark"></use>
        </svg>
        Matched Profile: {{ viewModel.matchedProfile?.test_code }}</div>
      <div *ngIf="noMatch">
        <svg class="spot-icon no-match-icon" aria-labelledby="title">
          <title>No match found</title>
          <use xlink:href="/assets/spot-library/dist/symbols/spot_symbols.svg#cancel"></use>
        </svg>
        No match found
      </div>
    </div>

    <pcc-confirm-action *ngIf="slot && slot.template_profile_id" symbol="delete" confirmPosition="spot-popover--bottom-left" buttonTitle="Delete Template" [useLink]="true" (okClick)="deleteTemplateConfirmed()" class="delete-button" confirmMsg="build-template.confirm_delete" buttonText="Delete Template"></pcc-confirm-action>
  </div>
  <div class="pcc-edit-panel__content" style="padding: 1em;">
    <form class="spot-form" id="itemForm" #itemForm="ngForm" name="itemForm">
      <!-- Selected by default -->
      <div class="spot-form__field-group" style="flex:1;">
        <pcc-checkbox [(checked)]="viewModel.defaultSelected" label="Selected by default?"></pcc-checkbox>
      </div>

      <div class="spot-form__field-group spot-form__field-group--horizontal">
        <!-- Species -->
        <div class="spot-form__field-group spot-form__field-group--horizontal" style="flex:1;">
          <fieldset class="spot-form__radio-group" role="radiogroup">
            <legend class="spot-form__radio-group-label">
              <span class="spot-form__label-required-indicator" title="This field is required." aria-hidden="true">*</span>
              Species
            </legend>
            <div class="spot-form__radio-group-inner">
              <label class="spot-form__radio" [for]="'template_' + species.value" *ngFor="let species of speciesList">
                <input name="template_species" [id]="'template_' + species.value" [value]="species.value" type="radio" class="spot-form__radio-input" [(ngModel)]="viewModel.species" (change)="speciesSelected(species)"/>
                <span class="spot-form__radio-inner">
                  <span class="spot-form__radio-visual"></span>
                  <span class="spot-form__radio-label"> {{species.displayName}} </span>
                </span>
              </label>
            </div>
          </fieldset>
        </div>

        <!-- Modality -->
        <div class="spot-form__field-group spot-form__field-group--horizontal" style="flex:1;">
          <fieldset class="spot-form__radio-group" role="radiogroup">
            <legend class="spot-form__radio-group-label">
              <span class="spot-form__label-required-indicator" title="This field is required." aria-hidden="true">*</span>
              Modality
            </legend>
            <div class="spot-form__radio-group-inner">
              <label class="spot-form__radio" [for]="'template_' + modality.value" *ngFor="let modality of modalityList">
                <input name="template_modality" [id]="'template_' + modality.value" [value]="modality" type="radio" class="spot-form__radio-input" [(ngModel)]="viewModel.modality"/>
                <span class="spot-form__radio-inner">
                  <span class="spot-form__radio-visual"></span>
                  <span class="spot-form__radio-label"> {{modality.displayName}} </span>
                </span>
              </label>
            </div>
          </fieldset>
        </div>

        <!-- Template Type -->
        <div class="spot-form__field-group" style="flex:1;">
          <label class="spot-form__label" for="test_type">
            <span class="spot-form__label-required-indicator" title="This field is required." aria-hidden="true">*</span>
            Template Type
          </label>
          <div class="spot-form__select">
            <select class="spot-form__select-input" name="templateType" id="templateType" [(ngModel)]="viewModel.templateType" (change)="templateTypeSelected()" required>
              <option class="spot-form__select-option" name="" value=""></option>
              <option class="spot-form__select-option" *ngFor="let templateType of templateTypes" [value]="templateType">{{ 'build-template.' + templateType | translate}}</option>
            </select>
            <div class="spot-form__select-inner"></div>
            <span class="spot-form__select-open-indicator">
              <svg class="spot-icon spot-form__select-open-icon" aria-labelledby="title">
                <title>caret-down</title>
                <use xlink:href="/assets/spot-library/dist/symbols/spot_symbols.svg#caret-down"></use>
              </svg>
            </span>
          </div>
        </div>
      </div>

      <div class="spot-form__field-group spot-form__field-group--horizontal" *ngIf="showStaticFields" >
        <!-- Price -->
        <div class="spot-form__field-group" style="flex:1;">
          <label class="spot-form__label" >Price</label> <!-- TODO: currency based on account settings -->
          <input type="text" currencyMask [(ngModel)]="viewModel.defaultPrice" name="defaultPrice" class="spot-form__input" style="text-align:left;width:300px;">
        </div>

        <!-- Price -->
        <div class="spot-form__field-group" style="flex:1;">
          <label class="spot-form__label" >Matched Profile</label>
          <input type="text" [(ngModel)]="viewModel.matchedProfile.test_code" name="matchedProfile" class="spot-form__input" style="width:300px;">
        </div>
      </div>

      <section *ngIf="hasChildren">
        <label>Select Active Profile Item Categories</label>
        <ul class="admin-pcc-categories">
          <pcc-slot-category *ngFor="let ctg of viewModel?.categories" [testCategory]="ctg" [templateType]="viewModel.templateType" (defaultChange)="defaultChange()"></pcc-slot-category>
        </ul>
      </section>

    </form>
  </div>

  <div class="pcc-edit-panel__footer button-panel">

    <span style="flex: 1;">&nbsp;</span>

    <button type="cancel" class="pcc-edit-panel__footer-cancel-button" (click)="cancelEdit()">Cancel</button>
    <button type="submit" class="spot-button spot-button--primary" [disabled]="!saveEnabled" (click)="save()">Save</button>
  </div>
</div>
