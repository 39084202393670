import { IAccount } from "./account";
import { IAccountSettings } from "./account-settings";
import { SessionModeEnum } from "./session-mode-enum";
import { ISystemSettings } from "./system-settings";
import { IEnrollment } from "./enrollment";
import { ISessionCount } from "./account-response";

const CURRENCY_BY_LOCALE = {
    "en-US": "USD",
    "fr-CA": "CAD"
};

/**
 * Interface representing the built-up set of information a user gathers while
 * running PCC.  Includes a list of built-out profiles, practice information,
 * champion information, vdc information, integration date, and training date.
 */
export class PCCSession {

    public accountInfo: IAccount;

    public accountSettings: IAccountSettings;

    public systemSettings: ISystemSettings;

    public submitting: boolean = false;

    public activeSettings?: IAccountSettings;

    // Keeps track if the current session is FRESH (new enrollment) vs ACTIVE (editing an existing,
    // submitted, and active enrollment) vs EXPIRED (editing an existing, submitted, but expired
    // enrollment) for the purpose of allowing/disabling certain functionality in the frontend depending on the data.
    public sessionMode = SessionModeEnum.FRESH;

    public enrollInfo?: IEnrollment;

    public sessionCount: ISessionCount;

    public isCorporate: boolean = false;

    public countryCd: string;

    private pLocale = "en-US";

    public get locale(): string {
        return this.pLocale;
    }

    public set locale(locale: string) {
        console.log(`setLocale: ${locale}`);
        this.pLocale = locale;
        this.currency = CURRENCY_BY_LOCALE[locale as keyof typeof CURRENCY_BY_LOCALE] || "USD";
        if (this.enrollInfo) {
            this.enrollInfo.locale = locale;
        }
    }

    public currency = CURRENCY_BY_LOCALE[this.pLocale as keyof typeof CURRENCY_BY_LOCALE];

    public currencyMaskOptions: any;

}
