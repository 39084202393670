<div class="goal-calculator">
  <div class="spot-typography__heading--level-3 goal-header">
    <i class="fa-solid fa-calculator fa-lg"></i>
    <span>{{'goal.calculator_header' | translate}}</span>
  </div>
  <section>
    <div class="pcc-typography-level-4">{{'goal.Step_1' | translate}}</div>
    <label>
      {{'goal.avg_visits' | translate}}
      <input type="number" style="text-align:center;" [(ngModel)]="wellnessVisitsPerMonth" (change)="visitsChange()" (input)="inputChange()"/>
    </label>

    <div class="pcc-typography-level-4">{{'goal.Step_2' | translate}}</div>
    <label>
      <span>{{'goal.compliance_lvl' | translate}}</span>
      <pcss-slider [(sliderValue)]="goalPercent" [step]="10" [min]="10" [max]="100" (sliderValueChange)="sliderChange()" (sliderValueInput)="sliderInput()"></pcss-slider>
    </label>

    <div class="pcc-typography-level-4">{{'goal.Step_3' | translate}}</div>
    <p>{{'goal.start_monthly' | translate}}</p>
    <div class="result-container">
      <div class="result">
        {{'goal.target_panels' | translate}} <span class="output">{{monthlyGoal}}</span>
      </div>
    </div>
  </section>
</div>
