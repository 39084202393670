import { Injectable } from "@angular/core";

import { firstValueFrom } from "rxjs";

import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ConfirmationComponent } from "../client/components/confirmation/confirmation.component";

import { IAccountResponse, ISessionCount } from "@shared/model/account-response";
import { IPartner } from "@shared/model/partner";
import { IAccount, ILimsAccount } from "@shared/model/account";
import { ManagedCountries } from "@shared/model/country";
import { LimsEnum } from "@shared/model/lims";
import { PCCSession } from "@shared/model/pcc-session";

import { AccountSettings, IAccountSettings, StatusEnum } from "../../../shared/model/account-settings";
import { IProfileTemplate } from "@shared/model/profile-template";

import { ApiService } from "./api.service";
import { AdminService } from "./admin.service";

import { PCCClientError } from "../shared/model/pcc-client-error";
import { UtilService } from "@shared/service/util.service";

export enum ConfirmSavePurge {
    OK,
    CANCEL,
    OK_NO_PURGE
}

const MODAL_CLASS = "confirmation-leaving";

@Injectable()
export class AccountService {

    public constructor(
        private adminAPI: AdminService,
        private apiService: ApiService,
        private modalService: NgbModal
    ) {}

    /**
     * Depending on usage (admin tool vs client), the specified account must fit certain requirements.
     * Admin tool only allows corporate parent accounts.
     * Client only allows corporate child accounts whose parent account is
     * configured in PCC or non-corp CAG accounts from certain countries.
     */
    public async findAccount(acctSettingsId?: number, sapId?: string, isAdmin?: boolean): Promise<IAccountResponse> {
        try {
            console.log(`findAccount: acctSettingsId=${acctSettingsId}, sapId=${sapId}, isAdmin=${isAdmin}`);
            const response = isAdmin === true
                ? await firstValueFrom(this.adminAPI.findAccount(acctSettingsId))
                : await firstValueFrom(this.apiService.findAccount(sapId));

            console.log("findAccount.response=", response);
            if (response.success && response.accountSettings) {
                response.accountSettings = this.parseAccountSettings(response.accountSettings);

                if (response.account && response.accountSettings) {
                    response.accountSettings.customer_name = response.accountSettings.customer_name || response.account.name;
                }

                if (!isAdmin && AccountService.hasParentStatusChanged(response)) {
                    console.log("change in parent_id status");
                    // pretend it's a whole new session and enrollment
                    response.existEnroll = null;
                    response.oldSession = null;
                }
            }
            console.log("Fetched account", response);
            return response;
        } catch (error) {
            console.error("Error calling findAccount", error);
            return {
                error
            };
        }
    }

    public async saveAccountSettings(formData: FormData): Promise<IAccountResponse> {
        try {
            console.log("account.service.saveAccountSettings", formData);

            const response = await firstValueFrom(this.adminAPI.saveAccountSettings(formData));

            if (response && response.success && response.accountSettings) {
                response.accountSettings = this.parseAccountSettings(response.accountSettings);
            }

            console.log("saveAccountSettings resp: ", response);
            return response;
        } catch (error) {
            console.error("Error saving account settings: ", error);
            return {
                success: false,
                error
            };
        }
    }

    public getProfile(slotId: number, accountSettingsId: number, session: PCCSession): IProfileTemplate {
        console.log("getProfile: ", slotId, accountSettingsId);
        // TODO: Check for account settings id.  If local, we're good. Otherwise needs to be a service call...
        if (session && session.accountSettings && session.accountSettings.profileTemplates) {
            return session.accountSettings.profileTemplates.find((pt: IProfileTemplate): boolean =>
                pt.template_profile_id === slotId
            );
        }
        return null;
    }

    private parseAccountSettings(resp: IAccountSettings): AccountSettings {
        if (!resp) {
            return null;
        }
        return new AccountSettings(resp);
    }

    public async getAccountCached(sapId: string, session: PCCSession): Promise<IAccount> {
        console.log("getAccountCached: ", sapId);
        if (!sapId || !session) {
            console.error("Can't retrieve account: ", sapId);
            return null;
        }
        const acct = session.accountInfo;
        if (acct.sap_id === sapId) {
            return acct;
        }
        const acctResp = await this.findAccount(null, sapId);
        if (acctResp.success) {
            return acctResp.account;
        }
        console.error("Failed to retrieve account!");
        return null;
    }

    public async getAccountSettings(acctSettingsId?: number, session?: PCCSession, noCache?: boolean): Promise<IAccountSettings> {
        console.log(`getAccountSettings: ${acctSettingsId}`);

        if (this.shouldUseCachedSettings(acctSettingsId, session, noCache)) {
            return session.accountSettings;
        }

        try {
            const accountResponse = await this.fetchAccountSettings(acctSettingsId);
            return this.handleAccountSettingsResponse(accountResponse, acctSettingsId);
        } catch (error) {
            console.error("Error thrown calling getAccountSettings: ", error);
            return null;
        }
    }

    private shouldUseCachedSettings(acctSettingsId: number, session: PCCSession, noCache: boolean): boolean {
        return session?.accountSettings && !noCache
            && (acctSettingsId && session.accountSettings.account_settings_id === acctSettingsId);
    }

    private async fetchAccountSettings(acctSettingsId: number): Promise<IAccountResponse> {
        return await firstValueFrom(this.adminAPI.getAccountSettings(acctSettingsId));
    }

    private handleAccountSettingsResponse(resp: IAccountResponse, acctSettingsId?: number): AccountSettings | null {
        console.log("accountSettings resp: ", resp);
        if (!resp.success) {
            return null;
        }
        if (acctSettingsId && resp.accountSettings && resp.accountSettings.account_settings_id === acctSettingsId) {
            return this.parseAccountSettings(resp.accountSettings);
        }
        return null;
    }

    public async getAllAccountSettings(): Promise<AccountSettings[]> {
        try {
            console.log("getAllAccountSettings");
            const response = await firstValueFrom(this.adminAPI.getAllAccountSettings());
            console.log("accountSettings response: ", response);
            if (response.success && response.accountSettingsList) {
                return response.accountSettingsList.map((as: IAccountSettings): AccountSettings => this.parseAccountSettings(as));
            }
            return [];
        } catch (error) {
            console.error("Error calling getAllAccountSettings: ", error);
            return [];
        }
    }

    public getSalesOrg(acct: IAccount): string {
        console.log("getSalesOrg: ", acct);
        let salesOrg = null;
        if (acct && acct.partners && acct.partners.length > 0) {
            let foundP = acct.partners.find((p: IPartner): boolean => p.partner_function === "AG");
            if (!foundP) {
                foundP = acct.partners.find((p: IPartner): boolean => p.partner_function === "WE");
            }
            if (!foundP) {
                foundP = acct.partners.find((p: IPartner): boolean => p.partner_function === "RE");
            }

            if (!foundP) {
                console.warn("Partner info not found, using first found...", acct.partners);
                foundP = acct.partners[0];
            }
            salesOrg = foundP.sales_organization;
        }

        return salesOrg;
    }

    /**
     * If account has existing sessions and user saves account settings, prompt user if they want to:
     * 1) Save account settings and purge any partial sessions.
     * 2) Save account settings but don't purge.
     * 3) Cancel save entirely
    */
    public async confirmSavePurge(acctSettings: IAccountSettings, sessionCount: ISessionCount): Promise<ConfirmSavePurge> {
        console.log("confirmSavePurge");
        let retVal: ConfirmSavePurge;
        if (StatusEnum.ACTIVE.equals(acctSettings.status)
            && sessionCount && (sessionCount.partial_session_count > 0)) {
            console.log("Partial sessions exist, confirming action from user...");
            const confirmResp = await this.confirmDeleteSessionsOnSave(sessionCount.partial_session_count > 0);
            console.log("confirmResp=", confirmResp);
            if (confirmResp === "ok") {
                retVal = ConfirmSavePurge.OK;
            } else if (confirmResp === "okNoPurge") {
                retVal = ConfirmSavePurge.OK_NO_PURGE;
            } else {
                retVal = ConfirmSavePurge.CANCEL;
            }
        } else {
            console.log("No old sessions.  Returning OK...");
            retVal = ConfirmSavePurge.OK_NO_PURGE;
        }
        return retVal;
    }

    private async confirmDeleteSessionsOnSave(containsPartials: boolean): Promise<"ok" | "okNoPurge" | "cancel"> {
        console.log("confirmDeleteSessionsOnSave: ", containsPartials);
        const ref = this.modalService.open(ConfirmationComponent, {
            size: "xl",
            windowClass: MODAL_CLASS,
            backdrop: "static",
            keyboard: false
        });
        ref.componentInstance.title = "clearSessions.title";
        if (containsPartials) {
            ref.componentInstance.body = "clearSessions.confirmDeleteMsg";
        }
        ref.componentInstance.button1 = {
            text: "clearSessions.button1", value: "ok", cls: "spot-button--primary"
        };
        ref.componentInstance.button2 = {
            text: "clearSessions.button2", value: "okNoPurge", cls: "spot-button--secondary"
        };
        const value = await ref.result;
        console.log("Confirm value=", value);
        return value.value;
    }

    public async confirmPurgeSessions(): Promise<boolean> {
        console.log("confirmPurgeSessions");
        const ref = this.modalService.open(ConfirmationComponent, {
            size: "xl",
            windowClass: MODAL_CLASS,
            backdrop: "static",
            keyboard: false
        });
        ref.componentInstance.title = "clearSessions.title";
        ref.componentInstance.body = "clearSessions.msg";
        ref.componentInstance.button1 = {
            text: "clearSessions.button1", value: "ok", cls: "pcc-primary-button"
        };
        const value = await ref.result;
        console.log("Confirm value=", value);
        return (value.value === "ok");
    }

    /**
     * Get list of lims to use when matching lims tests to a profile.
     * This information should come from the account itself during client usage,
     * but at times (such as in the admin tool) can be defaulted from the country the account is in.
     *
     * NOTE: Specific country handling should go away, but given that the user experience is very
     * different for US vs CA vs AU vs (the rest of the global countries), it may be inevitable that
     * it remains in some form.
     *
     * Throws error if invalid session specified.
     */
    public getDefaultLims(session: PCCSession): string[] {
        if (!session) {
            throw new PCCClientError("ACCOUNT_DEFAULT_LIMS", "Null session specified");
        }
        let limsNames: string[] = [];

        if (session.accountInfo && session.accountInfo.limsAccountInfo) {
            limsNames = session.accountInfo.limsAccountInfo.map((limsAccount: ILimsAccount): string => limsAccount.context_name);
        }
        if (!limsNames.length) {
            console.error("No lims from account info");
            if (ManagedCountries.CA === session.countryCd) {
                limsNames = [
                    ...LimsEnum.LYNXX_CA.limsNames
                ];
            } else if (ManagedCountries.AU === session.countryCd) {
                limsNames = [
                    ...LimsEnum.LYNXX_AU.limsNames
                ];
            } else {
                limsNames = [
                    ...LimsEnum.LYNXX_US.limsNames
                ];
            }
        }
        return limsNames;
    }

    private static hasParentStatusChanged(resp: IAccountResponse): boolean {
        const existingEnrollmentId = UtilService.stripLeadingZeros(resp.existEnroll?.parent_sap_id);
        const corpSapId = UtilService.stripLeadingZeros(resp.account?.corpParent?.sap_id);
        const oldSessionSapId = UtilService.stripLeadingZeros(resp.oldSession?.parent_sap_id);
        if (resp.existEnroll) {
            return (existingEnrollmentId && corpSapId) && existingEnrollmentId !== corpSapId;
        }
        return (oldSessionSapId && corpSapId) && oldSessionSapId !== corpSapId;
    }
}
