export interface IPCCError<T = any> {
    name: string;
    code?: string;
    message: string;
    sourceError?: Error | null;
    isSystemError?: boolean;
    context?: T | null;
    stack?: string;
}

export class PCCError<T = any> extends Error implements IPCCError<T> {
    public static readonly NAME: string = "PCCError";

    public readonly sourceError?: Error | null;

    public readonly context?: T | null;

    public readonly code?: string;

    public readonly isSystemError: boolean = false;

    public constructor(code: string, msg?: string, err?: Error | null, context?: T | null, isSystemError?: boolean) {
        super(msg || code);
        // Required because we are extending a built-in class
        Object.setPrototypeOf(this, PCCError.prototype);

        this.name = PCCError.NAME;
        this.code = code || msg;
        this.sourceError = err;
        this.context = context;
        this.isSystemError = (isSystemError === undefined) ? false : isSystemError;

        if (err) {
            this.stack = err.stack;
        } else {
            Error.captureStackTrace(this, this.constructor);
        }
    }

    /**
     * Used to generate toString json object that is in turn "stringified" with JSON.stringify
     */
    toJSON() {
        const retVal: any = {
            "name": this.name,
            "code": this.code,
            "message": this.message,
            "isSystemError": this.isSystemError
        };
        if (this.context) {
            retVal.context = this.context;
        }
        return retVal;
    }

    public toString(): string {
        const retVal: any = this.toJSON();
        return JSON.stringify(retVal);
    }

    public getErrorCode(): string {
        if (this.code) {
            return this.code;
        }
        return this.message;
    }
}
