import { Component, OnInit, OnDestroy } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { AppFacade } from "frontend/app/facade/app.facade";
import { Subscription } from "rxjs";
import { PCCSession } from "@shared/model/pcc-session";
import { ITestMixItem } from "@shared/model/service/test-mix-service";

@Component({
    selector: "pcc-testmix",
    templateUrl: "./testmix.component.html",
    styleUrls: [
        "./testmix.component.scss"
    ]
})
export class TestMixComponent implements OnInit, OnDestroy {
    private sessionSub: Subscription;

    public session: PCCSession;

    private testMixSub: Subscription;

    public testMix: ITestMixItem[];

    public testMixDiscount: number;

    public loading = true;

    public constructor(
        public activeModal: NgbActiveModal,
        public appFacade: AppFacade
    ) {
    }

    public ngOnInit(): void {
        this.sessionSub = this.appFacade.getCurrentSession().subscribe(
            (session): void => this.setSession(session)
        );
    }

    private setSession(session: PCCSession): void {
        this.session = session;

        this.testMixSub = this.appFacade.getTestMix(session?.accountInfo).subscribe(
            (resp): void => {
                this.loading = false;
                if (resp.success && resp.data) {
                    this.testMix = resp.data.priceList;
                    this.testMixDiscount = resp.data.discount;
                } else {
                    console.error("Error pulling test mix data: ", resp);
                }
            },
            (err: Error): void => {
                console.error("Error getting test mix data", err);
                this.loading = false;
            }
        );
    }

    public ngOnDestroy(): void {
        this.sessionSub.unsubscribe();
        this.testMixSub.unsubscribe();
    }
}
