import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { AuthRoutingModule } from "./auth-routing.module";
import { AuthGuard } from "./auth.guard";

import { FormsModule } from "@angular/forms";

import { BrowserCacheLocation, IPublicClientApplication, LogLevel, PublicClientApplication } from "@azure/msal-browser";
import {
    MSAL_GUARD_CONFIG,
    MSAL_INSTANCE,
    MSAL_INTERCEPTOR_CONFIG,
    MsalBroadcastService,
    MsalGuard,
    MsalGuardConfiguration,
    MsalInterceptor,
    MsalInterceptorConfiguration,
    MsalModule,
    MsalRedirectComponent,
    MsalService
} from "@azure/msal-angular";

import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { AuthInterceptor } from "./auth.interceptor";

import { AuthCallbackComponent } from "./auth-callback/auth-callback.component";
import { RoleGuardService } from "./role.guard";
import { NativeUtil } from "../utils/native";
import { envUtils } from "../globals/env";
import { LoginFailedComponent } from "./login-failed/login-failed.component";

const DEFAULT_INTERACTION_TYPE = NativeUtil.getInteractionType();

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

export function loggerCallback(logLevel: LogLevel, message: string) {
    console.log("MSAL log: ", message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
    console.log("redirectUri=", `/auth/callback/`);
    //    console.log("redirectUri=", `${envUtils.SERVICE_TARGET}/auth/callback/`);

    // Make sure resulting url _exactly_ matches what's set up in Azure.
    // Even a missing slash at the end will result in a 401 error
    // signing in!

    return new PublicClientApplication({
        auth: {
            clientId: envUtils.SSO_CLIENT_ID,
            authority: envUtils.SSO_LOGIN_URL,
            redirectUri: `/auth/callback/`,
            //            redirectUri: `${envUtils.SERVICE_TARGET}/auth/callback/`,
            postLogoutRedirectUri: "/"
        },
        cache: {
            cacheLocation: BrowserCacheLocation.LocalStorage,
            storeAuthStateInCookie: isIE, // set to true for IE 11
        },
        system: {
            loggerOptions: {
                loggerCallback,
                logLevel: LogLevel.Info,
                piiLoggingEnabled: false
            }
        }
    });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
    const protectedResourceMap = new Map<string, Array<string>>();
    protectedResourceMap.set("https://graph.microsoft.com/v1.0/me", [
        "user.read"
    ]);

    return {
        interactionType: DEFAULT_INTERACTION_TYPE,
        protectedResourceMap
    };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
    return {
        interactionType: DEFAULT_INTERACTION_TYPE,
        authRequest: {
            scopes: [
                "user.read"
            ]
            //           scopes: ['User.Read', 'openid', 'profile']
        },
        loginFailedRoute: "/login-failed"
    };
}

@NgModule({
    declarations: [
        AuthCallbackComponent,
        LoginFailedComponent
    ],
    providers: [
        AuthGuard,
        RoleGuardService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true
        },
        {
            provide: MSAL_INSTANCE,
            useFactory: MSALInstanceFactory
        },
        {
            provide: MSAL_GUARD_CONFIG,
            useFactory: MSALGuardConfigFactory
        },
        {
            provide: MSAL_INTERCEPTOR_CONFIG,
            useFactory: MSALInterceptorConfigFactory
        },
        MsalService,
        MsalGuard,
        MsalBroadcastService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        }
    ],
    imports: [
        MsalModule,
        CommonModule,
        AuthRoutingModule,
        FormsModule
    ],
    exports: [],
    bootstrap: [
        MsalRedirectComponent
    ]
})
export class AuthModule {
}
