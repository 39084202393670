<div class="page-header">
  <div>
    <span class="spot-typography__heading--level-2">Run Pricing File Output Jobs</span>
  </div>
</div>

<div>
  <button class="spot-button spot-button--primary" (click)="runPricingOutputJob('us')">Run US Job</button>
  <button class="spot-button spot-button--primary" (click)="runPricingOutputJob('ca')">Run CA Job</button>
  <button class="spot-button spot-button--primary" (click)="runPricingOutputJob('au')">Run AU Job</button>
</div>

<h2>Last job profile count was: {{count}}</h2>
