import { Component } from "@angular/core";
import { PCCAlertService, AlertEvent } from "../../../service/alert.service";

@Component({
    selector: "pcc-busy",
    templateUrl: "./busy.component.html",
    styleUrls: [
        "./busy.component.scss"
    ]
})
export class BusyComponent {

    public isBusy: boolean = true;

    public busyMsg: string;

    public constructor(
        public alertService: PCCAlertService
    ) {
        this.alertService.busySubject.subscribe((busyEvent: AlertEvent): void => {
            this.isBusy = busyEvent?.isBusy;
            this.busyMsg = busyEvent?.busyMsg;
        });
    }
}
