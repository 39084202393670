import { Component, OnInit, OnChanges } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

import { AdminFacade } from "../../../facade/admin.facade";
import { PCCAlertService } from "../../../service/alert.service";
import { IAccountResponse } from "@shared/model/account-response";

import { IAccountSettings } from "@shared/model/account-settings";
import { ICustomPage, CustomPageTypes } from "@shared/model/custom-page";
import { IAPIResponseData } from "@shared/model/service/service";
import { PCCClientError } from "../../../shared/model/pcc-client-error";
import { SettingsKey } from "@shared/model/settings-flags";

@Component({
    selector: "pcc-account-settings-landing-pages",
    templateUrl: "./account-settings-landing-pages.component.html",
    styleUrls: [
        "./account-settings-landing-pages.component.scss"
    ]
})
export class AccountSettingsLandingPagesComponent implements OnInit, OnChanges {

    public accountSettings: IAccountSettings;

    public landingPages: ICustomPage[] = [];

    public selectedPage?: ICustomPage;

    public pageType = "enrollmentPages";

    public selectedType: string;

    public constructor(
        public route: ActivatedRoute,
        private adminFacade: AdminFacade,
        private alertService: PCCAlertService
    ) {}

    public ngOnInit(): void {
        this.route.parent.data.subscribe((data): void => {
            console.log("Account resp data here: ", data);
            if (data.accountData) {
                const acctResp: IAccountResponse = data.accountData;
                if (acctResp.success === true) {
                    this.setAccountSettings(acctResp.accountSettings);
                }
            }
        });
    }

    public ngOnChanges(changes: any): void {
        console.log("ngOnChanges: ", changes);
        this.ngOnInit();
    }

    public setAccountSettings(acctSettings: IAccountSettings): void {
        this.accountSettings = acctSettings;

        this.landingPages = this.accountSettings.customPages.filter((page: ICustomPage): boolean => page.pageType === CustomPageTypes.LAUNCH);
    }

    public addCustomPage(): void {
        console.log("addCustomPage");
        const newPage: ICustomPage = {
            pageType: "LAUNCH",
            accountSettingsId: this.accountSettings.account_settings_id,
            links: [],
            displayOrder: 0,
            localizedKeys: {},
            active: true
        };
        this.accountSettings.customPages.push(newPage);
        this.setSelectedPage(newPage);

        // Set the display order to end of existing pages
        this.renumberPages(this.accountSettings.customPages);
    }

    private renumberPages(pages: ICustomPage[]): ICustomPage[] {
        let order = 0;
        const updatedPages: ICustomPage[] = [];
        pages.forEach((page: ICustomPage): void => {
            if (page.displayOrder !== order) {
                // Update the page, and add it to list to be updated...
                page.displayOrder = order;
                updatedPages.push(page);
            }

            order += 1;
        });
        return updatedPages;
    }

    public setSelectedPage(page: ICustomPage): void {
        console.log("setSelectedPage: ", page);
        this.selectedPage = page;

        if (this.selectedPage?.id) {
            this.getSelectedPageDetails();
        }
    }

    public isSelected(page: ICustomPage): boolean {
        return page?.id && page?.id === this.selectedPage?.id;
    }

    private async getSelectedPageDetails(): Promise<void> {
        this.alertService.setBusy(true, "Loading page");
        try {
            const resp = await this.adminFacade.getCustomPage(this.selectedPage.id);
            console.log("customPageDetails resp: ", resp);
            if (resp.success) {
                this.selectedPage = resp.data;
            } else {
                console.error("Error pulling custom page details: ", resp);
            }
        } catch (err) {
            console.error("Error pulling custom page details: ", err);
        } finally {
            this.alertService.setBusy(false);
        }
    }

    public pageClicked(page: ICustomPage): void {
        console.log("existingPageClicked");
        this.setSelectedPage(page);
    }

    private async refreshCustomPages(): Promise<boolean> {
        try {
            // Note: service only returns active pages.
            const resp = await this.adminFacade.getCustomPages(this.accountSettings.account_settings_id);
            console.log("getCustomPages resp=", resp);
            if (resp.success) {
                this.accountSettings.customPages = resp.data;

                this.landingPages = this.accountSettings.customPages.filter((page: ICustomPage): boolean => page.pageType === CustomPageTypes.LAUNCH);
            }
            return true;
        } catch (err) {
            console.error("Error calling getCustomPages: ", err);
            return false;
        }
    }

    // How to optimize this so we don't have to go saving everything?
    // We don't have a parent object in some cases (system pages), so we'll need to make a call with all affected pages...
    private async savePageOrder(updatedPages: ICustomPage[]): Promise<void> {
        console.log("savePageOrder:", updatedPages);

        try {
            this.setBusy(true, "Updating page order...");

            let hasError = false;

            const updatePromises: Promise<IAPIResponseData<ICustomPage>>[] = [];
            updatedPages.forEach((page: ICustomPage): void => {
                updatePromises.push(this.saveCustomPage(page));
            });

            const updateResults = await Promise.all(updatePromises);
            updateResults.forEach((result: IAPIResponseData<ICustomPage>): void => {
                console.log("saveCustomPageResp=", result);
                if (result.success !== true) {
                    console.error("Save failed: ", result);
                    this.alertService.showError("Save failed");
                    hasError = true;
                }
            });

            if (hasError === false) {
                this.alertService.showToast("Page order updated");
            }
        } catch (err) {
            console.error("Error updating page order: ", err);
        } finally {
            console.log("Done saving!");
            this.setBusy(false);
        }
    }

    // Safe wrapper around adminService.saveCustomPage
    private async saveCustomPage(page: ICustomPage): Promise<IAPIResponseData<ICustomPage>> {
        try {
            const resp = await this.adminFacade.saveCustomPage(page);
            console.log("saveResp=", resp);
            if (resp.success !== true) {
                console.error("Save failed: ", resp);
            }
            return resp;
        } catch (err) {
            console.error("Error saving custom page: ", err);
            return {
                success: false,
                error: new PCCClientError("SYSTEM_ERROR", "Error thrown calling adminFacade.saveCustomPage", err)
            };
        }
    }

    private setBusy(isBusy: boolean, msg?: string): void {
        this.alertService.setBusy(isBusy, msg);
    }

    public pagesReordered(newPages: ICustomPage[]): void {
        const pages = this.renumberPages(newPages);
        this.savePageOrder(pages);
    }

    public async pageDeleted(page: ICustomPage): Promise<void> {
        console.log("pageDeleted", page);
        await this.refreshCustomPages();
        this.selectedPage = null;
    }

    public pageSaved(page: ICustomPage): void {
        console.log("pageSaved", page);
        this.refreshCustomPages();
    }

    public pageCanceled(page: ICustomPage): void {
        console.log("pageCanceled: ", page);
        this.refreshCustomPages();
    }

    public setPageType(pageType: string): void {
        console.log("setPageType: ", pageType);
        this.pageType = pageType;
    }

    public async landingPageEnabledChange(event: Event): Promise<void> {
        console.log("landingPageEnabledChange: ", this.accountSettings.flags.page_enabled_landing);

        const checkbox = event.target as HTMLInputElement;
        const isChecked = checkbox.checked;
        const name = checkbox.name;

        await this.adminFacade.updateFlag(this.accountSettings.account_settings_id, name as SettingsKey, isChecked);
    }

}
