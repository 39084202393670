import { Component, OnChanges, OnInit, Input, Output, EventEmitter } from "@angular/core";

import { IAccount } from "@shared/model/account";
import { IAccountSettings } from "@shared/model/account-settings";
import { INavItem } from "@client/shared/model/nav-item";

declare const spot$2: any;
declare const spot$3: any;
declare const spot$4: any;

@Component({
    selector: "pcc-account-nav",
    templateUrl: "./account-nav.component.html",
    styleUrls: [
        "./account-nav.component.scss"
    ]
})
export class AccountNavComponent implements OnChanges, OnInit {

    @Input() public account: IAccount;

    @Input() public accountSettings: IAccountSettings;

    @Input() public activeItem: string;

    @Output() public navClick = new EventEmitter<INavItem>();

    public navItems: INavItem[] = [];

    public ngOnInit(): void {
        spot$2.SplitButton.initialize();
        spot$3.Popover.initialize();
        spot$4.Panel.initialize();

        this.navItems.push({
            key: "accountSettings",
            displayName: "General",
            url: `/admin/config/${this.accountSettings.account_settings_id}/general`
        });

        if (!this.accountSettings.isCorp) {
            this.navItems.push({
                key: "profileItems",
                displayName: "Profile items",
                url: `/admin/config/${this.accountSettings.account_settings_id}/profile-items`
            });
        }

        this.navItems.push({
            key: "accountTemplate",
            displayName: "Template setup",
            url: `/admin/config/${this.accountSettings.account_settings_id}/templates`
        });

        this.navItems.push({
            key: "enrollPages",
            displayName: "Enrollment pages",
            url: `/admin/config/${this.accountSettings.account_settings_id}/enroll-pages`
        });

        this.navItems.push({
            key: "customPages",
            displayName: "Custom pages",
            url: `/admin/config/${this.accountSettings.account_settings_id}/learning-pages`
        });

        if (!this.activeItem && this.navItems.length) {
            this.activeItem = this.navItems[0].key;
        }
    }

    public ngOnChanges(changes: any): void {
        console.log("ngOnChanges: ", changes);
        this.setActiveItem(this.activeItem);
    }

    private setActiveItem(itemKey: string): void {
        console.log("setActiveItem: ", itemKey);
        this.activeItem = itemKey;
    }

    public navClicked(navItem: INavItem): void {
        console.log("navClicked: ", navItem);
        this.activeItem = navItem.key;

        this.navClick.emit(navItem);
    }
}
