<div class="pcc-edit-panel">
  <div class="pcc-edit-panel__header profile-item-header">
    <pcc-localized-text label="Display Name" [languages]="accountSettings?.languages" key="displayName" [localizedKey]="selectedTest.localizedKeys.displayName" [required]="true" (textChanged)="textChanged($event)" title="Display Name" style="flex:1;"></pcc-localized-text>

    <pcc-confirm-action *ngIf="selectedTest && selectedTest.profile_item_id" symbol="delete" confirmPosition="spot-popover--bottom-left" [useLink]="true" (okClick)="deleteProfileItemConfirmed(selectedTest)" class="delete-button" confirmMsg="profile-item-edit.confirm_delete" buttonText="Delete Profile Item"></pcc-confirm-action>
  </div>
  <div class="pcc-edit-panel__content" style="padding: 1em;">
    <form class="spot-form" id="itemForm" #itemForm="ngForm" name="itemForm">
      <!-- profile item active -->
      <div class="spot-form__field-group">
        <input type="checkbox" class="spot-toggle" id="isActive" [(ngModel)]="selectedTest.active" name="isActive" (change)="activeToggled()"/>
        <label class="spot-toggle-label" for="isActive">Active</label>
      </div>
      <div class="spot-form__field-group spot-form__field-group--horizontal">
        <!-- test type -->
        <div class="spot-form__field-group" style="flex:1;">
          <label class="spot-form__label" for="test_type">
            <span class="spot-form__label-required-indicator" title="This field is required." aria-hidden="true">*</span>
            Test Type
          </label>
          <div class="spot-form__select">
            <select class="spot-form__select-input" name="test_type" id="test_type" [(ngModel)]="selectedTest.test_type" (change)="testTypeSelected()" required>
              <option class="spot-form__select-option" name="" value=""></option>
              <option class="spot-form__select-option" *ngFor="let testType of testTypes" [value]="testType">{{testType}}</option>
            </select>

            <div class="spot-form__select-inner"></div>
            <span class="spot-form__select-open-indicator">
              <svg class="spot-icon spot-form__select-open-icon" aria-labelledby="title">
                <title>caret-down</title>
                <use xlink:href="/assets/spot-library/dist/symbols/spot_symbols.svg#caret-down"></use>
              </svg>
            </span>
          </div>
        </div>
        <div class="spot-form__field-group" style="flex:2;">
          <label class="spot-form__label" for="category">
            <span class="spot-form__label-required-indicator" title="This field is required." aria-hidden="true">*</span>
            Category
          </label>
          <div class="spot-form__select">
            <select class="spot-form__select-input" name="category" id="category" [(ngModel)]="selectedTest.category" (change)="categorySelected()" required [compareWith]="compareCategory">
              <option class="spot-form__select-option" name="" value=""></option>
              <option class="spot-form__select-option" *ngFor="let ctg of categories" [ngValue]="ctg">{{ctg.displayName}}</option>
            </select>
            <div class="spot-form__select-inner"></div>
            <span class="spot-form__select-open-indicator">
              <svg class="spot-icon spot-form__select-open-icon" aria-labelledby="title">
                <title>caret-down</title>
                <use xlink:href="/assets/spot-library/dist/symbols/spot_symbols.svg#caret-down"></use>
              </svg>
            </span>
          </div>
        </div>
      </div>

      <div class="spot-form__field-group spot-form__field-group--horizontal">

        <div class="spot-form__field-group" style="flex:1;">
          <label class="spot-form__label" for="countryCd">
            <span class="spot-form__label-required-indicator" title="This field is required." aria-hidden="true">*</span>
            Country
          </label>
          <div class="spot-form__select">
            <select class="spot-form__select-input" name="countryCd" id="countryCd" [(ngModel)]="selectedTest.countryCd" (change)="countrySelected()" required>
              <option class="spot-form__select-option" name="" value=""></option>
              <option class="spot-form__select-option" *ngFor="let country of countryList" [value]="country.code">{{country.code}} - {{country.name}}</option>
            </select>
            <div class="spot-form__select-inner"></div>
            <span class="spot-form__select-open-indicator">
              <svg class="spot-icon spot-form__select-open-icon" aria-labelledby="title">
                <title>caret-down</title>
                <use xlink:href="/assets/spot-library/dist/symbols/spot_symbols.svg#caret-down"></use>
              </svg>
            </span>
          </div>
        </div>

        <div class="spot-form__field-group" style="flex:2;">
          <label class="spot-form__label" for="modality">
            <span class="spot-form__label-required-indicator" title="This field is required." aria-hidden="true">*</span>
            Modality
          </label>
          <div class="spot-form__select">
            <select class="spot-form__select-input" name="modality" id="modality" [(ngModel)]="selectedTest.modality" required (change)="modalitySelected()" [compareWith]="compareModality">
              <option class="spot-form__select-option" name="" value=""></option>
              <option class="spot-form__select-option" *ngFor="let md of modalities" [ngValue]="md">{{md.name}}</option>
            </select>
            <div class="spot-form__select-inner"></div>
            <span class="spot-form__select-open-indicator">
              <svg class="spot-icon spot-form__select-open-icon" aria-labelledby="title">
                <title>caret-down</title>
                <use xlink:href="/assets/spot-library/dist/symbols/spot_symbols.svg#caret-down"></use>
              </svg>
            </span>
          </div>
        </div>
      </div>

      <div class="spot-form__field-group spot-form__field-group--horizontal">

        <div class="spot-form__field-group spot-form__field-group--horizontal" style="flex:1;">
          <fieldset class="spot-form__checkbox-group" role="group">
            <legend class="spot-form__checkbox-group-label">
              Species
            </legend>
            <div class="spot-form__checkbox-group-inner">
              <label class="spot-form__label" *ngFor="let spec of species" class="spot-form__checkbox" [for]="spec.species_id">
                <input type="checkbox" class="spot-form__checkbox-input" [id]="spec.species_id" (change)="speciesClicked(spec, false)" [checked]="isSpeciesSelected(spec)"/>
                <span class="spot-form__checkbox-inner">
                  <span class="spot-form__checkbox-visual">
                    <svg class="spot-icon spot-form__checkbox-checked-icon" aria-labelledby="title">
                      <title>checkmark</title>
                      <use xlink:href="/assets/spot-library/dist/symbols/spot_symbols.svg#checkmark"></use>
                    </svg>
                    <svg class="spot-icon spot-form__checkbox-indeterminate-icon" aria-labelledby="title">
                      <title>checkbox-indeterminate</title>
                      <use xlink:href="/assets/spot-library/dist/symbols/spot_symbols.svg#checkbox-indeterminate"></use>
                    </svg>
                  </span>
                  <span class="spot-form__checkbox-label"> {{ spec.display_name }} </span>
                </span>
              </label>
            </div>
          </fieldset>
        </div>

      </div>

      <div class="spot-form__field-group" *ngIf="priceEnabled">
        <label class="spot-form__label" for="price">Price</label>
        <input type="text" class="spot-form__input test-price" name="price" currencyMask [(ngModel)]="selectedTest.price"/>
      </div>

      <label *ngIf="productsEnabled" class="spot-form__checkbox" for="products_enabled">
        <input id="products_enabled" type="checkbox" class="spot-form__checkbox-input" [checked]="showProducts" (change)="showProductsChecked()" [readonly]="prodCount > 0"/>
        <span class="spot-form__checkbox-inner">
          <span class="spot-form__checkbox-visual">
            <svg class="spot-icon spot-form__checkbox-checked-icon" aria-labelledby="title">
              <title>checkmark</title>
              <use xlink:href="/assets/spot-library/dist/icons/spot_icons.svg#checkmark"></use>
            </svg>
            <svg class="spot-icon spot-form__checkbox-indeterminate-icon" aria-labelledby="title">
              <title>checkbox-indeterminate</title>
              <use xlink:href="/assets/spot-library/dist/icons/spot_icons.svg#checkbox-indeterminate"></use>
            </svg>
          </span>
          <span class="spot-form__checkbox-label">
            Products
          </span>
        </span>
      </label>
      <div *ngIf="showProducts" class="products">

        <div class="spot-form__field-group" *ngIf="prodCount > 0">
          <table class="table table-hover" aria-label="Products">
            <thead>
              <tr>
                <th scope="col">Name</th>
                <th scope="col">LIMS</th>
                <th scope="col">SAP Material</th>
                <th scope="col">Division</th>
                <th scope="col">Quantity</th>
                <th scope="col">Test Code</th>
                <th scope="col">LIMS Type</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let sp of selectedTest.products" (click)="productSelected(sp)">
                <td>{{sp.display_name}}</td>
                <td>{{sp.lims_src}}</td>
                <td>{{sp.sap_material_number}}</td>
                <td>{{sp.division}}</td>
                <td *ngIf="sp.sap_material_number!=null"><input type="number" [id]="sp.product_id" [name]="sp.product_id + '.default_quantity'" [(ngModel)]="sp.default_quantity"></td>
                <td *ngIf="sp.sap_material_number==null">{{sp.default_quantity}}</td>
                <td>{{sp.test_code}}</td>
                <td>{{sp.component_type}}</td>
                <td>
                  <button class="spot-button spot-button--secondary spot-button--icon-only spot-button--with-icon" style="box-shadow:none;" (click)="removeProduct(sp)">
                    <svg class="spot-icon spot-button__icon spot-button__icon--left" aria-labelledby="title">
                      <title>Delete</title>
                      <use xlink:href="/assets/spot-library/dist/symbols/spot_symbols.svg#delete"></use>
                    </svg>
                  </button>
                </td>
                <td>
                  <button class="spot-button spot-button--secondary spot-button--icon-only spot-button--with-icon" style="box-shadow:none;" (click)="refreshProduct(sp)">
                    <svg class="spot-icon spot-button__icon spot-button__icon--left" aria-labelledby="title">
                      <title>Refresh</title>
                      <use xlink:href="/assets/spot-library/dist/symbols/spot_symbols.svg#refresh-redo"></use>
                    </svg>

                  </button>
              </tr>
            </tbody>
          </table>
        </div>
        <form>
          <div class="form-row">
            <div class="col" *ngIf="selectedTest.modality && selectedTest.modality.developer_name == 'REF_LAB' || selectedTest.modality && selectedTest.modality.developer_name == 'REF_LAB_IHD'">
              <label class="spot-form__label" for="searchTestCode">Test Code</label>
              <input type="text" length=5 class="spot-form__input mb-2 mr-sm-2" id="searchTestCode" name="searchTestCode" [(ngModel)]="searchRequest.testCode"/>
            </div>
            <div class="col" *ngIf="selectedTest.modality && (selectedTest.modality.developer_name == 'SNAP' || selectedTest.modality.developer_name == 'IHD')">
              <label class="spot-form__label" for="searchProductSapMaterialNum">SAP Material Number</label>
              <input type="text" class="spot-form__input mb-2 mr-sm-2" id="searchProductSapMaterialNum" name="searchProductSapMaterialNum" [(ngModel)]="searchRequest.sapMaterialNum"/>
            </div>
            <button type="submit" class="spot-button spot-button-secondary" (click)="findProduct()" [disabled]="!validProductSearch()"><i class="fa-solid fa-search-plus fa-2x"></i></button>
          </div>
        </form>
        {{ message }}

        <div class="pcc-found-products" *ngIf="foundProducts && foundProducts.length > 0">
          <div *ngFor="let fp of foundProducts" class="pcc-found-test">
            <div class="card">
              <div class="card-header">
                <span class="found-product-title">{{fp.display_name}}</span>
                <button style="float:right;" title="Add product" (click)="addProduct(fp)"><i class="fa-solid fa-plus fa-2x"></i></button>
              </div>
              <div class="card-body">
                <table style="width:400px;" aria-label="Found Products">
                  <tr>
                    <th> </th><th> </th>
                  </tr>
                  <tr>
                    <td>{{fp.lims_src}}</td>
                  </tr>
                  <tr>
                    <td>{{fp.component_type}}</td>
                  </tr>
                  <tr>
                    <td>{{fp.test_code}}</td>
                    <td>{{fp.sap_material_number}}</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <label *ngIf="replacementRulesEnabled" class="spot-form__checkbox" for="replacement_rules_enabled">
        <input type="checkbox" id="replacement_rules_enabled" class="spot-form__checkbox-input" [checked]="showReplacementRules" (change)="replaceRulesChecked()" [readonly]="replaceRulesCount > 0"/>
        <span class="spot-form__checkbox-inner">
          <span class="spot-form__checkbox-visual">
            <svg class="spot-icon spot-form__checkbox-checked-icon" aria-labelledby="title">
              <title>checkmark</title>
              <use xlink:href="/assets/spot-library/dist/icons/spot_icons.svg#checkmark"></use>
            </svg>
            <svg class="spot-icon spot-form__checkbox-indeterminate-icon" aria-labelledby="title">
              <title>checkbox-indeterminate</title>
              <use xlink:href="/assets/spot-library/dist/icons/spot_icons.svg#checkbox-indeterminate"></use>
            </svg>
          </span>
          <span class="spot-form__checkbox-label">
            Replacement Rules
          </span>
        </span>
      </label>
      <div *ngIf="showReplacementRules" class="replacement-rules">
        <div class="spot-form__field-group" *ngIf="replaceRulesCount > 0">
          <table class="table table-hover" aria-label="Replacement Rules">
            <thead>
              <tr>
                <th scope="col">From Product</th>
                <th scope="col">Replacement Product</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let sp of selectedTest.replacementRules">
                <td>{{sp.from_product.display_name}} ({{sp.from_product.test_code}})</td>
                <td>{{sp.to_product.display_name}} ({{sp.to_product.test_code}})</td>
                <td>
                  <button class="spot-button spot-button--secondary spot-button--icon-only spot-button--with-icon" (click)="removeReplaceRule(sp)" style="box-shadow:none;">
                    <svg class="spot-icon spot-button__icon spot-button__icon--left" aria-labelledby="title">
                      <title>Delete</title>
                      <use xlink:href="/assets/spot-library/dist/symbols/spot_symbols.svg#delete"></use>
                    </svg>
                  </button>
              </tr>
            </tbody>
          </table>
        </div>

        <form>
          <div class="form-row">
            <div class="col" >
              <label class="spot-form__label" for="fromProductId">From Product</label>
              <input type="text" class="spot-form__input mb-2 mr-sm-2" id="fromProductId" name="fromProductId" length=5 [(ngModel)]="replaceRuleRequest.from_test_code"/>
            </div>
            <div class="col" >
              <label class="spot-form__label" for="toProductId">To Product</label>
              <input type="text" class="spot-form__input mb-2 mr-sm-2" id="toProductId" name="toProductId" length=5 [(ngModel)]="replaceRuleRequest.to_test_code"/>
            </div>
            <button type="submit" class="spot-button spot-button--secondary" (click)="findReplaceRule()" [disabled]="!validRuleSearch()"><i class="fa-solid fa-search-plus fa-2x"></i></button>
            {{replaceRuleMsg}}
          </div>
        </form>
      </div>
      <br/>
      <br/>
    </form>
  </div>
  <div class="pcc-edit-panel__footer button-panel">
    <button type="cancel" class="pcc-edit-panel__footer-cancel-button" (click)="cancel()">Cancel</button>
    <button type="submit" class="spot-button spot-button--primary" [disabled]="!saveEnabled && !saving" (click)="save()">Save</button>
  </div>
</div>
