import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

import { IProfileItem, PROFILE_ITEM_TYPES, ItemUtils } from "@shared/model/profile-item";
import { IProfileCategory } from "@shared/model/profile-category";
import { ITestSelection } from "../../model/test-selection";

@Component({
    selector: "pcc-profile-category",
    templateUrl: "./profile-category.component.html",
    styleUrls: [
        "./profile-category.component.scss"
    ]
})
export class ProfileCategoryComponent implements OnInit {

    @Input() public testCtg: IProfileCategory;

    @Input() public selectedTests: IProfileItem[];

    @Output() public testSelected = new EventEmitter<IProfileItem[]>();

    public testSelection: ITestSelection = {
    };

    public sortedProfileItems: IProfileItem[];

    public ngOnInit(): void {

        if (this.testCtg && this.testCtg.profileItems) {
            // Sort the items so standard items show up first, then others, then add-ons last
            this.sortedProfileItems = this.sortProfileItems(this.testCtg.profileItems);
        }

        if (this.selectedTests) {
            this.selectedTests.forEach((tc: IProfileItem): void => {
                if (ItemUtils.isAddOn(tc)) {
                    this.testSelection.addOn = tc;
                } else {
                    this.testSelection.test = tc;
                }
            });
        }
    }

    public clearTest(): void {
        console.log("clearTest");

        this.testSelection = {
        };
        delete this.selectedTests;

        this.testSelected.emit(this.selectedTests);
    }

    /**
     * An available test was clicked in the view.  This could be either a test or an add-on.
     * For a single category, we can have at most one test and one add-on.
     */
    public testClicked(clickEvent: { test: IProfileItem, selected: boolean }): void {
        console.log("testClicked: ", clickEvent, this.testSelection);

        if (this.shouldIgnore(clickEvent)) {
            console.log("Ignoring click event: ", clickEvent);
            return;
        }

        if (ItemUtils.isAddOn(clickEvent.test)) {
            this.handleAddOnClick(clickEvent);
        } else {
            this.handleTestClick(clickEvent);
        }

        this.selectedTests = Object.values(this.testSelection);

        this.testSelected.emit(this.selectedTests);
    }

    /*
     * Add-on button clicked.
     * Manage this.testSelection.addOn
     */
    private handleAddOnClick(clickEvent: { test: IProfileItem, selected: boolean }): void {
        const isSelected = clickEvent.selected;
        if (isSelected) {
            this.testSelection.addOn = clickEvent.test;
        } else {
            delete this.testSelection.addOn;
        }
    }

    /*
     * Standard test button clicked.
     * Manage this.testSelection.test
     */
    private handleTestClick(clickEvent: { test: IProfileItem, selected: boolean }): void {
        const isSelected = clickEvent.selected;
        if (isSelected) {
            this.testSelection.test = clickEvent.test;
        } else {
            // Note: if category is required and isSelected is false, this event is ignored
            // above in shouldIgnore()
            delete this.testSelection.test;
        }
    }

    /**
     * Add-on button is only enabled once a standard test is first selected.
     */
    public isAddOnEnabled(): boolean {
        let retVal = false;

        if (this.testSelection && this.testSelection.test) {
            retVal = true;
        }

        return retVal;
    }

    public testMatches(testComp: IProfileItem, matchItem?: IProfileItem): boolean {
        if (testComp && matchItem) {
            return testComp.profile_item_id === matchItem.profile_item_id;
        }

        return testComp === matchItem;
    }

    public isSelected(testComp: IProfileItem): boolean {
        if (!testComp) {
            return false;
        }

        return testComp === this.testSelection.test || testComp === this.testSelection.addOn;
    }

    /**
     * Ignore click events that don't change the state of selected items.
     * For example, clicking an already selected test on a required category item.
     * It won't toggle off since it's required, so won't change state of selected test.
     */
    public shouldIgnore(clickEvent: { test: IProfileItem, selected: boolean }): boolean {
        if (!clickEvent) {
            return true;
        }

        const testComp = clickEvent.test;
        const isSelected = clickEvent.selected;
        if (!testComp) {
            return true;
        }
        if (ItemUtils.isAddOn(testComp)) {
            if (!this.isAddOnEnabled()) {
                console.log("Add on not currently enabled");
                return true;
            }
            // If currently selected add on already matches, ignore if state is selected.
            if (this.testSelection.addOn === testComp) {
                return isSelected;
            }
            return false;
        }

        // Regular profile item.  Ignore if already selected and isSelected is true or if isSelected is false and testCtg.is_required is true.
        return (!isSelected && this.testCtg.is_required)
            || this.testMatches(this.testSelection.test, testComp) && isSelected;
    }

    private sortProfileItems(profileItems: IProfileItem[]): IProfileItem[] {
        return [
            ...profileItems
        ].sort((a: IProfileItem, b: IProfileItem): number => {
            if (a.display_order === b.display_order) {
                if (a.test_type === b.test_type) {
                    return 0;
                }
                const at = Object.keys(PROFILE_ITEM_TYPES).indexOf(a.test_type);
                const bt = Object.keys(PROFILE_ITEM_TYPES).indexOf(b.test_type);
                return at - bt;
            }
            return a.display_order - b.display_order;
        });
    }
}
