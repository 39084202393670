<span class="account-name-section">
  <span class="account-name">{{account?.name}} ({{account?.sap_id}})</span>
  &nbsp;&nbsp;
  <pcc-confirm-action class="confirm-action" buttonText="client-header.account_change" symbol="" confirmPosition="spot-popover--center-right" (okClick)="goHome()"></pcc-confirm-action>
</span>
<span class="account-controls" *ngIf="hasTestMix || hasCorpInfo || enablePreview">
  <span class="resources"> {{ 'client-header.resources' | translate }} </span>
  <a class="spot-link account-info" (click)="showCorpInfo()" [title]="'client-header.corp_info' | translate" *ngIf="hasCorpInfo">
    {{ 'client-header.corp_info' | translate }}
  </a>
  <a class="spot-link account-info" (click)="showTestMix()" [title]="'client-header.test_mix' | translate" *ngIf="hasTestMix">
    {{ 'client-header.test_mix' | translate }}
  </a>
  <a #pdfButton class="spot-link account-info" (click)="pdfPreview()" title="'account.pdf_preview' | translate" *ngIf="enablePreview">
    {{ 'account.pdf_preview' | translate }}
  </a>
</span>
