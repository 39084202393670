import { Component, OnInit, OnChanges, AfterViewInit, Input, Output, ViewChild, EventEmitter, SimpleChanges } from "@angular/core";

import { NgForm } from "@angular/forms";

import { UtilService } from "@shared/service/util.service";
import { ErrorUtils } from "@shared/model/error/error-utils";
import { AdminFacade } from "../../../facade/admin.facade";
import { IProduct } from "@shared/model/product";
import { IProfileItem, PROFILE_ITEM_TYPES, ProfileItemType, ItemUtils, IReplaceRule } from "@shared/model/profile-item";
import { ModalityEnum, IModality } from "@shared/model/modality";
import { ICategory } from "@shared/model/category";
import { ISpecies } from "@shared/model/species";
import { IProductRequest, IProductResponse } from "@shared/model/service/product-service";
import { ISystemSettings } from "@shared/model/system-settings";
import { PCCAlertService } from "../../../service/alert.service";
import { Country } from "@shared/model/country";
import { IAccountSettings } from "@shared/model/account-settings";
import { LocalizedString } from "@shared/model/language";
import { PCCTranslateService } from "../../../service/translate.service";

import _ from "lodash";

@Component({
    selector: "pcc-profile-item-edit",
    templateUrl: "./profile-item-edit.component.html",
    styleUrls: ["./profile-item-edit.component.scss"]
})
export class ProfileItemEditComponent implements OnInit, OnChanges, AfterViewInit {

    @Input() public selectedTest: IProfileItem;

    @Input() public systemSettings: ISystemSettings;

    @Input() public accountSettings: IAccountSettings;

    @Output() public itemSaved = new EventEmitter<IProfileItem>();

    @Output() public itemCanceled = new EventEmitter<IProfileItem>();

    @Output() public itemDeleted = new EventEmitter<IProfileItem>();

    @ViewChild("itemForm", {
        static: false
    }) public itemForm: NgForm;

    public testTypes: ProfileItemType[] = Object.values(PROFILE_ITEM_TYPES);

    public categories: ICategory[];

    public countryList: Country[] = [];

    public profileItems: IProfileItem[] = [];

    public species: ISpecies[];

    public modalities: IModality[];

    public selectedFoundProduct: IProduct;

    public products: IProduct[];

    public selectedProduct: IProduct;

    public prodCount: number;

    public replaceRulesCount: number;

    public replaceRuleMsg: string;

    // Controls if products form is visible (opened) in the form.
    public showProducts = false;

    // Controls if replacement rules form is visible (opened) in the form.
    public showReplacementRules = false;

    public priceEnabled = false;

    // Controls if testType supports products.
    public productsEnabled = true;

    // Controls if testType supports replacement rules.
    public replacementRulesEnabled = true;

    public searchRequest: IProductRequest = {
        testCode: null,
        sapMaterialNum: null,
        limsNames: [
            "LYNXX US"
        ],
        modalityCd: ModalityEnum.REF_LAB.value
    };

    public replaceRuleRequest = {
        from_test_code: null,
        to_test_code: null,
    };

    public saveEnabled = false;

    public saving = false;

    public message: string;

    public foundProducts: IProduct[];

    public editName = false;

    public constructor(
        private adminFacade: AdminFacade,
        private alertService: PCCAlertService,
        protected translateService: PCCTranslateService
    ) {}

    public async ngOnInit(): Promise<void> {
        if (this.systemSettings) {
            this.initData();
        }
    }

    private async initData(): Promise<void> {
        console.log("initData");
        this.products = this.systemSettings.products;
        this.categories = this.getSortedCategories(this.systemSettings.categories);
        this.species = this.systemSettings.species;
        this.modalities = this.systemSettings.modalities;

        this.countryList = this.systemSettings.countries;

    }

    public ngOnChanges(changes: SimpleChanges): void {
        console.log("ngOnChanges: ", changes);

        if (this.systemSettings) {
            this.initData();
        }

        this.clearSearch();
        this.clearRuleSearch();

        this.prodCount = this.getProductCount();
        this.replaceRulesCount = this.getReplaceRulesCount();

        this.showProducts = !this.selectedTest?.profile_item_id || (this.prodCount > 0);
        this.showReplacementRules = (this.replaceRulesCount > 0);

        // Scroll back to top of form
        const element = document.querySelector("#itemForm");
        element.scrollIntoView();
    }

    public ngAfterViewInit(): void {
        console.log("this.itemForm=", this.itemForm);
        if (this.itemForm) {
            this.itemForm.statusChanges.subscribe((status): void => {
                console.log("itemForm status change: ", status);
                this.updateButtons();
            });

            this.itemForm.valueChanges.subscribe((value): void => {
                console.log("value=", value);
                console.log("itemForm value change: ", value);
                this.findInvalidControls();
            });
        } else {
            console.error("this.itemForm doesn't exist!");
        }
    }

    public findInvalidControls(): string[] {
        const invalid = [];
        const controls = this.itemForm.controls;
        for (const name in controls) {
            if (controls[name].invalid) {
                invalid.push(name);
            }
        }
        console.log("invalid=", invalid);
        return invalid;
    }

    public updateButtons(): void {
        let valid = this.itemForm.form.valid;
        console.log("valid errors: ", this.itemForm.form.errors);
        console.log("this.itemForm.form.status=", this.itemForm.form.status);

        console.log(`form valid: ${valid}`);
        valid = valid && this.selectedTest && this.hasDisplayName();

        const locales = this.accountSettings.supportedLocales;
        if (!this.translateService.allLocalesPopulated(this.selectedTest.localizedKeys.displayName, locales)) {
            console.error("Not all locales are populated: ", this.selectedTest.localizedKeys.displayName, locales);
            valid = false;
        }

        this.saveEnabled = valid;
    }

    public cancel(): void {
        console.log("cancel");
        this.itemCanceled.emit(this.selectedTest);
    }

    public async save(): Promise<boolean> {
        console.log("save test", this.selectedTest);

        // We need some text to use to generate a developer_name here.
        // This is used in the pricing algorithm for corp.
        const displayName = this.translateService.getDefaultValue(this.selectedTest.localizedKeys.displayName);
        this.selectedTest.developer_name = this.selectedTest.developer_name || UtilService.cleanName(`${this.selectedTest.countryCd} ${displayName}`);

        this.alertService.setBusy(true, "Saving...");

        this.saving = true;

        try {

            // Add account settings id here so custom text can be persisted server-side.
            // Account settings is is not persisted to database.
            this.selectedTest.accountSettingsId = this.accountSettings.account_settings_id;

            const resp = await this.adminFacade.saveProfileItem(this.selectedTest);
            console.log("saveProfileItem result: ", resp);

            this.alertService.setBusy(false);
            this.saving = false;

            if (resp) {
                this.alertService.showToast("Save sucessful");

                this.itemSaved.emit(this.selectedTest);

                await this.translateService.updateDataTranslations(this.accountSettings.account_settings_id);

                return true;
            }
            this.alertService.showError(ErrorUtils.getErrorMessage(resp.error),
                "Error saving test: ");
            return false;
        } catch (err) {
            console.error("Error saving test: ", err);
            this.alertService.showError(ErrorUtils.getErrorMessage(err),
                "Error saving test: ");
            this.alertService.setBusy(false);

            this.saving = false;

            return false;
        }
    }

    public setSelectedProduct(product: IProduct): void {
        console.log("setSelectedProduct: ", product);
        this.selectedProduct = product;
    }

    public refreshProduct(product: IProduct): void {
        console.log("refreshProduct: ", product);
        this.searchRequest.testCode = product.test_code;
        this.searchRequest.sapMaterialNum = product.sap_material_number;
        this.searchRequest.limsNames = this.getLimsNames(this.selectedTest.countryCd);
        this.findProduct(product);
    }

    public async findProduct(existProduct?: IProduct): Promise<void> {
        console.log("findProduct: ", this.searchRequest, "existProduct=", existProduct);

        delete this.selectedProduct;
        delete this.message;
        delete this.foundProducts;

        // TODO: More work here to catch duplicates once I figure out tests->products logic...
        const isRefresh = (existProduct != null && existProduct.product_id != null);

        if (!existProduct) {
            existProduct = this.findExistingProduct(this.products, this.searchRequest);
        }

        if (!isRefresh && existProduct) {
            console.log("Product already exists!", existProduct);
            this.addProduct(existProduct);

            this.clearSearch();
            this.updateButtons();

            return;
        }

        this.alertService.setBusy(true, "Searching...");

        this.searchRequest.modalityCd = this.selectedTest.modality.developer_name;

        try {
            const resp: IProductResponse = await this.adminFacade.findProduct(this.searchRequest);
            this.alertService.setBusy(false);
            console.log("Found products: ", resp);
            if (resp && resp.products && resp.products.length > 0) {
                this.foundProducts = resp.products;
                console.log("foundProducts = ", this.foundProducts);

                if (resp.products.length === 1) {
                    if (isRefresh) {
                        // Merge current product id with found product to get latest display name, etc.
                        resp.products[0].product_id = existProduct.product_id;
                    }

                    this.addProduct(resp.products[0]);
                    delete this.foundProducts;
                }

                this.clearSearch();
            } else {
                this.message = "No results found";
            }
        } catch (err) {
            console.error(`Error finding product: ${this.searchRequest}`, err);
            this.alertService.setBusy(false);
            this.alertService.showError("Error finding product", ErrorUtils.getErrorMessage(err));
        }
    }

    private findExistingProduct(products: IProduct[], searchRequest: IProductRequest): IProduct {
        return products.find((t: IProduct): boolean => {
            if (ModalityEnum.REF_LAB.equals(searchRequest.modalityCd)) {
                return searchRequest.testCode && (t.test_code === searchRequest.testCode);
            }
            return searchRequest.sapMaterialNum && (t.sap_material_number === searchRequest.sapMaterialNum);
        });
    }

    public async findReplaceRule(): Promise<void> {
        console.log("findReplaceRule: ", this.replaceRuleRequest);

        delete this.selectedProduct;
        delete this.message;
        delete this.foundProducts;
        delete this.replaceRuleMsg;

        const existRule = this.selectedTest.replacementRules.find((t: IReplaceRule): boolean =>
            this.replaceRuleRequest.from_test_code === t.from_product.test_code
            && this.replaceRuleRequest.to_test_code === t.to_product.test_code
        );
        if (existRule) {
            this.replaceRuleMsg = "Rule already present";
            return;
        }
        const newRule: IReplaceRule = {
            from_product: null,
            to_product: null
        };

        // TODO: products don't have lims context code defined.
        // If there is a cross-over from MEM to VAR/TOR, we could have a problem.
        let foundProduct = _.find(this.products, (p: IProduct): boolean =>
            p.test_code === this.replaceRuleRequest.from_test_code
        );
        console.log("results of looking for from product: ", this.replaceRuleRequest.from_test_code, foundProduct);
        if (foundProduct) {
            newRule.from_product = foundProduct;
        }

        foundProduct = this.products ? this.products.find((p: IProduct): boolean =>
            p.test_code === this.replaceRuleRequest.to_test_code
        ) : undefined;
        console.log("results of looking for to product: ", this.replaceRuleRequest.to_test_code, foundProduct);
        if (foundProduct) {
            newRule.to_product = foundProduct;
        }

        this.alertService.setBusy(true, "Searching...");

        if (!newRule.from_product) {
            console.log("Searching for from product...");
            const testCode = this.replaceRuleRequest.from_test_code;
            const searchReq: IProductRequest = {
                testCode,
                limsNames: this.getLimsNames(this.selectedTest.countryCd),
                modalityCd: ModalityEnum.REF_LAB.value
            };

            const fromProduct = await this.findSingleProduct(searchReq);
            console.log("fromProduct=", fromProduct);
            if (!fromProduct) {
                this.replaceRuleMsg = `Product not found: ${testCode}`;
                this.alertService.setBusy(false);
                return;
            }
            newRule.from_product = fromProduct;
        }

        if (!newRule.to_product) {
            console.log("Searching for to product...");
            const testCode = this.replaceRuleRequest.to_test_code;
            const searchReq: IProductRequest = {
                testCode,
                limsNames: this.getLimsNames(this.selectedTest.countryCd),
                modalityCd: ModalityEnum.REF_LAB.value
            };

            const toProduct = await this.findSingleProduct(searchReq);
            console.log("toProduct=", toProduct);
            if (!toProduct) {
                this.replaceRuleMsg = `Product not found: ${testCode}`;
                return;
            }
            newRule.to_product = toProduct;
        }

        console.log("Finished result: ", this.selectedTest);
        this.selectedTest.replacementRules.push(newRule);

        this.alertService.setBusy(false);

        this.clearRuleSearch();

        this.replaceRulesCount = this.getReplaceRulesCount();
    }

    public async findSingleProduct(prodReq: IProductRequest): Promise<IProduct> {
        const prodResp = await this.findProduct2(prodReq);
        if (!prodResp.success) {
            console.log("Error searching for product: ", prodResp);
            return null;
        }
        if (!prodResp.products || prodResp.products.length === 0) {
            console.error("No product found!");
            return null;
        }
        if (prodResp.products.length > 1) {
            console.error("Multiple products found!", prodResp.products);
        }
        return prodResp.products[0];
    }

    public async findProduct2(prodReq: IProductRequest): Promise<IProductResponse> {
        console.log("findProduct2: ", prodReq);
        try {
            const prodResp = await this.adminFacade.findProduct(prodReq);
            console.log("Found products: ", prodResp);
            return prodResp;
        } catch (err) {
            console.error(`Error finding product: ${prodReq}`, err);
            return null;
        }
    }

    public clearSearch(): void {
        console.log("clearSearch");
        delete this.searchRequest.testCode;
        delete this.searchRequest.sapMaterialNum;
    }

    public clearRuleSearch(): void {
        console.log("clearRuleSearch");
        delete this.replaceRuleRequest.from_test_code;
        delete this.replaceRuleRequest.to_test_code;
    }

    public getProductCount(): number {
        let count = 0;
        if (this.selectedTest && this.selectedTest.products) {
            count = this.selectedTest.products.length;
        }
        return count;
    }

    public getReplaceRulesCount(): number {
        let count = 0;
        if (this.selectedTest && this.selectedTest.replacementRules) {
            count = this.selectedTest.replacementRules.length;
        }
        return count;
    }

    public hasNoProducts(): boolean {
        return this.selectedTest && (!this.selectedTest.products || this.selectedTest.products.length > 0);
    }

    public hasSingleProduct(): boolean {
        return this.selectedTest
            && this.selectedTest.products
            && this.selectedTest.products.length === 1;
    }

    public categorySelected(): void {
        console.log("categorySelected: ", this.selectedTest);
    }

    public modalitySelected(): void {
        console.log("modalitySelected: ", this.selectedTest);
    }

    public countrySelected(): void {
        console.log("countrySelected: ", this.selectedTest.countryCd);
        this.searchRequest.limsNames = this.getLimsNames(this.selectedTest.countryCd);
    }

    // TODO: Refactor so not limited to known countries?
    public getLimsNames(countryCd?: string): string[] {
        console.log("getLimsNames for countryCd: ", countryCd);
        if (!countryCd) {
            return null;
        }

        let limsNames: string[];
        if (countryCd === "US") {
            limsNames = [
                "LYNXX US"
            ];
        } else if (countryCd === "CA") {
            limsNames = [
                "LYNXX CA East",
                "LYNXX CA West"
            ];
        } else if (countryCd === "AU") {
            limsNames = [
                "LYNXX AU"
            ];
        }

        if (!limsNames) {
            console.warn("No lims defined for country code: ", countryCd);
        }
        return limsNames;
    }

    public testTypeSelected(): void {
        this.setTestType(this.selectedTest.test_type);
    }

    private setTestType(testType: ProfileItemType): void {
        console.log("setTestType: ", testType);
        const isStatic = testType === PROFILE_ITEM_TYPES.STATIC;

        this.showProducts = !isStatic;
        this.priceEnabled = !isStatic;
        this.productsEnabled = !isStatic;
        this.replacementRulesEnabled = !isStatic;
    }

    public productSelected(prod: IProduct): void {
        console.log("productSelected: ", prod);
    }

    public addProduct(newProduct: IProduct): void {
        console.log("addProduct", newProduct);
        console.log("this.selectedTest = ", this.selectedTest);

        if (!newProduct.test_code) {
            newProduct.default_quantity = newProduct.default_quantity || 1;
        }

        const idx = this.selectedTest.products.findIndex((p: IProduct): boolean =>
            (p.test_code && newProduct.test_code && p.test_code === newProduct.test_code)
            || (p.sap_material_number && newProduct.sap_material_number && p.sap_material_number === newProduct.sap_material_number)
        );
        if (idx === -1) {
            this.selectedTest.products.push(newProduct);
        } else {
            const existProd = this.selectedTest.products[idx];
            existProd.sap_material_number = newProduct.sap_material_number;
            existProd.division = newProduct.division;
        }

        this.foundProducts = this.foundProducts ? this.foundProducts.filter((product: IProduct): boolean => newProduct.sap_material_number !== product.sap_material_number) : []

        if (!this.hasDisplayName()) {
            const prodDisplayName = newProduct.display_name || newProduct.test_code || newProduct.sap_material_number;
            this.translateService.setLocalizedText(this.selectedTest.localizedKeys, "displayName", prodDisplayName, this.accountSettings.supportedLocales);
        }

        this.prodCount = this.getProductCount();
    }

    private hasDisplayName(locale?: string): boolean {
        if (!this.selectedTest) {
            return false;
        }
        return UtilService.trimToNull(
            this.translateService.getDefaultValue(this.selectedTest.localizedKeys.displayName, locale || this.accountSettings.defaultLocale)
        ) !== null;
    }

    public speciesClicked(spec: ISpecies, isSelected: boolean): void {
        console.log("spec selected: ", spec.display_name, isSelected);
        if (isSelected) {
            this.addSpecies(this.selectedTest, spec);
        } else {
            this.removeSpecies(this.selectedTest, spec);
        }
        console.log("selectedSpecies: ", this.selectedTest.speciesList);
    }

    public addSpecies(pi: IProfileItem, spec: ISpecies): void {
        console.log("addSpecies: ", spec);
        if (pi && spec) {
            pi.speciesList = pi.speciesList || [];
            const ix = pi.speciesList.findIndex((s: ISpecies): boolean =>
                s.species_id === spec.species_id
            );
            if (ix === -1) {
                pi.speciesList.push(spec);
            } else {
                console.log("Species list already contains: ", spec);
            }
        }
    }

    public removeSpecies(pi: IProfileItem, spec: ISpecies): void {
        console.log("removeSpecies: ", spec);
        if (pi && spec) {
            pi.speciesList = pi.speciesList || [];
            pi.speciesList = pi.speciesList.filter((species: ISpecies): boolean => species.species_id !== spec.species_id);
        }
    }

    public removeProduct(p: IProduct): void {
        console.log("removeProduct", p);
        this.selectedTest.products = this.selectedTest.products ? this.selectedTest.products.filter((n: IProduct): boolean =>
            ItemUtils.equalsProducts(p, n)
        ) : [];
        this.prodCount = this.getProductCount();
    }

    public removeReplaceRule(ruleToDelete: IReplaceRule): void {
        console.log("removeReplaceRule", ruleToDelete);
        this.selectedTest.replacementRules = this.selectedTest.replacementRules.filter((replacementRule: IReplaceRule): boolean => {
            if (ruleToDelete.replacement_rule_id) {
                return (ruleToDelete.replacement_rule_id !== replacementRule.replacement_rule_id);
            }
            return !(replacementRule.from_product.product_id === ruleToDelete.from_product.product_id
                && replacementRule.to_product.product_id === ruleToDelete.to_product.product_id);
        });
        this.replaceRulesCount = this.getReplaceRulesCount();
    }

    public async deleteProfileItemConfirmed(profileItem: IProfileItem): Promise<void> {
        try {
            const testId = profileItem.profile_item_id;
            const resp = await this.adminFacade.deleteProfileItem(testId);
            console.log("deleteProfileItem results: ", resp);
            this.alertService.showToast("Profile Item deleted.");

            this.itemDeleted.emit(profileItem);
        } catch (err) {
            console.error("Error deleting Profile Item: ", err);
            this.alertService.showError("Error deleting Profile Item", ErrorUtils.getErrorMessage(err));
        }
    }

    public validProductSearch(): boolean {
        return !!(this.searchRequest
            && this.searchRequest.modalityCd
            && (this.searchRequest.testCode || this.searchRequest.sapMaterialNum));
    }

    public validRuleSearch(): boolean {
        return !!(this.replaceRuleRequest
            && this.replaceRuleRequest.from_test_code
            && this.replaceRuleRequest.to_test_code
            && this.replaceRuleRequest.from_test_code != this.replaceRuleRequest.to_test_code);
    }

    public replaceRulesChecked(): void {
        console.log("replaceRulesChecked");
        this.showReplacementRules = !this.showReplacementRules;
        console.log("Now: this.showReplacementRules=", this.showReplacementRules);
    }

    public showProductsChecked(): void {
        console.log("showProductsChecked");
        this.showProducts = !this.showProducts;
        console.log("Now: this.showProducts=", this.showProducts);
    }

    public isSpeciesSelected(spec: ISpecies): boolean {
        return this.selectedTest.speciesList.some((s: ISpecies): boolean =>
            s.species_id === spec.species_id
        );
    }

    public compareCategory(c1: ICategory, c2: ICategory): boolean {
        return c1 && c2 ? c1.category_id === c2.category_id : c1 === c2;
    }

    public compareModality(m1: IModality, m2: IModality): boolean {
        return m1 && m2 ? m1.modality_id === m2.modality_id : m1 === m2;
    }

    private getSortedCategories(categories: ICategory[]): ICategory[] {
        return categories.sort((ctg1: ICategory, ctg2: ICategory): number => ctg1.display_order - ctg2.display_order);
    }

    public async activeToggled(): Promise<void> {
        if (!this.selectedTest?.profile_item_id) {
            // New profile item.  Don't auto-save until user first persists this item.
            return;
        }

        try {
            this.alertService.setBusy(true, "Saving...");

            const resp = await this.adminFacade.setProfileItemActive(this.selectedTest);
            console.log("setProfileItemActive resp: ", resp);

            this.alertService.setBusy(false);

            if (resp && resp.success) {
                this.alertService.showToast("Save sucessful");
                return;
            }

            this.alertService.showError(ErrorUtils.getErrorMessage(resp.error),
                "Error saving active status: ");

        } catch (err) {
            this.alertService.showError(ErrorUtils.getErrorMessage(err),
                "Error saving active status: ");
        }

    }

    public editNameClicked(): void {
        this.editName = !this.editName;
    }

    public textChanged(textInfo: LocalizedString): void {
        console.log("localizedTextChanged: ", textInfo);
        this.translateService.updateLocalizedKey(textInfo, this.selectedTest.localizedKeys);

        this.updateButtons();
    }
}
