import { EnvironmentEnum } from "../../../shared/model/environment";
import { ISystemSettings } from "../../../shared/model/system-settings";
import { EnvConfig } from "@shared/model/env-config";

export const envUtils = {

    SSO_CLIENT_ID: "",
    SSO_LOGIN_URL: "",
    HEROKU_APP_NAME: "",
    ENVIRONMENT: "",
    ENV_HEADER: "",
    RELEASE: "",
    TRAINING_ENABLED: "",

    GOOGLE_TARGET_ID: "",
    HANDLING_EMAIL: "",

    APP_IRL_URL: "",
    APP_EVC_URL: "",

    SERVICE_TARGET: "",

    setConfig(configResp: EnvConfig): void {
        Object.assign(this, configResp);
    },

    getEnvironment(): EnvironmentEnum {
        const envName = this.ENVIRONMENT;

        return EnvironmentEnum.forString(envName);
    },

    isProd(): boolean {
        return this.getEnvironment() === EnvironmentEnum.PROD;
    },

    isTraining(): boolean {
        return this.getEnvironment() === EnvironmentEnum.TRAINING
            || this.TRAINING_ENABLED === true
            || this.TRAINING_ENABLED === "true";
    },

    getEnvHeader(): string {
        const headerStr = this.ENV_HEADER;
        return headerStr || "";
    },

    update(sysSettings: ISystemSettings): void {
        console.log("envUtils.update: ", sysSettings);
        this.TRAINING_ENABLED = (sysSettings.trainingEnabled === true);
    }

};
