<div class="page-header">
  <span class="spot-typography__heading--level-2">Language and Translations</span>
</div>
<div class="page-body">
  <div class="page-section" style="display:block;padding: 10px;overflow:hidden;">
    <div class="language-view">
      <form id="pageForm"  name="pageForm" style="padding:10px;">
        <div style="display:flex;align-items:center;gap:1em;">
	      <div class="spot-form__field-group" style="width:300px;">
	        <label class="spot-form__label" for="langCode" id="langCode">
              <span class="spot-form__label-required-indicator">*</span>
              Language
            </label>
	        <div class="spot-form__select">
              <select name="lang" class="spot-form__select-input" [(ngModel)]="selectedLocale" (change)="selectedLocaleChanged()" required>
	            <option class="spot-form__select-option" value="">Select an language</option>
	            <option class="spot-form__select-option" *ngFor="let locale of availLocales" [value]="locale.tag">{{ locale.name }} ( {{ locale.tag }} )</option>
	          </select>
	          <div class="spot-form__select-inner"></div>
	          <span class="spot-form__select-open-indicator">
                <svg class="spot-icon spot-form__select-open-icon" aria-labelledby="title">
                  <title>caret-down</title>
                  <use xlink:href="/assets/spot-library/dist/symbols/spot_symbols.svg#caret-down"></use>
                </svg>
	          </span>
	        </div>
	      </div>
          <button class="spot-button spot-button--primary" (click)="addLanguage()">Add language</button>
        </div>
      </form>
      
      <section name="activeLanguages">
        <span class="spot-typography__heading--level-5">Active System Languages</span>
        <table class="spot-data-table lang-table">
          <thead>
            <tr>
              <th>Language</th>
              <th>Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let lang of activeLanguages">
              <td>{{lang.displayName}} ({{ lang.locale}})</td>
              <td><span class="json-missing">JSON Missing</span></td>
              <td>
                <pcc-confirm-action symbol="delete" confirmPosition="spot-popover--bottom-left" (okClick)="deleteLanguage(lang)" class="delete-button" confirmMsg="confirm_action.are_you_sure"></pcc-confirm-action>
              </td>
            </tr>
          </tbody>
        </table>
      </section>
    </div>
  </div>
</div>
