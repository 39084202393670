import { Component } from "@angular/core";
import { ApiService } from "../../../service/api.service";
import { PCCAlertService } from "../../../service/alert.service";
import { firstValueFrom } from "rxjs";

@Component({
    selector: "pricing-output",
    templateUrl: "./pricing-output.component.html",
    styleUrls: [
        "./pricing-output.component.scss"
    ]
})
export class PricingOutputComponent {

    public count: string = "0";

    public constructor(
        private apiService: ApiService,
        private alertService: PCCAlertService
    ) {
    }

    public async runPricingOutputJob(countryCode: string): Promise<void> {
        this.alertService.setBusy(true, `Running Output Job for : ${countryCode.toUpperCase()}`);

        const response = await firstValueFrom(this.apiService.runJob(countryCode)) as { [key: string]: string };

        if (response.success) {
            this.count = response.count;
        } else {
            this.alertService.showError("Error Running Pricing Job", "There was an error running the pricing job: " + response.error);
            console.error(response.error);
        }

        this.alertService.setBusy(false);
    }
}
