import { Component } from "@angular/core";
import { CustomPageEditComponent } from "../../components/custom-page-edit/custom-page-edit.component";

@Component({
    selector: "pcc-page-enrollment",
    templateUrl: "./page-enrollment.component.html",
    styleUrls: [
        "./page-enrollment.component.scss"
    ]
})
export class PageEnrollmentComponent extends CustomPageEditComponent {

    // Standard fields like title, subTitle, and body are handled by customPage.
    // But any additional fields need to be initialized if they're not already present.
    protected setupLocalization() {
        if (!this.selectedPage) {
            return;
        }
        console.log("setupLocalization");

        this.selectedPage.localizedKeys = this.selectedPage.localizedKeys || {};
        this.selectedPage.localizedKeys.calendarName = this.selectedPage.localizedKeys.calendarName || { key: "calendarName" };
        this.selectedPage.localizedKeys.programResources = this.selectedPage.localizedKeys.programResources || { key: "programResources" };
    }
}
