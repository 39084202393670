<div class="view-body-noscroll">
  <section class="goal-form">
    <div class="view-title spot-typography__heading--level-2"> {{ customContent?.titleKey | translate }} </div>
    <p> {{ customContent?.subTitleKey | translate }} </p>

	<div style="display:flex;">
      <pcss-goal-calculator *ngIf="session?.accountSettings.flags.show_goal_calculator" class="goal-calculator" (monthlyGoalChange)="monthlyGoal = $event; validate();"></pcss-goal-calculator>

	  <div style="margin-left:40px;">
		<div class="goal-box">
		  <div class="spot-typography__heading--level-3"> {{'goal.target_panels_start' | translate}}</div>

		  <div class="goal-section">
			<!--  show if blank or less than 5.   -->
			<input type="number" class="goal-input" name="monthlyGoal" [(ngModel)]="monthlyGoal" (ngModelChange)="goalInputChanged($event)"/>
			<span>{{'goal.target_panels_finish' | translate}}</span>

		  </div>
		  <p class="error" *ngIf="(monthlyGoal || monthlyGoal === 0) && monthlyGoal < 5"> {{'goal.error' | translate}} </p>
		</div>
	  </div>
	</div>
  </section>
</div>
