import { Component, Input, Output, EventEmitter } from "@angular/core";
import { v4 as uuidv4 } from "uuid";

@Component({
    selector: "pcc-checkbox",
    templateUrl: "./checkbox.component.html",
    styleUrls: [
        "./checkbox.component.scss"
    ]
})
export class CheckboxComponent {

    @Input() public label?: string;

    @Input() public checked: boolean;

    @Output() public checkedChange = new EventEmitter<boolean>(); // For two-way binding

    @Input() public size: "small" | "large" = "large";

    @Input() public inputName: string = uuidv4();

    // Unique id so multiple <pcc-checkboxes> can be on the same page and <label [for]="uniqueId> will work.
    public uniqueId: string = uuidv4();

    public onCheckboxChange(event: any): void {
        const isChecked = event.target.checked;
        this.checkedChange.emit(isChecked);
    }
}
