import { Component, OnInit, Output, EventEmitter } from "@angular/core";

import { AccountSettingsCreateRequest } from "@shared/model/account-settings";
import { IBuyingGroup } from "@shared/model/buying-group";
import { IAccountResponse } from "@shared/model/account-response";

import { AdminFacade } from "../../../facade/admin.facade";
import { PCCAlertService } from "../../../service/alert.service";

import { Country } from "@shared/model/country";
import { countries } from "countries-list";
import { ErrorUtils } from "@shared/model/error/error-utils";

@Component({
    selector: "pcc-create-account-settings",
    templateUrl: "./create-account-settings.component.html",
    styleUrls: [
        "./create-account-settings.component.scss"
    ]
})
export class CreateAccountSettingsComponent implements OnInit {
    @Output() public newAccountSettingsAdded = new EventEmitter<IAccountResponse>();

    public accountSettingTypeInfo = {
        COUNTRY: "Country",
        BUYING_GROUP: "Buying Group",
        CORP: "Corporate Account"
    };

    public acctSettingTypes = Object.values(this.accountSettingTypeInfo);

    public selectedType: string = this.accountSettingTypeInfo.COUNTRY;

    public countryList: Country[];

    public buyingGroups: IBuyingGroup[] = [];

    public selectedCountry: string;

    public corpSapId: string;

    public selectedBuyingGroup: IBuyingGroup;

    private createRequest: AccountSettingsCreateRequest;

    public constructor(
        private adminFacade: AdminFacade,
        private alertService: PCCAlertService
    ) {
    }

    public async ngOnInit(): Promise<void> {
        this.countryList = Object.keys(countries).map((code: string): Country => {
            return {
                code,
                name: countries[code].name
            }
        });

        const systemSettings = await this.adminFacade.getSystemSettingsCached();
        this.buyingGroups = systemSettings.buyingGroups;
    }

    public async next(): Promise<void> {
        console.log("next");

        this.alertService.setBusy(true, "Creating Account Settings...");

        try {
            const createResp: IAccountResponse = await this.adminFacade.createAccountSettings(this.createRequest);
            console.log("createAccountSettings createResp:", createResp);

            if (createResp && createResp.success === true) {

                this.alertService.showToast("AccountSettings create successful");

                this.notify(createResp);
            }

        } catch (err) {
            console.error("Error saving system settings: ", err);
            const msg = `System Error saving system settings: ${ErrorUtils.getErrorMessage(err)}`;

            this.alertService.showError(msg);
        } finally {
            this.alertService.setBusy(false);
        }
    }

    public cancel(): void {
        console.log("cancel");
    }

    public accountSettingTypeSelected(type: string): void {
        console.log("accountSettingTypeSelected: ", type);
        this.selectedType = type;
    }

    public notify(accountResp: IAccountResponse): void {
        this.newAccountSettingsAdded.emit(accountResp);
    }

    public countrySelected(): void {
        console.log("countrySelected: ", this.selectedCountry);
        this.createRequest = { countryCd: this.selectedCountry };
    }

    public corpSapIdSelected(): void {
        console.log("corpSapIdSelected: ", this.corpSapId);
        this.createRequest = { corpParentSapId: this.corpSapId };
    }

    public buyingGroupSelected(): void {
        console.log("buyingGroupSelected: ", this.selectedBuyingGroup);
        this.createRequest = { buyingGroupId: this.selectedBuyingGroup.buying_group_id };
    }
}
