import { Component, OnInit, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { AppFacade } from "../../../facade/app.facade";
import { EnvironmentEnum } from "@shared/model/environment";
import { PCCSession } from "@shared/model/pcc-session";
import { AppModeEnum } from "@shared/model/app-mode-enum";
import { envUtils } from "../../../globals/env";

@Component({
    selector: "pcc-env-header",
    templateUrl: "./env-header.component.html",
    styleUrls: [
        "./env-header.component.scss"
    ]
})
export class EnvHeaderComponent implements OnInit, OnDestroy {

    public environment: EnvironmentEnum;

    public headerText = "env-header.training_mode_no_submit";

    public envName: string;

    public release: string;

    public configStatus = "active";

    private sessionSub: Subscription;

    public session: PCCSession;

    public canToggleTraining = false;

    public constructor(
        private appFacade: AppFacade
    ) {
        this.environment = envUtils.getEnvironment();
        this.envName = this.environment.displayName;
        this.release = envUtils.RELEASE;
        this.headerText = envUtils.ENV_HEADER || this.headerText;

        // Submission of enrollments are always allowed in a sub-prod environment,
        //   even if training mode is enabled.
        // Submission of enrollments is not allowed in prod when in training mode.
        if (!this.appFacade.isSubmitEnabled()) {
            this.headerText = "env-header.training_mode_no_submit";
        } else {
            this.headerText = "env-header.training_mode";
        }

        // If training mode is enabled on the back end, disable training toggle.
        this.canToggleTraining = !this.appFacade.isEventTrainingMode();
    }

    public ngOnInit(): void {
        this.sessionSub = this.appFacade.getCurrentSession().subscribe(
            (session): void => {
                this.setSession(session);
            }
        );
    }

    public ngOnDestroy(): void {
        this.sessionSub.unsubscribe();
    }

    public showHeader(): boolean {
        return !envUtils.isProd()
            || this.appFacade.isTrainingMode();
    }

    public isProd(): boolean {
        return envUtils.isProd();
    }

    public isTraining(): boolean {
        return this.appFacade.isTrainingMode();
    }

    public setSession(session: PCCSession): void {
        console.log("env-header.setSession: ", session);
        this.session = session;
    }

    public toggleTraining(): void {
        console.log("toggleTraining");
        this.appFacade.setAppMode(AppModeEnum.PROD);
    }
}
/*
In prod, in prod mode, no header.  In training mode, show Training msg and version
In sub-prod, always show header.  In training mode, show Env, Training msg, and version.  Otherwise, just show env and version.
*/
