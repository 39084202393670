<div>
  <div style="display:flex;align-items:baseline;">
    <span class="spot-typography__heading--level-5">Images</span>
    <div class="list-controls">
	  <a class="spot-link add-item" (click)="addFile();">
        <svg class="spot-icon spot-link__icon spot-link__icon--left" aria-labelledby="title">
          <title>New Image File</title>
          <use xlink:href="/assets/spot-library/dist/symbols/spot_symbols.svg#add"></use>
        </svg>
        Add new image
      </a>
    </div>
  </div>

  <pcc-drag-drop-list [items]="activeFiles" (reordered)="filesReordered($event);" (itemClicked)="fileClicked($event)" style="height: 100%;" [itemTemplate]="fileTemplate"></pcc-drag-drop-list>
</div>

<ng-template #fileTemplate let-item="item" let-selected="selected">
  <div class="file-item" [ngClass]="item.error ? 'item-error' : ''">
	<pcc-icon icon="image" iconClass="spot-icon" title="Document"></pcc-icon>
	<div class="item-title">
	  {{item.name}}
	</div>
	<div class="item-title">
	  {{item.desc}}
	</div>
      <button type="button" class="spot-button spot-button--icon-only round-button edit-text-button" (click)="editClicked(item)">
        <svg class="spot-icon" aria-labelledby="title">
          <title>Edit</title>
          <use xlink:href="/assets/spot-library/dist/symbols/spot_symbols.svg#edit"></use>
        </svg>
      </button>
  </div>
</ng-template>
