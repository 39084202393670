import { Component, OnInit } from "@angular/core";

import { CustomPageEditComponent } from "../../components/custom-page-edit/custom-page-edit.component";

@Component({
    selector: "pcc-page-pet-owner-pricing",
    templateUrl: "./page-pet-owner-pricing.component.html",
    styleUrls: [
        "./page-pet-owner-pricing.component.scss"
    ]
})
export class PagePetOwnerPricingComponent extends CustomPageEditComponent implements OnInit {

}
