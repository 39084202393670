import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";

import { CustomPageTypes, ICustomPage } from "@shared/model/custom-page";
import { FileInfo } from "@shared/model/file";
import { ISeismicLink } from "@shared/model/seismic";

import { IAccountSettings } from "@shared/model/account-settings";

import { LocalizedString } from "@shared/model/language";
import { IAPIResponseData } from "@shared/model/service/service";
import { AdminFacade } from "../../../facade/admin.facade";
import { PCCAlertService } from "../../../service/alert.service";
import { PCCTranslateService } from "../../../service/translate.service";
import { PCCClientError } from "../../../shared/model/pcc-client-error";
import { SettingsKey } from "@shared/model/settings-flags";

declare const spot: any; // eslint-disable-line @typescript-eslint/no-explicit-any

@Component({
    selector: "pcc-custom-page-edit",
    templateUrl: "./custom-page-edit.component.html",
    styleUrls: [
        "./custom-page-edit.component.scss"
    ]
})
export class CustomPageEditComponent implements AfterViewInit, OnChanges, OnInit {
    @ViewChild("pageForm", {
        static: false
    }) public pageForm: NgForm;

    @Input() public selectedPage: ICustomPage;

    @Input() public accountSettings: IAccountSettings;

    @Output() private customPageChanged = new EventEmitter<ICustomPage>();

    @Output() private saved = new EventEmitter<ICustomPage>();

    @Output() private deleted = new EventEmitter<ICustomPage>();

    @Output() private canceled = new EventEmitter<ICustomPage>();

    public selectedLink?: ISeismicLink;

    public selectedFile?: FileInfo;

    public pIsValid = true;

    public get isValid(): boolean {
        return this.pIsValid;
    }

    public set isValid(value: boolean) {
        this.pIsValid = value;
        this.updateButtons(value);
    }

    public pageTypes = Object.values(CustomPageTypes);

    public saveEnabled = false;

    public saving: boolean;

    public constructor(
        protected adminFacade: AdminFacade,
        protected alertService: PCCAlertService,
        protected translateService: PCCTranslateService
    ) {}

    public ngOnInit(): void {
        this.setupLocalization();
    }

    public ngOnChanges(): void {
        console.log("ngOnChanges");
        this.selectedLink = null;
        this.selectedFile = null;

        this.setupLocalization();

        this.validate();
    }

    public ngAfterViewInit(): void {
        spot.Tabs.initialize();

        if (this.pageForm) {
            this.pageForm.statusChanges.subscribe((status: unknown): void => {
                console.log("form status change: ", status);
                this.validate();
            });
        } else {
            console.error("this.pageForm doesn't exist!");
        }
    }

    private validate(): boolean {
        if (!this.pageForm) {
            return true;
        }
        try {
            if (!this.pageForm?.valid) {
                console.log("form invalid: ", this.pageForm);
                this.isValid = false;
                return false;
            }

            this.isValid = true;
            return true;
        } catch (err) {
            console.log("invalid error: ", err);
            this.isValid = false;
            return false;
        }
    }

    public linkAdded(link: ISeismicLink): void {
        this.customPageChanged.emit(this.selectedPage);
        this.validate();
    }

    public linkDeleted(oldLink: ISeismicLink): void {
        this.selectedLink = null;

        this.customPageChanged.emit(this.selectedPage);

        this.validate();
    }

    public fileAdded(file: FileInfo): void {
        this.customPageChanged.emit(this.selectedPage);
        this.validate();
    }

    public fileDeleted(file: FileInfo): void {
        this.selectedFile = null;

        this.customPageChanged.emit(this.selectedPage);

        this.validate();
    }

    public async onSubmit(): Promise<void> {
        console.log("onSubmit");

        this.setBusy(true, "Saving...");

        const resp = await this.saveCustomPage(this.selectedPage);
        if (resp.success !== true) {
            console.error("Save failed: ", resp);
            this.alertService.showError("Save failed");
        } else if (resp.data) {
            this.updatePageData(this.selectedPage, resp.data);

            this.setupLocalization();

            this.saved.emit(this.selectedPage);

            await this.translateService.updateDataTranslations(this.accountSettings.account_settings_id);

            this.alertService.showToast("Save successful");
        }

        this.setBusy(false);

        console.log("Done saving!");
    }

    // Safe wrapper around adminService.saveCustomPage
    protected async saveCustomPage(page: ICustomPage): Promise<IAPIResponseData<ICustomPage>> {
        try {
            const resp = await this.adminFacade.saveCustomPage(page);
            console.log("saveResp=", resp);
            if (resp.success !== true) {
                console.error("Save failed: ", resp);
            }
            return resp;
        } catch (err) {
            console.error("Error saving custom page: ", err);
            return {
                success: false,
                error: new PCCClientError("SYSTEM_ERROR", "Error thrown calling adminFacade.saveCustomPage", err)
            };
        }
    }

    // Update local copy of page and links with new ids once persisted to database.
    private updatePageData(page: ICustomPage, newData: ICustomPage): void {
        this.selectedPage = newData;
    }

    /**
     * Note that deleting custom page simply marks it as inactive rather than removing the data from the database.
     */
    public async deleteCustomPageConfirmed(page: ICustomPage): Promise<void> {
        // If this page hasn't yet been persisted, then just discard it.
        if (!page.id) {
            this.deleted.emit(page);

            return;
        }

        this.setBusy(true, "Deleting custom page...");

        page.active = false;
        const resp = await this.saveCustomPage(page);
        if (resp.success !== true) {
            console.error("Update failed: ", resp);
            this.alertService.showError("Delete failed");
        } else {
            console.log("Update successful, page marked as inactive");
            this.deleted.emit(page);
        }

        this.setBusy(false);
    }

    public cancel(): void {
        console.log("cancel");
        const page = this.selectedPage;

        this.canceled.emit(page);
    }

    private setBusy(isBusy: boolean, msg?: string): void {
        this.alertService.setBusy(isBusy, msg);

        this.saving = isBusy;
    }

    private updateButtons(enabled: boolean): void {
        console.log(`form valid: ${enabled}`);

        this.saveEnabled = enabled;
    }

    public localizedTextChanged(textInfo: LocalizedString): void {
        console.log("localizedTextChanged: ", textInfo);
        this.translateService.updateLocalizedKey(textInfo, this.selectedPage.localizedKeys);
        this.validate();
    }

    // Call from (change) event in a toggle input field.
    // Make sure that the name="" attribute matches the flag name.
    public async flagToggled(event: Event): Promise<void> {
        const checkbox = event.target as HTMLInputElement;
        const isChecked = checkbox.checked;
        const name = checkbox.name;
        console.log("flagToggled: ", name, isChecked);

        await this.adminFacade.updateFlag(this.accountSettings.account_settings_id, name as SettingsKey, isChecked);
    }

    // Standard fields like title, subTitle, and body are handled by customPage.
    // But any additional fields need to be initialized if they're not already present.
    protected setupLocalization(): void {
    }
}
