<div class="env-header" *ngIf="showHeader()">
  <div style="text-align:center;flex:1;padding: 5px;display:flex;align-items:center;justify-content:center;" *ngIf="isTraining()">
 	<div class="training-msg">{{headerText | translate}}
	  <!-- Can only toggle off training mode if in local training mode -->
	  <pcc-confirm-action *ngIf="canToggleTraining" confirmPosition="spot-popover--center-left" okKey="env-header.turn_off" symbol="" buttonText="env-header.training_on" buttonTitle="env-header.turn_off" (okClick)="toggleTraining()" class="toggle-training-confirm" confirmButtonClass="spot-button spot-button--secondary"></pcc-confirm-action>
	  <!-- Else, just display ON text -->
	  <span *ngIf="!canToggleTraining"> {{ 'env-header.training_on' | translate }} </span>
	</div>
  </div>
  <ng-container *ngIf="!isProd()">
	<ng-container *ngIf="isTraining()">
	  <div style="text-align:right;padding:5px;"><span class="env-name spot-typography__heading--level-3">{{envName}}</span></div>
	</ng-container>
	<ng-container *ngIf="!isTraining()">
	  <div style="flex:1;text-align:center;"><span class="env-name spot-typography__heading--level-3">{{envName}}</span></div>
	</ng-container>
  </ng-container>
</div>
