import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { QuillModule } from "ngx-quill";
import { FormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { NgxCurrencyModule } from "ngx-currency";
import { ClipboardModule } from '@angular/cdk/clipboard';

import { SharedModule } from "../shared/shared.module";

import { AdminRoutingModule } from "./admin-routing.module";
import { UserSettingsComponent } from "./views/user-settings/user-settings.component";
import { ProfileItemsComponent } from "./views/profile-items/profile-items.component";
import { AccountSearchComponent } from "./views/account-search/account-search.component";
import { AccountSettingsComponent } from "./views/account-settings/account-settings.component";
import { AccountSettingsGeneralComponent } from "./views/account-settings-general/account-settings-general.component";
import { SystemLanguagesComponent } from "./views/system-languages/system-languages.component";
import { AccountPricingComponent } from "./views/account-pricing/account-pricing.component";
import { AccountTemplatesComponent } from "./views/account-templates/account-templates.component";
import { SlotCategoryComponent } from "./components/slot-category/slot-category.component";
import { SlotCategoryTestComponent } from "./components/slot-category-test/slot-category-test.component";
import { TestCategoryComponent } from "./components/test-category/test-category.component";
import { AdminLayoutComponent } from "./layout/admin-layout/admin-layout.component";
import { BuildTemplateComponent } from "./components/build-template/build-template.component";

import { MaterialDesignModule } from "../../material-design/material-design.module";
import { AdminNavComponent } from "./components/admin-nav/admin-nav.component";
import { AccountNavComponent } from "./components/account-nav/account-nav.component";
import { EnrollmentSearchComponent } from "./views/enrollment-search/enrollment-search.component";
import { EnrollmentEditComponent } from "./views/enrollment-edit/enrollment-edit.component";
import { AngularSplitModule } from "angular-split";
import { BuyingGroupsComponent } from "./views/buying-groups/buying-groups.component";
import { PricingOutputComponent } from "./views/pricing-output/pricing-output.component";
import { DragDropListComponent } from "./components/drag-drop-list/drag-drop-list.component";
import { AccountTemplateListItemComponent } from "./components/account-template-list-item/account-template-list-item.component";
import { CustomPageEditComponent } from "./components/custom-page-edit/custom-page-edit.component";
import { SeismicLinkEditComponent } from "./components/seismic-link-edit/seismic-link-edit.component";
import { ManageFilesComponent } from "./components/manage-files/manage-files.component";
import { ManagedFileEditComponent } from "./components/managed-file-edit/managed-file-edit.component";
import { SeismicLinksComponent } from "./components/seismic-links/seismic-links.component";
import { CreateAccountSettingsComponent } from "./components/create-account-settings/create-account-settings.component";
import { AccountSettingsEnrollmentPagesComponent } from "./views/account-settings-enrollment-pages/account-settings-enrollment-pages.component";
import { AccountSettingsLandingPagesComponent } from "./views/account-settings-landing-pages/account-settings-landing-pages.component";
import { PageEnrollmentComponent } from "./pages/page-enrollment/page-enrollment.component";
import { PageProfilesComponent } from "./pages/page-profiles/page-profiles.component";
import { PagePetOwnerPricingComponent } from "./pages/page-pet-owner-pricing/page-pet-owner-pricing.component";
import { PageTestMixComponent } from "./pages/page-test-mix/page-test-mix.component";
import { PagePricingComponent } from "./pages/page-pricing/page-pricing.component";
import { PageCorpPricingComponent } from "./pages/page-corp-pricing/page-corp-pricing.component";
import { PagePdfComponent } from "./pages/page-pdf/page-pdf.component";
import { PageGoalsComponent } from "./pages/page-goals/page-goals.component";
import { CorpLogoComponent } from "./components/corp-logo/corp-logo.component";
import { TrainingModeConfigComponent } from "./components/training-mode-config/training-mode-config.component";
import { ProfileItemEditComponent } from "./components/profile-item-edit/profile-item-edit.component";
import { LocalizedTextComponent } from './components/localized-text/localized-text.component';
import { LanguageRadioGroupComponent } from './components/language-radio-group/language-radio-group.component';
import { AccountLanguageComponent } from './components/account-language/account-language.component';
import { PageDoneComponent } from './pages/page-done/page-done.component';

@NgModule({
    declarations: [
        AccountPricingComponent,
        AccountSearchComponent,
        AccountSettingsComponent,
        AccountSettingsGeneralComponent,
        SystemLanguagesComponent,
        AccountTemplatesComponent,
        ProfileItemsComponent,
        UserSettingsComponent,
        SlotCategoryComponent,
        SlotCategoryTestComponent,
        TestCategoryComponent,
        AdminLayoutComponent,
        BuildTemplateComponent,
        AdminNavComponent,
        AccountNavComponent,
        EnrollmentSearchComponent,
        EnrollmentEditComponent,
        BuyingGroupsComponent,
        DragDropListComponent,
        AccountTemplateListItemComponent,
        CustomPageEditComponent,
        SeismicLinkEditComponent,
        ManageFilesComponent,
        ManagedFileEditComponent,
        SeismicLinksComponent,
        PricingOutputComponent,
        CreateAccountSettingsComponent,
        AccountSettingsEnrollmentPagesComponent,
        AccountSettingsLandingPagesComponent,
        TrainingModeConfigComponent,
        PageCorpPricingComponent,
        PageEnrollmentComponent,
        PageGoalsComponent,
        PagePdfComponent,
        PagePetOwnerPricingComponent,
        PagePricingComponent,
        PageProfilesComponent,
        PageTestMixComponent,
        CorpLogoComponent,
        ProfileItemEditComponent,
        LocalizedTextComponent,
        LanguageRadioGroupComponent,
        AccountLanguageComponent,
        PageDoneComponent
    ],
    imports: [
        TranslateModule,
        NgxCurrencyModule,
        FormsModule,
        SharedModule,
        CommonModule,
        AdminRoutingModule,
        MaterialDesignModule,
        QuillModule.forRoot(),
        AngularSplitModule,
        ClipboardModule
    ],
    entryComponents: []
})
export class AdminModule {
}
