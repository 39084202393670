export interface IPricedItem {
    // SAP list price (netPrice)
    listPrice?: number; // SAP sourced list_price

    // Customer-specific pricing taking from sap order simulate (totalPrice)
    customerPrice?: number; // reference_price

    specialPrice?: number;
}

export const PricingMethods = {
    CORP: "CORP",
    STATIC: "STATIC",
    US: "US",
    CA: "CA",
    AU: "AU"
} as const;
export type PricingMethod = keyof typeof PricingMethods;

