import { Component, OnInit } from "@angular/core";
import { CustomPageEditComponent } from "../../components/custom-page-edit/custom-page-edit.component";

@Component({
    selector: "pcc-page-pricing",
    templateUrl: "./page-pricing.component.html",
    styleUrls: [
        "./page-pricing.component.scss"
    ]
})
export class PagePricingComponent extends CustomPageEditComponent implements OnInit {

}
