<label *ngIf="canSelect" class="spot-form__checkbox seismic-checkbox" [for]="'isSelected' + link.id">
  <input  type="checkbox" class="spot-form__checkbox-input" [id]="'isSelected'+link.id" [(ngModel)]="link.selected" [name]="'isSelected'+link.id" (change)="selected(link, $event)"/>
  <span class="spot-form__checkbox-inner">
    <span class="spot-form__checkbox-visual">
      <pcc-icon icon="checkmark" iconClass="spot-icon spot-form__checkbox-checked-icon"></pcc-icon>
      <pcc-icon icon="checkbox-indeterminate" iconClass="spot-icon spot-form__checkbox-indeterminate-icon"></pcc-icon>
    </span>
  </span>
</label>
<div class="seismic-link" [class.selected]="link.selected">
  <div class="seismic-link-thumbnail">
	<a class="spot-button--link" (click)="openSeismicDoc(link)">
	  <img *ngIf="link.thumbnailData?.url" [src]="link.thumbnailData.url" [alt]="link.desc">
	  <svg *ngIf="!link.thumbnailData?.url" class="spot-icon" aria-labelledby="title">
		<title>Document</title>
		<use xlink:href="/assets/spot-library/dist/symbols/spot_symbols.svg#document"></use>
	  </svg>
	</a>
  </div>
  <div class="seismic-link-desc">
	<a class="spot-button--link" (click)="openSeismicDoc(link)">
	  {{link.desc}}
	</a>
  </div>
</div>
