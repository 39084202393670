import { Component, Input } from "@angular/core";

@Component({
    selector: "pcc-overlay",
    templateUrl: "./overlay.component.html",
    styleUrls: [
        "./overlay.component.scss"
    ]
})
export class OverlayComponent {
    @Input() public busy: boolean;

    @Input() public text: string;
}
