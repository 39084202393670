<div class="page-body">

  <div class="page-header">
    <span class="pcc-level-3-10">Enrollment page group</span>
    <input type="checkbox" class="spot-toggle" id="page_enabled_enrollment" name="enrollment_enabled" [(ngModel)]="accountSettings.flags.enrollment_enabled" (change)="enrollmentEnabledChange($event)">
    <label class="spot-toggle-label" for="page_enabled_enrollment">Enabled</label>
  </div>
  <div class="pages-content">
    <as-split direction="horizontal" unit="pixel" #split="asSplit" style="flex: 1;">
      <as-split-area [size]="420" #area1="asSplitArea">
        <div class="spot-container--light-primary left-bar left-bar">
          <div class="action-header">
            <span class="spot-typography__heading--level-5">Select Enrollment Page</span>
          </div>
          <ul class="spot-list-group" style="overflow:auto;height:100%;">
            <li *ngFor="let pageType of enrollPageTypes" class="spot-list-group__item" [ngClass]="selectedType === pageType ? 'spot-list-group__item--active' : ''">
              <a class="spot-list-group__link" (click)="enrollPageClicked(pageType)">
                <div style="display:flex;flex-direction:row;width:100%;justify-content:space-between;align-items:center;">
                  <span class="spot-list-group__item-label" style="flex:1">
                    {{'account-settings-enrollment-pages.' + pageType | translate}}
                  </span>
                  <span>
                    <svg *ngIf="isPageEnabled(pageType)" class="spot-icon pcc-spot-icon--green" aria-labelledby="title">
                      <title>checkmark</title>
                      <use xlink:href="/assets/spot-library/dist/symbols/spot_symbols.svg#checkmark"></use>
                    </svg>
                    <svg *ngIf="!isPageEnabled(pageType)" class="spot-icon blocked-icon" aria-labelledby="title">
                      <title>blocked</title>
                      <use xlink:href="/assets/spot-library/dist/symbols/spot_symbols.svg#blocked"></use>
                    </svg>
                  </span>
                </div>
              </a>
            </li>
          </ul>
        </div>
      </as-split-area>

      <as-split-area  #area3="asSplitArea" style="overflow:hidden">
        <div style="flex:1;display:flex;flex-direction:column;flex-grow:1;height:100%;" *ngIf="selectedPage">
          <pcc-page-profiles *ngIf="selectedType === 'PROFILES'" [selectedPage]="selectedPage" [accountSettings]="accountSettings"></pcc-page-profiles>
          <pcc-page-pet-owner-pricing *ngIf="selectedType === 'PET_OWNER_PRICE'" [selectedPage]="selectedPage" [accountSettings]="accountSettings"></pcc-page-pet-owner-pricing>
          <pcc-page-goals *ngIf="selectedType === 'GOAL'" [selectedPage]="selectedPage" [accountSettings]="accountSettings"></pcc-page-goals>
          <pcc-page-enrollment *ngIf="selectedType === 'ENROLL'" [selectedPage]="selectedPage" [accountSettings]="accountSettings"></pcc-page-enrollment>
          <pcc-page-done *ngIf="selectedType === 'DONE'" [selectedPage]="selectedPage" [accountSettings]="accountSettings"></pcc-page-done>
          <pcc-page-corp-pricing *ngIf="selectedType === 'CORP_PRICING'" [selectedPage]="selectedPage" [accountSettings]="accountSettings"></pcc-page-corp-pricing>
          <pcc-page-pricing *ngIf="selectedType === 'PRICING'" [selectedPage]="selectedPage" [accountSettings]="accountSettings"></pcc-page-pricing>
          <pcc-page-pdf *ngIf="selectedType === 'PDF'" [selectedPage]="selectedPage" [accountSettings]="accountSettings"></pcc-page-pdf>
          <pcc-page-test-mix *ngIf="selectedType === 'TEST_MIX'" [selectedPage]="selectedPage" [accountSettings]="accountSettings"></pcc-page-test-mix>
          <section *ngIf="!selectedPage">
            <div style="margin: auto;">
              <!-- Select or create new Custom Page to continue... -->
            </div>
          </section>
        </div>
      </as-split-area>
    </as-split>

  </div>
</div>
