import { Component, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router, NavigationEnd, Event } from "@angular/router";
import { Subscription } from "rxjs";
import { filter } from "rxjs/operators";
import { AppFacade } from "../../../facade/app.facade";
import { PCCSession } from "@shared/model/pcc-session";

@Component({
    selector: "pcc-client-layout",
    templateUrl: "./client-layout.component.html",
    styleUrls: [
        "./client-layout.component.scss"
    ]
})
export class ClientLayoutComponent implements OnInit, OnDestroy {

    private sessionSubscription: Subscription;

    private routeSubscription: Subscription;

    public enablePreview = false;

    public showProgressBar = false;

    public session: PCCSession;

    public constructor(
        private route: ActivatedRoute,
        private router: Router,
        private appFacade: AppFacade
    ) { }

    public ngOnInit(): void {
        this.sessionSubscription = this.appFacade.getCurrentSession().subscribe(
            (session: PCCSession): void => {
                this.setSession(session);
            }
        );
        this.routeSubscription = this.router.events.pipe(
            filter((event): boolean => event instanceof NavigationEnd)
        )
            .subscribe((events: Event): void => {
                const data = this.route.snapshot?.firstChild?.data;
                this.enablePreview = data?.enablePreview;
            });
    }

    public ngOnDestroy(): void {
        this.sessionSubscription.unsubscribe();
        this.routeSubscription.unsubscribe();
    }

    private setSession(session: PCCSession): void {
        this.session = session;

        // Only show progress bar if current session has an enrollment in progress.
        // In the case of session-confirm, we'll have account info, account settings, etc, but no enrollment.
        this.showProgressBar = !!this.session.enrollInfo ?? false;

    }
}
