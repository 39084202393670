import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from "@angular/core";

@Component({
    selector: "calendar-widget",
    templateUrl: "./calendar-widget.component.html",
    styleUrls: [
        "./calendar-widget.component.scss"
    ]
})
export class CalendarWidgetComponent implements OnChanges {
    @Input()
    public date ?: string;

    @Input()
    public min ?: string;

    @Input()
    public disabled ?: boolean;

    @Output()
    public dateChange = new EventEmitter<string>();

    public dateAsDate ?: Date;

    public ngOnInit() {
        this.dateAsDate = new Date(this.date);
    }

    public ngOnChanges(changes : SimpleChanges) {
        if(changes.date) {
            this.dateAsDate = new Date(this.date);
        }
    }

    public dateChanged($event): void {
        this.date = $event.value;
        this.dateAsDate = new Date(this.date);
        this.dateChange.emit(CalendarWidgetComponent.formatDate(this.dateAsDate));
    }

    public minAsDate() {
        const minAsDate = new Date(this.min);
        if(this.dateAsDate < minAsDate) {
            this.dateAsDate = minAsDate;
            this.dateChange.emit(CalendarWidgetComponent.formatDate(this.dateAsDate));
        }
        return minAsDate;
    }

    private static formatDate(date?: Date): string {
        if (!date) {
            return "";
        }
        return date.toLocaleDateString('en-US');
    }
}
