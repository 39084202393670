<div class="admin-nav">
  <section class="admin-primary">
    <ng-container *ngFor="let navItem of navItems">
      <button class="spot-button spot-button--primary spot-typography__heading--level-5 admin-main-nav-button" [routerLink]="[navItem.url]" (click)="navClicked(navItem)">
        {{navItem.displayName}}
      </button>
    </ng-container>
  </section>

  <div class="spot-button-wrapper admin-secondary">
    <button class="spot-button spot-button--secondary spot-button--dropdown-toggle">
      <span class="spot-button__text"> Other jobs and settings... </span>
      <svg class="spot-icon spot-button__icon spot-button__icon--right">
        <use xlink:href="/assets/spot-library/dist/symbols/spot_symbols.svg#caret-down"></use>
      </svg>
    </button>
    <div class="spot-dropdown admin-nav-dropdown">
      <ul class="spot-dropdown__content account-nav-content">
        <li *ngFor="let navItem of secondaryNavItems" class="spot-dropdown__content-item" [ngClass]="activeItem === navItem.key ? ' spot-list-group__item--active' : ''">
          <a class="spot-list-group__link" [ngClass]="activeItem === navItem.key ? ' spot-list-group__item--active' : ''" [routerLink]="[navItem.url]" (click)="navClicked(navItem)">
            {{navItem.displayName}}
          </a>
        </li>
        <li class="spot-dropdown__content-item">
          <a class="spot-list-group__link" (click)="trainingModeClicked()">
            Training Mode
          </a>
        </li>
      </ul>
    </div>
  </div>
</div>
