import { Component, Input } from "@angular/core";

import { EditTextComponent } from "../edit-text/edit-text.component";

/**
 * Extends the functionality of edit-text, but adds a locale string to the front of the text.
 */
@Component({
    selector: 'pcc-edit-text-locale',
    templateUrl: './edit-text-locale.component.html',
    styleUrls: ['./edit-text-locale.component.scss',
        '../edit-text/edit-text.component.scss']
})
export class EditTextLocaleComponent extends EditTextComponent {

    @Input() public locale: string;

    @Input() public showLanguage = true;

}
