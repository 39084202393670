import { IAccountSettings } from "./account-settings";
import { IUser } from "./user";
import { IPartner } from "./partner";
import { IBuyingGroup } from "./buying-group";
import { SalesOrgEnum } from "./sales-org-enum";

export interface IAccountRep extends IUser {
    rep_role: string;
}

export interface ILimsAccount {
    lims_src: string;
    context_code: string; // LYNXX context code
    context_name: string;
    lims_practice_id: string;
}

/**
 * Interface representing the sap-derived information about an account.
 */
export interface IAccount {
    sf_id: string;
    practice_discount?: number;
    au_sf_id?: string;
    name: string;
    sap_id: string;
    city: string;
    state: string;
    country_cd: string;
    account_type?: string;
    customer_specialty?: string;
    is_cag?: boolean;
    limsAccountInfo: ILimsAccount[];
    customer_group?: string;
    customer_group_desc?: string;
    parent_id?: string;

    corpParent?: IAccount;
    buyingGroup?: IBuyingGroup;

    partners: IPartner[];

    settings?: IAccountSettings;

    // For AU
    iftsUser?: IAccountRep;

    vdcUser?: IAccountRep;
    fsrUser?: IAccountRep;
    psvUser?: IAccountRep;

}

export const AccountUtils = {
    getPartner(account: IAccount, pType: string, division: string): IPartner | null {
        console.log("getPartner: ", pType, division, account);
        if (!account || !account.partners) {
            return null;
        }
        const countryCd = account.country_cd;
        const salesOrg = SalesOrgEnum.values.find((so: SalesOrgEnum): boolean =>
            so.division === division && so.countryCd === countryCd
        );

        const partner = account.partners.find((p: IPartner): boolean =>
            salesOrg
            && p.partner_function === pType
            && p.division === division
            && p.sales_organization === salesOrg.salesOrg
        );
        console.log("partner=", partner);
        if (partner) {
            return partner;
        }
        return null;
    }
};

export class SFRoleEnum {
    private static ORDINAL_SEQ: number = 0;

    public static readonly VDC = new SFRoleEnum("VDC", "VDC");

    public static readonly FSR = new SFRoleEnum("FSR", "FSR DX");

    public static readonly PSV = new SFRoleEnum("Psv", "PSV");

    public static readonly IFTS = new SFRoleEnum("IFTS", "IFTS");

    public readonly ordinal: number = 0;

    public static values: SFRoleEnum[];

    // private to disallow creating other instances of this type
    private constructor(public readonly displayName: string, public readonly value: string) {
        this.ordinal = SFRoleEnum.ORDINAL_SEQ++;
        SFRoleEnum.values = SFRoleEnum.values || [];
        SFRoleEnum.values.push(this);
    }

    public static forString(value: string): SFRoleEnum {
        if (value === SFRoleEnum.VDC.value) {
            return SFRoleEnum.VDC;
        }
        if (value === SFRoleEnum.FSR.value) {
            return SFRoleEnum.FSR;
        }
        if (value === SFRoleEnum.IFTS.value) {
            return SFRoleEnum.IFTS;
        }
        if (value === SFRoleEnum.PSV.value) {
            return SFRoleEnum.PSV;
        }
        console.warn("Invalid value type passed in.  Defaulting to VDC AccountRep...");
        return SFRoleEnum.VDC;
    }

    public static forAccountRep(rep: IAccountRep): SFRoleEnum | null {
        if (!rep) {
            console.error("rep is null, no match found");
            return null;
        }
        for (const role of SFRoleEnum.values) {
            if (role.equals(rep)) {
                return role;
            }
        }
        console.error("No enum found for accountRep: ", rep);
        return null;
    }

    public equals(value: string | IAccountRep): boolean {
        if (typeof value === "string") {
            return this.value === value;
        }
        if (value && value.rep_role) {
            return this.value === value.rep_role;
        }
        return false;
    }

    public toString(): string {
        return this.displayName;
    }
}
