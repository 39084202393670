<div class="view-body">
  <div class="cp-header">
	<a [routerLink]="landingLink" [title]="'navigation.Launch' | translate" class="spot-link nav-landing-link">
	  <svg class="pcc-spot-icon-large spot-icon" aria-labelledby="title">
		<title>Book</title>
		<use xlink:href="/assets/spot-library/dist/symbols/spot_symbols.svg#book"></use>
      </svg>
	  &nbsp;{{ 'custom-page.return' | translate }}
	</a>
  </div>
  <div class="custom-content">
	<div class="custom-page-title spot-typography__heading--level-2" [innerHTML]="safeTitle"></div>
	<div class="custom-page-sub-title spot-typography__heading--level-5" [innerHTML]="safeSubTitle"></div>

	<div class="custom-page-content">
	  <div class="pcc-carousel-wrapper" style="flex:1" >
		<pcc-carousel *ngIf="images" style="width: 100%;height: 100%;" [images]="images" [title]="customPage.titleKey | customTranslate">
		</pcc-carousel>
	  </div>

	  <div class="custom-page-links">
		<pcc-seismic-thumbnail *ngFor="let link of links" [link]="link" class="custom-page-link"/>
	  </div>
	</div>

	<div class="custom-pages">
	  <ul class="custom-page-more">
		<ng-container *ngFor="let cp of customPages">
		  <li>
			<a *ngIf="customPage?.id !== cp.id" (click)="pageClicked(cp)" class="spot-link">{{cp.titleKey | customTranslate}}</a>
			<span *ngIf="customPage?.id === cp.id" class="selected-page">{{ cp.titleKey | customTranslate }}</span>
		  </li>
		</ng-container>
	  </ul>
	</div>
  </div>
</div>
