import { IChampionRole } from "./champion-role";
import { IProfile, Profile, ProfileUtils } from "./profile";
import { IProfileTemplate } from "./profile-template";
import { UtilService } from "../service/util.service";
import { IAccountRep } from "./account";
import { IAccount, ILimsAccount } from "./account";
import { ISeismicLink } from "./seismic";
import { IAccountSettings, AccountSettings } from "./account-settings";
import { SessionModeEnum } from "./session-mode-enum";
import { IUser } from "./user";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";

const EXPIRE_DAYS = 30;

export interface IEnrollmentMeta {
    enrollment_meta_id: number;
    enrollment_id: number;

    s3_etag?: string;
    s3_filename?: string;
    s3_error?: string;

    mail_id?: string;
    mail_error?: string;
}

export interface IEnrollment {
    enrollment_id?: number;

    // Used during re-enroll and modify enrollment
    src_enrollment_id?: number;

    // The account settings used when this enrollment was created.
    account_settings_id?: number;

    // As Profiles are built, they are added here.
    profiles: IProfile[];

    sap_id?: string;
    practiceName?: string;
    practiceCity?: string;
    practiceStateRegion?: string;
    practiceCountryCd?: string;
    practicePimsName?: string;
    practicePimsOtherName?: string;
    practicePimsVersion?: string;

    lims_practice_id?: string[];

    parent_sap_id?: string;

    championSalutation?: string;
    championFirstName?: string;
    championLastName?: string;
    championPhone?: string;
    championEmail?: string;
    championRole?: IChampionRole;

    vdcUser?: IAccountRep;

    // Used for both fsr and IFTS (AU)
    fsrUser?: IAccountRep;

    psvUser?: IAccountRep;

    integrationDate: string;
    launchDate?: string;

    enrollmentDate?: Date;
    enrollmentEndDate?: string;

    locale?: string;

    metaInfo?: IEnrollmentMeta;

    marked_for_deletion: boolean;

    // Used to indicate when current enrollment has been submitted so the UI won't allow them to return to earlier screens.
    // Needed because we're allowing edits of completed enrollments which would fail based on just checking enrollmentDate.
    submitted: boolean;

    status?: string;

    created_on?: Date;
    createdBy?: IUser;

    updated_on?: Date;
    updatedBy?: IUser;

    dirty?: boolean;

    version: number;

    trainingMode?: boolean;

    totalGoalQuantities: number;

    selectedDocuments: ISeismicLink[];

    petCareHeroesEnrolled?: boolean;

    petCareHeroesNumStaff?: number;

    petCareHeroesEnrollmentDate?: Date;

    petCareHeroesLastEnrollmentDate?: Date;
}

export type EnrollmentFields = keyof IEnrollment;

export interface EnrollmentStatus {
    mail: { complete: boolean; error: string };
    s3: { complete: boolean; error: string };
    id: number
}

/**
 * Represents the Enrollment information defined by a user during a particular session.
 * Includes a list of built-out profiles, practice information,
 * champian information, vdc information, integration date, and training date.
 */
export class Enrollment implements IEnrollment {
    public enrollment_id?: number;

    // Used during re-enroll and modify enrollment
    public src_enrollment_id?: number;

    // The account settings used when this enrollment was created.
    public account_settings_id?: number;

    // As Profiles are built, they are added here.
    // In the case of corporate, all templates are converted to non-populated Profiles upfront and the list of templates is cleared out.
    // In the case of us/canada, this starts as empty
    public profiles: IProfile[] = [];

    public sap_id?: string;

    public practiceName?: string;

    public practiceCity?: string;

    public practiceStateRegion?: string;

    public practiceCountryCd?: string;

    public practicePimsName?: string;

    public practicePimsOtherName?: string;

    public practicePimsVersion?: string;

    public lims_practice_id?: string[];

    public championSalutation?: string;

    public championFirstName?: string;

    public championLastName?: string;

    public championPhone?: string;

    public championEmail?: string;

    public championRole?: IChampionRole;

    public vdcUser?: IAccountRep;

    // Used for both fsr and IFTS (AU)
    public fsrUser?: IAccountRep;

    public psvUser?: IAccountRep;

    public integrationDate: string;

    public launchDate: string;

    public enrollmentDate?: Date;

    public enrollmentEndDate?: string;

    public parent_sap_id?: string;

    public locale?: string;

    public metaInfo?: IEnrollmentMeta;

    public marked_for_deletion = false;

    // Used to indicate when current enrollment has been submitted so the UI won't allow them to return to earlier screens.
    // Needed because we're allowing edits of completed enrollments which would fail based on just checking enrollmentDate.
    public submitted = false;

    public status?: string;

    public created_on?: Date;

    public createdBy?: IUser;

    public updated_on?: Date;

    public updatedBy?: IUser;

    public dirty?: boolean;

    public version = 1;

    public totalGoalQuantities = 0;

    public selectedDocuments: ISeismicLink[] = [];

    public petCareHeroesEnrolled?: boolean;

    public petCareHeroesNumStaff?: number;

    public petCareHeroesEnrollmentDate?: Date;

    public petCareHeroesLastEnrollmentDate?: Date;

    public constructor(acctInfo?: IAccount, oldSession?: IEnrollment, accountSettings?: IAccountSettings) {
        console.log("Enrollment constructor: ", acctInfo, oldSession);
        if (acctInfo) {
            this.sap_id = acctInfo.sap_id;
            this.practiceName = acctInfo.name;
            this.practiceCity = acctInfo.city;
            this.practiceStateRegion = acctInfo.state;
            this.practiceCountryCd = acctInfo.country_cd;
            if (acctInfo.limsAccountInfo) {
                const limsList: string[] = acctInfo.limsAccountInfo.map((la: ILimsAccount): string => la.lims_practice_id);
                this.lims_practice_id = limsList;
            }

            this.vdcUser = acctInfo.vdcUser;
            this.fsrUser = acctInfo.fsrUser || acctInfo.iftsUser;
            this.psvUser = acctInfo.psvUser;

            if (acctInfo.corpParent) {
                this.parent_sap_id = acctInfo.corpParent.sap_id;
            }
        }

        this.account_settings_id = accountSettings?.account_settings_id

        const today = new Date();
        this.integrationDate = today.toLocaleDateString("en-US");
        const launchDate = new Date();
        launchDate.setDate(launchDate.getDate() + 1);
        this.launchDate = launchDate.toLocaleDateString("en-US");

        if (oldSession) {
            if (oldSession.enrollmentDate) {
                // Retrieve submitted enrollment.
                this.retrieveEnrollment(oldSession);
            } else {
                // Retrieve in-process
                this.retrieveSession(oldSession);
            }
        }

        this.created_on = this.created_on || new Date();

        if (accountSettings && !this.parent_sap_id && AccountSettings.isBuyingGroup(accountSettings)) {
            this.parent_sap_id = accountSettings.sap_id;
        }

        if (!EnrollUtils.isEnrollmentExpired(this)) {
            EnrollUtils.updateEnrollmentEndDate(this);
        }
    }

    // For save and retrieve or re-enroll, need to initialize this enrollment
    // record with data from the previous session.
    private retrieveSession(oldSession: IEnrollment, isPartial: boolean = true, isComplete: boolean = false): void {
        console.log("retrieveSession: ", oldSession);

        if (!oldSession) {
            console.error("oldSession is null");
            return;
        }

        this.enrollment_id = oldSession.enrollment_id;
        this.sap_id = oldSession.sap_id;
        this.account_settings_id = oldSession.account_settings_id;
        this.practiceName = oldSession.practiceName;
        this.practiceCity = oldSession.practiceCity;
        this.practiceStateRegion = oldSession.practiceStateRegion;
        this.practiceCountryCd = oldSession.practiceCountryCd;
        this.practicePimsName = oldSession.practicePimsName;
        this.practicePimsOtherName = oldSession.practicePimsOtherName;
        this.practicePimsVersion = oldSession.practicePimsVersion;
        this.lims_practice_id = oldSession.lims_practice_id;
        this.parent_sap_id = oldSession.parent_sap_id;
        this.championSalutation = oldSession.championSalutation;
        this.championFirstName = oldSession.championFirstName;
        this.championLastName = oldSession.championLastName;
        this.championPhone = oldSession.championPhone;
        this.championEmail = oldSession.championEmail;
        this.championRole = oldSession.championRole;
        this.vdcUser = oldSession.vdcUser;
        this.fsrUser = oldSession.fsrUser;
        this.psvUser = oldSession.psvUser;
        this.version = oldSession.version;
        this.integrationDate = oldSession.integrationDate || this.integrationDate;
        this.launchDate = oldSession.launchDate || this.launchDate;

        if (oldSession.enrollmentDate) {
            this.enrollmentDate = EnrollUtils.toDateLiteral(oldSession.enrollmentDate) || this.enrollmentDate;
        }
        if (oldSession.enrollmentEndDate) {
            this.enrollmentEndDate = oldSession.enrollmentEndDate || this.enrollmentEndDate;
        }

        this.locale = oldSession.locale;
        this.metaInfo = oldSession.metaInfo;
        this.marked_for_deletion = oldSession.marked_for_deletion;
        this.status = oldSession.status;
        this.created_on = oldSession.created_on;
        this.createdBy = oldSession.createdBy;

        this.updated_on = oldSession.updated_on;
        this.updatedBy = oldSession.updatedBy;

        this.totalGoalQuantities = oldSession.totalGoalQuantities;

        this.selectedDocuments = oldSession.selectedDocuments;

        this.petCareHeroesEnrolled = oldSession.petCareHeroesEnrolled;
        this.petCareHeroesNumStaff = oldSession.petCareHeroesNumStaff;
        this.petCareHeroesEnrollmentDate = oldSession.petCareHeroesEnrollmentDate;
        this.petCareHeroesLastEnrollmentDate = oldSession.petCareHeroesLastEnrollmentDate;

        const extras: IProfile[] = [];

        this.profiles = oldSession.profiles;
        const newProfiles: IProfile[] = [];
        if (this.profiles) {
            for (const p of this.profiles) {
                const newProfile = ProfileUtils.retrieveProfile(p, isPartial, isComplete);
                newProfiles.push(newProfile);

                if (!newProfile.clientId || (newProfile.clientId === "na")) {
                    newProfile.clientId = EnrollUtils.nextClientId();
                }

                if (p.isExtra) {
                    extras.push(newProfile);
                }
            }
            this.profiles = newProfiles;
        }

        // Join up "extra" profiles with their parent profile.
        if (extras.length > 0) {
            const parentProfiles: IProfile[] = [];
            for (const extraProfile of extras) {
                // Find the original profile that is the "parent" of this extra profile.
                if (!extraProfile.profile_test_code) {
                    console.warn("Extra profile doesn't have match!", extraProfile);
                    continue;
                }

                const searchTestCode = `${extraProfile.profile_test_code}S`;
                const origProfile = Enrollment.findMatchingProfileForExtra(extraProfile, searchTestCode, this.profiles, parentProfiles);

                if (origProfile) {
                    origProfile.extraProfile = extraProfile;
                    parentProfiles.push(origProfile);
                } else {
                    console.error("Didn't find match for extra profile!", extraProfile);
                }
            }
        }
    }

    // For edit of existing enrollment re-loaded from database.
    // We're copying the data into a new enrollment record.
    // This includes copying all of the old data over, but deleting specific pieces to force saving to a new record in the database.
    // The original enrollment has it's enrollment_id saved to src_enrollment_id of the new record.
    // For active enrollments, the original enrollment is marked as deleted.
    // For completed enrollments, the original enrollment will be updated with new data and saved, and then the copy will be marked as deleted.
    private retrieveEnrollment(oldSession: IEnrollment): void {
        console.log("retrieveEnrollment: ", oldSession);

        const enrollmentCompleted = EnrollUtils.isEnrollmentExpired(oldSession);
        console.log("enrollmentCompleted=", enrollmentCompleted);

        // Call retrieveSession to copy over all of the old data.
        this.retrieveSession(oldSession, false, enrollmentCompleted);

        // Note that we're clearing out the enrollment_id here and saving it as
        // src_enrollment_id instead.  This will cause old enrollment record to be marked
        // for deletion when/if user submits this enrollment.
        this.src_enrollment_id = oldSession.enrollment_id;
        delete this.enrollment_id;

        // Not copying over enrollmentDate as a new one will be populated when
        // user submits.  Otherwise, this will confuse a partial enrollment with a submitted one.
        delete this.enrollmentDate;

        if (!enrollmentCompleted) {
            this.version = 1; // Reset the version


            // Reset the status to PENDING until user submits
            this.status = "PENDING";
        }

        if (this.profiles) {
            this.profiles.forEach((profile: IProfile): void => {
                // Delete the profile id.  But only after re-connecting extra profiles.
                delete profile.enrollment_profile_id;
                delete profile.enrollment_id;
            })
        }

        console.log("At end of retrieveEnrollment: ", this);
    }

    public getProfile(pId: number): IProfile {
        console.log(`getProfile: ${pId}`);
        return this.profiles.find((profile: IProfile): boolean =>
            pId === profile.template_profile_id
        );
    }

    public isEnrolled(): boolean {
        return this.enrollmentDate != null;
    }

    /**
     * Saved enrollment contains all configured profiles.  But only a subset has been selected by the user to be added to the enrollment.
     * Return profiles marked as selected=true.
     */
    public static getSelectedProfiles(enroll: IEnrollment): IProfile[] {
        if (!enroll || !enroll.profiles) {
            return [];
        }

        return enroll.profiles.filter((profile: IProfile): boolean => (
            profile && profile.selected === true
        ));
    }

    /**
     * Find the "source" profile that the specified extra profile was created from.
     * Used for IDEXX Anywhere/Urinalysis logic
     */
    private static findMatchingProfileForExtra(extraProfile: IProfile, searchTestCode: string, profiles: IProfile[], foundProfiles: IProfile[]): IProfile {
        if (!extraProfile || !profiles || !searchTestCode) {
            return null;
        }

        return profiles.find((profile: IProfile): boolean => {
            if (extraProfile.enrollment_profile_id === profile.enrollment_profile_id) {
                return false;
            }
            if (foundProfiles.indexOf(profile) != -1) {
                return false;
            }
            if (profile.isExtra === true) {
                return false;
            }
            if (profile.template_profile_id !== extraProfile.template_profile_id) {
                return false;
            }
            if (profile.profile_test_code
                && profile.profile_test_code === searchTestCode) {
                return true;
            }
            return false;
        });
    }

    public autoAddProfilesIfNeeded(accountSettings: IAccountSettings, locale?: string): void {
        const localeCode = locale || accountSettings.defaultLocale;
        const translations = accountSettings.translations[localeCode] || {};

        // If any templates are marked as default selected, add them to the new enrollment here.
        // TODO: pricing needs to run later
        accountSettings.profileTemplates.forEach((tpl: IProfileTemplate): void => {
            if (tpl.defaultSelected) {
                let profile = this.profiles.find((p: IProfile): boolean => p.profileTemplate?.template_profile_id === tpl.template_profile_id);
                if (!profile) {
                    console.log("Auto-adding template: ", tpl);
                    profile = Profile.fromTemplate(tpl);

                    // Pull template display name from translations
                    profile.display_name = translations[tpl.displayNameKey];

                    EnrollUtils.addProfile(this, profile);
                }
            }
        });
    }
}

export const EnrollUtils = {
    nextClientId(): string {
        return uuidv4();
    },

    /**
     * Add new built profile to Enrollment.
     * Checks for duplicates.
     */
    addProfile(enrollInfo: IEnrollment, p: IProfile, targetProfile?: IProfile): void {
        console.log("addProfile: ", p.profile_test_code, p);
        if (p) {
            console.log("Looking for existing profile...");
            const foundProfile = EnrollUtils.findProfile(p, enrollInfo);
            if (foundProfile) {
                enrollInfo.profiles = enrollInfo.profiles.filter((profile: IProfile): boolean => profile !== foundProfile);
            }
            if (!p.clientId || (p.clientId === "na")) {
                p.clientId = EnrollUtils.nextClientId();
            }

            p.enrollment_id = enrollInfo.enrollment_id;
            if (!targetProfile) {
                enrollInfo.profiles.push(p);
            } else {
                const index = enrollInfo.profiles.indexOf(targetProfile);
                if (index === -1) {
                    enrollInfo.profiles.push(p);
                } else {
                    enrollInfo.profiles.splice(index + 1, 0, p);
                }
            }
        }
    },

    /**
     * Update enrollment info after a successful save call.
     */
    updateEnrollment(enrollInfo: IEnrollment, savedEnroll: IEnrollment): IEnrollment {
        console.log("updateEnrollment: ", enrollInfo, savedEnroll);

        if (!enrollInfo) {
            return savedEnroll;
        }
        if (!savedEnroll) {
            return enrollInfo;
        }

        enrollInfo.enrollment_id = savedEnroll.enrollment_id;
        for (const prf of enrollInfo.profiles) {
            const foundProfile = EnrollUtils.findProfile(prf, savedEnroll);
            if (foundProfile) {
                ProfileUtils.updateProfile(prf, foundProfile);
            } else {
                console.warn("Didn't find profile: ", prf, savedEnroll.profiles);
            }
        }

        enrollInfo.version = savedEnroll.version;

        return enrollInfo;
    },

    updateEnrollmentEndDate(enrollInfo: IEnrollment): void {
        const date = new Date(enrollInfo.launchDate);
        console.log("launchDate=", date);
        if (date) {
            // Start out with same date as launchDate
            // Add six months, and then set to start of that month.
            // Note that with a launchDate of 1/1/21, we don't want to lose the year.
            // So taking today and just adding six months misses the year part
            // of the new date.
            const enrollmentEndDate = new Date(date);
            enrollmentEndDate.setDate(1);
            enrollmentEndDate.setMonth(date.getMonth() + 6);
            enrollmentEndDate.setDate(0);
            enrollInfo.enrollmentEndDate = enrollmentEndDate.toLocaleDateString("en-US");
        }
    },

    toDateLiteral(d: Date | string): Date {
        if (d) {
            if (typeof d === "string") {
                return new Date(d);
            }
            return this.toDateLiteral(d.toISOString());
        }
        return null;
    },

    resetDates(enrollInfo: IEnrollment): void {
        console.log("resetDates");
        enrollInfo.integrationDate = new Date().toLocaleDateString("en-US");
        const launchDate = new Date();
        launchDate.setDate(launchDate.getDate() + 1);
        enrollInfo.launchDate = launchDate.toLocaleDateString("en-US");
        this.updateEnrollmentEndDate(enrollInfo);
    },

    clearPricing(enrollInfo: IEnrollment): void {
        console.log("clearPricing: ", enrollInfo);
        if (!enrollInfo || !enrollInfo.profiles) {
            return;
        }
        for (const profile of enrollInfo.profiles) {
            ProfileUtils.clearPricing(profile);
        }
    },

    isPricingExpired(enrollInfo: IEnrollment, sessionMode: SessionModeEnum): boolean {
        console.log("isPricingExpired: ", enrollInfo?.created_on);
        if (!enrollInfo || !enrollInfo.created_on) {
            console.warn("No enrollInfo or created on?", enrollInfo);
            return false;
        }

        // Don't reprice if enrollment has been submitted.  For both active and completed enrollments.
        if (sessionMode === SessionModeEnum.ACTIVE || sessionMode === SessionModeEnum.EXPIRED) {
            return false;
        }

        if (enrollInfo.enrollmentDate) {
            // Don't reprice if enrollment has been submitted.  For both active and completed enrollments.
            return false;
        }

        const today = moment();
        const createdOn = moment(enrollInfo.created_on);

        let isExpired = !((today.diff(createdOn, "days") < EXPIRE_DAYS)
            && createdOn.year() === today.year());

        console.log("isPricingExpired returning: ", isExpired, this.needsPricing(enrollInfo));

        return isExpired || this.needsPricing(enrollInfo);
    },

    isEnrollmentExpired(enrollInfo: IEnrollment): boolean {
        if (!enrollInfo.enrollmentEndDate) {
            return false;
        }

        return UtilService.compareDatesIgnoreTime(enrollInfo.enrollmentEndDate, new Date()) <= 0;
    },

    findProfile(searchProfile: IProfile, enrollInfo: IEnrollment): IProfile {
        if (!searchProfile || !enrollInfo) {
            console.error("Error: Invalid params sent to findProfile: ", searchProfile, enrollInfo);
            return null;
        }
        let foundProfile = enrollInfo.profiles.find((p: IProfile): boolean => {
            if (searchProfile.enrollment_profile_id && p.enrollment_profile_id) {
                return p.enrollment_profile_id === searchProfile.enrollment_profile_id;
            }
            return p.clientId && p.clientId === searchProfile.clientId;
        });
        if (!foundProfile) {
            // Try again just using matched profile code.
            // Problem here is that user can theoretically build the same profile
            // multiple times and we want to make sure we match against different profiles
            // coming back, or we start losing profiles again...
            foundProfile = enrollInfo.profiles.find((p: IProfile): boolean => {
                if (!p.clientId && p.profile_test_code && searchProfile.profile_test_code) {
                    return p.profile_test_code === searchProfile.profile_test_code;
                }
                return false;
            });
        }
        return foundProfile;
    },

    replaceProfile(profile: IProfile, enrollInfo: IEnrollment): boolean {
        if (!profile || !enrollInfo) {
            console.error("Bad arguments to replaceProfile", profile, enrollInfo);
            return false;
        }

        if (!profile.enrollment_profile_id) {
            console.warn("No profile id yet!");
        }

        enrollInfo.profiles = enrollInfo.profiles.map((enrollmentProfile: IProfile): IProfile => {
            if (enrollmentProfile.enrollment_profile_id === profile.enrollment_profile_id
                || profile.clientId && profile.clientId === enrollmentProfile.clientId) {
                return profile;
            }
            return enrollmentProfile;
        });
        return true;
    },

    getVdcUser(enrollInfo: IEnrollment): IAccountRep {
        return enrollInfo.vdcUser || {
            rep_role: "VDC"
        };
    },

    // Return true if any profiles in this enrollment don't yet have pricing defined.
    // This should only arise if a template is marked as selected by default.
    needsPricing(enrollInfo: IEnrollment): boolean {
        return enrollInfo?.profiles?.some((profile: IProfile): boolean => !profile.isPetCareHeroes && !profile.acceptedPracticePrice && !profile.acceptedPetOwnerPrice);
    }
};
