import { ISeismicLink } from "./seismic";
import { FileInfo } from "./file";
import { IPCCError } from "@shared/model/error";
import { LocalizedResource, LocalizedContent } from "./language";

export const CustomPageTypes = {
    LAUNCH: "LAUNCH",
    PROFILES: "PROFILES",
    PET_OWNER_PRICE: "PET_OWNER_PRICE",
    GOAL: "GOAL",
    ENROLL: "ENROLL",
    DONE: "DONE",
    CORP_PRICING: "CORP_PRICING",
    PRICING: "PRICING",
    PDF: "PDF",
    TEST_MIX: "TEST_MIX"
} as const;

export type CustomPageType = keyof typeof CustomPageTypes;

export interface ICustomPage extends LocalizedResource {
    pageType: CustomPageType;
    id?: number;
    accountSettingsId?: number;

    titleKey?: string;

    subTitleKey?: string;

    bodyKey?: string;

    images?: FileInfo[];

    links: ISeismicLink[];

    displayOrder: number;

    active: boolean;

    // Used to contain localized display text for this object
    localizedKeys: LocalizedContent;

    errors?: IPCCError[];

    createdOn?: Date;
    createdBy?: number;

    updatedOn?: Date;
    updatedBy?: number;
}
