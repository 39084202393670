import { HttpRequest } from "@angular/common/http";

type ErrorConfig = {
    count: number;
    duration: number;
    status: number;
}
export class Test {
    public static paths: {
        [key: string]: {
            method: string,
            error: ErrorConfig
        }
    } = {};

    public static service(path: string, { method = "get", error }: { method: string; error: ErrorConfig }): void {
        if (!error) {
            delete this.paths[path];
        } else {
            this.paths[path] = {
                method: method.toLowerCase(), error
            };
        }
    }

    public static _serviceInject(request: HttpRequest<any> | { url: string; method?: string; params: { [key: string]: any } }): void {
        const paths = Object.keys(this.paths);
        const values = Object.values(this.paths);
        for (let i = 0, ln = paths.length; i < ln; i += 1) {
            const path = paths[i];
            const config = values[i];

            if (request.url.indexOf(path) !== -1 && request.method.toLowerCase() === config.method) {
                if (config.error) {
                    if (!request.params) {
                        (request as any).params = {
                        };
                    }

                    function setParam(param: string, value: string | boolean | number): void {
                        if (!request.params) {
                            (request as any).params = {
                            };
                        }
                        if (request.params.set) {
                            (request as any).params = request.params.set(param, value);
                        } else {
                            request.params[param] = value;
                        }
                    }

                    setParam("error", true);
                    if (config.error.status) {
                        setParam("e_status", config.error.status);
                    }
                    if (config.error.duration) {
                        setParam("e_duration", config.error.duration);
                    }
                    if (config.error.count) {
                        setParam("e_count", config.error.count);
                    }
                }
                break;
            }
        }
    }
}

if (typeof window !== "undefined") {
    window["Test"] = Test;
}
