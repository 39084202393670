import fr_CA from "@angular/common/locales/fr-CA";
import { registerLocaleData } from "@angular/common";
import { NativeUtil } from "./utils/native";
import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { TranslateModule, TranslateLoader, MissingTranslationHandler } from "@ngx-translate/core";
import { FormsModule } from "@angular/forms";
import { HttpClientModule, HttpClient } from "@angular/common/http";
import { PCCAlertService } from "./service/alert.service";
import { PCCPriceService } from "./service/price.service";
import { CorpPricingAlgorithmService } from "./service/corp-pricing-algorithm.service";
import { PCCTranslateService } from "./service/translate.service";
import { AccountService } from "./service/account.service";
import { TestMixService } from "./service/testmix.service";
import { AppService } from "./service/app.service";
import { ApiService } from "./service/api.service";
import { AdminService } from "./service/admin.service";
import { AppFacade } from "./facade/app.facade";
import { AdminFacade } from "./facade/admin.facade";
import { NavService } from "./service/nav.service";
import { SharedModule } from "./shared/shared.module";
import { AdminModule } from "./admin/admin.module";
import { ClientModule } from "./client/client.module";
import { AppRoutingModule } from "./app-routing.module";
import { AppLoadModule } from "./app-load.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgbModule, NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { MaterialDesignModule } from "../material-design/material-design.module";
import { AuthModule } from "./auth/auth.module";
import { TranslateLoaderFactory } from "frontend/app/translate/translate.loader";
import { PCCMissingTranslationHandler } from "frontend/app/translate/missing-translate.handler";

import { VersionService } from "./service/version.service";
import { AngularSplitModule } from "angular-split";

registerLocaleData(fr_CA);
NativeUtil.scrollToElementOnKeyboardShow();

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        AngularSplitModule,
        TranslateModule.forRoot({
            defaultLanguage: "en-US",
            loader: {
                provide: TranslateLoader,
                useFactory: TranslateLoaderFactory,
                deps: [
                    HttpClient
                ]
            },
            missingTranslationHandler: {
                provide: MissingTranslationHandler,
                useClass: PCCMissingTranslationHandler,
            },
            useDefaultLang: true
        }),
        AppRoutingModule,
        AppLoadModule,
        BrowserAnimationsModule,
        BrowserModule,
        ClientModule,
        AdminModule,
        AuthModule,
        FormsModule,
        HttpClientModule,
        MaterialDesignModule,
        NgbModule,
        SharedModule
    ],
    exports: [
        SharedModule
    ],
    providers: [
        NgbActiveModal,
        AppService,
        ApiService,
        AdminService,
        AppFacade,
        AdminFacade,
        TestMixService,
        AccountService,
        PCCAlertService,
        PCCPriceService,
        CorpPricingAlgorithmService,
        PCCTranslateService,
        VersionService,
        NavService,
        AngularSplitModule
    ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule {}
