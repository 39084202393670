<div style="display:flex;flex-direction:row;width:100%;">
  <label class="spot-form__label" style="flex:1;" for="richTextField">{{label}}</label>
  <button *ngIf="showEditButton" type="button" class="spot-button spot-button--icon-only round-button" (click)="editClicked()">
    <svg class="spot-icon" aria-labelledby="title">
      <title>Edit</title>
      <use xlink:href="/assets/spot-library/dist/symbols/spot_symbols.svg#edit"></use>
    </svg>
  </button>
</div>
<quill-editor *ngIf="editMode" id="richTextField" name="richTextField" [(ngModel)]="richText" (onContentChanged)="textChanged()"></quill-editor>
<div *ngIf="!editMode && richText" [innerHtml]="richText" class="rich-text-readonly"></div>
