<div class="view-body" style="display:flex;flex-direction:column;">
  <div class="view-title spot-typography__heading--level-2">{{customContent?.titleKey | translate}}</div>
  <div style="flex:1;display:flex;">
	<div style="flex:1;">
		<section class="page-info">
		<div class="description">
          <p style="font-size: 18px;padding: 10px 0;">{{customContent?.subTitleKey | translate}}</p>
          <div [innerHTML]="customContent?.bodyKey | customTranslate"></div>
		</div>
	  </section>

	  <pcc-profiles-tab-panel
		[profiles]="getActiveProfiles()"
		[petOwnerPricePage]="true"
		[canEdit]="false"
		[canEditPetOwnerPrice]="true"
		[canEditPracticePrice]="false"
		[canSelect]="false"
		></pcc-profiles-tab-panel>
	</div>
	<section class="resources">
      <div class="pcc-level-5-20 spot-typography__heading--level-5">{{'pet-owner-pricing.resources' | translate}}</div>
	  <pcc-seismic-docs-panel [loading]="loadingDocs" [docs]="docs" [canSelect]="false"></pcc-seismic-docs-panel>
	</section>
  </div>
</div>
