import { ImageInfo } from "./image";
import { IPCCError } from "./error";
import { Readable } from "stream";

export interface SeismicThumbnail extends ImageInfo {
    contentId: string;
    format?: string;
    error?: IPCCError;
}

export interface ISeismicLink {
    id?: number;
    custom_page_id?: number;

    url?: string;
    desc?: string;

    // format of file - pdf, mp4, etc
    format?: string;

    // Size of document
    size?: number;

    docCenterUrl?: string;

    thumbnailData?: SeismicThumbnail;

    contentId?: string;

    displayOrder: number;

    active: boolean;

    locale?: string

    error?: IPCCError;

    createdOn?: Date;
    createdBy?: number;

    updatedOn?: Date;
    updatedBy?: number;

    isDefault: boolean;

    tag?: string;
}

export interface SelectableSeismicLink extends ISeismicLink {
    selected?: boolean;
}

export interface SeismicDoc {
    url: string;
    tenantId: string;
    teamSiteId: string;
    contentId: string;
}

export interface CustomProperty {
    id: string;
    name: string;
    values: string[];
}

export interface SeismicFileProperties {
    size: number;
    id: string;
    type: string;
    name: string;
    version: string;
    format: string;
    status: string;
    thumbnailId: string;
    thumbnailUrl: string;
    properties: CustomProperty[];
}

export interface SeismicDocument {
    contentId: string;
    fileName?: string;
    format?: string;
    version?: string;
    url?: string;
    contentType?: string;
    contentLength?: number;

    fileData?: string;
    fileStream?: Readable;

    error?: Error;
}

export const DOC_TAGS = {
    PCH: "PCH",
    IAUA: "IAUA"
};
