<div style="display:flex;align-items: center;gap:1em;">
  <div class="spot-form__field-group">
    <label class="spot-form__label" for="supportedLocales">
      <span class="spot-form__label-required-indicator">*</span>
      Supported Language(s)
    </label>
    <div class="spot-form__select">
      <select name="supportedLocales" class="spot-form__select-input" aria-label="Supported Languages" [(ngModel)]="selectedLocale"  required>
        <option class="spot-form__select-option" value=""></option>
        <option *ngFor="let lang of allLanguages" [value]="lang.locale" class="spot-form__select-option">{{ lang.displayName }} ({{ lang.locale }})</option>
      </select>
      <div class="spot-form__select-inner"></div>
      <span class="spot-form__select-open-indicator">
        <svg class="spot-icon spot-form__select-open-icon" aria-labelledby="title">
          <title>caret-down</title>
          <use xlink:href="/assets/spot-library/dist/symbols/spot_symbols.svg#caret-down"></use>
        </svg>
      </span>
    </div>
  </div>
  <button type="button" class="spot-button spot-button--primary" (click)="addLanguage()">Add language</button>
</div>

<div class="active-languages">
  <span class="active-languages-title">Active Language(s)</span>
  <table class="spot-data-table" style="width:100%;">
    <thead>
      <tr>
        <th style="text-align:left !important;">Language</th>
        <th>Default Language</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let lang of accountSettings.languages" >
        <td>
          {{ lang.displayName }} ({{ lang.locale }})
        </td>
        <td style="text-align: center">
          <label class="spot-form__radio no-margin" [for]="'default_' + lang.locale">
            <input type="radio" [id]="'default_' + lang.locale" name="defaultLocale" [value]="lang.locale" class="spot-form__radio-input" [(ngModel)]="accountSettings.defaultLocale"/>
            <span class="spot-form__radio-inner">
              <span class="spot-form__radio-visual"></span>
            </span>
          </label>
        </td>
        <td>
          <pcc-confirm-action symbol="delete" confirmPosition="spot-popover--bottom-left" (okClick)="deleteLanguageConfirmed(lang)" class="delete-button" confirmMsg="confirm_action.are_you_sure"></pcc-confirm-action>
        </td>
      </tr>
    </tbody>
  </table>
</div>
