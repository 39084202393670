import { envUtils } from "../globals/env";

declare const gtag: Function;

class GoogleAnalytics {
    private static googleAnalyticsInstance: GoogleAnalytics;

    private readonly googleTag: Function;

    private constructor(googleTag: Function) {
        this.googleTag = googleTag;
    }

    public static async getInstance(): Promise<GoogleAnalytics> {
        if (!GoogleAnalytics.googleAnalyticsInstance) {
            GoogleAnalytics.googleAnalyticsInstance = new GoogleAnalytics(gtag || function () {});
            GoogleAnalytics.registerIpadEvent();
        }
        return GoogleAnalytics.googleAnalyticsInstance;
    }

    public static async initGoogle(): Promise<void> {
        console.log("Running initGoogle...");
        const targetId = envUtils.GOOGLE_TARGET_ID;

        await GoogleAnalytics.loadScript(`https://www.googletagmanager.com/gtag/js?id=${targetId}`);

        gtag("js", new Date());

        if (envUtils.ENVIRONMENT === "PROD") {
            gtag("config", targetId);
        } else {
            gtag("config", targetId, { "debug_mode": true });
        }
        console.log("Done running initGoogle");
    }

    public static async registerUser(id: string): Promise<void> {
        (await GoogleAnalytics.getInstance())
            .googleTag("set", { user_id: id });
    }

    public static async registerPageView(url: string): Promise<void> {
        (await GoogleAnalytics.getInstance())
            .googleTag("set", { "page_path": url });
    }

    public static async registerEvent(actionName: string, category: string, customParams: { [key: string]: string } | Object = {}, label?: string, value?: number): Promise<void> {
        (await GoogleAnalytics.getInstance())
            .googleTag("event", actionName, {
                ...customParams,
                event_category: category,
                event_label: label,
                value
            });
    }

    public static async registerIpadEvent(): Promise<void> {
        const isMacUserAgent = window.navigator.userAgent.match(/Mac/);
        const hasBeenChecked = window.localStorage.getItem("ipadChecked") === "true";
        const hasManyTouchPoints = window.navigator.maxTouchPoints > 2;
        await GoogleAnalytics.registerEvent("is_ipad_test", "browser_type", {
            userAgent: window.navigator.userAgent,
            touchPoints: window.navigator.maxTouchPoints.toString(),
            hasBeenChecked: hasBeenChecked.toString(),
            ipadChecked: window.localStorage.getItem("ipadChecked")
        });
        if (isMacUserAgent && hasManyTouchPoints && !hasBeenChecked) {
            await GoogleAnalytics.registerEvent("is_ipad", "browser_type");
            window.localStorage.setItem("ipadChecked", "true");
        }
    }

    private static async loadScript(url: string): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            const script = document.createElement('script');
            script.src = url;
            script.type = "text/javascript";
            script.onload = () => {
                resolve();
            };
            script.onerror = (error) => {
                console.error("Error loading google analytics: ", error);
                reject(error);
            };
            document.head.appendChild(script);
        });
    }
}

export default GoogleAnalytics;
