import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { NgbActiveModal, NgbPopover, NgbPopoverConfig } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";

import { IProfile, Profile, ProfileUtils, IModalityCategories } from "@shared/model/profile";
import { AppFacade } from "frontend/app/facade/app.facade";
import { PCCSession } from "@shared/model/pcc-session";
import { Subscription } from "rxjs";
import { PCCAlertService } from "../../../service/alert.service";
import { ErrorUtils } from "@shared/model/error/error-utils";
import { SamePriceEnum } from "../../../service/price.service";
import { IProfileResponse } from "@shared/model/service/profile-service";
import { IEnrollResponse } from "@shared/model/service/enroll-service";
import { AccountSettings } from "@shared/model/account-settings";
import { ICustomPage, CustomPageTypes } from "@shared/model/custom-page";

@Component({
    selector: "pcc-edit-pricing-ca",
    templateUrl: "./edit-pricing-ca.component.html",
    styleUrls: [
        "./edit-pricing-ca.component.scss"
    ],
    providers: [
        NgbPopoverConfig
    ]
})
export class EditPricingCAComponent implements OnInit, OnDestroy {
    @Input() public profile: IProfile;

    public price: number;

    public MODALITY_ICONS = {
        "REF_LAB": "/assets/images/test_lab.png",
        "REF_LAB_IHD": "/assets/images/lab_ihd.png",
        "SNAP": "/assets/images/snap.png",
        "IHD": "/assets/images/ihd_price.png"
    };

    @ViewChild(NgbPopover, {
        static: false
    }) public errorPopover: NgbPopover;

    public showPricingError = false;

    public saving = false;

    public floorPrice: number;

    public recommendedPrice: number;

    public listPrice: number;

    public customerPrice: number;

    public inhousePrice: number;

    public session: PCCSession;

    public selectedTestsByModalityAndCategory: IModalityCategories[];

    public showFloorPrice = true;

    public priceRangeErrorMsg: string;

    public hasInhousePricing = false;

    private sessionSub: Subscription;

    public customContent: ICustomPage;

    public customPrefix: string;

    public constructor(
        public activeModal: NgbActiveModal,
        public appFacade: AppFacade,
        private alertService: PCCAlertService,
        private translateService: TranslateService,
        popoverConfig: NgbPopoverConfig
    ) {
        popoverConfig.placement = "bottom-right";
        popoverConfig.popoverClass = "error-popover";
        popoverConfig.autoClose = false;
    }

    public ngOnInit(): void {
        this.sessionSub = this.appFacade.getCurrentSession().subscribe((session): void => this.setSession(session));
    }

    public ngOnDestroy(): void {
        this.sessionSub.unsubscribe();
    }

    private setSession(session: PCCSession): void {
        console.log("setSession:", session);
        this.session = session;

        this.showFloorPrice = this.session.accountSettings.flags.show_floor_price;
        console.log("showFloorPrice=", this.showFloorPrice);

        let msgKey = "edit-pricing-ca.Special_price_range_error";
        if (this.showFloorPrice === false) {
            msgKey = "edit-pricing-ca.Special_price_range_error2";
        }

        this.priceRangeErrorMsg = this.translateService.instant(msgKey);

        const customPages = AccountSettings.getCustomPages(this.session.accountSettings, CustomPageTypes.PRICING);
        this.customContent = customPages.length > 0 ? customPages[0] : null;

        this.customPrefix = `CUSTOM_PAGE.${this.customContent?.id}.`;
    }

    public setProfile(p: Profile): void {
        console.log("setProfile: ", p);

        this.profile = p;
        this.listPrice = parseFloat(parseFloat(`${this.profile.listPrice}`).toFixed(2));
        this.customerPrice = parseFloat(parseFloat(`${this.profile.customerPrice}`).toFixed(2));
        this.inhousePrice = parseFloat(parseFloat(`${this.profile.inhousePrice}`).toFixed(2));
        this.price = parseFloat(parseFloat(`${this.profile.specialPrice}`).toFixed(2));
        this.recommendedPrice = parseFloat(parseFloat(`${this.profile.recommendedPracticePrice}`).toFixed(2));
        this.floorPrice = parseFloat(parseFloat(`${this.profile.floorPrice}`).toFixed(2));
        this.hasInhousePricing = (this.profile.inhousePrice != null && this.profile.inhousePrice > 0);

        this.selectedTestsByModalityAndCategory = ProfileUtils.getTestsByModalityAndCategory(this.profile, this.profile.profileItems);

    }

    private async save(): Promise<void> {
        console.log("save: price=", this.price);
        const enrollInfo = this.session.enrollInfo;
        let saveAll = false;

        const samePriceResp = await this.appFacade.samePriceCheck(enrollInfo, this.profile, true);
        if (samePriceResp === SamePriceEnum.COPY) {
            const resp = this.appFacade.revertPrices(this.profile, enrollInfo);
            // Only need to save this profile, which happens below.
            console.log("revertPrices success=", resp);
        } else if (samePriceResp === SamePriceEnum.REVERT) {
            const resp = this.appFacade.copyPrices(this.profile, enrollInfo);
            // Need to save entire enrollment to save any modified profiles as well.
            console.log("copyPrices success=", resp);
            saveAll = true;
        }

        this.setBusy(true, "Saving...");
        this.profile.error = null;

        try {
            let saveResp: IProfileResponse | IEnrollResponse;
            if (saveAll === false) {
                saveResp = await this.appFacade.saveProfile(this.profile);
            } else {
                saveResp = await this.appFacade.saveSession();
            }
            console.log("saveResp=", saveResp);

            if (saveResp.success === true) {

                // Keep adjusted price in sync between original and extra profiles
                // for Idexx Anywhere.
                if (this.profile.extraProfile) {
                    console.log("extraProfile present: ", this.profile.extraProfile);
                    this.profile.extraProfile.specialPrice = this.profile.specialPrice;
                    this.profile.extraProfile.acceptedPracticePrice = this.calculateAcceptedPrice(this.profile.extraProfile);
                    console.log("specialPrice=", this.profile.specialPrice);
                    const saveExtraResp = await this.appFacade.saveProfile(this.profile.extraProfile);
                    console.log("saveExtraResp=", saveExtraResp);

                    this.setBusy(false);
                    if (saveExtraResp.success === true) {
                        this.activeModal.close(this.profile);
                        return;
                    }
                    console.error("Error saving extra profile: ", ErrorUtils.getErrorMessage(saveExtraResp.error));

                }

                this.activeModal.close(this.profile);
            }
            this.setBusy(false);
        } catch (err) {
            console.error("Error saving profile: ", err);
            this.setBusy(false);
        }

    }

    public onPriceChange(price: any): void {
        console.log(`onPriceChange: ${price}`);
        this.price = price;
        if (this.price < this.floorPrice
            || this.price > this.listPrice) {
            this.showPricingError = true;
            this.errorPopover.open();
        } else {
            this.showPricingError = false;
            this.errorPopover.close();
        }
    }

    public onUpdateProfile(): void {
        if (this.showPricingError) {
            return;
        }
        this.profile.specialPrice = this.price;
        this.profile.acceptedPracticePrice = this.price + this.profile.inhousePrice;

        this.save();
    }

    public cancelClicked(): void {
        this.activeModal.close(this.profile);
    }

    private setBusy(isBusy: boolean, msg?: string): void {
        this.alertService.setBusy(isBusy, msg);
        this.profile.loading = isBusy;
        this.profile.error = null;
        this.saving = isBusy;
    }

    private calculateAcceptedPrice(profile: IProfile): number {
        return parseFloat(`${profile.specialPrice}`) + profile.inhousePrice;
    }
}
