import { Component, Input, Output, EventEmitter, OnInit } from "@angular/core";

@Component({
    selector: "pcc-edit-text",
    templateUrl: "./edit-text.component.html",
    styleUrls: [
        "./edit-text.component.scss"
    ]
})
export class EditTextComponent implements OnInit {

    @Input() public label: string;

    @Input() public editMode = false;

    @Input() public editEnabledEmpty = false;

    @Input() public editText!: string;

    @Input() public required = false;

    @Input() public showEditButton = true;

    @Input() public hasRichText = false;

    @Output() private editTextChange = new EventEmitter<string>();

    public ngOnInit(): void {
        if (this.editEnabledEmpty && this.editText && !this.editText.trim()) {
            this.editMode = true;
        }
    }

    public editClicked(): void {
        this.editMode = !this.editMode;
        console.log("editClicked: ", this.editMode);
    }

    public textChanged(): void {
        this.editTextChange.emit(this.editText);
    }
}
