import { Component, Input, Output, EventEmitter, AfterViewInit, ViewChild, OnInit, OnChanges } from "@angular/core";
import { NgForm } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

import { Clipboard } from "@angular/cdk/clipboard";

import { SeismicDoc, ISeismicLink, SeismicFileProperties, DOC_TAGS } from "@shared/model/seismic";
import { SeismicUtilsService } from "@shared/service/seismic-utils.service";
import { IAccountSettings } from "@shared/model/account-settings";

import { AdminFacade } from "../../../facade/admin.facade";
import { PCCAlertService } from "../../../service/alert.service";
import { PCCTranslateService } from "@client/service/translate.service";

import { Language } from "@shared/model/language";
import { ErrorUtils } from "@shared/model/error/error-utils";

export const MODAL_RESULTS = {
    SAVED: "saved",
    DELETED: "deleted",
    CANCELLED: "cancelled"
}

@Component({
    selector: "pcc-seismic-link-edit",
    templateUrl: "./seismic-link-edit.component.html",
    styleUrls: [
        "./seismic-link-edit.component.scss"
    ]
})
export class SeismicLinkEditComponent implements AfterViewInit, OnChanges, OnInit {

    @ViewChild("linkForm", {
        static: false
    }) public linkForm: NgForm;

    @Input() public selectedLink?: ISeismicLink;

    @Input() public accountSettings: IAccountSettings;

    @Input() public showTag = true;

    @Output() public valid = new EventEmitter<boolean>();

    @Output() public linkChanged = new EventEmitter<ISeismicLink>();

    @Output() public linkDeleted = new EventEmitter<ISeismicLink>();

    public hasUrlError = false;

    public isValid = false;

    public fileProperties: SeismicFileProperties;

    public tagList = Object.values(DOC_TAGS);

    public languages: Language[] = [];

    public isLoading = false;

    public loadingMessage = "Loading...";

    public constructor(
        private adminFacade: AdminFacade,
        private alertService: PCCAlertService,
        public activeModal: NgbActiveModal,
        private clipboard: Clipboard,
        private translateService: PCCTranslateService
    ) {}

    public ngOnInit(): void {
        if (this.accountSettings) {
            this.languages = this.accountSettings.languages;
            this.selectedLink.locale = this.translateService.getDefaultLocale(this.accountSettings.supportedLocales, this.selectedLink.locale, false);
        }
    }

    public cancel(): void {
        console.log("cancel");
        this.activeModal.close(MODAL_RESULTS.CANCELLED);
    }

    public ngOnChanges(): void {
        console.log("ngOnChanges");
        this.isValid = this.validate();
    }

    public ngAfterViewInit(): void {
        if (this.linkForm) {
            this.linkForm.statusChanges.subscribe((status: unknown): void => {
                console.log("linkForm status change: ", status);
                this.isValid = this.validate();
            });
        } else {
            console.error("this.linkForm doesn't exist!");
        }
    }

    public validate(): boolean {
        const isValid = SeismicUtilsService.isLinkValid(this.selectedLink);
        if (isValid !== this.isValid) {
            this.valid.emit(isValid);
        }
        return isValid;
    }

    public async docUrlChanged(link: ISeismicLink, url: string): Promise<void> {
        console.log("docUrlChanged: ", url);
        this.hasUrlError = false;
        if (!url) {
            delete link.contentId;
            return;
        }

        try {
            const docInfo: SeismicDoc = SeismicUtilsService.parseDocUrl(url);
            console.log("docInfo=", docInfo);
            link.contentId = docInfo.contentId;
            await this.refreshFileProperties();

            this.linkChanged.emit(link);
        } catch (err) {
            console.error("Error parsing doc url: ", url, err);
            this.hasUrlError = true;
        }
    }

    public async deleteLinkConfirmed(oldLink: ISeismicLink): Promise<void> {

        this.setBusy(true, "Deleting...");

        try {
            oldLink.active = false;

            const saveResp = await this.adminFacade.saveSeismicLink(oldLink);


            if (saveResp && saveResp.success === true) {

                this.alertService.showToast("Save successful");

                this.linkDeleted.emit(oldLink);

                this.activeModal.close(MODAL_RESULTS.DELETED);
            }
        } catch (err) {
            console.error("Error deleting link: ", err);
            const msg = `System Error deleting link: ${ErrorUtils.getErrorMessage(err)}`;

            this.alertService.showError(msg);
        } finally {
            this.setBusy(false);
        }
    }

    public async refreshFileProperties(): Promise<void> {
        console.log("refreshFileProperties");
        if (this.selectedLink && this.selectedLink.contentId) {
            this.setBusy(true, "Pulling document data from Seismic...");
            const resp = await this.adminFacade.getFileProperties(this.selectedLink.contentId);
            console.log("fileProperties resp: ", resp);
            if (resp.success) {
                this.setFileProperties(resp.data);
            } else {
                console.error("Error pulling file properties: ", resp);
            }
            this.setBusy(false);
        }
    }

    private setFileProperties(fileInfo: SeismicFileProperties): void {
        this.fileProperties = fileInfo;

        if (fileInfo) {
            if (!this.selectedLink.desc) {
                this.selectedLink.desc = fileInfo.name;
            }
            this.selectedLink.format = fileInfo.format;
            this.selectedLink.size = fileInfo.size;
        }
    }

    public async refreshCache(): Promise<void> {
        console.log("refreshCache");
        if (this.selectedLink.contentId) {
            try {
                this.setBusy(true, "Refreshing cached file from Seismic...");
                const resp = await this.adminFacade.refreshFile(this.selectedLink.contentId);
                console.log("refreshFile resp=", resp);
            } catch (err) {
                console.error("Error refreshing cached file: ", err);
                this.alertService.showError("Error refreshing cached file: " + ErrorUtils.getErrorMessage(err));
            } finally {
                this.setBusy(false);
            }
        }
    }

    public formatFileSize(fileSizeInBytes: number): string {
        const units = [
            "B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"
        ];

        if (fileSizeInBytes === 0) {
            return "0 B";
        }

        const i = Math.floor(Math.log(fileSizeInBytes) / Math.log(1024));
        const sizeInUnit = fileSizeInBytes / (1024 ** i);

        // Round to two decimal places
        const roundedSize = Math.round(sizeInUnit * 100) / 100;

        return `${roundedSize} ${units[i]}`;
    }

    public onLanguageSelected(lang: Language): void {
        console.log("onLanguageSelected: ", lang);
        this.selectedLink.locale = lang?.locale;
    }

    public saveClicked(): void {
        this.save();
    }

    public async save(): Promise<void> {
        console.log("save");

        this.setBusy(true, "Saving...");

        try {
            const saveResp = await this.adminFacade.saveSeismicLink(this.selectedLink);
            console.log("saveSystemSettings saveResp:", saveResp);

            if (saveResp && saveResp.success === true) {

                this.alertService.showToast("Save successful");

                this.activeModal.close(MODAL_RESULTS.SAVED);
            }
        } catch (err) {
            console.error("Error saving link: ", err);
            const msg = `System Error saving link: ${ErrorUtils.getErrorMessage(err)}`;

            this.alertService.showError(msg);
        } finally {
            this.setBusy(false);
        }
    }

    private setBusy(isBusy: boolean, msg?: string): void {
        this.isLoading = isBusy;
        this.loadingMessage = msg;
    }

    public copyContentId(): void {
        this.clipboard.copy(this.selectedLink.contentId);
        console.log("Content id copied");
    }
}
