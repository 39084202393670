<div class="item-list" cdkDropList cdkDropListLockAxis="y" (cdkDropListDropped)="drop($event)" [cdkDropListData]="items">
  <div [ngClass]="'item-box ' + (item === selectedItem ? 'item-selected' : '')" *ngFor="let item of items" cdkDrag (click)="listItemClicked(item)">
	<div class="item-placeholder" *cdkDragPlaceholder></div>
	<ng-container [ngTemplateOutlet]="itemTemplate"
				  [ngTemplateOutletContext]="{item:item,selected:(item === selectedItem)}"></ng-container>
	<div class="item-handle" cdkDragHandle>
	  <pcc-icon icon="more-2"></pcc-icon>
	</div>
  </div>
</div>
