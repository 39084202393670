<ng-template #pchInfo>
  <div class="pch-info-msg" [innerHTML]="'enroll-pet-care-heroes.pet_care_heroes_info' | translate"></div>
</ng-template>

<section class="pch-fields">
  <div class="pch-enrolled">
    <label class="spot-form__checkbox" for="pch-enrolled">
      <input id="pch-enrolled" type="checkbox" class="spot-form__checkbox-input" name="pch-enrolled" [(ngModel)]='enrollInfo.petCareHeroesEnrolled' (change)="pchEnrolledChange()" [disabled]="!pchEnabled"/>
      <span class="spot-form__checkbox-inner">
        <span class="spot-form__checkbox-visual">
          <svg class="spot-icon spot-form__checkbox-checked-icon" aria-labelledby="title">
            <title>checkmark</title>
            <use xlink:href="/assets/spot-library/dist/symbols/spot_symbols.svg#checkmark"></use>
          </svg>
          <svg class="spot-icon spot-form__checkbox-indeterminate-icon" aria-labelledby="title">
            <title>checkbox-indeterminate</title>
            <use xlink:href="/assets/spot-library/dist/symbols/spot_symbols.svg#checkbox-indeterminate"></use>
          </svg>
        </span>
        <span class="spot-form__checkbox-label">
          {{ 'enroll-pet-care-heroes.pet_care_heroes_enroll' | translate }}
          <span [ngbTooltip]="pchInfo" tooltipClass="locked-enroll-popup" placement="auto" [closeDelay]="3000">
	        <svg
              class="spot-icon"
              aria-labelledby="title"
              >
              <use xlink:href="/assets/spot-library/dist/symbols/spot_symbols.svg#info-2"></use>
	        </svg>
          </span>
        </span>
      </span>
    </label>
  </div>
  <div class="pch-num-staff">
    <div class="spot-form__field-group" [hidden]="!enrollInfo.petCareHeroesEnrolled">
      <label class="spot-form__label" for="pch_staff_number">{{'enroll-pet-care-heroes.pet_care_heroes_staff_numbers' | translate}}</label>
      <input type="number" [min]="1" [(ngModel)]="enrollInfo.petCareHeroesNumStaff" class="spot-form__input" id="pch_staff_number" name="pch_staff_number" [required]="enrollInfo.petCareHeroesEnrolled" #pch_staff_number="ngModel" [readonly]="!pchEnabled"/>
      <div *ngIf="pch_staff_number.invalid" class="spot-form__field-error" role="alert">
        <div *ngIf="pch_staff_number.errors?.required" class="spot-form__field-error-text"> {{'enroll-pet-care-heroes.missing_pch_staff_number' | translate }} </div>
      </div>
    </div>
  </div>

</section>
<div *ngIf="pchDateMessageKey" class="pch-last-enrolled-message">{{ pchDateMessageKey | translate:pchMessageParam }} </div>

