import { Component, Input, Output, EventEmitter } from "@angular/core";

@Component({
    selector: "pcc-confirm-action",
    templateUrl: "./confirm-action.component.html",
    styleUrls: [
        "./confirm-action.component.scss"
    ]
})
export class ConfirmActionComponent {

    @Input() public confirmId = `confirmId-${new Date().getDate()}`;

    @Input() public buttonTitle = "confirm_action.confirm";

    @Input() public buttonText = "";

    @Input() public symbol = "undo";

    @Input() public confirmMsg = "confirm_action.are_you_sure";

    @Input() public okKey = "confirm_action.ok";

    @Input() public cancelKey = "confirm_action.cancel";

    @Input() public confirmPosition = "spot-popover--center-left";

    @Output() public closed = new EventEmitter<boolean>();

    @Output() public okClick = new EventEmitter<boolean>();

    @Output() public cancelClick = new EventEmitter<boolean>();

    @Input() public confirmButtonClass = "spot-button spot-button--with-icon spot-button--large";

    @Input() public confirmLinkClass = "spot-link spot-link--with-icon";

    @Input() public useLink = false;

    public popoverClosed(closed: boolean): void {
        this.closed.emit(closed);
    }

    public okClicked(): void {
        this.okClick.emit(true);
    }

    public cancelClicked(): void {
        this.cancelClick.emit(true);
    }
}
