import { Component, Input, OnInit, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { Router } from "@angular/router";

import { DomSanitizer, SafeHtml } from "@angular/platform-browser";

import { ICustomPage, CustomPageTypes } from "@shared/model/custom-page";
import { ISeismicLink } from "@shared/model/seismic";
import { FileInfo } from "@shared/model/file";
import { AppFacade } from "../../../facade/app.facade";
import { PCCAlertService } from "../../../service/alert.service";
import { AccountSettings } from "@shared/model/account-settings";
import googleAnalytics from "../../../analytics/googleAnalytics";

@Component({
    selector: "pcc-custom-page",
    templateUrl: "./custom-page.component.html",
    styleUrls: [
        "./custom-page.component.scss"
    ]
})
export class CustomPageComponent implements OnInit, OnDestroy {
    @Input() public customPage: ICustomPage;

    @Input() public customPages: ICustomPage[];

    private localeSub: Subscription;

    public images: FileInfo[];

    public safeTitle: SafeHtml;

    public safeSubTitle: SafeHtml;

    public links: ISeismicLink[];

    public landingLink: string;

    public locale: string;

    public constructor(
        private router: Router,
        public appFacade: AppFacade,
        private sanitizer: DomSanitizer,
        private alertService: PCCAlertService
    ) {
    }

    public async ngOnInit(): Promise<void> {
        const session = this.appFacade.getSession();
        const account = session.accountInfo;
        this.landingLink = `/account/${account.sap_id}/landing`;

        const state = window.history.state;
        this.customPages = state.customPages;

        this.locale = session.locale;

        await this.setCustomPage(state.customPage);

        this.localeSub = this.appFacade.getLocaleSubject().subscribe(
            (locale: string): void => {
                this.setLocale(locale);
            }
        );
    }

    public ngOnDestroy(): void {
        this.localeSub.unsubscribe();
    }

    private setLocale(locale: string): void {
        if (this.locale === locale) {
            return;
        }

        this.locale = locale;

        const session = this.appFacade.getSession();
        const accountSettings = session.accountSettings;

        // Get the index of the current page in the current list of custom pages for the old locale.
        const pageOrder = this.getPageOrder(this.customPage, this.customPages);

        this.customPages = AccountSettings.getCustomPages(accountSettings, CustomPageTypes.LAUNCH);

        // Try to re-select the equivalent page in the new locale just based on page order.
        // NOTE: This assumes they will have the same pages, just translated into a different language
        // rather than a different set of content.
        const newCustomPage = this.customPages[pageOrder] || this.customPages[0];

        // If this language doesn't have any custom pages, then just go to the profiles page
        if (!newCustomPage) {
            this.router.navigate([`/account/${session.accountInfo.sap_id}/profiles`]);
        } else {
            this.updateView();
        }
    }

    // Pull details from seismic api for thumbnail urls and initialize seismic oauth
    private async getCustomPageDetails(): Promise<void> {
        try {
            this.alertService.setBusy(true);

            const resp = await this.appFacade.getCustomPage(this.customPage.id);
            console.log("customPageDetails resp: ", resp);
            if (resp.success) {
                this.customPage = resp.data;
                this.updateView();
            } else {
                console.error("Error pulling custom page details: ", resp);
            }
        } catch (err) {
            console.error("Error pulling custom page details: ", err);
        } finally {
            this.alertService.setBusy(false);
        }
    }

    private updateView(): void {
        if (this.customPage) {
            this.safeTitle = this.sanitizer.bypassSecurityTrustHtml(
                this.appFacade.safeTranslate(this.customPage.titleKey) || ""
            );

            this.safeSubTitle = this.sanitizer.bypassSecurityTrustHtml(
                this.appFacade.safeTranslate(this.customPage.subTitleKey) || ""
            );

            this.images = this.customPage.images ? this.customPage.images.filter((file: FileInfo): boolean => file.locale === this.locale) : [];
            this.links = this.customPage.links ? this.customPage.links.filter((link: ISeismicLink): boolean => link.locale === this.locale) : [];
        }
    }

    public pageClicked(cp: ICustomPage): void {
        this.setCustomPage(cp);
    }

    private async setCustomPage(page: ICustomPage): Promise<void> {
        console.log("setCustomPage: ", page);
        this.customPage = page;
        googleAnalytics.registerEvent("custom_page_click", "click", {
            custom_page_id: page.id,
            title: this.appFacade.translate(page.titleKey),
            subtitle: this.appFacade.translate(page.subTitleKey),
            from_custom_page_footer: true
        });
        await this.getCustomPageDetails();
        this.updateView();
    }

    // Return the index of the currently selected page in the list of pages defined for this locale.
    private getPageOrder(targetPage: ICustomPage, pageList: ICustomPage[]): number {
        return pageList.findIndex((page: ICustomPage): boolean => targetPage.id === page.id);
    }
}
