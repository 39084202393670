<div class="spot-modal carousel-wrapper">
  <div class="spot-modal__header">
	<div class="spot-typography__heading--level-2">{{ title }}</div>
	<button type="button" class="pcc-cancel-modal-button" aria-label="Close" (click)="closeModal()">
      <svg class="spot-icon spot-modal__header-cancel-button-icon" aria-labelledby="title">
        <title>{{ 'sessionPrompt.close' | translate }}</title>
        <use xlink:href="/assets/spot-library/dist/symbols/spot_symbols.svg#cancel"></use>
      </svg>
	</button>
  </div>
  <div class="spot-modal__content">
	<pcc-carousel class="carousel" [images]="images" [currentIndex]="currentIndex" [canExpand]="canExpand"></pcc-carousel>
  </div>
</div>
