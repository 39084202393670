import { Component, Input, Output, EventEmitter, OnChanges, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

import { SafeResourceUrl } from "@angular/platform-browser";
import { ICustomPage } from "@shared/model/custom-page";

import { FileInfo, PARENT_TYPES } from "@shared/model/file";
import { IAccountSettings } from "@shared/model/account-settings";

import { nanoid } from "nanoid";

import { ManagedFileEditComponent } from "../managed-file-edit/managed-file-edit.component";

export interface ISafeFileInfo extends FileInfo {
    safeThumbnailUrl?: SafeResourceUrl;
}

@Component({
    selector: "pcc-manage-files",
    templateUrl: "./manage-files.component.html",
    styleUrls: [
        "./manage-files.component.scss"
    ]
})
export class ManageFilesComponent implements OnInit, OnChanges {

    @Input() public selectedPage?: ICustomPage;

    @Input() public selectedFile?: ISafeFileInfo;

    @Input() public accountSettings: IAccountSettings;

    @Output() public valid = new EventEmitter<boolean>();

    @Output() private fileSelected = new EventEmitter<FileInfo>();

    @Output() public fileChanged = new EventEmitter<ISafeFileInfo>();

    @Output() public fileDeleted = new EventEmitter<ISafeFileInfo>();

    @Output() public fileAdded = new EventEmitter<ISafeFileInfo>();

    @Output() public fileOrderChanged = new EventEmitter<ICustomPage>();

    public activeFiles: FileInfo[];

    public constructor(
        private modalService: NgbModal
    ) {}

    public ngOnInit(): void {
        this.updateView();
    }

    public ngOnChanges(): void {
        console.log("ngOnChanges");
        this.updateView();
    }

    private updateView(): void {
        if (!this.selectedPage) {
            console.warn("No selectedPage defined");
            return;
        }
        this.activeFiles = this.getActiveImages(this.selectedPage);
    }

    private getActiveImages(page: ICustomPage): FileInfo[] {
        if (!page || !page.images) {
            return [];
        }
        return page.images.filter((file: FileInfo): boolean => file.active);
    }

    public deleteFileConfirmed(oldFile: FileInfo): void {
        this.selectedFile = null;

        if (!oldFile.id) {
            // Remove unpersisted records from list rather than setting active flag.
            this.removeFile(oldFile);
        }

        this.fileDeleted.emit(oldFile);

        this.updateView();

    }

    public addFile(): void {
        console.log("addFile");

        const nextOrder = this.selectedPage.images?.length ?? 0;

        const newFile: FileInfo = {
            active: true,
            parentId: this.selectedPage.id,
            parentType: PARENT_TYPES.CUSTOM_PAGE,
            displayOrder: nextOrder,
            clientId: nanoid(10)
        };

        // Key could be sapId_accountSettingsId_developer_name_clientId

        this.selectedPage.images = this.selectedPage.images ?? [];
        this.selectedPage.images.push(newFile);
        this.selectedFile = newFile;

        this.renumberFiles(this.selectedPage.images);

        this.fileAdded.emit(newFile);

        this.updateView();
    }

    public fileWasDeleted(oldFile: FileInfo): void {
        console.log("fileDeleted: ", oldFile);

        this.selectedFile = null;

        this.fileDeleted.emit(oldFile);

        this.updateView();
    }

    public fileClicked(file: FileInfo): void {
        this.selectedFile = file;

        this.fileSelected.emit(file);

        this.editClicked(file);
    }

    public filesReordered(newFiles: FileInfo[]): void {
        this.renumberFiles(newFiles);
    }

    private async renumberFiles(files: FileInfo[]): Promise<void> {
        let order = 0;
        files.forEach((file: FileInfo): void => {
            file.displayOrder = order;
            order += 1;
        });
        this.fileOrderChanged.emit(this.selectedPage);
    }

    public fileHasChanged(file: FileInfo): void {
        console.log("fileChanged: ", file);
        this.fileChanged.emit(file);
    }

    private removeFile(oldFile: FileInfo): boolean {
        if (oldFile.id) {
            console.warn("Not removing persisted file.  Use active flag.", oldFile);
            return;
        }

        this.selectedPage.images = this.selectedPage.images.filter((file: FileInfo): boolean =>
            file === oldFile
        );
    }

    public editClicked(file: ISafeFileInfo): void {
        console.log("editClicked: ", file);

        const ref = this.modalService.open(ManagedFileEditComponent, {
            size: "xl",
            windowClass: "modal-centered",
            backdrop: true,
            keyboard: true
        });
        ref.componentInstance.selectedFile = file;
        ref.componentInstance.accountSettings = this.accountSettings;

        // Wait for dialog to close.  User can either Save, Delete, or Cancel
        ref.result.then((result): void => {
            console.log("Edit Managed File modal closed: ", result);
            this.updateView();
        }, (reason): void => {
            console.log("Edit Price modal dismissed", reason);
        });
    }
}
