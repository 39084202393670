import { Injectable } from "@angular/core";

import { Observable, BehaviorSubject, firstValueFrom } from "rxjs";

import { ICustomPage, CustomPageTypes } from "@shared/model/custom-page";
import { AccountSettings } from "@shared/model/account-settings";
import { ISystemSettingsResponse, ISystemSettings } from "@shared/model/system-settings";
import { IAdminSettingsResponse } from "@shared/model/service/admin-service";
import { IProductRequest, IProductResponse } from "@shared/model/service/product-service";
import { ISpecies } from "@shared/model/species";
import { ICategory } from "@shared/model/category";
import { IBuyingGroup } from "@shared/model/buying-group";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

import { IProduct } from "@shared/model/product";
import { IProfileItem } from "@shared/model/profile-item";
import { IProfileTemplate } from "@shared/model/profile-template";
import { IUser } from "@shared/model/user";

import { IDataResponse } from "@shared/model/data-response";
import { IAPIResponseData } from "@shared/model/service/service";

import { Router } from "@angular/router";
import { ApiService } from "./api.service";
import { PCCTranslateService } from "./translate.service";

import { envUtils } from "../globals/env";
import { PCCSession } from "@shared/model/pcc-session";
import { AppModeEnum } from "@shared/model/app-mode-enum";
import { IRecentSearchesResponse } from "@shared/model/service/recent-searches-service";
import { PCCAlertService } from "./alert.service";

import { AccountInfoComponent } from "../client/views/account-info/account-info.component";

const MAX_RECENT_SEARCHES = 5;

@Injectable()
export class AppService {

    private systemSettings: ISystemSettings;

    public appMode: AppModeEnum;

    public appModeSubject: BehaviorSubject<AppModeEnum>;

    public constructor(
        private router: Router,
        private apiService: ApiService,
        private modalService: NgbModal,
        public alertService: PCCAlertService,
        private translateService: PCCTranslateService
    ) {
        // If Training mode is enabled server-side, then client application is
        // locked into Training mode.
        if (envUtils.isTraining()) {
            this.appMode = AppModeEnum.TRAINING;
        } else {
            this.appMode = AppModeEnum.PROD;
        }
        this.appModeSubject = new BehaviorSubject(this.appMode);
        console.log("appMode=", this.appMode);

        // Preload
        this.getSystemSettings();
    }

    public getAppMode(): BehaviorSubject<AppModeEnum> {
        return this.appModeSubject;
    }

    public setAppMode(mode: AppModeEnum): void {
        console.log("setAppMode: ", mode);
        this.appMode = mode;
        this.appModeSubject.next(mode);
    }

    public isEventTrainingMode(): boolean {
        return envUtils.isTraining();
    }

    public isLocalTrainingMode(): boolean {
        return this.appMode === AppModeEnum.TRAINING;
    }

    public isTrainingMode(): boolean {
        return this.isLocalTrainingMode() || this.isEventTrainingMode();
    }

    public isSubmitEnabled(): boolean {
        let retVal = false;

        if (!envUtils.isProd()
            || !this.isTrainingMode()) {
            retVal = true;
        }

        console.log(`isSubmitEnabled: ${retVal}`);
        return retVal;
    }

    public getProfileItems(): Promise<IAdminSettingsResponse> {
        return this.apiService.getTests();
    }

    public getProfileItem(profileItemId: number): Promise<IAdminSettingsResponse> {
        return this.apiService.getTest(profileItemId);
    }

    public async deleteProfileItem(testId: number): Promise<IDataResponse> {
        return this.apiService.deleteProfileItem(testId);
    }

    public getProduct(productId: string): Promise<IDataResponse> {
        return this.apiService.getProduct(productId);
    }

    public getRecentSearches(): Observable<IRecentSearchesResponse> {
        return this.apiService.getRecentSearches(MAX_RECENT_SEARCHES);
    }

    public findProduct(searchRequest: IProductRequest): Promise<IProductResponse> {
        return this.apiService.findProduct(searchRequest);
    }

    public deleteProduct(productId: number): Promise<IDataResponse> {
        return this.apiService.deleteProduct(productId);
    }

    public saveCategory(ctg: ICategory): Promise<IDataResponse> {
        return this.apiService.saveCategory(ctg);
    }

    public saveBuyingGroup(bg: IBuyingGroup): Promise<IDataResponse> {
        return this.apiService.saveBuyingGroup(bg);
    }

    public saveProfileItem(test: IProfileItem): Promise<IDataResponse> {
        return this.apiService.saveTest(test);
    }

    public setProfileItemActive(profileItem: IProfileItem): Promise<IAPIResponseData<IProfileItem>> {
        return this.apiService.setProfileItemActive(profileItem);
    }

    public saveProduct(product: IProduct): Promise<IDataResponse> {
        return this.apiService.saveProduct(product);
    }

    public saveProfileTemplate(ctg: IProfileTemplate): Promise<IAdminSettingsResponse> {
        return this.apiService.saveProfileTemplate(ctg);
    }

    public deleteProfileTemplate(pt: IProfileTemplate): Promise<IAdminSettingsResponse> {
        return this.apiService.deleteProfileTemplate(pt);
    }

    public async getSystemSettingsCached(): Promise<ISystemSettings> {
        if (this.systemSettings) {
            return this.systemSettings;
        }

        return await this.getSystemSettings();
    }

    public async getSystemSettings(forAdmin?: boolean): Promise<ISystemSettings> {
        console.log("getSystemSettings", forAdmin);
        const resp = await firstValueFrom(
            this.apiService.getSystemSettings(forAdmin)
        );
        console.log("Fetched systemSettings", resp);

        if (!resp || !resp.success || resp.error) {
            throw resp.error || new Error("Unknown error calling getSystemSettings");
        }

        const systemSettings = resp ? resp.systemSettings : null;
        this.setSystemSettings(systemSettings);

        return systemSettings;
    }

    public setSystemSettings(sysSettings: ISystemSettings): void {
        console.log("setSystemSettings: ", sysSettings);
        this.systemSettings = sysSettings;
    }

    public mergeTests(lists: IProduct[][]): string[] {
        const results: string[] = [];

        if (!lists || lists.length === 0) {
            return results;
        }

        const newList: string[] = [];
        for (const tc of lists[0]) {
            const addString = tc.test_code;
            const r = this.mergeTests(lists.slice(1));
            if (r.length === 0) {
                newList.push(addString);
            } else {
                for (const str of r) {
                    newList.push(`${addString},${str}`);
                }
            }
        }

        return newList;
    }

    public static getSpeciesNames(speciesList: ISpecies[]): string[] {
        if (!speciesList) {
            return [];
        }

        return speciesList.map((species: ISpecies): string => species.display_name);
    }

    public getUsers(): Promise<IAdminSettingsResponse> {
        return this.apiService.getUsers();
    }

    public getRoles(): Promise<IAdminSettingsResponse> {
        return this.apiService.getRoles();
    }

    public saveUserRole(user: IUser): Promise<IAdminSettingsResponse> {
        return this.apiService.saveUserRole(user);
    }

    public async saveSystemSettings(sysSettings: ISystemSettings): Promise<ISystemSettingsResponse> {
        const resp = await this.apiService.saveSystemSettings(sysSettings);
        if (resp.success) {
            this.setSystemSettings(resp.systemSettings);
        }
        return resp;
    }

    // Called after an account search has been resolved to a new session.
    // Check for expired pricing - reset dates and clear pricing (has UI impact of profiles repricing)
    // Check for special_instructions (corp) and show as modal
    // Start loading test mix data.
    // Navigate to the "next" page.
    public async startEnrollmentSession(session: PCCSession, retrievePartial = false): Promise<void> {
        console.log("startEnrollmentSession: ", session, retrievePartial);

        this.translateService.locale = session.locale;

        if (session.accountSettings.special_instructions) {
            this.showCorpAccountInfo(session);
        }

        const showLandingPage = this.shouldShowLandingPage(session);
        console.log("showLandingPage=", showLandingPage);

        if (showLandingPage) {
            this.router.navigate([
                "account",
                session.enrollInfo.sap_id,
                "landing"
            ]);
        } else {
            this.router.navigate([
                "account",
                session.enrollInfo.sap_id,
                "profiles"
            ]);
        }
    }

    public showCorpAccountInfo(session: PCCSession): void {
        const modalRef = this.modalService.open(AccountInfoComponent, {
            windowClass: "corp-info modal-dialog-scrollable modal-dialog-centered"
        });
        modalRef.componentInstance.setSession(session);
    }

    public shouldShowLandingPage(session: PCCSession): boolean {

        const profiles = session.enrollInfo?.profiles;
        if (profiles && profiles.length > 0) {
            console.log("Session in progress.  Don't show landing page");
            return false;
        }

        const customPages: ICustomPage[] = AccountSettings.getCustomPages(session.accountSettings, CustomPageTypes.LAUNCH);

        return customPages.length > 0;
    }

}
