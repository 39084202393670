import { Component } from "@angular/core";

@Component({
    selector: "pcc-admin-layout",
    templateUrl: "./admin-layout.component.html",
    styleUrls: [
        "./admin-layout.component.scss"
    ]
})
export class AdminLayoutComponent {
}
