import { Component, OnInit, OnChanges, Input, Output, EventEmitter } from "@angular/core";

import { IProfileItem, ItemUtils } from "@shared/model/profile-item";
import { IViewCategory } from "../../../shared/model/view";
import { TemplateType, TEMPLATE_TYPES } from "@shared/model/profile-template";
import { ITestSelection } from "../../../client/model/test-selection";
import { PCCTranslateService } from "@client/service/translate.service";

@Component({
    selector: "pcc-slot-category",
    templateUrl: "./slot-category.component.html",
    styleUrls: [
        "./slot-category.component.scss"
    ]
})
export class SlotCategoryComponent implements OnInit, OnChanges {

    @Input() public testCategory: IViewCategory;

    @Input() public templateType: TemplateType;

    @Output() public testClick = new EventEmitter<IProfileItem>();

    @Output() public defaultChange = new EventEmitter<IProfileItem>();

    public profileItems: IProfileItem[];

    // Note: used only for Static template type where no alternatives are allowed in the template - only a test code and maybe an add-on.
    public staticTestSelection: ITestSelection = {
    };

    public isStaticTemplate = false;

    public constructor(
        private translateService: PCCTranslateService
    ) {}


    public ngOnInit(): void {
        this.resortProfileItems();

        this.isStaticTemplate = (this.templateType === TEMPLATE_TYPES.STATIC);
    }

    public ngOnChanges(): void {
        this.isStaticTemplate = (this.templateType === TEMPLATE_TYPES.STATIC);
    }

    /**
     * One of the many profile items for this category has been clicked.
     In static mode, at most two elements (a test code and an add-on) can be active at one time.
     So when in static mode, when a test is clicked, and is active, then de-select any other test codes of the same type.
     */
    public testClicked(test: IProfileItem, isSelected: boolean): void {
        console.log("testClicked: ", JSON.stringify(test), isSelected);

        const selected: string[] = [];

        if (this.templateType === TEMPLATE_TYPES.STATIC) {
            this.handleStaticTestSelected(test, isSelected);
        }

        for (const t of this.testCategory.profileItems) {
            if (t.isSelected) {
                selected.push(this.translateService.instant(t.displayNameKey));
            }
        }
        if (selected.length > 0) {
            this.testCategory.isSelected = true;
        } else if (!this.testCategory.is_required) {
            this.testCategory.isSelected = false;
        }

        this.testClick.emit(test);
    }

    // Static templates only allow one test code per category (and one add-on), so enforce this by
    // deselecting other items in the same category.  Also for static templates, all codes are required,
    // no option to deselect on the client side, so set that here as well.
    private handleStaticTestSelected(test: IProfileItem, isSelected: boolean): void {
        if (!isSelected) {
            if (ItemUtils.isAddOn(test)) {
                delete this.staticTestSelection.addOn;
            } else {
                delete this.staticTestSelection.test
            }

            // De-select is_required for category if nothing is selected.
            this.testCategory.is_required = !(!this.staticTestSelection.test && !this.staticTestSelection.addOn);

            return;
        }

        if (ItemUtils.isAddOn(test)) {
            this.staticTestSelection.addOn = test;
        } else {
            this.staticTestSelection.test = test;
        }

        // Category is required if anything is selected (for STATIC templates)
        this.testCategory.is_required = true;

        test.is_default = true;

        // Go through and deselect all others.
        this.deselectStaticSelected();
    }

    // Go through and deselect all others.
    private deselectStaticSelected(): void {
        // Go through and deselect all others.
        this.testCategory.profileItems.forEach((profileItem: IProfileItem): void => {
            if (!this.isStaticSelected(profileItem)) {
                profileItem.isSelected = false;
                profileItem.is_default = false;
            }
        });
    }

    // Returns true if template type is static and supplied test is either the selected test or add-on.
    private isStaticSelected(profileItem: IProfileItem): boolean {
        return (this.testMatches(this.staticTestSelection.test, profileItem)
            || this.testMatches(this.staticTestSelection.addOn, profileItem));
    }

    private testMatches(test1?: IProfileItem, test2?: IProfileItem): boolean {
        if ((test1 === null || test1 === undefined) && (test2 === null || test2 === undefined)) {
            return true;
        }

        if (test1 && test2) {
            return test1.profile_item_id === test2.profile_item_id;
        }

        return false;

    }

    public defaultClicked(test: IProfileItem): void {
        if (test.is_default === true) {
            for (const profileItem of this.testCategory.profileItems) {
                if (profileItem !== test && profileItem.is_default === true) {
                    profileItem.is_default = false;
                }
            }
        }

        this.defaultChange.emit(test);
    }

    /*
      After re-ordering, re-number so numbers are consistent.
     */

    public moveLeft(pi: IProfileItem): void {
        const ix = this.testCategory.profileItems.findIndex((c: IProfileItem): boolean =>
            c.profile_item_id === pi.profile_item_id
        );
        let otherPi: IProfileItem;
        if (ix > 0) {
            otherPi = this.testCategory.profileItems[ix - 1];
            otherPi.display_order++;
            if (pi.display_order > 0) {
                pi.display_order--;
            }
        }
        this.resortProfileItems();
    }

    public moveRight(pi: IProfileItem): void {
        const ix = this.testCategory.profileItems.findIndex((c: IProfileItem): boolean =>
            c.profile_item_id === pi.profile_item_id
        );
        let otherPi: IProfileItem;
        if (ix !== -1) {
            if (this.testCategory.profileItems[ix + 1] && this.testCategory.profileItems[ix + 1].display_order > 0) {
                otherPi = this.testCategory.profileItems[ix + 1];
                otherPi.display_order--;
            }
            pi.display_order++;
        }
        this.resortProfileItems();
    }

    /**
     * Clean up display order so it stays consistent.
     * Instead of 0, 0, 1, 5, 7..., it should be 0, 1, 2, 3, 4...
     */
    private resortProfileItems(): void {
        if (!this.testCategory || !this.testCategory.profileItems) {
            console.warn("No profileItems: ", this.testCategory);
            return;
        }

        // First sort the array by display_order;
        this.profileItems = this.testCategory.profileItems.sort((a: IProfileItem, b: IProfileItem): number =>
            a.display_order - b.display_order
        );

        // Next re-number them in sequential order.
        for (let ix = 0; ix < this.testCategory.profileItems.length; ix++) {
            this.testCategory.profileItems[ix].display_order = ix;
        }
    }

}
