import { Component, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

import { ErrorUtils } from "@shared/model/error/error-utils";
import { AdminFacade } from "../../../facade/admin.facade";

import { PCCAlertService } from "../../../service/alert.service";
import { ISystemSettings, ISystemSettingsResponse } from "@shared/model/system-settings";

import { envUtils } from "../../../globals/env";

export const MODAL_RESULTS = {
    SAVED: "saved",
    CANCELLED: "cancelled"
}

@Component({
    selector: "pcc-training-mode-config",
    templateUrl: "./training-mode-config.component.html",
    styleUrls: [
        "./training-mode-config.component.scss"
    ]
})
export class TrainingModeConfigComponent implements OnInit {

    public systemSettings: ISystemSettings;

    public allowTraining = true;

    public trainingSapIds: string;

    public isLoading = false;

    public loadingMessage = "Loading...";

    public constructor(
        private adminFacade: AdminFacade,
        private alertService: PCCAlertService,
        public activeModal: NgbActiveModal
    ) {
    }

    public async ngOnInit(): Promise<void> {
        try {
            if (envUtils.isProd()) {
                this.allowTraining = false;
            }
            this.setBusy(true, "Loading system settings...");

            this.setSystemSettings(await this.adminFacade.getSystemSettingsCached());
        } catch (err) {
            console.error("Error retrieving system settings: ", err);
        } finally {
            this.setBusy(false);
        }
    }

    private setSystemSettings(systemSettings: ISystemSettings): void {
        this.systemSettings = systemSettings;

        if (systemSettings) {
            this.setTrainingSapIds(this.systemSettings.trainingSapIdList || []);
        }
    }

    private setTrainingSapIds(sapList: string[]): void {
        this.trainingSapIds = sapList.join(", ");
        this.updateTrainingSapIds();
    }

    public updateTrainingSapIds(): void {
        console.log("updateTrainingSapIds");
        const value = this.trainingSapIds || "";
        const list = value.split(",");
        this.systemSettings.trainingSapIdList = list
            .map((id: string): string => id.trim())
            .filter((id: string): boolean => id.length > 0)
            .filter((id: string, index: number, self: string[]): boolean => self.indexOf(id) === index);
    }

    public reformatSapList(): void {
        console.log("reformatSapList");
        this.setTrainingSapIds(this.systemSettings.trainingSapIdList);
    }

    public saveTrainingEnabled(): void {
        console.log("saveTrainingEnabled: ", this.systemSettings.trainingEnabled);
        this.save();
    }

    public async saveClicked(): Promise<void> {
        const saveResp = await this.save();
        if (saveResp?.success) {
            this.activeModal.close(MODAL_RESULTS.SAVED);
        }
    }

    public async save(): Promise<ISystemSettingsResponse> {
        console.log("save");

        this.setBusy(true, "Saving...");

        try {
            const saveResp: ISystemSettingsResponse = await this.adminFacade.saveSystemSettings(this.systemSettings);
            console.log("saveSystemSettings saveResp:", saveResp);

            if (saveResp && saveResp.success === true) {

                this.alertService.showToast("Save successful");

                this.systemSettings = saveResp.systemSettings;

                envUtils.update(this.systemSettings);
            }

            return saveResp;
        } catch (err) {
            console.error("Error saving system settings: ", err);
            const msg = `System Error saving system settings: ${ErrorUtils.getErrorMessage(err)}`;

            this.alertService.showError(msg);

            return {
                success: false, error: err
            };
        } finally {
            this.setBusy(false);
        }
    }

    public cancel(): void {
        console.log("cancel");
        this.setTrainingSapIds(this.systemSettings.trainingSapIdList || []);
        this.activeModal.close(MODAL_RESULTS.CANCELLED);
    }

    private setBusy(isBusy: boolean, msg?: string): void {
        this.isLoading = isBusy;
        this.loadingMessage = msg;
    }
}
