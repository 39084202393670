import { Component, OnInit, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { AppFacade } from "../../../facade/app.facade";
import { PCCTranslateService } from "../../../service/translate.service";
import { Language } from "@shared/model/language";

import { PCCSession } from "@shared/model/pcc-session";
import googleAnalytics from "../../../analytics/googleAnalytics";

@Component({
    selector: "pcc-language-toggle",
    templateUrl: "./language-toggle.component.html",
    styleUrls: [
        "./language-toggle.component.scss"
    ]
})
export class LanguageToggleComponent implements OnInit, OnDestroy {
    public locale: string;

    private localeSub: Subscription;

    public selectedLanguage: Language;

    public languages: Language[] = [];

    private sessionSub: Subscription;

    public constructor(
        private appFacade: AppFacade,
        private translateService: PCCTranslateService
    ) {
    }

    public async ngOnInit(): Promise<void> {

        this.resetLanguages();

        this.sessionSub = this.appFacade.getCurrentSession().subscribe(
            (session): void => {
                this.setSession(session);
            }
        );

        this.localeSub = this.translateService.localeSubject.subscribe((locale: string): void => {
            this.locale = locale;
        });
    }

    private setSession(session: PCCSession): void {
        if (session.accountSettings) {
            this.languages = session.accountSettings.languages;
            this.selectedLanguage = this.translateService.getLanguageInfo(this.locale);
        } else {
            // Not in account context.  Maybe Search screen or admin pages
            this.resetLanguages();
        }
    }

    private resetLanguages() {
        this.languages = this.translateService.getSupportedLanguages();
        this.languages = this.filterDistinctLanguages(this.languages);

        this.selectedLanguage = this.translateService.getCurrentLanguage();
    }

    public ngOnDestroy(): void {
        this.localeSub.unsubscribe();
        this.sessionSub.unsubscribe();
    }

    public setLang(lang: Language): void {
        console.log("setLang: ", lang);
        googleAnalytics.registerEvent("language_change", "change", {
            from_language: this.selectedLanguage.displayName,
            to_language: lang.displayName
        });
        this.selectedLanguage = lang;
        this.locale = lang.locale;
        this.appFacade.setLocale(this.locale);
    }

    private filterDistinctLanguages(languages: Language[]): Language[] {
        console.log("filterDistinctLanguages: ", languages);
        return languages.reduce((acc: Language[], language: Language) => {
            const existingObj = acc.find((langInfo: Language) => langInfo.langCode === language.langCode);

            if (!existingObj) {
                acc.push(language);
            }

            return acc;
        }, []);
    }
}
