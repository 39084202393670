import { Injectable } from "@angular/core";
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";

import { AdminFacade } from "../../facade/admin.facade";

@Injectable({
    providedIn: "root"
})
export class AccountGuard implements CanActivate {
    public constructor(
        public router: Router,
        private adminFacade: AdminFacade) {
    }

    public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const session = this.adminFacade.getSession();
        if (!session || !session.accountInfo) {
            this.router.navigate([
                "/admin/config"
            ]);
            return false;
        }
        return true;
    }
}
