import { Component, Input, Output, EventEmitter, OnInit, OnChanges, SimpleChanges } from "@angular/core";
import { SpeciesEnum } from "@shared/model/species";
import { IProfile } from "@shared/model/profile";
import { TEMPLATE_TYPES } from "@shared/model/profile-template";
import googleAnalytics from "../../../analytics/googleAnalytics";

@Component({
    selector: "pcc-profiles-tab-panel",
    templateUrl: "./profiles-tab-panel.component.html",
    styleUrls: [
        "./profiles-tab-panel.component.scss"
    ]
})
export class ProfilesTabPanelComponent implements OnInit, OnChanges {
    @Input() public profiles: IProfile[] = [];

    @Input() public flexProfiles: IProfile[] = [];

    @Input() public canSelect = true;

    @Input() public petOwnerPricePage = false;

    // If canEdit = false, then profiles are read-only
    // If canEdit = true, then profiles can be modified
    @Input() public canEdit = true;

    @Input() public canEditPracticePrice = true;

    @Input() public canEditPetOwnerPrice = true;

    @Input() public useTabs = true;

    @Input() public selectedProperty = "selected";

    @Output() public editPriceClick = new EventEmitter<IProfile>();

    @Output() public slotClick = new EventEmitter<IProfile>();

    @Output() public resetClick = new EventEmitter<IProfile>();

    @Output() public deleteClick = new EventEmitter<IProfile>();

    @Output() public selected = new EventEmitter<IProfile>();

    // Contains join of PRECONFIG templates with configured Profiles currently in the session,
    // grouped by species.
    public profileCards: Record<string, IProfile[]> = {
    };

    // Contains any FLEX templates to be shown at bottom of the screen.  "Build new X profile" link style
    public flexCards: Record<string, IProfile[]> = {
    };

    public speciesNames: string[] = [
        SpeciesEnum.CANINE.value,
        SpeciesEnum.FELINE.value
    ];

    public selectedTab: string = this.speciesNames[0];

    public speciesIcons = {
        "CANINE": "animal-canine",
        "FELINE": "animal-feline"
    };

    public ngOnInit(): void {
        console.log("this.profiles=", this.profiles);
        console.log("this.flexProfiles=", this.flexProfiles);

        this.updateLayout();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.profiles || changes.flexProfiles) {
            this.updateLayout();
        }
    }

    /**
     * Maintaining four lists of profiles/templates, 2 per species:
     * - A join of "PRECONFIG" templates defined in account settings admin with populated profiles
     *   defined in the enrollment.
     *   This will allow keeping the view static as profiles are built, keep things in the proper order,
     *   and only allow the user to create one profile per defined template.
     *
     * - A list of profiles for every "FLEX" template configured in account settings.
     *   Each FLEX template will result in one extra "new" profile added at the end to allow user to
     *   add more instances of the same FLEX template.
     */
    private updateLayout(): void {
        this.speciesNames.forEach((species: string): void => {
            const { profileCards, flexCards } = this.groupProfiles(species);
            this.profileCards[species] = profileCards;
            this.flexCards[species] = flexCards;
        });

        // Only show tabs when more than one species is populated.
        // Note that we are _not_ filtering by selected profiles here.
        // It only matters that there is something present.
        this.useTabs = this.speciesNames
            .filter((speciesName: string): boolean => this.hasCards(speciesName))
            .length > 1;
    }

    /*
     * Given a species, a list of templates, and a list of built profiles, returns two lists:
     * profileCards - join of PRECONFIG templates with configured Profiles currently in the session
     * flexCards - any FLEX templates to be shown at bottom of the screen.  "Build new X profile" link style
     */
    private groupProfiles(species: string): { profileCards: IProfile[], flexCards: IProfile[] } {
        let profileCards: IProfile[] = [];
        let flexCards: IProfile[] = [];

        if (this.profiles) {
            profileCards = this.profiles.filter((profile: IProfile): boolean =>
                profile.species?.developer_name === species
            );
        }

        if (this.flexProfiles) {
            flexCards = this.flexProfiles.filter((profile: IProfile): boolean =>
                profile.species?.developer_name === species
            );
        }

        return { profileCards, flexCards };
    }

    public async cardClicked(profile: IProfile): Promise<void> {
        this.slotClick.emit(profile);
    }

    public async resetClicked(profile: IProfile): Promise<void> {
        this.resetClick.emit(profile);
    }

    public async profileSelected(profile: IProfile): Promise<void> {
        this.selected.emit(profile);
    }

    public deleteClicked(profile: IProfile): void {
        this.deleteClick.emit(profile);
    }

    public editPriceClicked(profile: IProfile): void {
        this.editPriceClick.emit(profile);
    }

    public canReset(profile: IProfile): boolean {
        return this.canEdit === true && profile.templateType === TEMPLATE_TYPES.PRECONFIG;
    }

    public canDelete(profile: IProfile): boolean {
        return this.canEdit === true && profile.templateType === TEMPLATE_TYPES.FLEX;
    }

    public selectTab(species: string): void {
        console.log("selectTab: ", species);
        googleAnalytics.registerEvent("species_toggle", "click", { species });
        this.selectedTab = species;
    }

    public hasCards(species: string): boolean {
        return (this.profileCards[species] && this.profileCards[species].length > 0)
            || (this.hasFlexCards(species));
    }

    public hasFlexCards(species: string): boolean {
        return this.flexCards[species] && this.flexCards[species].length > 0;
    }
}
