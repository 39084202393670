<pcc-loading [isBusy]="isLoading" [busyMsg]="loadingMessage"></pcc-loading>

<div class="page-body">

  <div class="page-header">
    <span class="pcc-level-3-10">Profile Items Management</span>
  </div>

  <div class="profile-items-content">
    <as-split direction="horizontal" unit="pixel" #split="asSplit" style="flex: 1;">
      <as-split-area [size]="410" #area1="asSplitArea">
        <div class="spot-container--light-primary left-bar">
          <div class="controls">
            <form class="spot-form spot-search-bar" method="post" style="margin:5px;" title="Search by Category, Profile Item Name, or Product name">
              <input class="spot-form__input spot-search-bar__input" type="text" name="searchText" [(ngModel)]="searchText" (input)="searchProfileItems($event)">
              <button class="spot-search-bar__search-button" type="submit" (click)="searchProfileItems($event)">
                <svg class="spot-icon" aria-labelledby="title">
                  <title>search</title>
                  <use xlink:href="/assets/spot-library/dist/icons/spot_icons.svg#search"></use>
                </svg>
              </button>
            </form>
          </div>

          <div class="action-header">
            <span class="spot-typography__heading--level-5">Select Profile Item</span>
            <a class="spot-link add-item" (click)="addTest();">
              <svg class="spot-icon spot-link__icon spot-link__icon--left" aria-labelledby="title">
                <title>Add</title>
                <use xlink:href="/assets/spot-library/dist/symbols/spot_symbols.svg#add"></use>
              </svg>
              Add a profile item
            </a>
          </div>

          <ul class="spot-list-group item-list">
            <ng-container *ngFor="let categoryGroup of groupedProfileItems">
              <li class="spot-list-group__item spot-list-group__item--header profile-item-category">
                <span class="spot-list-group__header">
                  {{categoryGroup.category}}
                </span>
              </li>
              <li class="spot-list-group__item" [ngClass]="isSelected(tc) ? 'spot-list-group__item--active' : ''"  *ngFor="let tc of categoryGroup.items" >
                <a class="spot-list-group__link" (click)="existingTestClicked(tc)">
                  <span class="spot-list-group__item-label profile-item-name">
                    {{ tc.displayNameKey | translate }}
                  </span>
                </a>
              </li>
            </ng-container>
          </ul>

        </div>

      </as-split-area>

      <as-split-area #area3="asSplitArea" style="overflow:hidden">
        <pcc-profile-item-edit *ngIf="selectedTest" [selectedTest]="selectedTest" [systemSettings]="systemSettings" [accountSettings]="accountSettings" (itemCanceled)="cancel()" (itemDeleted)="profileItemDeleted($event)" (itemSaved)="profileItemSaved($event)"></pcc-profile-item-edit>
      </as-split-area>
    </as-split>
  </div>
</div>
