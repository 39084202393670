<div class="pcc-edit-panel">
  <div class="pcc-edit-panel__header">
    <span class="spot-typography__heading--level-3">Page: Done</span>
  </div>
  <div class="pcc-edit-panel__content" style="padding: 1em;">
    <form class="spot-form" id="pageForm" #pageForm="ngForm" name="pageForm">

      <div class="spot-form__field-group" style="display:flex;flex-direction:column;">
        <input type="checkbox" class="spot-toggle" id="show_enrollment_end_date" name="show_enrollment_end_date" [(ngModel)]="accountSettings.flags.show_enrollment_end_date" (change)="flagToggled($event)">
        <label class="spot-toggle-label" for="show_enrollment_end_date">Show Enrollment End Date</label>
      </div>

    </form>
  </div>

  <div class="pcc-edit-panel__footer button-panel">
    <button type="cancel" class="pcc-edit-panel__footer-cancel-button" (click)="cancel()">Cancel</button>
    <button type="submit" class="spot-button spot-button--primary" [disabled]="!saveEnabled && !saving" (click)="onSubmit()">Save</button>
  </div>
</div>
