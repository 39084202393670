import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { firstValueFrom } from "rxjs";

import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { IPdfFile } from "@shared/model/pdf";
import { ICustomPage } from "@shared/model/custom-page";
import { SeismicUtilsService } from "@shared/service/seismic-utils.service";
import { IAPIResponseData } from "@shared/model/service/service";
import { ISeismicLink, SeismicFileProperties, SeismicDocument } from "@shared/model/seismic";
import { DocWrapperComponent } from "../shared/components/doc-wrapper/doc-wrapper.component";
import { PCCAlertService } from "./alert.service";
import { PCCTranslateService } from "../service/translate.service";
import { AppService } from "../service/app.service";
import { IPCCError, ErrorUtils } from "@shared/model/error";
import { PCCClientError } from "frontend/app/shared/model/pcc-client-error";

import googleAnalytics from "../analytics/googleAnalytics";

@Injectable({
    providedIn: "root"
})
export class DocService {

    public constructor(
        private http: HttpClient,
        private alertService: PCCAlertService,
        private translateService: PCCTranslateService,
        private appService: AppService,
        private modalService: NgbModal
    ) {}

    public async openSeismicDoc(link: ISeismicLink): Promise<NgbModalRef> {
        console.log("openSeismicDoc: ", link);

        if (!link || !link.contentId) {
            throw new PCCClientError("SEISMIC.DOWNLOAD_ERROR", "No content id specified for seismic doc");
        }

        this.googleAnalyticSeismicLink(link);

        if (!SeismicUtilsService.canHandleFormat(link.format)) {
            console.warn("Opening file outside app!", link);
            this.openNonPDFSeismicFile(link);
            return null;
        }

        return await this.openModal(link);
    }

    private async openModal(link: ISeismicLink): Promise<NgbModalRef> {
        try {
            this.alertService.setBusy(true, this.translateService.instant("doc.opening_file"));
            const fileInfo = await this.fetchFile(link);

            if (!fileInfo) {
                throw new PCCClientError("SEISMIC.DOWNLOAD_FAILED", "Failed to open seismic doc");
            }

            // Open pdf document in viewer
            const modalRef = this.modalService.open(DocWrapperComponent, {
                size: "xl",
                centered: false,
                backdrop: "static",
                keyboard: true,
                modalDialogClass: "doc-wrapper-modal"
            });
            modalRef.componentInstance.showSeismicDoc(link, fileInfo);

            return modalRef;
        } catch (err) {
            console.error("Error: ", err);
            throw new PCCClientError("SEISMIC.DOWNLOAD_ERROR", "Error downloading seismic doc", err);
        } finally {
            this.alertService.setBusy(false);
        }
    }

    private googleAnalyticSeismicLink(seismicLink: ISeismicLink): void {
        googleAnalytics.registerEvent("seismic_link_clicked", "click", {
            seismic_doc_desc: seismicLink.desc,
            seismic_id: seismicLink.contentId
        });
    }

    /**
     * Note that fetchFile doesn't actually download the file from Seismic to the front-end.
     * It instead downloads the specified file from Seismic into an S3 bucket for optimized access.
     * Rather than hit Seismic for every single call, we're caching it in S3 for one hour.
     * Seismic documents are a limited set based on account settings/country/language, so many users
     * hitting many different accounts are accessing the same document.
     * And since these files can be very large (think 250Mb for possible video files), we can't have
     * dozens of downloaded files taking up memory on the server in order to download something.
     * So instead we're returning a signed url pointing to the document on S3 instead and letting the
     * browser do what it does best.
     */
    public async fetchFile(doc: ISeismicLink): Promise<SeismicDocument> {
        console.log("fetchFile: ", doc);
        try {
            const contentId = doc.contentId;

            const url = `/api/seismic/download/${contentId}`;
            const resp = await firstValueFrom(this.http.get<IAPIResponseData<SeismicDocument>>(url));
            if (resp.success && !resp.error) {
                return resp.data;
            }
            console.error("Error calling downloadSeismicDocument: ", contentId, resp);

            throw new PCCClientError("SEISMIC.DOWNLOAD_FILE_ERROR", ErrorUtils.getErrorMessage(resp.error));

        } catch (err) {
            throw new PCCClientError("SEISMIC.DOWNLOAD_PDF_DATA_MISSING", "Download pdf threw error", err);
        }
    }

    public async getFileProperties(contentId: string): Promise<IAPIResponseData<SeismicFileProperties>> {
        const url = `/api/seismic/file-properties/${contentId}`;
        return await firstValueFrom(this.http.get<IAPIResponseData<SeismicFileProperties>>(url));
    }

    public async refreshFile(contentId: string): Promise<IAPIResponseData<SeismicDocument>> {
        const url = `/api/seismic/file-refresh/${contentId}`;
        return await firstValueFrom(this.http.get<IAPIResponseData<SeismicDocument>>(url));
    }

    public async getCustomPage(customPageId: number, forAdmin = false): Promise<IAPIResponseData<ICustomPage>> {
        try {
            const resp = await firstValueFrom(this.http.get<IAPIResponseData<ICustomPage>>(`/api/custom-page/${customPageId}` + (forAdmin === true ? "?admin=true" : "")));

            if (!resp.success) {
                return resp;
            }

            const page = resp.data;
            if (page?.errors) {
                this.logPageErrors(page);
            }

            return resp;

        } catch (err) {
            console.error("Error downloading file: ", err);
            return {
                success: false,
                error: err
            };
        }
    }

    private logPageErrors(page: ICustomPage): void {
        if (!page.errors || page.errors.length === 0) {
            return;
        }
        page.errors.forEach((error: IPCCError): void => {
            console.error("Error pulling custom page: ", ErrorUtils.getErrorMessage(error));
        });
    }

    private async openNonPDFSeismicFile(link: ISeismicLink): Promise<void> {
        window.open(link.docCenterUrl ?? link.url, "_blank");
    }

    public async showPdf(enrollmentId: number, isPreview = false, locale: string = null): Promise<NgbModalRef> {
        try {
            this.alertService.setBusy(true, this.translateService.instant("pdf.downloading_pdf"));

            const pdfResp = await this.getPDF(enrollmentId, isPreview, locale);

            if (pdfResp.success && pdfResp.data?.url) {
                return await this.openPdf(pdfResp.data);
            }

            console.error("Error retrieving pdf: ", pdfResp.error);
            this.alertService.showError(this.translateService.instant("pdf.error_downloading_pdf"),
                ErrorUtils.getErrorMessage(pdfResp.error));
            return null;
        } finally {
            this.alertService.setBusy(false);
        }
    }

    public async openPdf(file: IPdfFile): Promise<NgbModalRef> {
        console.log("openPdf: ", file);

        // Open pdf document in viewer
        const modalRef = this.modalService.open(DocWrapperComponent, {
            size: "xl",
            centered: false,
            backdrop: "static",
            keyboard: true,
            modalDialogClass: "doc-wrapper-modal"
        });
        modalRef.componentInstance.showPdf(file);

        return modalRef;
    }

    public async getPDF(enrollmentId: number, isPreview = false, locale: string = null): Promise<IAPIResponseData<IPdfFile>> {
        return await firstValueFrom(
            this.http.get(
                `/api/pdf`,
                {
                    params: {
                        id: enrollmentId,
                        trainingMode: this.appService.isTrainingMode(),
                        locale,
                        isPreviewMode: isPreview
                    }
                }
            )
        );
    }

    public async saveSeismicLink(link: ISeismicLink): Promise<IAPIResponseData<ISeismicLink>> {
        return await firstValueFrom(
            this.http.post<IAPIResponseData<ISeismicLink>>("/api/admin/seismic/link", link)
        );
    }
}
