import { Injectable, Injector } from "@angular/core";

import { Observable, BehaviorSubject } from "rxjs";

import { NgbModalRef } from "@ng-bootstrap/ng-bootstrap";

import { AppService } from "../service/app.service";
import { AuthService } from "../auth/auth.service";
import { AccountService } from "../service/account.service";
import { PCCPriceService, SamePriceEnum } from "../service/price.service";
import { PCCSessionService } from "../service/pcc-session.service";
import { PCCTranslateService } from "../service/translate.service";
import { ProfileService } from "../service/profile.service";
import { DocService } from "../service/doc.service";
import { TestMixService } from "../service/testmix.service";

import { SessionModeEnum } from "@shared/model/session-mode-enum";
import { IAccountSettings } from "@shared/model/account-settings";
import { IAccount } from "@shared/model/account";
import { IEnrollment, EnrollmentStatus } from "@shared/model/enrollment";
import { IUser } from "@shared/model/user";
import { IAccountResponse } from "@shared/model/account-response";
import { IEnrollResponse, ICleanSessionsRequest } from "@shared/model/service/enroll-service";
import { IRecentSearchesResponse } from "@shared/model/service/recent-searches-service";
import { IProfileResponse } from "@shared/model/service/profile-service";
import { IProfileMatchRequest, IProfileMatchResponse } from "@shared/model/service/profile-match-service";
import { ISystemSettings } from "@shared/model/system-settings";
import { PCCSession } from "@shared/model/pcc-session";
import { AppModeEnum } from "@shared/model/app-mode-enum";
import { ITestMixServiceResponse } from "@shared/model/service/test-mix-service";
import { IProfile } from "@shared/model/profile";
import { ICustomPage } from "@shared/model/custom-page";
import { IAPIResponseData } from "@shared/model/service/service";
import { ISeismicLink } from "@shared/model/seismic";

@Injectable()
export class AppFacade {

    private pAppService: AppService;

    private pAuthService: AuthService;

    private pAccountService: AccountService;

    private pSessionService: PCCSessionService;

    private pTranslateService: PCCTranslateService;

    private pPriceService: PCCPriceService;

    private pProfileService: ProfileService;

    private pDocService: DocService;

    private pTestMixService: TestMixService;

    public constructor(
        private injector: Injector
    ) {
    }

    public get accountService(): AccountService {
        if (!this.pAccountService) {
            this.pAccountService = this.injector.get(AccountService);
        }
        return this.pAccountService;
    }

    public get appService(): AppService {
        if (!this.pAppService) {
            this.pAppService = this.injector.get(AppService);
        }
        return this.pAppService;
    }

    public get docService(): DocService {
        if (!this.pDocService) {
            this.pDocService = this.injector.get(DocService);
        }
        return this.pDocService;
    }

    public get testMixService(): TestMixService {
        if (!this.pTestMixService) {
            this.pTestMixService = this.injector.get(TestMixService);
        }
        return this.pTestMixService;
    }

    public get authService(): AuthService {
        if (!this.pAuthService) {
            this.pAuthService = this.injector.get(AuthService);
        }
        return this.pAuthService;
    }

    public get sessionService(): PCCSessionService {
        if (!this.pSessionService) {
            this.pSessionService = this.injector.get(PCCSessionService);
        }
        return this.pSessionService;
    }

    public get translateService(): PCCTranslateService {
        if (!this.pTranslateService) {
            this.pTranslateService = this.injector.get(PCCTranslateService);
        }
        return this.pTranslateService;
    }

    public get profileService(): ProfileService {
        if (!this.pProfileService) {
            this.pProfileService = this.injector.get(ProfileService);
        }
        return this.pProfileService;
    }

    public get priceService(): PCCPriceService {
        if (!this.pPriceService) {
            this.pPriceService = this.injector.get(PCCPriceService);
        }
        return this.pPriceService;
    }

    public getTestMix(accountInfo: IAccount): Observable<ITestMixServiceResponse> {
        return this.testMixService.getTestMix(accountInfo);
    }

    public async saveProfile(profile: IProfile): Promise<IProfileResponse> {
        return this.sessionService.saveProfile(profile);
    }

    public async saveEnrollment(enrollment: IEnrollment): Promise<IEnrollResponse> {
        return this.sessionService.saveEnrollment(enrollment);
    }

    public async deleteProfile(profile: IProfile, enrollInfo: IEnrollment, isReset: boolean): Promise<boolean> {
        return this.sessionService.deleteProfile(profile, enrollInfo, isReset);
    }

    public async getSystemSettingsCached(): Promise<ISystemSettings> {
        return this.appService.getSystemSettingsCached();
    }

    public getSession(): PCCSession {
        return this.sessionService.getSession();
    }

    public clearSession(): void {
        return this.sessionService.clearSession();
    }

    public performAction(action: string): boolean {
        console.log(`TODO: performAction: ${action}`);
        return true;
    }

    public createSession(acctInfo: IAccount, acctSettings: IAccountSettings, locale?: string, enrollInfo?: IEnrollment, persist?: boolean, sessionMode?: SessionModeEnum): Promise<PCCSession> {
        return this.sessionService.createSession(acctInfo, acctSettings, locale, enrollInfo, persist, sessionMode);
    }

    public setSession(sess: PCCSession): void {
        this.sessionService.setSession(sess);
    }

    public saveSession(): Promise<IEnrollResponse> {
        return this.sessionService.saveSession();
    }

    public getCurrentSession(): Observable<PCCSession> {
        return this.sessionService.getCurrentSession();
    }

    public async cleanupSessions(cleanReq: ICleanSessionsRequest): Promise<IEnrollResponse> {
        return this.sessionService.cleanupSessions(cleanReq);
    }

    public submitEnrollment(session?: PCCSession): Observable<IEnrollResponse> {
        return this.sessionService.submitEnrollment(session);
    }

    public reloadPricing(session: PCCSession): Promise<boolean> {
        return this.sessionService.reloadPricing(session);
    }

    public async findMatchingProfile(profileRequest: IProfileMatchRequest): Promise<IProfileMatchResponse> {
        return this.sessionService.findMatchingProfile(profileRequest);
    }

    public getRecentSearches(): Observable<IRecentSearchesResponse> {
        return this.appService.getRecentSearches();
    }

    public async findAccount(sapId: string, isAdmin?: boolean): Promise<IAccountResponse> {
        return this.accountService.findAccount(null, sapId, isAdmin);
    }

    public getUserDetails(): IUser {
        return this.authService.getUserDetails();
    }

    public setLocale(locale: string): void {
        this.translateService.locale = locale;
    }

    public getLocale(): string {
        return this.translateService.locale;
    }

    public getLocaleSubject(): BehaviorSubject<string> {
        return this.translateService.localeSubject;
    }

    public translate(key: string, context?: Object): string {
        return this.translateService.instant(key, context);
    }

    /**
     * If a translation is found for specified key, returns the translation.
     * Otherwise returns undefined (instead of returning key from missing-translate.handler)
     */
    public safeTranslate(key: string, context?: Object): string | undefined {
        if (this.hasTranslation(key)) {
            return this.translate(key, context);
        }
        return undefined;
    }

    public hasTranslation(key): boolean {
        return this.translateService.hasTranslation(key);
    }

    public sendEmail(idVal?: any): Observable<unknown> {
        return this.sessionService.sendEmail(idVal);
    }

    public checkEnrollmentStatus(idVal?: any): Observable<IAPIResponseData<EnrollmentStatus>> {
        return this.sessionService.checkEnrollmentStatus(idVal);
    }

    public async submitProfile(profile: IProfile, enrollInfo: IEnrollment, session: PCCSession, runPricing: boolean): Promise<boolean> {
        return this.profileService.submitProfile(profile, enrollInfo, session, runPricing);
    }

    public async samePriceCheck(enrollInfo: IEnrollment, profile: IProfile, fromEdit?: boolean): Promise<SamePriceEnum> {
        return this.priceService.samePriceCheck(enrollInfo, profile, fromEdit);
    }

    public copyPrices(profile: IProfile, enrollInfo: IEnrollment): boolean {
        return this.priceService.copyPrices(profile, enrollInfo);
    }

    public revertPrices(profile: IProfile, enrollInfo: IEnrollment): boolean {
        return this.priceService.revertPrices(profile, enrollInfo);
    }

    public isSubmitEnabled(): boolean {
        return this.appService.isSubmitEnabled();
    }

    public isTrainingMode(): boolean {
        return this.appService.isTrainingMode();
    }

    public isEventTrainingMode(): boolean {
        return this.appService.isEventTrainingMode();
    }

    public isLocalTrainingMode(): boolean {
        return this.appService.isLocalTrainingMode();
    }

    public getAppMode(): BehaviorSubject<AppModeEnum> {
        return this.appService.getAppMode();
    }

    public setAppMode(mode: AppModeEnum): void {
        return this.appService.setAppMode(mode);
    }

    public getDefaultLims(session: PCCSession): string[] {
        return this.accountService.getDefaultLims(session);
    }

    public async openSeismicDoc(link: ISeismicLink): Promise<NgbModalRef> {
        return await this.docService.openSeismicDoc(link);
    }

    public async showPdf(enrollId: number, isPreview = false, locale: string = null): Promise<NgbModalRef> {
        return await this.docService.showPdf(enrollId, isPreview, locale);
    }

    public async getCustomPage(customPageId: number): Promise<IAPIResponseData<ICustomPage>> {
        return this.docService.getCustomPage(customPageId);
    }

    public shouldShowLandingPage(session: PCCSession): boolean {
        return this.appService.shouldShowLandingPage(session);
    }

    public async startEnrollmentSession(session: PCCSession): Promise<void> {
        return this.appService.startEnrollmentSession(session);
    }
}
