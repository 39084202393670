<span *ngIf="!showLanguages">
  <span class="locale">
    {{ defaultLanguage?.displayName }} ( {{ defaultLanguage?.locale }} )
  </span>
</span>
<div class="spot-form__field-group spot-form__field-group--horizontal">
  <fieldset class="spot-form__radio-group" role="radiogroup" *ngIf="showLanguages">
    <legend class="spot-form__radio-group-label">
      <span *ngIf="isRequired" class="spot-form__label-required-indicator" title="This field is required." aria-hidden="true">*</span>
      Assign Language
    </legend>
    <div class="spot-form__radio-group-inner">
      <label class="spot-form__radio" [for]="item.locale" *ngFor="let item of languages">
        <input type="radio" [id]="item.locale" [name]="item.locale" [value]="item.locale" class="spot-form__radio-input" [(ngModel)]="selectedLocale" (change)="selectLanguage(item)"/>
        <span class="spot-form__radio-inner">
          <span class="spot-form__radio-visual"></span>
          <span class="spot-form__radio-label"> {{ item.displayName }} ( {{ item.locale }} ) </span>
        </span>
      </label>
    </div>
  </fieldset>
</div>
