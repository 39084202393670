import { Component, Input, Output, EventEmitter, OnInit } from "@angular/core";

import { Language } from "@shared/model/language";

@Component({
    selector: "pcc-language-radio-group",
    templateUrl: "./language-radio-group.component.html",
    styleUrls: [
        "./language-radio-group.component.scss"
    ]
})
export class LanguageRadioGroupComponent implements OnInit {

    @Input() public isRequired: boolean = false;

    @Input() public languages: Language[];

    @Input() public selectedLocale: string;

    @Output() public selectedLanguageChange = new EventEmitter<Language>();

    @Output() public selectedLocaleChange = new EventEmitter<string>();

    public showLanguages = true;

    public defaultLanguage: Language;

    public ngOnInit() {
        this.showLanguages = this.languages?.length > 1;
        this.defaultLanguage = this.getDefaultLanguage(this.selectedLocale);
    }

    private getDefaultLanguage(locale?: string): Language | null {
        if (this.languages?.length < 1) {
            return null;
        }

        if (locale) {
            return this.languages.find((lang: Language): boolean => lang.locale === locale);
        }

        return this.languages[0];
    }

    public selectLanguage(item: Language): void {
        this.selectedLocale = item?.locale;
        this.selectedLanguageChange.emit(item);
        this.selectedLocaleChange.emit(this.selectedLocale);
    }

}
