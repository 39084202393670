import { Component, Input, Output, EventEmitter } from "@angular/core";

@Component({
    selector: "pcc-rich-text",
    templateUrl: "./rich-text.component.html",
    styleUrls: [
        "./rich-text.component.scss"
    ]
})
export class RichTextComponent {

    @Input() public label: string;

    @Input() public editMode = false;

    @Input() public richText!: string;

    @Input() public showEditButton = true;

    @Output() private richTextChange = new EventEmitter<string>();

    public editClicked(): void {
        this.editMode = !this.editMode;
    }

    public textChanged(): void {
        console.log("textChanged");
        this.richTextChange.emit(this.richText);
    }
}
