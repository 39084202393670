<label class="spot-form__checkbox" [ngClass]="size==='small' ? 'spot-form__checkbox--small' : ''" [for]="uniqueId">
  <input type="checkbox" class="spot-form__checkbox-input" [id]="uniqueId" [name]="inputName" [(ngModel)]="checked" (change)="onCheckboxChange($event)"/>
  <span class="spot-form__checkbox-inner">
    <span class="spot-form__checkbox-visual">
      <svg class="spot-icon spot-form__checkbox-checked-icon" aria-labelledby="title">
        <title>Enabled</title>
        <use xlink:href="/assets/spot-library/dist/symbols/spot_symbols.svg#checkmark"></use>
      </svg>
      <svg class="spot-icon spot-form__checkbox-indeterminate-icon" aria-labelledby="title">
        <title>checkbox-indeterminate</title>
        <use xlink:href="/assets/spot-library/dist/symbols/spot_symbols.svg#checkbox-indeterminate"></use>
      </svg>
    </span>
    <span class="spot-form__checkbox-label">
      {{ label }}
    </span>
  </span>
</label>
