import { Component, OnInit } from "@angular/core";

import { Language } from "@shared/model/language";
import localeCodes from "locale-codes";

import { PCCAlertService } from "@client/service/alert.service";

import { AdminFacade } from "@client/facade/admin.facade";

@Component({
    selector: "pcc-system-languages",
    templateUrl: "./system-languages.component.html",
    styleUrls: [
        "./system-languages.component.scss"
    ]
})
export class SystemLanguagesComponent implements OnInit {

    public availLocales: localeCodes.ILocale[] = [];

    public selectedLocale: string;

    public activeLanguages: Language[] = [];

    public constructor(
        private adminFacade: AdminFacade,
        private alertService: PCCAlertService
    ) {}

    public async ngOnInit(): Promise<void> {
        this.updateLanguages();
    }

    private async updateLanguages(): Promise<void> {
        const resp = await this.adminFacade.getLanguages();

        this.activeLanguages = resp.data;

        this.availLocales = this.sortLanguages(localeCodes.all);
    }

    public async addLanguage(): Promise<void> {
        console.log("addLanguage");
        if (!this.selectedLocale) {
            return;
        }

        try {
            this.alertService.setBusy(true, "Saving...");

            console.log("selectedLocale=", this.selectedLocale);
            const localeInfo = localeCodes.getByTag(this.selectedLocale);
            console.log("localeInfo = ", localeInfo);

            const selectedLanguage: Language = {
                locale: this.selectedLocale,
                langCode: localeInfo['iso639-1'],
                displayName: localeInfo.name,
                nativeName: localeInfo.local || this.getNativeName(this.selectedLocale) || localeInfo.name, // Some countries in locale-codes don't have native name populated.
                active: true
            };

            const resp = await this.adminFacade.saveLanguage(selectedLanguage);
            console.log("save resp: ", resp);

            this.updateLanguages();
        } finally {
            this.alertService.setBusy(false);
        }
    }

    public selectedLocaleChanged(): void {
        console.log("selectedLocaleChanged: ", this.selectedLocale);
    }

    private getNativeName(locale: string): string {
        const languageNames = new Intl.DisplayNames(
            [
                locale
            ], { type: 'language' });
        const nativeName = languageNames.of(locale);
        console.log("getNativeName: ", locale, nativeName);
        return nativeName;
    }

    private sortLanguages(list: localeCodes.ILocale[]): localeCodes.ILocale[] {
        const preferredList = ['en-US', 'en-CA', 'fr-CA', 'de-DE']; // TODO: Drive from current languages?
        const activeList = this.activeLanguages.map((lang: Language): string => lang.locale);
        return list
            .sort((l1: localeCodes.ILocale, l2: localeCodes.ILocale) =>
                preferredList.includes(l1.tag) ? preferredList.includes(l2.tag) ? 0 : -1 : 1
            )
            .filter((localeInfo: localeCodes.ILocale): boolean => !activeList.includes(localeInfo.tag));
    }

    public async deleteLanguage(lang: Language): Promise<void> {
        console.log("deleteLanguage: ", lang);
        lang.active = false

        try {
            const resp = await this.adminFacade.deleteLanguage(lang);
            console.log("deleteLanguage resp: ", resp);

            this.updateLanguages();
        } catch (err) {
            console.error("Error deleting language: ", err);
        }
    }
}
