import { Component, Input, Output, EventEmitter, OnChanges, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

import { ICustomPage } from "@shared/model/custom-page";
import { ISeismicLink } from "@shared/model/seismic";
import { SeismicUtilsService } from "@shared/service/seismic-utils.service";
import { IAccountSettings } from "@shared/model/account-settings";

import { SeismicLinkEditComponent } from "../seismic-link-edit/seismic-link-edit.component";

@Component({
    selector: "pcc-seismic-links",
    templateUrl: "./seismic-links.component.html",
    styleUrls: [
        "./seismic-links.component.scss"
    ]
})
export class SeismicLinksComponent implements OnInit, OnChanges {

    @Input() public selectedPage?: ICustomPage;

    @Input() public accountSettings: IAccountSettings;

    @Input() public showTag = true;

    @Output() public valid = new EventEmitter<boolean>();

    @Output() public linkChanged = new EventEmitter<ISeismicLink>();

    @Output() public linkDeleted = new EventEmitter<ISeismicLink>();

    @Output() public linkAdded = new EventEmitter<ISeismicLink>();

    @Output() public linkOrderChanged = new EventEmitter<ICustomPage>();

    public activeLinks: ISeismicLink[];

    public constructor(
        private modalService: NgbModal
    ) {}

    public ngOnInit(): void {
        this.updateView();
    }

    public ngOnChanges(): void {
        console.log("ngOnChanges");
        this.updateView();
    }

    private updateView(): void {
        if (!this.selectedPage) {
            console.warn("No selectedPage defined");
            return;
        }
        this.activeLinks = this.getActiveLinks(this.selectedPage);
    }

    private getActiveLinks(page: ICustomPage): ISeismicLink[] {
        if (!page || !page.links) {
            return [];
        }
        return page.links.filter((link: ISeismicLink): boolean => link.active);
    }

    private validate(): boolean {
        console.log("validate");
        let isValid = true;
        this.selectedPage.links.forEach((link: ISeismicLink): void => {
            if (!SeismicUtilsService.isLinkValid(link)) {
                console.error("Invalid link: ", link);
                isValid = false;
            }
        });
        this.valid.emit(isValid);
        return isValid;
    }

    public addLink(): void {
        console.log("addLink");

        const newLink: ISeismicLink = {
            custom_page_id: this.selectedPage.id,
            displayOrder: 0,
            active: true,
            isDefault: false
        };

        this.selectedPage.links = this.selectedPage.links ?? [];
        this.selectedPage.links.push(newLink);

        this.renumberLinks(this.selectedPage.links);

        this.linkAdded.emit(newLink);

        this.editLinkClicked(newLink);

        this.updateView();

        this.validate();
    }

    public linkWasDeleted(oldLink: ISeismicLink): void {
        console.log("linkDeleted: ", oldLink);

        if (!oldLink.id) {
            // Remove unpersisted records from list rather than setting active flag.
            this.removeLink(oldLink);
        }

        this.linkDeleted.emit(oldLink);

        this.updateView();

        this.validate();
    }

    public linkClicked(link: ISeismicLink): void {
        this.editLinkClicked(link);
    }

    public linksReordered(newLinks: ISeismicLink[]): void {
        this.renumberLinks(newLinks);
    }

    private async renumberLinks(links: ISeismicLink[]): Promise<void> {
        let order = 0;
        links.forEach((link: ISeismicLink): void => {
            link.displayOrder = order;
            order += 1;
        });
        this.linkOrderChanged.emit(this.selectedPage);
    }

    public linkValidChanged(link: ISeismicLink, isValid: boolean): void {
        console.log("linkValidChanged: ", link, isValid);
        if (!isValid) {
            this.valid.emit(false);
        } else {
            this.validate();
        }
    }

    public linkHasChanged(link: ISeismicLink): void {
        console.log("linkChanged: ", link);
        this.validate();
        this.linkChanged.emit(link);
    }

    private removeLink(oldLink: ISeismicLink): boolean {
        if (oldLink.id) {
            console.warn("Not removing persisted file.  Use active flag.", oldLink);
            return;
        }

        this.selectedPage.links = this.selectedPage.links.filter((file: ISeismicLink): boolean =>
            file === oldLink
        );
    }

    public editLinkClicked(link: ISeismicLink): void {
        console.log("editLinkClicked: ", link);

        const ref = this.modalService.open(SeismicLinkEditComponent, {
            size: "xl",
            windowClass: "modal-centered",
            backdrop: true,
            keyboard: true
        });
        ref.componentInstance.selectedLink = link;
        ref.componentInstance.accountSettings = this.accountSettings;
        ref.componentInstance.showTag = this.showTag;

        // Wait for dialog to close.  User can either Save, Delete, or Cancel
        ref.result.then((result): void => {
            console.log("Edit Seismic Link modal closed: ", result);
            this.updateView();
        }, (reason): void => {
            console.log("Edit Price modal dismissed", reason);
        });
    }
}
