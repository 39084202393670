<div class="view-body" style="display:flex;flex-direction:column;">
  <div style="flex:1;display:flex;flex-direction:column;">
    <div class="view-title spot-typography__heading--level-2">{{ 'enroll.title' | translate }}</div>

    <pcc-locked-enrollment-message *ngIf="lockedEnrollment" [message]="lockedMsg"></pcc-locked-enrollment-message>

    <form #enrollForm="ngForm" (input)="onFormChange()" class="enroll-form spot-form" name="enrollForm">
      <div style="display:flex;">
        <div class="enroll-box">
          <section class="champion-box">
            <div class="pcc-level-5"> {{ 'enroll.Champion_Title' | translate }} </div>
            <div class="spot-form__field-group">
              <label class="spot-form__label" for="championSalutation">{{'enroll.Practice_champion_salutation' | translate}}</label>
              <div class="spot-form__select">
                <select [(ngModel)]='enrollInfo.championSalutation' class="spot-form__select-input" id="championSalutation" name="championSalutation"
                        #championSalutation="ngModel">
                  <option value="" class="spot-form__select-option"></option>
                  <option class="spot-form__select-option">{{'enroll.salutations.mister' | translate}}</option>
                  <option class="spot-form__select-option">{{'enroll.salutations.miss' | translate}}</option>
                  <option class="spot-form__select-option">{{'enroll.salutations.misses' | translate}}</option>
                  <option class="spot-form__select-option">{{'enroll.salutations.doctor' | translate}}</option>
                  <option class="spot-form__select-option">{{'enroll.salutations.professor' | translate}}</option>
                </select>
                <div class="spot-form__select-inner"></div>
                <span class="spot-form__select-open-indicator">
                  <svg class="spot-icon spot-form__select-open-icon" aria-labelledby="title">
                    <title>caret-down</title>
                    <use xlink:href="/assets/spot-library/dist/symbols/spot_symbols.svg#caret-down"></use>
                  </svg>
                </span>
              </div>
            </div>

            <div class="spot-form__field-group">
              <label class="spot-form__label" for="champFirstName">{{'enroll.Practice_champion_first_name' | translate}}</label>
              <input [(ngModel)]='enrollInfo.championFirstName' class="spot-form__input" id="champFirstName" name="champFirstName" type="text" required #champFirstName="ngModel"/>
              <div *ngIf="champFirstName.invalid && (champFirstName.dirty || champFirstName.touched)" class="spot-form__field-error" role="alert">
                <span *ngIf="champFirstName.errors?.['required']" class="spot-form__field-error-text"> {{'enroll.champ_first_name_required' | translate}} </span>
              </div>
            </div>

            <div class="spot-form__field-group">
              <label class="spot-form__label" for="champLastName">{{'enroll.Practice_champion_last_name' | translate}}</label>
              <input [(ngModel)]='enrollInfo.championLastName' class="spot-form__input" id="champLastName" name="champLastName" type="text" required #champLastName="ngModel"/>
              <div *ngIf="champLastName.invalid && (champLastName.dirty || champLastName.touched)" class="spot-form__field-error" role="alert">
                <span *ngIf="champLastName.errors?.['required']" class="spot-form__field-error-text"> {{'enroll.champ_last_name_required' | translate}} </span>
              </div>
            </div>

            <div class="spot-form__field-group">
              <label class="spot-form__label" for="champRole">{{'enroll.Practice_champion_role' | translate}}</label>
              <div class="spot-form__select">
                <select (change)="champRoleChanged()" [(ngModel)]="enrollInfo.championRole" [compareWith]='compareRole'
                        class="spot-form__select-input"
                        id="champRole" name="champRole" required #champRole="ngModel">
                  <option class="spot-form__select-option" disabled selected></option>
                  <option *ngFor="let role of championRoles" [ngValue]="role" class="spot-form__select-option" >{{role.name}}</option>
                </select>
                <div class="spot-form__select-inner"></div>
                <span class="spot-form__select-open-indicator">
                  <svg class="spot-icon spot-form__select-open-icon" aria-labelledby="title">
                    <title>caret-down</title>
                    <use xlink:href="/assets/spot-library/dist/symbols/spot_symbols.svg#caret-down"></use>
                  </svg>
                </span>
              </div>
              <div *ngIf="champRole.invalid && (champRole.dirty || champRole.touched)" class="spot-form__field-error" role="alert">
                <span *ngIf="champRole.errors?.['required']" class="spot-form__field-error-text"> {{'enroll.champ_role_required' | translate}} </span>
              </div>
            </div>

            <div class="spot-form__field-group">
              <label class="spot-form__label" for="championEmail">{{'enroll.Practice_champion_email' | translate}}</label>
              <input type="email" id="championEmail" name="championEmail" [(ngModel)]="enrollInfo.championEmail" class="spot-form__input" required email #championEmailInput="ngModel"/>
              <div *ngIf="championEmailInput.invalid && (championEmailInput.dirty || championEmailInput.touched)" class="spot-form__field-error" role="alert">
                <div *ngIf="championEmailInput.errors?.required" class="spot-form__field-error-text"> {{'enroll.champ_email_required' | translate}} </div>
                <div *ngIf="championEmailInput.value && championEmailInput.errors?.email" class="spot-form__field-error-text"> {{'enroll.champ_email_invalid' | translate }} </div>
              </div>
            </div>
          </section>
          <section class="vdc-box">
            <div class="pcc-level-5"> {{ 'enroll.VDC_Title' | translate }} </div>
            <div class="spot-form__field-group">
              <label class="spot-form__label" for="vdcName">{{'enroll.vdc' | translate}}</label>
              <input [(ngModel)]='vdcUser.name' class="spot-form__input" id="vdcName" name="vdcName" type="text" required
                     #vdcNameInput="ngModel"/>
              <div *ngIf="vdcNameInput.invalid && (vdcNameInput.dirty || vdcNameInput.touched)" class="spot-form__field-error" role="alert">
                <div *ngIf="vdcNameInput.errors?.['required']" class="spot-form__field-error-text"> {{'enroll.missing_vdc_name' | translate }} </div>
              </div>
            </div>
            <div class="spot-form__field-group">
              <label class="spot-form__label" for="vdcEmail">{{'enroll.vdc_email' | translate}}</label>
              <input type="email" id="vdcEmail" name="vdcEmail" [(ngModel)]="vdcUser.email" class="spot-form__input" required email #vdcEmailInput="ngModel"/>
              <div *ngIf="vdcEmailInput.invalid && (vdcEmailInput.dirty || vdcEmailInput.touched)" class="spot-form__field-error" role="alert">
                <div *ngIf="vdcEmailInput.errors?.required" class="spot-form__field-error-text"> {{'enroll.missing_vdc_email' | translate }} </div>
                <div *ngIf="vdcEmailInput.value && vdcEmailInput.errors?.email" class="spot-form__field-error-text"> {{'enroll.vdc_email_invalid' | translate }} </div>
              </div>
            </div>
            <div class="spot-form__field-group">
              <label class="spot-form__label" for="vdcPhone">{{'enroll.vdc_phone' | translate}}</label>
              <input [(ngModel)]='vdcUser.phone' [readonly]="!allowVDCPhoneEdit" class="spot-form__input"
                     id="vdcPhone"
                     name="vdcPhone" type="tel"/>
            </div>
          </section>
        </div>
        <section class="submit-box">
          <button [disabled]="!submitEnabled" class="spot-button spot-button--primary spot-button--large pcc-btn-primary submit-button" (click)="submitEnrollment()">{{ submitButtonKey | translate}}</button>
        </section>
      </div>
      <div class="date-box">
        <div class="pcc-level-5"> {{ customPrefix + 'importantDates' | customTranslate: { fallbackKey: 'enroll.Date_Title' } }} </div>
        <div class="date-box-content">
          <div class="enroll-field-group spot-form__field-group" style="margin-right:30px;">

            <label class="spot-form__label calendar-label">
              {{ customPrefix + 'calendarName' | customTranslate: { fallbackKey: 'enroll.Practice_integration_appointment' } }}
              <calendar-widget
                [min]="getMinIntegrationDate()"
                [disabled]="dateLocked"
                [(date)]="enrollInfo.integrationDate"
                />
            </label>
            <div *ngIf="!enrollInfo?.integrationDate" class="spot-form__field-error" role="alert">
              <span class="spot-form__field-error-text"> {{'enroll.integration_date_required' | translate}} </span>
            </div>
          </div>

          <div *ngIf="session?.accountSettings.flags.show_launch_calendar" class="enroll-field-group spot-form__field-group">
            <label class="spot-form__label calendar-label">
              {{ customPrefix + 'launchCalendarName' | customTranslate: { fallbackKey: 'enroll.Launch_training_appointment' } }}
              <calendar-widget
                [min]="enrollInfo?.integrationDate"
                [disabled]="dateLocked"
                [(date)]="enrollInfo.launchDate"
                />
            </label>
            <div *ngIf="!enrollInfo?.launchDate" class="spot-form__field-error" role="alert">
              <span class="spot-form__field-error-text"> {{'enroll.launch_date_required' | translate}} </span>
            </div>

          </div>
        </div>
      </div>

      <pcc-enroll-pet-care-heroes class="pet-care-heroes" [enrollInfo]="enrollInfo" [sessionMode]="this.session.sessionMode" [handlingEmail]="handlingEmail" (enrollChanged)="pchEnrolledChange()" [hidden]="!session?.accountSettings.flags.pch_enabled"></pcc-enroll-pet-care-heroes>

      <div *ngIf="validateMsg" class="spot-form__field-error" role="alert">
        <span class="spot-form__field-error-text"> {{validateMsg}} </span>
      </div>

      <section class="program-resources" *ngIf="hasResources">
        <div class="pcc-level-5-20"> {{ customPrefix + 'programResources' | customTranslate: { fallbackKey: 'enroll.Program_Resources' } }} </div>
        <pcc-seismic-docs-panel [loading]="loadingDocs" [docs]="docs" [selectedDocs]="enrollInfo.selectedDocuments" [canSelect]="true"></pcc-seismic-docs-panel>
      </section>
    </form>
    <pcc-profiles-tab-panel
      [profiles]="profileCards"
      [canSelect]="false"
      [canEdit]="false"
      [canEditPracticePrice]="false"
      [canEditPetOwnerPrice]="false"
      class="profiles"
      >
    </pcc-profiles-tab-panel>
  </div>
</div>
