<pcc-popover (closed)="popoverClosed($event)">
  <button *ngIf="!useLink" type="button" [class]="confirmButtonClass + (buttonText ? 'spot-button spot-button--link confirm-button' : 'spot-button spot-button--secondary spot-button--large round-button confirm-button')" [title]="buttonTitle | translate" [attr.spot-popover-id]="confirmId" id="confirm-button">
	<svg *ngIf="symbol" class="spot-icon spot-button__icon" [ngClass]="buttonText ? 'spot-button__icon--left' : ''" aria-labelledby="title">
	  <title>{{ buttonTitle | translate }}</title>
	  <use [attr.xlink:href]="'/assets/spot-library/dist/symbols/spot_symbols.svg#' + symbol"></use>
	</svg>
	{{ buttonText | translate }}
  </button>

  <a *ngIf="useLink" [class]="confirmLinkClass" [title]="buttonTitle | translate" [attr.spot-popover-id]="confirmId" id="confirm-button">
	<svg *ngIf="symbol" class="spot-icon spot-link__icon" [ngClass]="buttonText ? ' spot-link__icon--left' : ''" aria-labelledby="title">
	  <title>{{ buttonTitle | translate }}</title>
	  <use [attr.xlink:href]="'/assets/spot-library/dist/symbols/spot_symbols.svg#' + symbol"></use>
	</svg>
	{{ buttonText | translate }}
  </a>

  <div #confirmPopover [id]="confirmId" [class]="'spot-popover ' + confirmPosition" style="padding:0px;">
	<div style="padding:10px;display:flex;flex-direction:row;align-items:center;">
	  <span style="margin-right:2em;font-size:16px;font-weight:bold;white-space:nowrap;">
		{{ confirmMsg | translate }}
	  </span>
	  <button class="spot-button spot-button--secondary" (click)="okClicked();" spot-popover-close> {{ okKey | translate }} </button>
	  <button type="cancel" class="spot-button spot-button--link" (click)="cancelClicked()" spot-popover-cancel> {{ cancelKey | translate }} </button>
	</div>
  </div>
</pcc-popover>
