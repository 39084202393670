import { Component, OnInit, OnDestroy } from "@angular/core";

import { AppFacade } from "../../../facade/app.facade";

import { PCCSession } from "@shared/model/pcc-session";
import { AccountSettings } from "@shared/model/account-settings";
import { ICustomPage, CustomPageTypes } from "@shared/model/custom-page";
import { IProgressItem, NavService } from "../../../service/nav.service";
import { PCCAlertService } from "../../../service/alert.service";
import { Subscription } from "rxjs";
import googleAnalytics from "../../../analytics/googleAnalytics";

@Component({
    selector: "goal-setting",
    templateUrl: "./goal-setting.component.html",
    styleUrls: [
        "./goal-setting.component.scss"
    ]
})
export class GoalSettingComponent implements OnInit, OnDestroy {
    public monthlyGoal: number;

    private nextPage: IProgressItem = {
        name: "goal.Next",
        link: "enroll",
        onNext: this.nextClicked.bind(this),
        enabled: true
    };

    public session: PCCSession;

    public customContent: ICustomPage;

    private sessionSub: Subscription;

    private localeSub: Subscription;

    public constructor(
        public appFacade: AppFacade,
        private navService: NavService,
        private alertService: PCCAlertService
    ) {
        this.navService.setNextState(this.nextPage);
    }

    public ngOnInit(): void {
        this.sessionSub = this.appFacade.getCurrentSession().subscribe(
            (session: PCCSession): void => {
                this.setSession(session);
            }
        );

        this.localeSub = this.appFacade.getLocaleSubject().subscribe(
            (locale: string): void => {
                this.setLocale(locale);
            }
        );

    }

    public ngOnDestroy(): void {
        this.sessionSub.unsubscribe();
        this.localeSub.unsubscribe();
    }

    private setSession(session: PCCSession): void {
        this.session = session;

        this.monthlyGoal = session.enrollInfo.totalGoalQuantities;
        this.validate();
    }

    private setLocale(locale: string): void {
        console.log("setLocale: ", locale);

        const customPages = AccountSettings.getCustomPages(this.session.accountSettings, CustomPageTypes.GOAL);
        this.customContent = customPages.length > 0 ? customPages[0] : null;

        if (!this.customContent) {
            this.alertService.showError(this.appFacade.translate("goal.no_custom_page"));
        }
    }

    public async nextClicked(): Promise<void> {
        await this.save();
    }

    public goalInputChanged($event) {
        googleAnalytics.registerEvent("goal_directly_changed", "change", {
            previous_goal: this.monthlyGoal,
            new_goal: $event
        })
        this.validate();
    }

    public validate(): void {
        this.nextPage.enabled = this.monthlyGoal >= 5;
        this.navService.setNextState(this.nextPage);
    }

    private async save(): Promise<void> {
        this.alertService.setBusy(true, this.appFacade.translate("goal.loading"));

        const session = this.appFacade.getSession();
        session.enrollInfo.totalGoalQuantities = this.monthlyGoal;
        await this.appFacade.saveSession();

        this.alertService.setBusy(false);
    }
}
