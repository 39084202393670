import { Component, EventEmitter, Input, Output } from "@angular/core";
import googleAnalytics from "../../../analytics/googleAnalytics";

@Component({
    selector: "pcss-goal-calculator",
    templateUrl: "./pcss-goal-calculator.component.html",
    styleUrls: [
        "./pcss-goal-calculator.component.scss"
    ]
})
export class PcssGoalCalculatorComponent {
    @Input()
    public monthlyGoal?: number;

    @Output()
    public monthlyGoalChange = new EventEmitter<number>();

    public wellnessVisitsPerMonth: number;

    public goalPercent: number = 20;

    public visitsChange() {
        googleAnalytics.registerEvent("goal_visits_change", "change", {
            visits_value: this.wellnessVisitsPerMonth,
            monthly_goal: this.monthlyGoal
        });
    }

    public sliderChange() {
        googleAnalytics.registerEvent("goal_slider_change", "change", {
            slider_value: this.goalPercent,
            monthly_goal: this.monthlyGoal
        });
        this.inputChange();
    }

    public sliderInput() {
        this.inputChange();
    }

    public inputChange(): void {
        this.monthlyGoal = this.calculateGoalPercent();
        this.monthlyGoalChange.emit(this.monthlyGoal);
    }

    private calculateGoalPercent(): number {
        if (this.wellnessVisitsPerMonth) {
            return Math.ceil(this.wellnessVisitsPerMonth * this.goalPercent / 100);
        }
        return null;
    }
}
