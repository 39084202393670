import { Injectable } from "@angular/core";
import { Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { IRecentSearchesResponse } from "@shared/model/service/recent-searches-service";
import { AppFacade } from "../facade/app.facade";

@Injectable({
    providedIn: "root"
})
export class RecentSearchesResolver implements Resolve<IRecentSearchesResponse> {

    public constructor(
        private appFacade: AppFacade
    ) {
    }

    public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IRecentSearchesResponse> {
        return this.appFacade.getRecentSearches();
    }
}
