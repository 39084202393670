import { Component, Input, OnInit, OnChanges } from "@angular/core";

import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";

import { AccountInfoComponent } from "../../views/account-info/account-info.component";
import { TestMixComponent } from "../../views/testmix/testmix.component";

import { AppFacade } from "../../../facade/app.facade";
import { NavService } from "../../../service/nav.service";

import { PCCSession } from "@shared/model/pcc-session";
import { IAccount } from "@shared/model/account";

import googleAnalytics from "../../../analytics/googleAnalytics";

@Component({
    selector: "pcc-client-header",
    templateUrl: "./client-header.component.html",
    styleUrls: [
        "./client-header.component.scss"
    ]
})
export class ClientHeaderComponent implements OnInit, OnChanges {

    @Input() public enablePreview = false;

    @Input() public session: PCCSession;

    public account: IAccount;

    public hasTestMix: boolean = false;

    public hasCorpInfo: boolean = false;

    private modalRef: NgbModalRef;

    public constructor(
        private modalService: NgbModal,
        private appFacade: AppFacade,
        private navService: NavService
    ) {
    }

    public ngOnInit(): void {
        this.parseInfo();
    }

    public ngOnChanges(): void {
        this.parseInfo();
    }

    private parseInfo(): void {
        console.log("parseInfo", this.session);
        this.account = this.session.accountInfo;
        this.hasTestMix = this.session?.accountSettings?.flags.use_test_mix;
        this.hasCorpInfo = this.session.accountSettings?.isCorp;
    }

    public goHome(): void {
        console.log("goHome");
        googleAnalytics.registerEvent("change_account_started", "linkEvent");
        this.navService.goHome();
    }

    public pdfPreview(): void {

        googleAnalytics.registerEvent("enrollment_preview_clicked", "clickEvent");

        const enrollId = this.session?.enrollInfo?.enrollment_id;

        this.appFacade.showPdf(enrollId, true, this.session?.locale);
    }

    public showTestMix(): void {
        console.log("showTestMix");

        googleAnalytics.registerEvent("show_test_mix", "clickEvent");

        if (this.modalRef && this.modalService.hasOpenModals()) {
            console.log("Modal dialog already open.");
            return;
        }

        this.modalRef = this.modalService.open(TestMixComponent, {
            size: "xl",
            scrollable: true,
            windowClass: "testmix fixed",
            centered: true
        });

        this.modalRef.result.then((result): void => {
            console.log("Test Mix modal closed: ", result);
            delete this.modalRef;
        }, (reason): void => {
            console.log("Test Mix modal dismissed", reason);
            delete this.modalRef;
        });
    }

    public showCorpInfo(): void {
        console.log("showCorpInfo");

        googleAnalytics.registerEvent("show_corp_info", "clickEvent");

        if (this.modalRef && this.modalService.hasOpenModals()) {
            console.log("Modal dialog already open.");
            return;
        }

        this.modalRef = this.modalService.open(AccountInfoComponent, {
            windowClass: "corp-info fixed",
            centered: true
        });

        this.modalRef.componentInstance.setSession(this.session);

        this.modalRef.result.then((result): void => {
            console.log("Account Info modal closed: ", result);
            delete this.modalRef;
        }, (reason): void => {
            console.log("Account Info modal dismissed", reason);
            delete this.modalRef;
        });
    }

}
