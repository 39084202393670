import { Inject, Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { TranslateService } from "@ngx-translate/core";
import { BehaviorSubject, firstValueFrom } from "rxjs";

import { IAPIResponseData } from "@shared/model/service/service";
import { PCCClientError } from "../shared/model/pcc-client-error";
import { Title } from "@angular/platform-browser";
import { DOCUMENT } from '@angular/common';
import { LocalizedKey, Translations, Language, LocalizedContent, LocalizedString } from "@shared/model/language";
import { UtilService } from "@shared/service/util.service";

const DEFAULT_LOCALE = "en-US";

@Injectable()
export class PCCTranslateService {

    public localeSubject = new BehaviorSubject<string>(DEFAULT_LOCALE);

    public supportedLanguages: Language[] = [];

    public accountLanguages: Language[] = [];

    public dataTranslations: Record<string, Translations>;

    private pLocale: string = DEFAULT_LOCALE;

    public get locale() {
        return this.pLocale;
    }

    public set locale(locale: string) {
        console.log("setLocale: ", locale);
        if (!this.isSupported(locale)) {
            console.error("isSupported=false");

            throw new PCCClientError("TRANSLATE.UNSUPPORTED_LOCALE", `${locale} is not a supported locale`);
        }

        console.log("isSupported=true");
        this.pLocale = locale;

        this.updateLocale(locale);
    }

    public constructor(
        private http: HttpClient,
        public ngxTranslateService: TranslateService,
        @Inject(DOCUMENT) private document: Document,
        private title: Title
    ) {
        this.initLang();
    }

    // Gets called when someone assigns this.locale
    private async updateLocale(locale: string): Promise<void> {
        console.log("updateLocale: ", locale);

        const resp = await firstValueFrom(this.ngxTranslateService.use(locale));
        console.log("ngxTranslateService.use resp: ", resp);

        if (this.dataTranslations) {
            this.mergeDataTranslations(this.dataTranslations);
        }

        this.localeSubject.next(locale);

        this.get('site.title').subscribe(title => this.title.setTitle(title));
        this.document.documentElement.lang = locale;
    }

    private async initLang(): Promise<void> {
        console.log("initLang");

        this.supportedLanguages = await this.getSystemLanguages();
        console.log("supportedLanguages=", this.supportedLanguages);

        const defaultLocale = await this.getUserDefaultLocale();
        this.locale = defaultLocale;
        console.log("default locale set: ", this.locale);
    }

    public setAccountLanguages(languages: Language[], defaultLocale: string) {
        console.log("setAccountLanguages: ", languages, defaultLocale);
        this.locale = this.getClosestLanguage(languages, defaultLocale);
        this.accountLanguages = languages;
    }

    public get(key: string | Array<string>, interpolateParams?: Object): ReturnType<TranslateService["get"]> {
        return this.ngxTranslateService.get(key, interpolateParams);
    }

    public instant(key: string | Array<string>, interpolateParams?: Object): ReturnType<TranslateService["instant"]> {
        return this.ngxTranslateService.instant(key, interpolateParams);
    }

    public hasTranslation(translationKey: string, language?: string): boolean {
        const currentLang = language || this.ngxTranslateService.currentLang || this.ngxTranslateService.defaultLang;
        const translations = this.ngxTranslateService.translations[currentLang];
        if (translations) {
            const translation = translations[translationKey] || this.getValueFromNestedKey(translations, translationKey);
            return translation !== undefined && translation !== null;
        }
        console.log("No translations for language: ", language);
        return false;
    }

    private getValueFromNestedKey(obj: any, key: string): any {
        return key.split('.').reduce((result, k) => (result ? result[k] : null), obj);
    }

    public isSupported(locale: string): boolean {
        console.log("isSupported: ", locale);
        if (!locale) {
            return false;
        }
        const isGood = this.supportedLanguages.some((lang: Language): boolean => {
            if (typeof locale === "string") {
                return lang.locale === locale;
            }
            return lang === locale;
        });
        console.log("isSupported=", isGood, this.supportedLanguages);
        return isGood;
    }

    public async getUserDefaultLocale(): Promise<string> {
        const browserLanguages = this.getBrowserLanguages();
        console.log("browserLanguages=", browserLanguages);

        const defaultLocale = await this.getClosestMatch(browserLanguages);
        console.log("defaultLocale = ", defaultLocale);

        return defaultLocale;
    }

    // Return array of user's browser-defined list of preferred languages.
    public getBrowserLanguages(): readonly string[] {
        return navigator.languages ||
            [
                navigator.language
            ];
    }

    // Used when not in an account.
    public getSupportedLanguages(): Language[] {
        return this.supportedLanguages;
    }

    // Given a list of languages user has specified in their browser, return the closest match.
    // If no languages match, return default language.
    public async getClosestMatch(langs: readonly string[]): Promise<string> {
        if (!langs) {
            return DEFAULT_LOCALE;
        }

        const supportedLocales = this.supportedLanguages.map((lang: Language): string => lang.locale);
        const matchLang = langs.find((langCode: string): boolean =>
            supportedLocales.includes(langCode)
        );
        return matchLang ? matchLang : DEFAULT_LOCALE;
    }

    public getLanguageInfo(locale: string): Language {
        return this.supportedLanguages.find((lang: Language): boolean => lang.locale === locale);
    }

    // Returns display text for the language specified in locale string.
    // Note that "en-CA" will return "English (Canada)", while "en" will return "English"
    public static getDisplayName(locale: string): string {
        const languageNames = new Intl.DisplayNames([
            locale
        ], { type: 'language' });
        return languageNames.of(locale);
    }

    public getCurrentLanguage(): Language {
        return this.getLanguageInfo(this.locale);
    }

    /**
     * Retrieves the value for a specified key from localized resources.
     *
     * @param localizedKeys - An object containing localized resources.
     * @param key - The key for which to retrieve the localized value.
     * @param locale - An optional locale to retrieve the localized value for. If not specified or if no value is found for the given locale, the function returns the first available value.
     * @returns The localized value for the specified key and locale, or the first available value if the locale is not specified or not found.
     */
    public getDefaultValue(localizedKey?: LocalizedKey, locale?: string): string {
        if (!localizedKey) {
            return undefined;
        }

        // Check if localizedKey exists and if the specified locale has a non-empty value, return it
        if (locale && UtilService.trimToNull(localizedKey[locale]) !== null) {
            return localizedKey[locale];
        }
        // Return the first available localized value if no specific locale is found or specified
        return Object.values(localizedKey).find(value => UtilService.trimToNull(value) != null);
    }

    /**
     * Returns the default locale for account settings.
     *
     * If a default locale is specified and it is included in the list of supported locales,
     * it returns the default locale. Otherwise, it returns the first locale in the list.
     *
     * @param {string[]} localeList - An array of supported locales.
     * @param {string} [defaultLocale] - An optional default locale.
     * @param {boolean} [defaultFirst] - If more than one locale is passed in, choose the first if not found by other means.
     * @returns {string} - The default locale, or the first item in the list if the default is not set or not in the list.
     */
    public getDefaultLocale(localeList?: string[], defaultLocale?: string, defaultFirst: boolean = true): string | null {
        if (defaultLocale && localeList?.includes(defaultLocale)) {
            return defaultLocale;
        }
        // If localeList only contains a single locale, return it here.
        // If localeList has more than one locale, but defaultFirst is true, return the first one here.
        if (localeList?.length && (defaultFirst || localeList?.length === 1)) {
            return localeList[0];
        }

        // One of the following paths end here:
        // localeList is empty
        // localeList has more than one locale, but defaultFirst is false.
        return null;
    }

    // Set the localized value
    // If locales are specified, only the specified locales value are set.
    public setLocalizedText(localizedKeys: LocalizedContent, key: string, value: string, locales?: string[]): void {
        console.log("setLocalizedText: ", key, value, localizedKeys);
        localizedKeys[key] = localizedKeys[key] || { key };
        if (locales) {
            locales.forEach((locale: string): string => localizedKeys[key][locale] = value);
        } else {
            Object.keys(localizedKeys[key]).forEach((locale: string): string => localizedKeys[key][locale] = value);
        }
    }

    // Returns true if localizedKey has a value defined for all locales in supplied locales array.
    public allLocalesPopulated(localizedKey: LocalizedKey, locales: string[]): boolean {
        if (!localizedKey) {
            return false;
        }
        const localizedValues = Object.keys(localizedKey).filter((field) => field !== "key" && locales.includes(field));
        if (!UtilService.compareArrays(localizedValues, locales)) {
            console.warn("Locales don't match: ", localizedValues, locales);
            return false;
        }

        // Return false if the value for any locale is either missing or an empty string.
        return !locales.some((locale: string): boolean => UtilService.trimToNull(localizedKey[locale]) === null);
    }

    public setDataTranslations(translations: Record<string, Translations>): void {
        console.log("setDataTranslations: ", translations);
        this.dataTranslations = translations;

        if (!translations) {
            return;
        }

        this.mergeDataTranslations(translations);
    }

    private mergeDataTranslations(translations: Record<string, Translations>): void {
        if (!translations[this.pLocale]) {
            console.warn("No translations found for locale: ", this.pLocale);
            return;
        }

        // Merge in any custom language text
        this.ngxTranslateService.setTranslation(this.pLocale, translations[this.pLocale], true);
        console.log("Merged in data translations for locale, ", this.pLocale, translations[this.pLocale]);
    }

    public async getSystemLanguages(): Promise<Language[]> {
        const resp = await firstValueFrom(
            this.http.get<IAPIResponseData<Language[]>>("/api/languages")
        );
        return resp.data;
    }

    public updateTranslations(translations: Translations): void {
        this.ngxTranslateService.setTranslation(this.pLocale, translations, true);
    }

    public updateLocalizedKey(textInfo: LocalizedString, localizedKeys: LocalizedContent): void {
        const { key, value, locale } = textInfo;
        localizedKeys[key] = localizedKeys[key] || { key };
        localizedKeys[key][locale] = value;
    }

    public async updateDataTranslations(accountSettingsId: number, locale?: string): Promise<Translations> {
        const dataTranslations = await this.getDataTranslations(accountSettingsId, locale);
        this.updateTranslations(dataTranslations);
        return dataTranslations;
    }

    public async getDataTranslations(accountSettingsId: number, locale?: string): Promise<Translations> {
        locale = locale || this.ngxTranslateService.currentLang;
        const resp = await firstValueFrom(
            this.http.get<IAPIResponseData<Translations>>(`/api/admin/translations/${accountSettingsId}/${locale}`)
        );
        return resp.data;
    }

    // Given a list of languages specified for an account, find the closest one to the currently
    // selected locale.
    // If no match is possible (for example, if current locale is fr-CA and account only supports
    // en-US), then return defaultLocale.
    public getClosestLanguage(langs: Language[], defaultLocale: string): string {
        console.log("getClosestLanguage");
        if (!Array.isArray(langs)) {
            console.log("No languages defined, returning ", DEFAULT_LOCALE);
            return DEFAULT_LOCALE;
        }

        if (langs.some((lang: Language): boolean => lang.locale === this.locale)) {
            // Currently selected language supported by account
            console.log("Currently selected language is supported by account.");
            return this.locale;
        }

        // If selected locale isn't supported, look for first match using just language code.
        const currentLangCode = this.getLanguageCode(this.locale);
        const langMatch = langs.find((lang: Language): boolean => lang.langCode === currentLangCode);
        if (langMatch) {
            console.log("Found a close match using language code ", currentLangCode);
            return langMatch.locale;
        }

        console.warn("Currently selected language isn't supported by account: ", this.locale, langs, defaultLocale);
        return defaultLocale || langs[0]?.locale;
    }

    private getLanguageCode(locale: string): string {
        const parts = locale.split(/[-_]/);
        return parts[0];
    }
}
