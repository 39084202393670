<div class="page-body">

  <div class="page-header">
    <span class="pcc-level-3-10">Simple Start Guide</span>
    <input type="checkbox" class="spot-toggle" id="page_enabled_landing" name="page_enabled_landing" [(ngModel)]="accountSettings.flags.page_enabled_landing" (change)="landingPageEnabledChange($event)">
    <label class="spot-toggle-label add-item" for="page_enabled_landing">Enabled</label>
  </div>

  <div class="pages-content">
    <as-split direction="horizontal" unit="pixel" #split="asSplit" style="flex: 1;">
      <as-split-area [size]="420" #area1="asSplitArea">
        <div class="left-bar">
          <div class="action-header">
            <span class="spot-typography__heading--level-5">Select Custom Page</span>
            <a class="spot-link add-item" (click)="addCustomPage();">
              <svg class="spot-icon spot-link__icon spot-link__icon--left" aria-labelledby="title">
                <title>Add</title>
                <use xlink:href="/assets/spot-library/dist/symbols/spot_symbols.svg#add"></use>
              </svg>
              Add a new Custom Page
            </a>
          </div>
          <pcc-drag-drop-list [items]="landingPages" (reordered)="pagesReordered($event)" (itemClicked)="pageClicked($event)" style="overflow:auto;" [itemTemplate]="pageTemplate">
          </pcc-drag-drop-list>
        </div>
      </as-split-area>

      <as-split-area #area3="asSplitArea" style="overflow:hidden">
        <div style="flex:1;display:flex;flex-direction:column;flex-grow:1;height:100%;">
          <pcc-custom-page-edit *ngIf="selectedPage" [selectedPage]="selectedPage" [accountSettings]="accountSettings" (deleted)="pageDeleted($event)" (canceled)="pageCanceled($event)" (saved)="pageSaved($event)"></pcc-custom-page-edit>
          <ng-container *ngIf="!selectedPage">
            <div style="margin: auto;">
              Select or create new Custom Page to continue...
            </div>
          </ng-container>
        </div>
      </as-split-area>
    </as-split>
  </div>
</div>
<ng-template #pageTemplate let-item="item">
  <div class="custom-page" [ngClass]="isSelected(item) ? ' spot-list-group__item--active' : ''">
    <div class="title">{{item.titleKey | customTranslate }}</div>
    <div class="sub-title">{{item.subTitleKey | customTranslate }}</div>
  </div>
</ng-template>
