<div class="page-body">

  <div class="page-header">
    <span class="pcc-level-3-10">Profile Template Management</span>
  </div>

  <div class="account-templates-content">
    <as-split direction="horizontal" unit="pixel" #split="asSplit" style="flex: 1;">
      <as-split-area [size]="500" #area1="asSplitArea">
        <div class="left-bar">
          <div class="action-header">
            <span class="spot-typography__heading--level-5">Select Template</span>
            <a class="spot-link add-item" (click)="addNewTemplate()">
              <svg class="spot-icon spot-link__icon spot-link__icon--left" aria-labelledby="title">
                <title>Add</title>
                <use xlink:href="/assets/spot-library/dist/symbols/spot_symbols.svg#add"></use>
              </svg>
              Add a new template
            </a>
          </div>

          <div class="spot-form__field-group spot-form__field-group--horizontal species-select">
            <fieldset class="spot-form__radio-group" role="radiogroup">
              <legend class="spot-form__radio-group-label">Species</legend>
              <div class="spot-form__radio-group-inner">
                <label *ngFor="let species of speciesNames" class="spot-form__radio" [for]="species">
                  <input [id]="species" [name]="selectedSpecies" [value]="species" type="radio" class="spot-form__radio-input" [(ngModel)]="selectedSpecies" >
                  <span class="spot-form__radio-inner">
                    <span class="spot-form__radio-visual"></span>
                    <span class="spot-form__radio-label ">
                      {{ species }}
                    </span>
                  </span>
                </label>
              </div>
            </fieldset>
          </div>
          <!-- all cards here -->
          <ng-container *ngFor="let species of speciesNames">
            <pcc-drag-drop-list [items]="templateCards[species]" (reordered)="cardsReordered($event)" style="background-color: white;height: 100%;" [itemTemplate]="slotTemplate" *ngIf="species===selectedSpecies"></pcc-drag-drop-list>
          </ng-container>
        </div>
      </as-split-area>

      <as-split-area  #area3="asSplitArea" style="overflow:hidden">
        <pcc-build-template *ngIf="selectedTemplate" [slot]="selectedTemplate" [accountSettings]="accountSettings" [systemSettings]="systemSettings" (delete)="deleteSlot($event)" (cancel)="slotCancelled($event)" (saved)="slotSaved($event)"></pcc-build-template>
      </as-split-area>
    </as-split>
  </div>
</div>
<ng-template #slotTemplate let-item="item">
  <pcc-account-template-list-item [slot]="item" [selected]="selectedTemplate === item" (click)='configSlot(item)' style="width:100%;"></pcc-account-template-list-item>
</ng-template>
