import { Injectable } from "@angular/core";

import exprEval from "expr-eval";
import { ISystemSettings } from "@shared/model/system-settings";
import { ISpecies } from "@shared/model/species";
import { IProfileItem } from "@shared/model/profile-item";
import { IProfile } from "@shared/model/profile";

import { PCCTranslateService } from "./translate.service";

@Injectable()
export class CorpPricingAlgorithmService {

    public constructor(
        private translateService: PCCTranslateService
    ) {}

    public evalCorpPrice(priceAlg: string, profile: IProfile): number {
        console.log(`evalPrice: ${priceAlg}`, profile);

        if (!profile) {
            console.error("Profile is null!");
            return 0;
        }

        if (!priceAlg || priceAlg === "") {
            console.error("No pricing algorithm provided!");
            return 0;
        }

        try {
            const varVals: Record<string, exprEval.Value> = {};
            varVals.v_CUSTOMER_PRICE = profile.customerPrice;
            varVals.v_LIST_PRICE = profile.listPrice;

            if (profile.species) {
                varVals.s_FELINE = profile.species.developer_name === "FELINE" ? 1 : 0;
                varVals.s_CANINE = profile.species.developer_name === "CANINE" ? 1 : 0;
            }

            for (const st of profile.profileItems) {
                varVals[`p_${st.profile_item_id}`] = 1;
            }

            console.log("varVals=", varVals);

            const result = this.runPriceCalc(priceAlg, varVals);
            console.log("result=", result);

            return result;
        } catch (e) {
            console.error("Error parsing expression: ", e);

            return 0;
        }
    }

    public runPriceCalc(priceAlg: string, varValues: Record<string, any>): number {
        console.log(`runPriceCalc: ${priceAlg}`, varValues);

        if (!varValues) {
            console.error("varValues is null!");
            return 0;
        }

        if (!priceAlg || priceAlg === "") {
            console.error("No pricing algorithm provided!");
            return 0;
        }

        try {
            const cleanAlg = priceAlg.replace(/[{}]/g, "");
            console.log(`cleanAlg=${cleanAlg}`);

            const parser = new exprEval.Parser();
            const expression = parser.parse(cleanAlg);

            const vars: string[] = expression.variables();
            for (const v of vars) {
                if (!varValues[v]) {
                    console.log("variable not selected: ", v);
                    varValues[v] = 0;
                }
            }

            const result = expression.evaluate(varValues);
            console.log("result=", result);

            return result.toFixed(2);
        } catch (e) {
            console.error("Error parsing expression: ", e);

            return 0;
        }
    }

    /**
     * Given a pricing algorithm using variable name format (s_CANINE}, convert it
     * to human-readable form: {Canine}.
     */
    public convertToDisplayNameVersion(alg: string, species: ISpecies[], profileItems: IProfileItem[], systemSettings: ISystemSettings): string {
        console.log(`convertToDisplayNameVersion: ${alg}`);
        if (!alg || alg.trim() === "") {
            console.warn("Empty pricing algorithm");
            return alg;
        }

        let dispAlg = "";
        const delimiterPattern = /({[^}]*})/g;
        const pieces = alg.split(delimiterPattern);
        console.log("pieces=", pieces);
        for (const p of pieces) {
            if (p === "") {
                continue;
            }
            if (p.startsWith("{")) {
                const varArg = p.replace(/[{}]/g, "");
                const v = this.convertVarToDisp(varArg, species, profileItems, systemSettings);
                dispAlg += v;
            } else {
                dispAlg += p;
            }
        }

        console.log("converted: ", dispAlg);
        return dispAlg;
    }

    /**
     * Convert {p_139} to {CHEM 25 w/SDMA}
     */
    public convertVarToDisp(pVarName: string, species: ISpecies[], profileItems: IProfileItem[], systemSettings: ISystemSettings): string {
        console.log(`convertVarToDisp: ${pVarName}`);
        const varName = pVarName.trim();
        let dispVar: string;

        if (varName === "v_CUSTOMER_PRICE") {
            dispVar = "Customer Price";
        } else if (varName === "v_LIST_PRICE") {
            dispVar = "List Price";
        } else {
            for (const sp of species) {
                if (varName === `s_${sp.developer_name}`) {
                    dispVar = sp.display_name;
                    break;
                }
            }

            if (!dispVar) {
                const pi = this.getProfileItem(varName, profileItems);
                if (pi) {
                    dispVar = this.translateService.instant(pi.displayNameKey).trim();
                }
            }
        }

        if (!dispVar) {
            console.warn("Warning, test not in valid profile items for this account: ", varName);
            const pi = this.getProfileItem(varName, systemSettings?.profileItems);
            if (pi) {
                console.log("Found profile item from system items rather than account!", pi);
                dispVar = this.translateService.instant(pi.displayNameKey).trim();
            }
        }

        if (!dispVar) {
            console.error(`Unknown variable: '${varName}'`);
            dispVar = varName;
        }

        dispVar = `{${dispVar}}`;

        console.log(`Converted ${varName} to ${dispVar}`);

        return dispVar;
    }

    private getProfileItem(varName: string, knownTests: IProfileItem[]): IProfileItem {
        console.log("getProfileItem: ", varName);
        if (!knownTests) {
            return null;
        }

        for (const t of knownTests) {
            if (varName === `p_${t.profile_item_id}`) {
                return t;
            }
            if (varName === this.translateService.instant(t.displayNameKey).trim()) {
                console.warn("Warning, matched display name rather than var name: ", varName);
                return t;
            }
        }
        console.warn(`Not found: ${varName}`);
        return null;
    }
}
