import { Injectable } from "@angular/core";
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild, UrlTree } from "@angular/router";

import { AuthService } from "./auth.service";

import { MsalGuard } from "@azure/msal-angular";
import { BehaviorSubject } from "rxjs";

export enum AUTH_STATUS {
    PENDING = "PENDING",
    SUCCESS = "SUCCESS",
    FAILURE = "FAILURE"
}

@Injectable({
    providedIn: "root"
})
export class AuthGuard implements CanActivate, CanActivateChild {
    public status = new BehaviorSubject<AUTH_STATUS>(AUTH_STATUS.FAILURE);

    public authorized = new BehaviorSubject<boolean>(false);

    public constructor(
        private authService: AuthService,
        private msalGuard: MsalGuard
    ) {
    }

    public async checkAuth(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
        console.log("checkAuth", next, state);

        const targetUrl = this.getTargetUrl(state.url);

        const result = await this.msalGuard.canActivate(next, state).toPromise();
        console.log("From msalGuard: ", result);

        if (!result) {
            return result;
        }

        this.authService.checkAndSetActiveAccount();

        if (!this.authService.hasAzureAccount()) {
            console.log("Azure SSO hasn't completed yet.");

            // This is async and might not return in the case of redirect...
            await this.authService.doLogin(targetUrl);

            console.log("Returning false1");
            return false;
        }

        if (this.authService.isLoggedIn()) {
            console.log("isLoggedIn=true");
            return true;
        }

        console.warn("Not logged in, opening login to Salesforce...");

        await this.authService.doLogin(targetUrl);

        console.log("Returning false2");
        return false;
    }

    public async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
        console.log("canActivate:", next, state);

        return this.checkAuth(next, state);
    }

    public async canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
        console.log("canActivateChild:", next, state);

        return this.checkAuth(next, state);

    }

    private getTargetUrl(destUrl: string): string {
        console.log(`getTargetUrl: ${destUrl}`);
        let targetUrl = destUrl;
        if (destUrl) {
            // If the user isn't logged in, then after they do log in, they need to be sent back to the beginning.
            // It should re-load from database the latest state (even for client once we hook that up to store session as we move through).
            if (destUrl.startsWith("/admin")) {
                targetUrl = "/admin";
            } else {
                targetUrl = "/search";
            }
        }
        console.log(`targetUrl=${targetUrl}`);
        return targetUrl;
    }
}
