<div class="carousel-container">
  <div class="carousel">
    <div class="slide" *ngFor="let image of images; let i = index" [ngClass]="{'active': currentIndex === i}">
      <div class="image-container">
		<img [src]="image.url" [alt]="image.title">
		<button *ngIf="canExpand" class="spot-button expand-button" (click)="expand()" [title]="'carousel.expand' | translate">
		  <svg class="spot-icon" aria-labelledby="title">
			<title>{{'carousel.expand' | translate}}</title>
			<use xlink:href="/assets/spot-library/dist/symbols/spot_symbols.svg#fullscreen"></use>
		  </svg>
		</button>
		<button class="prev-button" (click)="prevSlide()"><i class="fas fa-chevron-left"></i></button>
        <button class="next-button" (click)="nextSlide()"><i class="fas fa-chevron-right"></i></button>
	  </div>
      <div class="caption">{{ image.title }}</div>
    </div>
  </div>

  <div class="progress-indicators">
    <span class="indicator" *ngFor="let image of images; let i = index" [ngClass]="{'active': currentIndex === i}" (click)="goToSlide(i)"></span>
  </div>
</div>
