<span style="color:red;" class="locked-enroll">

  <ng-template #enrollmentLocked>
    <div class="locked-enroll-msg" [innerHTML]="message"></div>
  </ng-template>

  <span
	class="locked-enroll"
	[ngbTooltip]="enrollmentLocked"
	tooltipClass="locked-enroll-popup"
	placement="auto"
	[closeDelay]="3000"
	>
	<svg
      class="spot-icon locked-enroll-icon"
      aria-labelledby="title"
      >
      <use xlink:href="/assets/spot-library/dist/symbols/spot_symbols.svg#info-2"></use>
	</svg>
	{{ 'locked-enrollment.details' | translate }}
  </span>
</span>
