import { Component, Input, Output, EventEmitter } from "@angular/core";
import { ISearch } from "@shared/model/service/recent-searches-service";

@Component({
    selector: "pcc-recent-searches",
    templateUrl: "./recent-searches.component.html",
    styleUrls: [
        "./recent-searches.component.scss"
    ]
})
export class RecentSearchesComponent {
    @Input() public recentSearches: ISearch[];

    @Output() public selected = new EventEmitter<ISearch>();

    public searchSelected(search: ISearch): void {
        this.selected.emit(search);
    }
}
