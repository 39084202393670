<div class="view-body">
  <div class="view-title spot-typography__heading--level-2">
    {{ customPrefix + '.selectPanelInstruction' | customTranslate: { fallbackKey: 'profiles.title' } }}
  </div>
  <div class="profiles-content">
	<pcc-locked-enrollment-message *ngIf="lockedEnrollment" [message]="lockedMsg"></pcc-locked-enrollment-message>
	<pcc-profiles-tab-panel
	  [profiles]="profileCards"
	  [flexProfiles]="flexProfileCards"
	  [canSelect]="true"
	  (slotClick)='cardClicked($event)'
	  (editPriceClick)='editPrice($event)'
	  (deleteClick)='deleteClicked($event)'
	  (resetClick)='resetClicked($event)'
	  (selected)="profileSelected($event)"
	  >
	</pcc-profiles-tab-panel>
  </div>
</div>
