import { Component, Input } from "@angular/core";

import { ISeismicLink, SelectableSeismicLink } from "@shared/model/seismic";

@Component({
    selector: "pcc-seismic-docs-panel",
    templateUrl: "./seismic-docs-panel.component.html",
    styleUrls: [
        "./seismic-docs-panel.component.scss"
    ]
})
export class SeismicDocsPanelComponent {
    @Input() public canSelect = false;

    @Input() public loading = false;

    @Input() public docs: SelectableSeismicLink[] = [];

    @Input() public selectedDocs: ISeismicLink[] = [];

}
