import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { filter } from "rxjs/operators";
import { NavigationEnd, Router } from "@angular/router";
import { ConfirmationComponent } from "./../client/components/confirmation/confirmation.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

import { AccountSettings } from "@shared/model/account-settings";
import { PCCSession } from "@shared/model/pcc-session";
import { IApp } from "@shared/model/app";
import { PCCSessionService } from "./pcc-session.service";
import { AppFacade } from "../facade/app.facade";
import { IProfileTemplate } from "@shared/model/profile-template";
import googleAnalytics from "../analytics/googleAnalytics";
import { CustomPageTypes } from "@shared/model/custom-page";

export interface IProgressItem {
    name?: string;
    link: string;
    enabled: boolean;
    action?: string;
    hideProgress?: boolean;
    onNext?: () => Promise<boolean>;
}

export interface IPCCNavEvent {
    name: string;
    states?: IProgressItem[],
    currentState?: IProgressItem,
    nextState?: IProgressItem,
    prevState?: IProgressItem
}

const LANDING_LINK = "/landing";

const STATES: Record<string, IProgressItem> = {
    PROFILES: {
        name: "navigation.Profile_creation", link: "/profiles", enabled: false
    },
    PET_OWNER_PRICING: {
        name: "navigation.pet-owner-pricing", link: "/pet-owner-pricing", enabled: false
    },
    GOALS: {
        name: "navigation.Goal_setting", link: "/goals", enabled: false
    },
    ENROLL: {
        name: "navigation.Enrollment",
        link: "/enroll",
        enabled: false
    },
    DONE: {
        name: "navigation.Done",
        link: "/done",
        enabled: false,
        hideProgress: true
    },
    LAUNCH: {
        link: "/landing",
        enabled: false,
        hideProgress: true
    },
    SESSION_CONFIRM: {
        link: "/session-confirm",
        enabled: false,
        hideProgress: true
    }
};

@Injectable()
export class NavService {

    public routePath: string[];

    public currentState: IProgressItem;

    public states: IProgressItem[] = [];

    public stateProvider: BehaviorSubject<IPCCNavEvent>;

    public prevState: IProgressItem;

    public nextState: IProgressItem;

    // Used to hold slot between pages...
    public activeSlot: IProfileTemplate;

    public session: PCCSession;

    public landingLink: string;

    public constructor(
        private router: Router,
        private appFacade: AppFacade,
        private modalService: NgbModal,
        private sessionService: PCCSessionService
    ) {

        const defaultState: IPCCNavEvent = {
            name: "session",
            states: this.states,
            currentState: this.currentState
        };

        this.stateProvider = new BehaviorSubject(defaultState);

        this.appFacade.getCurrentSession().subscribe(
            (session): void => {
                this.setSession(session);
            }
        );

        this.appFacade.getLocaleSubject().subscribe(
            (locale: string): void => {
                this.setLocale(locale);
            }
        );

        // Listen for page changes.  Update the progress bar accordingly.
        // Also listen for enabling/disabling a page link
        router.events.pipe(
            filter((e): boolean => {
                return e instanceof NavigationEnd;
            })
        ).subscribe((val: NavigationEnd): void => {
            this.enableState(val.url);
            googleAnalytics.registerPageView(val.urlAfterRedirects);
        });

    }

    /**
     * Maintain progress bar model.
     * Should probably move this and some of the component logic into a separate
     * thing, since this is UI logic, not just data.
     */
    public enableState(url: string): void {
        const refState = this.states.find((s): boolean => (
            url === s.link
        ));

        if (refState) {
            this.currentState = refState;
            refState.enabled = true;

            const navEvent: IPCCNavEvent = {
                name: "nav",
                states: this.states,
                currentState: this.currentState
            };

            this.stateProvider.next(navEvent);

        }
    }

    public setSession(session: PCCSession): void {
        this.session = session;

        this.states = this.getAccountStates(true);

        this.landingLink = this.getLandingLink();

        this.currentState = this.states && this.states.length > 0 ? this.states[0] : null;

        this.states.forEach((s: IProgressItem): void => {
            s.enabled = false;
        });

        if (this.currentState) {
            this.currentState.enabled = true;
        }

        const allStates = this.getAccountStates(false);
        this.routePath = allStates.map((s: IProgressItem): string => s.link);

        const navEvent: IPCCNavEvent = {
            name: "session",
            states: this.states,
            currentState: this.currentState
        };

        this.stateProvider.next(navEvent);
    }

    private setLocale(locale: string): void {
        console.log("setLocale: ", locale);

        // When locale changes, re-check if landing page is enabled based on if custom pages exist for it.
        this.getLandingLink();
    }

    public getNavState(): BehaviorSubject<IPCCNavEvent> {
        return this.stateProvider;
    }

    public showAdmin(): void {
        this.sessionService.clearSession();
        this.router.navigate([
            "/admin"
        ]);
    }

    public showClient(): void {
        this.router.navigate([
            "/search"
        ]);
    }

    public confirmGoHome(): void {
        const ref = this.modalService.open(ConfirmationComponent, {
            size: "xl",
            windowClass: "confirmation-leaving"
        });
        ref.componentInstance.title = "navigation.Leaving_page_title";
        ref.componentInstance.body = "navigation.Leaving_page_body";
        ref.result.then(({ value }): void => {
            if (value !== "yes") {
                return;
            }
            this.appFacade.clearSession();
            this.router.navigate([
                "/search"
            ]);
        });

    }

    public goHome(): void {
        this.router.navigate([
            "/search"
        ]);
        this.appFacade.clearSession();
    }

    public goApp(app: IApp): void {
        const ref = this.modalService.open(ConfirmationComponent, {
            size: "xl",
            windowClass: "confirmation-leaving"
        });
        ref.componentInstance.title = "navigation.Leaving_page_title";
        ref.componentInstance.body = "navigation.Leaving_page_body";
        ref.result.then(({ value }): void => {
            if (value !== "yes") {
                return;
            }
            this.router.navigateByUrl(app.url);
        });

    }

    public async goNext(nextPage: IProgressItem): Promise<void> {
        if (!nextPage) {
            return;
        }

        if (nextPage.onNext) {
            await nextPage.onNext();
        }

        if (nextPage.action) {
            const success = this.appFacade.performAction(nextPage.action);
            if (!success) {
                console.error("action failed");
                return;
            }
        }

        const nextUrl = nextPage.link;
        if (nextUrl) {
            const accountPrefix = this.getAccountPrefix();
            this.router.navigate([
                accountPrefix,
                nextUrl
            ]);
        }
    }

    // Based on selected account, return /account/:sapId/
    public getAccountPrefix(): string {
        let prefix = "";
        if (this.session && this.session.accountInfo) {
            const sapId = this.session.accountInfo.sap_id;
            prefix = `/account/${sapId}`;
        }
        return prefix;
    }

    /**
     * Get array of IProgressItem that have correct account prefix and handle different nav for corporate.
     */
    public getAccountStates(forNav: boolean): IProgressItem[] {
        const accountPrefix = this.getAccountPrefix();
        const states: IProgressItem[] = [];

        Object.values(STATES).forEach((state: IProgressItem): void => {
            if (state === STATES.GOALS && !this.session.accountSettings?.flags.page_enabled_goals) {
                return;
            }
            if (state === STATES.PET_OWNER_PRICING && !this.session.accountSettings?.flags.page_enabled_pet_owner_pricing) {
                return;
            }
            if (forNav && state === STATES.DONE) {
                return;
            }
            // Add account prefix
            const aState = this.getStateForAccount(state, accountPrefix);
            states.push(aState);
        });

        return states;
    }

    private getLandingLink(): string {
        if (!this.hasLandingLink()) {
            return null;
        }

        const accountPrefix = this.getAccountPrefix();

        return accountPrefix + LANDING_LINK;
    }

    private hasLandingLink(): boolean {
        if (this.session?.accountSettings?.customPages) {
            return AccountSettings.getCustomPages(this.session.accountSettings, CustomPageTypes.LAUNCH).length > 0;
        }
        return false;
    }

    public getStateForAccount(pi: IProgressItem, accountPrefix: string): IProgressItem {
        const item = JSON.parse(JSON.stringify(pi));
        item.link = accountPrefix + item.link;
        return item;
    }

    public goPrev(prevPage: IProgressItem): void {
        if (!prevPage) {
            return;
        }

        const prevUrl = prevPage.link;
        if (prevUrl) {
            const accountPrefix = this.getAccountPrefix();
            this.router.navigate([
                accountPrefix,
                prevUrl
            ]);
        }
    }

    public setNextState(nextState: IProgressItem): void {
        this.nextState = nextState;
        const navEvent: IPCCNavEvent = {
            name: "nextState",
            nextState: this.nextState
        };

        this.stateProvider.next(navEvent);
    }

    public setCurrentState(state: IProgressItem): void {
        this.currentState = state;
        const navEvent: IPCCNavEvent = {
            name: "currentState",
            currentState: this.currentState
        };

        this.stateProvider.next(navEvent);
    }
}
