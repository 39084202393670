import { Component, OnInit, OnDestroy } from "@angular/core";

import { Subscription } from "rxjs";

import { NavService, IPCCNavEvent, IProgressItem } from "../../../service/nav.service";

@Component({
    selector: "pcc-client-footer",
    templateUrl: "./client-footer.component.html",
    styleUrls: [
        "./client-footer.component.scss"
    ]
})
export class ClientFooterComponent implements OnInit, OnDestroy {

    public nextPage: IProgressItem;

    public prevPage: IProgressItem;

    private navStateSubscription: Subscription;

    public constructor(
        public navService: NavService
    ) {
        console.log("client-footer constructor");
    }

    public setNavInfo(navEvent: IPCCNavEvent): void {
        console.log("setNavInfo: ", navEvent);

        if (!navEvent) {
            return;
        }
        if (navEvent.name === "nextState") {
            this.setNextPage(navEvent.nextState);
        } else if (navEvent.name === "prevState") {
            this.setPrevPage(navEvent.prevState);
            this.prevPage = navEvent.prevState;
        } else {
            console.log("Ignoring state change");
        }
    }

    public setNextPage(np: IProgressItem): void {
        console.log("setNextPage: ", np);
        this.nextPage = np;
    }

    public setPrevPage(np: IProgressItem): void {
        console.log("setPrevPage: ", np);
        this.prevPage = np;
    }

    public ngOnInit(): void {
        this.navStateSubscription = this.navService.getNavState().subscribe(
            (navEvent: IPCCNavEvent): void => {
                this.setNavInfo(navEvent);
            }
        );
    }

    public ngOnDestroy(): void {
        this.navStateSubscription.unsubscribe();
    }

    // NOTE: Generally, next button is just for navigation.
    // But on the final page, it's instead used to "generate pdf of enrollment and send enrollment email".
    // That logic shouldn't be implemented here in UI component, but instead should be delegated to the business facade.

    public nextButtonClicked(): void {
        console.log("nextButtonClicked: ", this.nextPage);

        this.navService.goNext(this.nextPage);

    }

    public prevButtonClicked(): void {
        console.log("prevButtonClicked: ", this.prevPage);

        this.navService.goPrev(this.prevPage);
    }
}
