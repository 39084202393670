import { Component } from "@angular/core";

import { DomSanitizer, SafeHtml, SafeUrl } from "@angular/platform-browser";

import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

import { PCCSession } from "@shared/model/pcc-session";
import { IAccount } from "@shared/model/account";

@Component({
    selector: "pcc-account-info",
    templateUrl: "./account-info.component.html",
    styleUrls: [
        "./account-info.component.scss"
    ]
})
export class AccountInfoComponent {

    public accountInfo: IAccount;

    public logoUrl: SafeUrl;

    public safeSpecialInstructions: SafeHtml;

    public constructor(
        public activeModal: NgbActiveModal,
        private sanitizer: DomSanitizer
    ) {
    }

    public setSession(session: PCCSession): void {
        this.accountInfo = session.accountInfo;
        this.logoUrl = this.parseLogo(session.accountSettings.logo);
        const specialInstructions = session.accountSettings.special_instructions || "";
        this.safeSpecialInstructions = this.sanitizer.bypassSecurityTrustHtml(specialInstructions);
    }

    // Parse image Buffer into render-able image data.
    private parseLogo(logoBuffer: any): SafeUrl {
        if (logoBuffer) {
            try {
                const typedArray = new Uint8Array(logoBuffer.data);
                const stringChar = String.fromCharCode.apply(null, typedArray);
                const base64String = btoa(stringChar);
                return this.sanitizer.bypassSecurityTrustUrl(`data:image/jpg;base64, ${base64String}`);
            } catch (err) {
                console.error("Error parsing logo image: ", err);
            }
        }
        return null;
    }
}
