const TAP_MOVE_THRESHOLD = 10; //in pixels

import { InteractionType } from "@azure/msal-browser";

export class NativeUtil {
    public static download({ base64data, filename, element, contentType }: { base64data: string; filename: string; element: HTMLElement; contentType: string }): Promise<boolean> {
        return new Promise<boolean>((resolve, reject): void => {
            if (window["plugins"] && window["plugins"].socialsharing) {
                const data = `data:${contentType};df:${filename};base64,${base64data}`;
                const shareRect = element.getBoundingClientRect();
                window["plugins"].socialsharing.shareWithOptions({
                    message: "",
                    subject: "Enrollment",
                    files: [
                        data
                    ],
                    iPadCoordinates: `${shareRect.x},${shareRect.y},${shareRect.width},${shareRect.height}`
                }, (): void => resolve(true), (): void => reject("error saving file"));
            } else {
                const data = `data:${contentType};base64,${base64data}`,
                    link = document.createElement("a");

                link.href = data;
                link.download = filename;
                link.click();
                resolve(true);
            }
        });
    }

    public static lockOrientation({ orientation }: { orientation: "landscape" | "portrait" }): void {
        if (window.screen.orientation && window.screen.orientation.lock) {
            window.screen.orientation
                .lock(orientation)
                .catch((err): void => {
                    console.error("Error locking screen orientation: ", err);
                });
        }
    }

    public static scrollToElementOnKeyboardShow(): void {
        window.addEventListener("keyboardDidShow", (): void => {
            if (document.body.classList) {
                document.body.classList.add("keyboard-open");
            } else {
                document.body.className = "keyboard-open";
            }
            if (document.activeElement) {
                if (document.activeElement["scrollIntoViewIfNeeded"]) {
                    document.activeElement["scrollIntoViewIfNeeded"]();
                } else {
                    document.activeElement.scrollIntoView();
                }
            }
        });
        window.addEventListener("keyboardDidHide", (): void => {
            if (document.body.classList) {
                document.body.classList.remove("keyboard-open");
            }
        });

        const touchMap: { [key: number]: Touch } & { length: number } = {
            length: 0
        };
        let forfeitTouches = false;


        window.addEventListener("touchstart", (e): void => {
            const touches = e.changedTouches;
            for (let i = 0, ln = touches.length; i < ln; i += 1) {
                const touch = touches.item(i);
                touchMap[touch.identifier] = touch;
                touchMap.length++;
            }
            if (touchMap.length > 1) {
                forfeitTouches = true;
            }
        });
        window.addEventListener("touchmove", (e): void => {
            const touches = e.changedTouches;
            for (let i = 0, ln = touches.length; i < ln; i += 1) {
                const changedTouch = touches.item(i);
                let touch: Touch;
                if (touch = touchMap[changedTouch.identifier]) {
                    if ((Math.sqrt((changedTouch.pageX - touch.pageX) ** 2 + (changedTouch.pageY - touch.pageY) ** 2)) > TAP_MOVE_THRESHOLD) {
                        forfeitTouches = true;
                    }
                }
            }
        });
        window.addEventListener("touchcancel", (e): void => {
            const touches = e.changedTouches;
            for (let i = 0, ln = touches.length; i < ln; i += 1) {
                const touch = touches.item(i);
                if (touchMap[touch.identifier]) {
                    delete touchMap[touch.identifier];
                    forfeitTouches = true;
                    touchMap.length--;
                }
            }
            if (touchMap.length <= 0) {
                forfeitTouches = false;
            }
        });
        window.addEventListener("touchend", (e): void => {
            const touches = e.changedTouches;
            for (let i = 0, ln = touches.length; i < ln; i += 1) {
                const touch = touches.item(i);
                if (touchMap[touch.identifier]) {
                    delete touchMap[touch.identifier];
                    touchMap.length--;
                }
            }
            if (touchMap.length <= 0) {
                if (!forfeitTouches) {
                    if (document.activeElement instanceof HTMLInputElement || document.activeElement.getAttribute("contenteditable")) {
                        (document.activeElement as HTMLElement).blur();
                    }
                }
                forfeitTouches = false;
            }
        });
    }

    public static isMobile(): boolean {
        let retVal = false;
        // iPads now request desktop sites by faking their user agent. Check for max touch points as well.
        if ((navigator.maxTouchPoints > 1) || (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))) {
            retVal = true;
        }

        console.log("isMobile: ", navigator.userAgent, retVal);

        return retVal;
    }

    public static getInteractionType(): InteractionType.Popup | InteractionType.Redirect {
        //return InteractionType.Popup;//Redirect;

        const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

        const isIpad = this.isMobile();

        return (isIE || isIpad) ? InteractionType.Redirect : InteractionType.Popup;
    }
}
