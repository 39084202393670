import { Injectable } from "@angular/core";
import { Test } from "./../utils/test";
import { HttpRequest, HttpHandler, HttpInterceptor, HttpErrorResponse, HttpEvent } from "@angular/common/http";
import { AuthService } from "./auth.service";
import { BehaviorSubject, Observable, throwError } from "rxjs";
import { catchError, switchMap, filter, take, tap } from "rxjs/operators";
import { VersionService } from "../service/version.service";
import googleAnalytics from "../analytics/googleAnalytics";

const FORBIDDEN = 403;
const UNAUTHORIZED = 401;
@Injectable({
    providedIn: "root"
})
export class AuthInterceptor implements HttpInterceptor {

    isRefreshing: boolean = false;

    refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    constructor(
        private authService: AuthService,
        private versionService: VersionService
    ) {}

    errorObserver(val: HttpEvent<any> | any, request: HttpRequest<any>) {
        if (val && val.body && val.body.error) {
            googleAnalytics.registerEvent("service_error", "response_error", {
                response: JSON.stringify(val.body),
                request: JSON.stringify(request),
                error: val.body.error
            });
        }
    }

    authErrorHandler(err, request: HttpRequest<any>, next: HttpHandler): any {
        console.log("Auth Error here: ", err);
        if (err instanceof HttpErrorResponse) {
            switch ((<HttpErrorResponse>err).status) {
                case UNAUTHORIZED:
                    return this.handle401Error(request, next);
                case FORBIDDEN:
                    return <any>this.authService.showLogin();
                default:
                    return throwError(err);
            }
        } else {
            return throwError(err);
        }
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
        // Piggy-backing on the auth interceptor to inform the version check service
        // that the user is actively using the app (by the fact that service calls are being made).
        this.versionService.httpEvent(req);

        const jwtToken = this.authService.getToken();

        Test._serviceInject(req);

        if (jwtToken) {
            req = AuthInterceptor.addToken(req, jwtToken);

            return next.handle(req).pipe(
                tap((val: HttpEvent<any> | any): void => { this.errorObserver(val, req); }),
                catchError((err) => { return this.authErrorHandler(err, req, next); })
            );
        }
        return next.handle(req);
    }

    private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
        console.log("handle401Error");
        console.log(`this.isRefreshing: ${this.isRefreshing}`);
        if (!this.isRefreshing) {
            this.isRefreshing = true;
            this.refreshTokenSubject.next(null);

            // TODO: How to refresh azure token?
            // return this.authService.callRefreshToken(null).pipe(
            //     switchMap((resp: IAuthResp) => {
            //         console.log("After refresh token call: ", resp);
            //         this.isRefreshing = false;
            //         this.refreshTokenSubject.next(resp.authInfo.jwt_token);
            //         return next.handle(this.addToken(request, resp.authInfo.jwt_token));
            //     }));
        } else {
            return this.refreshTokenSubject
                .pipe(filter((token) => token != null),
                    take(1),
                    switchMap((token) => next.handle(AuthInterceptor.addToken(request, <string>token))));
        }
    }

    private static addToken(request: HttpRequest<any>, jwtToken: string) {
        request = request.clone({
            withCredentials: true,
            setHeaders: {
                "Authorization": `Bearer ${jwtToken}`
            }
        });
        return request;
    }
}
