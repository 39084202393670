export interface Country {
    code: string;
    name: string;
}

export const ManagedCountries: Record<string, string> = {
    "US": "US",
    "CA": "CA",
    "AU": "AU"
}
