<div class="spot-modal">
  <div class="spot-modal__header">
    <div class="spot-modal__titles">
      <div class="profile-name">
        {{profile?.display_name || ('edit-pricing-ca.Profile_Name_Here' | translate)}}
      </div>
      <div class="test-code">
        {{ 'edit-pricing-ca.Test_code' | translate}}: {{profile?.profile_test_code}}
      </div>
    </div>
    <button class="spot-modal__header-cancel-button" aria-label="Close" (click)="activeModal.dismiss('Close click')">
      <svg class="spot-icon spot-modal__header-cancel-button-icon" aria-labelledby="title">
        <title>cancel</title>
        <use xlink:href="/assets/spot-library/dist/symbols/spot_symbols.svg#cancel"></use>
      </svg>
    </button>
  </div>

  <div class="edit-pricing-ca-body" *ngIf="profile">
    <div class="edit-pricing-ca-testsbymodalityandcategory">
      <div class="edit-pricing-ca-modality" [ngClass]="modality.modality" *ngFor="let modality of selectedTestsByModalityAndCategory">
        <div class="edit-pricing-ca-group-wrapper">
          <div class="modality-header">
            <img [src]="MODALITY_ICONS[modality.modality]" class="modality-icon" alt="">
            <h3>{{'edit-pricing-ca.' + modality.modality | translate}}</h3>
          </div>
          <div class="edit-pricing-ca-group" *ngFor="let category of modality.categories">
            <span class="edit-pricing-ca-category">{{'category.' + category.category | translate}}</span>
            <span class="modality-tests">
              <pcc-test-pill *ngFor="let tc of category.tests;" [name]="tc.displayNameKey | translate"></pcc-test-pill>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="edit-pricing-ca-priceinfo">
      <div class="breakdown">
        <h2>
          {{ customPrefix + 'pricingDetail' | customTranslate:{ fallbackKey: 'edit-pricing-ca.pricing_detail'}  }}
        </h2>
        <div class="edit-pricing-ca-priceinfo-group" *ngIf="session?.accountSettings?.flags.show_list_price">
          <span class="edit-pricing-ca-priceinfo-label">
            {{ customPrefix + 'pricingListPrice'  | customTranslate: { fallbackKey: 'edit-pricing-ca.List_price' } }}
          </span>
          <span class="edit-pricing-ca-priceinfo-value">{{listPrice | currency : session?.currency : 'symbol-narrow' : '1.2-2' : session?.locale}}</span>
        </div>
        <div class="edit-pricing-ca-priceinfo-group" *ngIf="session?.accountSettings?.flags.show_practice_price">
          <span class="edit-pricing-ca-priceinfo-label">
            {{ customPrefix + 'pricingCurrentPrice'  | customTranslate: { fallbackKey: 'edit-pricing-ca.Current_price' } }}
          </span>
          <span class="edit-pricing-ca-priceinfo-value">{{customerPrice | currency : session?.currency : 'symbol-narrow' : '1.2-2' : session?.locale}}</span>
        </div>
        <div class="edit-pricing-ca-priceinfo-group" *ngIf="session?.accountSettings?.flags.show_recommended_price">
          <span class="edit-pricing-ca-priceinfo-label">
            {{ customPrefix + 'pricingRecommendedPrice'  | customTranslate: { fallbackKey: 'edit-pricing-ca.Recommended_price' } }}
          </span>
          <span class="edit-pricing-ca-priceinfo-value">{{recommendedPrice | currency : session?.currency : 'symbol-narrow' : '1.2-2' : session?.locale}}</span>
        </div>
        <div class="edit-pricing-ca-priceinfo-group" *ngIf="session?.accountSettings?.flags.show_floor_price">
          <span class="edit-pricing-ca-priceinfo-label">
            {{ customPrefix + 'pricingFloorPrice'  | customTranslate: { fallbackKey: 'edit-pricing-ca.Floor_price' } }}
          </span>
          <span class="edit-pricing-ca-priceinfo-value">{{floorPrice | currency : session?.currency : 'symbol-narrow' : '1.2-2' : session?.locale}}</span>
        </div>
        <div class="edit-pricing-ca-priceinfo-group special">
          <span class="edit-pricing-ca-priceinfo-label special">
            {{ customPrefix + 'pricingSpecialPrice' | customTranslate: { fallbackKey: 'edit-pricing-ca.Special_price' } }}
          </span>
          <span class="edit-pricing-ca-priceinfo-value special">
            <input #specialPrice name="money" type="text" currencyMask [options]="session?.currencyMaskOptions" [ngbPopover]="priceRangeErrorMsg" triggers="manual" [ngModel]="price" (ngModelChange)="onPriceChange($event)" />
          </span>
        </div>
        <div class="edit-pricing-ca-price-info-group snap" *ngIf="hasInhousePricing">
          <div class="edit-pricing-ca-priceinfo-group in-house">
            <span class="edit-pricing-ca-priceinfo-label">
              {{ customPrefix + 'pricingInHousePrice'  | customTranslate: { fallbackKey: 'edit-pricing-ca.In_house_price' } }}
            </span>
            <span class="edit-pricing-ca-priceinfo-value">{{inhousePrice | currency : session?.currency : 'symbol-narrow' : '1.2-2' : session?.locale}}</span>
          </div>
        </div>
      </div>
      <div class="panel-total">
        <span class="label">
          {{ customPrefix + 'pricingPanelPrice'  | customTranslate: { fallbackKey: 'edit-pricing-ca.Panel_price' } }}
        </span>
        <span class="value">{{(price + inhousePrice) | currency : session?.currency : 'symbol-narrow' : '1.2-2' : session?.locale}}</span>
      </div>
    </div>
  </div>
  <div class="spot-modal__footer">
    <button class="spot-modal__footer-cancel-button" (click)="cancelClicked()" [disabled]="saving || showPricingError">{{'edit-pricing-ca.cancel' | translate}}</button>
    <button class="spot-button spot-button--primary spot-button--large" (click)="onUpdateProfile()" [disabled]="saving || showPricingError">{{'edit-pricing-ca.Update_profile' | translate}}</button>
  </div>
</div>
