import { Component, Input } from "@angular/core";

@Component({
    selector: "pcc-locked-enrollment-message",
    templateUrl: "./locked-enrollment-message.component.html",
    styleUrls: [
        "./locked-enrollment-message.component.scss"
    ]
})
export class LockedEnrollmentMessageComponent {

    @Input() public message: string;

}
