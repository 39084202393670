import { Component, Input } from "@angular/core";

/**
 * A "local" loading indicator that can be just shown over a container rather than the entire app.
 * Intended to be used to lock a single form or modal.
 *
 * When using, make sure the parent container has position: relative defined.
 */
@Component({
    selector: "pcc-loading",
    templateUrl: "./loading.component.html",
    styleUrls: [
        "./loading.component.scss"
    ]
})
export class LoadingComponent {
    @Input() public isBusy: boolean = true;

    @Input() public busyMsg: string;
}

