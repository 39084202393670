import { Component, OnInit } from "@angular/core";
import { VersionService } from "./service/version.service";

@Component({
    selector: "pcc-root",
    templateUrl: "./app.component.html",
    styleUrls: [
        "./app.component.scss"
    ]
})
export class AppComponent implements OnInit {
    public title = "IDEXX Preventive Care Simple Start";

    public isIframe = false;

    public version: string;

    public updateRequired = false;

    public constructor(
        private versionService: VersionService
    ) {
    }

    public async ngOnInit(): Promise<void> {
        this.isIframe = window !== window.parent && !window.opener;

        this.versionService.version.subscribe((version: string) => {
            if (this.version === undefined || this.version === null) {
                this.version = version;
            }
            if (this.version !== version) {
                console.log("applicationUpdated, suggesting reload");
                this.version = version;
                this.updateRequired = true;
            }
        });
        console.log("End of App.ngOnInit");
    }

    public onUpdateClick(): void {
        window.location.reload();
    }
}
