<div class="spot-button-wrapper" style="width:100%;">
  <button class="spot-button spot-button--secondary account-nav-button spot-button--dropdown-toggle">
    <span class="spot-button__text"> Menu </span>
    <svg class="spot-icon spot-button__icon spot-button__icon--right">
      <use xlink:href="/assets/spot-library/dist/symbols/spot_symbols.svg#caret-down"></use>
    </svg>
  </button>
  <div class="spot-dropdown account-nav-dropdown">
    <ul class="spot-dropdown__content account-nav-content">
      <li class="spot-dropdown__content-item account-nav-item" *ngFor="let navItem of navItems">
        <a [routerLink]="[navItem.url]" (click)="navClicked(navItem)" class="spot-list-group__link" [ngClass]="activeItem === navItem.key ? ' spot-list-group__item--active' : ''">{{navItem.displayName}}</a>
        <div *ngIf="navItem.desc" class="nav-item-description">{{navItem.desc}}</div>
      </li>
    </ul>
  </div>
</div>
