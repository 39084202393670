import { Component, Input } from "@angular/core";

import { ISeismicLink, SelectableSeismicLink } from "@shared/model/seismic";

import { AppFacade } from "../../../facade/app.facade";

import { PCCAlertService } from "../../../service/alert.service";
import googleAnalytics from "../../../analytics/googleAnalytics";

@Component({
    selector: "pcc-seismic-thumbnail",
    templateUrl: "./seismic-thumbnail.component.html",
    styleUrls: ["./seismic-thumbnail.component.scss"]
})
export class SeismicThumbnailComponent {

    @Input() public link: SelectableSeismicLink;

    @Input() public canSelect = false;

    public constructor(
        public appFacade: AppFacade,
        private alertService: PCCAlertService
    ) {
    }

    public async openSeismicDoc(link: ISeismicLink): Promise<void> {
        console.log("openSeismicDoc: ", link);

        try {
            await this.appFacade.openSeismicDoc(link);
        } catch (err) {
            console.error("Error: ", err);
            this.alertService.showError(this.appFacade.translate("seismic-docs-panel.error_title"),
                this.appFacade.translate("seismic-docs-panel.seismic_doc_failed_open"));
        }
    }

    public selected(link: ISeismicLink, $event): void {
        googleAnalytics.registerEvent("seismic_doc_selected", "click", {
            seismic_id: link.contentId,
            is_selected: $event.target.value,
            description: link.desc
        });
    }
}
