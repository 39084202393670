import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { QuillModule } from "ngx-quill";
import { FormsModule } from "@angular/forms";
import { SlotComponent } from "./slot/slot.component";
import { MaterialDesignModule } from "../../material-design/material-design.module";
import { NgbModule, NgbPopoverModule } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";
import { NgxExtendedPdfViewerModule } from "ngx-extended-pdf-viewer";

import { EnvHeaderComponent } from "./components/env-header/env-header.component";
import { RangeSliderComponent } from "./components/range-slider/range-slider.component";
import { IconComponent } from "./components/icon/icon.component";
import { PopoverComponent } from "./components/popover/popover.component";
import { ConfirmActionComponent } from "./components/confirm-action/confirm-action.component";
import { DocWrapperComponent } from "./components/doc-wrapper/doc-wrapper.component";
import { AccordionDragDropListComponent } from "./components/accordion-drag-drop-list/accordion-drag-drop-list.component";
import { BusyComponent } from "./components/busy/busy.component";
import { OverlayComponent } from "./components/overlay/overlay.component";
import { TestPillComponent } from "./components/test-pill/test-pill.component";
import { CalendarWidgetComponent } from "./components/calendar-widget/calendar-widget.component";
import { NgxCurrencyModule } from "ngx-currency";
import { LoadingComponent } from "./components/loading/loading.component";
import { RichTextComponent } from "./components/rich-text/rich-text.component";
import { CheckboxComponent } from "./components/checkbox/checkbox.component";
import { EditTextComponent } from "./components/edit-text/edit-text.component";
import { EditTextLocaleComponent } from './components/edit-text-locale/edit-text-locale.component';
import { EnrollPetCareHeroesComponent } from '../client/components/enroll-pet-care-heroes/enroll-pet-care-heroes.component';

import { CustomTranslatePipe } from "./pipes/custom-translate.pipe";

@NgModule({
    declarations: [
        SlotComponent,
        EnvHeaderComponent,
        RangeSliderComponent,
        IconComponent,
        PopoverComponent,
        ConfirmActionComponent,
        DocWrapperComponent,
        AccordionDragDropListComponent,
        BusyComponent,
        OverlayComponent,
        TestPillComponent,
        CalendarWidgetComponent,
        LoadingComponent,
        RichTextComponent,
        CheckboxComponent,
        EditTextComponent,
        EditTextLocaleComponent,
        CustomTranslatePipe,
        EnrollPetCareHeroesComponent
    ],
    imports: [
        NgbModule,
        NgbPopoverModule,
        QuillModule.forRoot(),
        TranslateModule,
        FormsModule,
        CommonModule,
        MaterialDesignModule,
        NgxExtendedPdfViewerModule,
        NgxCurrencyModule
    ],
    exports: [
        NgbModule,
        NgbPopoverModule,
        SlotComponent,
        EnvHeaderComponent,
        RangeSliderComponent,
        IconComponent,
        PopoverComponent,
        ConfirmActionComponent,
        DocWrapperComponent,
        NgxExtendedPdfViewerModule,
        AccordionDragDropListComponent,
        BusyComponent,
        OverlayComponent,
        TestPillComponent,
        CalendarWidgetComponent,
        LoadingComponent,
        RichTextComponent,
        CheckboxComponent,
        EditTextComponent,
        EditTextLocaleComponent,
        CustomTranslatePipe,
        EnrollPetCareHeroesComponent
    ]
})
export class SharedModule {}
