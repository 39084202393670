import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";

import { Sort } from "@angular/material/sort";

import { UtilService } from "@shared/service/util.service";
import { AdminFacade } from "../../../facade/admin.facade";
import { IProduct } from "@shared/model/product";
import { IProfileItem, PROFILE_ITEM_TYPES, ItemUtils } from "@shared/model/profile-item";
import { ModalityEnum, IModality } from "@shared/model/modality";
import { ISystemSettings } from "@shared/model/system-settings";
import { IAdminSettingsResponse } from "@shared/model/service/admin-service";
import { PCCAlertService } from "../../../service/alert.service";
import { IAccountResponse } from "@shared/model/account-response";
import { IAccount } from "@shared/model/account";
import { IAccountSettings } from "@shared/model/account-settings";

import _ from "lodash";

interface CategoryProfileItems {
    category: string,
    items: IProfileItem[];
}

@Component({
    selector: "pcc-profile-items",
    templateUrl: "./profile-items.component.html",
    styleUrls: [
        "./profile-items.component.scss"
    ]
})
export class ProfileItemsComponent implements OnInit {
    public accountSettings: IAccountSettings;

    public account: IAccount;

    public profileItems: IProfileItem[] = [];

    public groupedProfileItems: CategoryProfileItems[] = [];

    public filteredProfileItems: IProfileItem[] = [];

    public sortedData: IProfileItem[] = [];

    public selectedTest: IProfileItem;

    public modalities: IModality[];

    public refLabModality: IModality;

    public systemSettings: ISystemSettings;

    private lastSort: Sort;

    public isLoading = false;

    public loadingMessage: string = "Loading...";

    public sortBy: string = "category";

    public searchText: string;

    public constructor(
        public router: Router,
        public route: ActivatedRoute,
        private adminFacade: AdminFacade,
        private alertService: PCCAlertService
    ) {}

    public async ngOnInit(): Promise<void> {
        this.setBusy(true, "Loading...");
        try {
            // Pull fresh version of system settings
            this.systemSettings = await this.adminFacade.getSystemSettings();
            console.log("systemSettings=", this.systemSettings);

            this.route.parent.data.subscribe((data): void => {
                console.log("ProfileItems Account resp data here: ", data);
                if (data.accountData) {
                    const acctResp: IAccountResponse = data.accountData;
                    if (acctResp.success === true) {
                        this.setAccountData(acctResp.account, acctResp.accountSettings);
                    }
                }
            });

            this.profileItems = this.systemSettings.profileItems;

            this.modalities = this.systemSettings.modalities;

            this.sortedData = this.profileItems.slice();

            this.sortItems();

            if (this.modalities) {
                this.refLabModality = this.modalities.find((m: IModality): boolean =>
                    ModalityEnum.REF_LAB.equals(m.developer_name)
                );
            }
        } finally {
            this.setBusy(false);
        }
    }

    public addTest(): void {
        console.log("addTest");
        this.setSelectedTest(
            ItemUtils.createProfileItem(this.accountSettings.account_settings_id, this.accountSettings.countryCd, this.refLabModality, PROFILE_ITEM_TYPES.STANDARD)
        );
    }

    public setSelectedTest(test: IProfileItem): void {
        console.log("setSelectedTest: ", test);
        this.selectedTest = test;
    }

    public async existingTestClicked(test: IProfileItem): Promise<void> {
        try {
            this.setBusy(true, "Loading latest data for profile item...");
            const resp: IAdminSettingsResponse = await this.adminFacade.getProfileItem(test.profile_item_id);
            console.log("getProfileItem resp: ", resp);
            if (resp && resp.profileItem) {
                this.setSelectedTest(resp.profileItem);
            } else {
                console.error("Error retrieving profile item: ", resp);
                this.setSelectedTest(test);
            }
        } catch (err) {
            console.error("Error retrieving profile item2: ", err);
            this.setSelectedTest(test);
        } finally {
            this.setBusy(false);
        }
    }

    public profileItemDeleted(profileItem: IProfileItem): void {
        this.alertService.showToast("Profile Item deleted.");
        this.refreshProfileItems();
    }

    public cancel(): void {
        console.log("cancel");
        this.setSelectedTest(null);

        this.refreshProfileItems();
    }

    public async profileItemSaved(profileItem: IProfileItem): Promise<void> {
        console.log("profileItemSaved", profileItem);

        if (profileItem) {
            // Save reference to selected so it won't get wiped out in refreshProfileItems.
            const selected = this.selectedTest;

            await this.refreshProfileItems();
            this.existingTestClicked(selected);
        }
    }

    public async refreshProfileItems(): Promise<boolean> {
        this.setSelectedTest(null);
        try {
            this.setBusy(true, "Refreshing profile items...");

            const resp = await this.adminFacade.getProfileItems();

            this.profileItems = resp && resp.profileItems ? resp.profileItems : [];
            this.filteredProfileItems = this.filterProfileItems(this.profileItems, this.searchText);

            this.groupedProfileItems = this.groupProfileItemsByCategory(this.filteredProfileItems);

            // Refresh the local system settings list of profile items so we don't need to pull
            // fresh system settings elsewhere that depends on this.
            this.systemSettings.profileItems = this.profileItems;

            this.resort();
            return true;
        } catch (err) {
            console.error("Error getting tests: ", err);
            return false;
        } finally {
            this.setBusy(false);
        }
    }

    public formatProducts(pList: IProduct[]): string {
        if (!pList) {
            return "";
        }
        const names: string[] = [];
        pList.forEach((p: IProduct): void => {
            names.push(`${p.display_name}(${p.test_code || p.sap_material_number})`);
        });

        return names.join(", ");
    }

    public sortItems(): void {
        this.profileItems = this.profileItems.sort(
            (a: IProfileItem, b: IProfileItem): number => {
                if (a.modality.modality_id === b.modality.modality_id) {
                    if (a.category.category_id === b.category.category_id) {
                        if (a.display_order === b.display_order) {
                            return 0;
                        }
                        return a.display_order - b.display_order;
                    }
                    // Price is only important when cities are the same
                    return b.category.display_order - a.category.display_order;
                }
                return a.modality.display_order > b.modality.display_order ? 1 : -1;
            });
    }

    public resort(): void {
        console.log("resort");
        if (this.lastSort) {
            this.sortData(this.lastSort);
        } else {
            this.sortData({
                active: "category", direction: "asc"
            });
        }
        console.log("lastSort not defined, not sorting...");
    }

    public sortData(sort: Sort): void {
        console.log("sortData: ", sort);
        const data = this.profileItems.slice();

        this.lastSort = sort;

        if (!sort.active || sort.direction === "") {
            this.sortedData = data;
            return;
        }

        this.sortedData = data.sort((a: IProfileItem, b: IProfileItem): number => {
            const isAsc = sort.direction === "asc";
            switch (sort.active) {
                case "id": return UtilService.compare(a.profile_item_id, b.profile_item_id, isAsc);
                case "name": return UtilService.compare(
                    this.adminFacade.translate(a.displayNameKey),
                    this.adminFacade.translate(b.displayNameKey),
                    isAsc);
                case "type": return UtilService.compare(a.test_type, b.test_type, isAsc);
                case "category": return UtilService.compare(a.category.displayName, b.category.displayName, isAsc);
                case "products": return UtilService.compare(_.map(a.products, "name").join(","), _.map(b.products).join(","), isAsc);
                case "modality": return UtilService.compare(a.modality.name, b.modality.name, isAsc);
                default: return 0;
            }
        });
    }

    public isSelected(tc: IProfileItem): boolean {
        let retVal = false;
        if (tc && this.selectedTest) {
            retVal = tc.profile_item_id === this.selectedTest.profile_item_id;
        }

        return retVal;
    }

    protected setAccountData(acct: IAccount, acctSettings: IAccountSettings): void {
        console.log("setAccountData: ", acct, acctSettings);

        this.accountSettings = acctSettings;
        this.account = acct;

        this.setAccountSettings(this.accountSettings);

        this.refreshProfileItems();
    }

    protected setAccountSettings(acctSettings: IAccountSettings): void {
        console.log("setAccountSettings: ", acctSettings);
        this.accountSettings = acctSettings;
    }

    /**
     * Returns a list of profile items filtered by the current country.
     *
     * Country code is pulled from account settings.
     * Need to know about "all" profile items for when we're handling corporate accounts.
     * There isn't such a thing as a "corporate profile item", just profile items defined by
     * country/lims system.  For modular expansion, we'll have profile items that don't have an
     * associated lims system, so sticking to just filtering by country code here.
     */
    private filterProfileItems(profileItems: IProfileItem[], _searchText?: string): IProfileItem[] {
        console.log("filterProfileItems: ", _searchText);
        // Edge case if route data is slow coming back
        if (!this.accountSettings) {
            console.warn("No account settings yet.  Returning empty profile items list");
            return [];
        }

        const searchText = this.getSearchText(_searchText);

        return profileItems.filter((profileItem: IProfileItem): boolean => {
            const countryMatches = profileItem.countryCd === this.accountSettings.countryCd;

            return countryMatches
                && (!searchText
                    || this.adminFacade.translate(profileItem.displayNameKey).toLowerCase().includes(searchText)
                    || profileItem.category.displayName.toLowerCase().includes(searchText)
                    || this.containsProductMatch(searchText, profileItem));
        });
    }

    private getSearchText(searchText?: string): string | null {
        const str = UtilService.trimToNull(searchText);
        return str?.toLowerCase();
    }

    private containsProductMatch(searchText: string, profileItem: IProfileItem): boolean {
        if (!searchText) {
            return true;
        }
        return profileItem.products.some((product: IProduct): boolean => product.display_name.includes(searchText));
    }

    /**
     * Returns a list of profile items grouped by category.
     */
    private groupProfileItemsByCategory(profileItems: IProfileItem[]): CategoryProfileItems[] {
        const results: CategoryProfileItems[] = [];
        const map = new Map<string, IProfileItem[]>();

        profileItems.forEach((profileItem: IProfileItem): void => {
            const category = profileItem.category.displayName;
            if (!map.has(category)) {
                map.set(category, []);
            }
            map.get(category)?.push(profileItem);
        });
        for (const [category, itemsList] of map) {
            results.push({ category, items: itemsList });
        }
        return results;
    }

    public sortChanged(): void {
        console.log("sortChanged: ", this.sortBy);
    }

    public async searchProfileItems(event): Promise<void> {
        console.log("searchProfileItems: ", event, this.searchText);
        this.filteredProfileItems = this.filterProfileItems(this.profileItems, this.searchText);
        this.groupedProfileItems = this.groupProfileItemsByCategory(this.filteredProfileItems);
    }

    private setBusy(isBusy: boolean, msg?: string): void {
        this.isLoading = isBusy;
        this.loadingMessage = msg;
    }
}
