<div class="page-header">
  <span class="spot-typography__heading--level-2">Enrollment Management</span>
</div>
<div class="page-body">
  <div class="page-section-scroll" style="display:block;padding: 10px;">
	<form style="display:flex;align-items:center;">
	  <div class="spot-form__field-group" style="margin-right: 10px;">
		<label class="spot-form__label" for="sapId">SAP Id</label>
		<input type="text" class="spot-form__input" name="sapId" [(ngModel)]="searchRequest.sapId"/>
	  </div>

	  <button class="spot-button spot-button--primary" type="submit" (click)="searchEnrollments()" [disabled]="!searchRequest.sapId">Search</button>
	</form>
	<table matSort (matSortChange)="sortData($event)" style="margin-top:1em; width: 100%;" aria-label="Enrollments" class="spot-data-table spot-data-table--clickable">
      <thead>
	    <tr>
		  <th mat-sort-header="sapId">SAP</th>
		  <th mat-sort-header="name">Practice</th>
		  <th mat-sort-header="enrollmentDate">Enrollment Date</th>
		  <th mat-sort-header="id">ID</th>
	    </tr>
      </thead>
      <tbody>
	    <tr *ngFor="let enroll of sortedData" (click)="enrollmentClicked(enroll)">
		  <td>{{enroll.sap_id}}</td>
		  <td>{{enroll.practiceName}}</td>
		  <td>{{enroll.enrollmentDate}}</td>
		  <td>{{enroll.enrollment_id}}</td>
	    </tr>
      </tbody>
	</table>
  </div>
</div>
