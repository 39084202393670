<div class="spot-tabs" *ngIf="useTabs">
  <div class="spot-tabs__list-wrap">
    <ul class="spot-tabs__list">
      <ng-container *ngFor="let species of speciesNames">
        <li *ngIf="hasCards(species)" class="spot-tabs__tab">
          <a [ngClass]="'species-tabs spot-tabs__link ' + (selectedTab===species ? ' spot-tabs__link--active':'')" (click)="selectTab(species)"> {{'species_panels.' + species | translate}} </a>
        </li>
      </ng-container>
    </ul>
  </div>
</div>

<ng-container *ngFor="let species of speciesNames">
  <fieldset class="profile-species" *ngIf="hasCards(species) && (!useTabs || species === selectedTab)">
    <legend class="species-title legend">
      <svg class="spot-icon species-icon">
        <use [attr.xlink:href]="'/assets/spot-library/dist/symbols/spot_symbols.svg#' + speciesIcons[species]"></use>
      </svg>
      {{'species_panels.' + species | translate}}
    </legend>
    <div *ngIf="canSelect && profileCards[species] && profileCards[species].length > 0" class="add-to-label">
      <div style="margin-bottom: 10px;">{{'profiles.ADD_TO_ENROLLMENT' | translate}}</div>
      <svg height="25px" width="40px">
        <polygon points="0,0 40,0 20,25" style="fill:#cccfd1" />
      </svg>
    </div>
    <div id="content" style="display:flex;flex-direction:column;margin: 0 auto;flex:1;" >
      <pcc-slot
        *ngFor="let profile of profileCards[species]"
        [profile]="profile"
        [canSelect]="canSelect"
        [selectedProperty]="selectedProperty"
        [canDelete]="canDelete(profile)"
        [canReset]="canReset(profile)"
        [canEdit]="canEdit"
        [canEditPracticePrice]="canEditPracticePrice"
        [canEditPetOwnerPrice]="canEditPetOwnerPrice"
        [petOwnerPricePage]="petOwnerPricePage"
        (slotClick)='cardClicked(profile)'
        (editPriceClick)='editPriceClicked($event)'
        (deleteClick)='deleteClicked($event)'
        (resetClick)='resetClicked(profile)'
        (selected)="profileSelected($event)"></pcc-slot>
    </div>
    <div class="pcc-flex-panels" *ngIf="hasFlexCards(species)">
      <div class="pcc-flex-add-panel">
        <span class="pcc-build-new-title"> {{ 'profiles.build_new_profiles' | translate }} </span>
        <button *ngFor="let profile of flexCards[species]" (click)="cardClicked(profile)" class="pcc-add-button spot-button spot-button--link spot-button--with-icon">
          <svg class="spot-icon spot-button__icon spot-button__icon--left" style="width:24px;" aria-labelledby="title">
            <title>add</title>
            <use xlink:href="/assets/spot-library/dist/symbols/spot_symbols.svg#add"></use>
          </svg>
          <span class="pcc-add-button-text spot-button__text"> {{ profile.display_name  }} </span>
        </button>
      </div>
    </div>
  </fieldset>
</ng-container>
