<button mat-button [matMenuTriggerFor]="langMenu" class="lang-button">
  <svg class="globe-icon" aria-labelledby="title">
	<title>{{ 'language-toggle.globe' | translate }}</title>
	<use xlink:href="/assets/spot-library/dist/symbols/spot_symbols.svg#globe"></use>
  </svg>
  {{ selectedLanguage?.langCode | uppercase }}
</button>
<mat-menu #langMenu="matMenu">
	<button mat-menu-item *ngFor="let lang of languages" (click)="setLang(lang);">{{lang.langCode | uppercase}} - {{lang.displayName}}</button>
</mat-menu>
