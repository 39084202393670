import { Injectable } from "@angular/core";
import { envUtils } from "../globals/env";
import { environment } from "../../environments/environment";
import { EnvConfig } from "@shared/model/env-config";
import { PCCServiceError } from "@shared/model/error/pcc-service-error";

// Need to hardcode the port here.
// Ideally, it should be pulled from environment vars during build, but that"s not a small change.
//const SERVICE_PORT = 3835;

@Injectable({
    providedIn: "root"
})
export class ConfigService {
    public constructor() {
    }

    public async fetchConfigOnAppInit(): Promise<EnvConfig> {
        try {
            //const port = SERVICE_PORT;
            const serviceUrl = ``;
            // USE THIS once client/server are split:
            //const serviceUrl = `${window.location.protocol}//${window.location.hostname}:${port}`;
            console.log("serviceUrl=", serviceUrl);

            const resp = await fetch(serviceUrl + "/api/config/config");

            if (!resp.ok) {
                console.log("resp=", resp);
                throw new PCCServiceError("CONFIG.SERVICE_ERROR", "Error pulling config from server: " + resp.statusText);
            }

            const configResp = await resp.json();

            envUtils.setConfig(configResp);
            environment.api_url = configResp.SERVICE_TARGET;

            return configResp;
        } catch (err) {
            console.error("Error calling config service", err);
            throw new PCCServiceError("CONFIG.SERVICE_ERROR", "Error pulling config from server", err);
        }
    }
}
