import { IUser } from "./user";

export interface ISpecies {
    species_id?: number;
    display_name: string;
    developer_name: string;

    created_on?: Date;
    createdBy?: IUser;

    updated_on?: Date;
    updatedBy?: IUser;

}

export const SpeciesUtils = {
    equals(sp1?: ISpecies, sp2?: ISpecies): boolean {
        if (!sp1 && !sp2) { // both null, equals=true;
            return true;
        }
        if (!sp1 || !sp2) { // one null, but not other, equals=false
            return false;
        }
        if (sp1.species_id == sp2.species_id) { // both not null, compare species_id
            return true;
        }
        return false;
    }
};

export class SpeciesEnum {
    static ORDINAL_SEQ: number = 0;

    static readonly CANINE = new SpeciesEnum("Canine", "CANINE");

    static readonly FELINE = new SpeciesEnum("Feline", "FELINE");

    readonly ordinal: number = 0;

    static values: SpeciesEnum[];

    // private to disallow creating other instances of this type
    private constructor(public readonly displayName: string, public readonly value: string) {
        this.ordinal = SpeciesEnum.ORDINAL_SEQ++;
        SpeciesEnum.values = SpeciesEnum.values || [];
        SpeciesEnum.values.push(this);
    }

    public static forString(value: string): SpeciesEnum {
        if (value === SpeciesEnum.FELINE.value) {
            return SpeciesEnum.FELINE;
        } else if (value === SpeciesEnum.CANINE.value) {
            return SpeciesEnum.CANINE;
        }
        console.warn("Invalid value type passed in.  Defaulting to Canine Species...");
        return SpeciesEnum.CANINE;
    }

    public static forSpecies(speciesInfo: ISpecies | string): SpeciesEnum {
        if (!speciesInfo) {
            console.error("speciesInfo is null, no match found");
            return null;
        }
        return SpeciesEnum.values.find((species: SpeciesEnum): boolean => species.equals(speciesInfo));
    }

    public equals(value: string | ISpecies): boolean {
        if (typeof value == "string") {
            return this.value == value;
        }
        if (value && value.developer_name) {
            return this.value == value.developer_name;
        }
        return false;
    }

    public toString(): string {
        return this.displayName;
    }

    public static getSpeciesForEnum(speciesEnum: SpeciesEnum, speciesList: ISpecies[]): ISpecies {
        if (!speciesList) {
            return null;
        }

        speciesEnum = speciesEnum || SpeciesEnum.CANINE;

        return speciesList.find((species: ISpecies): boolean => (
            speciesEnum.equals(species)
        ));
    }
}
