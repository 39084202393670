import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { AccountSettings } from "@shared/model/account-settings";
import { ICustomPage, CustomPageTypes } from "@shared/model/custom-page";
import { PCCSessionService } from "../../../service/pcc-session.service";
import { PCCSession } from "@shared/model/pcc-session";
import { AppFacade } from "../../../facade/app.facade";
import { NavService } from "../../../service/nav.service";
import googleAnalytics from "../../../analytics/googleAnalytics";

@Component({
    selector: "pcc-landing",
    templateUrl: "./landing.component.html",
    styleUrls: [
        "./landing.component.scss"
    ]
})
export class LandingComponent implements OnInit, OnDestroy {

    public session: PCCSession;

    private sessionSub: Subscription;

    public customPages: ICustomPage[];

    public constructor(
        private navService: NavService,
        private router: Router,
        private appFacade: AppFacade,
        public sessionService: PCCSessionService
    ) {
        console.log("Landing page constructor");
    }

    public ngOnInit(): void {

        // Hide next button
        this.navService.setNextState(null);

        this.sessionSub = this.appFacade.getCurrentSession().subscribe(
            (session: PCCSession): void => {
                this.setSession(session);
            }
        );
    }

    public ngOnDestroy(): void {
        this.sessionSub.unsubscribe();
    }

    private setSession(session: PCCSession): void {
        this.session = session;

        this.customPages = AccountSettings.getCustomPages(this.session.accountSettings, CustomPageTypes.LAUNCH);
    }

    public pageClicked(cp: ICustomPage): void {
        console.log("pageClicked: ", cp);
        this.googleAnalyticsCustomPage(cp);
        this.router.navigate([
            "account",
            this.session.accountInfo.sap_id,
            "custom-page",
            cp.id
        ], { state: { customPage: cp, customPages: this.customPages } });
    }

    public startEnrollment(): void {
        console.log("startEnrollment");

        this.googleanalyticsNoLearning();

        this.router.navigate([
            "account",
            this.session.accountInfo.sap_id,
            "profiles"
        ]);
    }

    private googleAnalyticsCustomPage(customPage: ICustomPage) {
        googleAnalytics.registerEvent("custom_page_click", "click", {
            custom_page_id: customPage.id,
            title: this.appFacade.translate(customPage.titleKey),
            subtitle: this.appFacade.translate(customPage.subTitleKey),
            from_custom_page_footer: false
        });
        window.localStorage.setItem("custom_page_visited_" + this.session.accountInfo.sap_id, "1");
    }

    private googleanalyticsNoLearning(): void {
        if (window.localStorage.getItem("custom_page_visited_" + this.session.accountInfo.sap_id) !== "1") {
            googleAnalytics.registerEvent("no_learn_enrollment", "click")
        }
    }
}
