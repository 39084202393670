<div class="spot-modal">

  <pcc-loading [isBusy]="isLoading" [busyMsg]="loadingMessage"></pcc-loading>

  <div class="spot-modal__header">
    <span class="spot-typography__heading--level-3">
      Seismic Document
    </span>
    <button class="spot-modal__header-cancel-button" (click)="cancel()">
      <svg class="spot-icon spot-modal__header-cancel-button-icon" aria-labelledby="title">
        <title>cancel</title>
        <use xlink:href="/assets/spot-library/dist/symbols/spot_symbols.svg#cancel"></use>
      </svg>
    </button>
  </div>
  <div class="spot-modal__content">

    <div class="seismic-link">
      <form class="spot-form link-form" id="linkForm" #linkForm="ngForm" name="linkForm">
        <div *ngIf="selectedLink?.error" class="spot-form__field-error" role="alert">
          <div class="spot-form__field-error-text"> Error pulling information from Seismic for this link </div>
        </div>
        <div style="display: flex;">
          <section *ngIf="selectedLink.thumbnailData?.url" class="link-thumbnail">
            <img [src]="selectedLink.thumbnailData.url" class="doc-thumbnail" [alt]="selectedLink.desc">
          </section>

          <section class="link-form">

            <div style="display:flex;align-items:center;margin-bottom:1em;">
              <pcc-language-radio-group
                [(selectedLocale)]="selectedLink.locale"
                label="Assign Language"
                [isRequired]="true"
                [languages]="languages"
                (selectedLanguageChange)="onLanguageSelected($event)">
              </pcc-language-radio-group>

              <pcc-confirm-action symbol="delete" confirmPosition="spot-popover--bottom-left" (okClick)="deleteLinkConfirmed(selectedLink)" class="delete-button" confirmMsg="seismic-link-edit.confirm_delete"></pcc-confirm-action>
            </div>

            <div class="spot-form__field-group">
              <label class="spot-form__label" for="linkUrl" style="display: flex">
                <span class="spot-form__label-required-indicator">*</span>
                Seismic URL
                <svg
                  class="spot-icon link-url-info-icon"
                  aria-labelledby="title"
                  >
                  <title>URL must be from the Library view in Seismic.  This is required to interact with the Seismic API.  The system will then convert that to DocCenter links for users. </title>
                  <use xlink:href="/assets/spot-library/dist/symbols/spot_symbols.svg#info-2"></use>
	            </svg>
                <span class="link-content-id" (click)="copyContentId()">
                  Content ID: <span class="copyable" title="Click to copy">{{ selectedLink.contentId }}</span>
                </span>
              </label>
              <input type="text" class="spot-form__input" name="linkUrl" [(ngModel)]="selectedLink.url" required (input)="docUrlChanged(selectedLink, selectedLink.url)"/>
              <span *ngIf="hasUrlError" class="spot-form__field-error" role="alert">
                <span class="spot-form__field-error-text"> URL must be from the Library view in Seismic.  This is required to interact with the Seismic API.  The system will then convert that to DocCenter links for users. </span>
              </span>
            </div>
            <div class="spot-form__field-group">
              <label class="spot-form__label">
                Name
              </label>
              <pcc-edit-text-locale [locale]="selectedLink.locale" [(editText)]="selectedLink.desc" (editTextChanged)="validate()" [required]="true"></pcc-edit-text-locale>
            </div>
            <div class="spot-form__field-group" *ngIf="showTag">
              <label class="spot-form__label" for="linkTag">Tag</label>

              <div class="spot-form__select">
                <select name="linkTag" class="spot-form__select-input" aria-label="Tag" [(ngModel)]="selectedLink.tag">
                  <option class="spot-form__select-option" value=""></option>
                  <option *ngFor="let tag of tagList" [value]="tag" class="spot-form__select-option">{{'seismic-link-edit.' + tag | translate}}</option>
                </select>
                <div class="spot-form__select-inner"></div>
                <span class="spot-form__select-open-indicator">
                  <svg class="spot-icon spot-form__select-open-icon" aria-labelledby="title">
                    <title>caret-down</title>
                    <use xlink:href="/assets/spot-library/dist/icons/spot_icons.svg#caret-down"></use>
                  </svg>
                </span>
              </div>
            </div>
            <div class="spot-form__field-group">
              <pcc-checkbox [(checked)]="selectedLink.isDefault" label="Default link"></pcc-checkbox>
            </div>
          </section>
        </div>
      </form>
    </div>
    <section class="seismic-controls">
      <button class="spot-button spot-button--link  spot-button--with-icon" title="Refresh file properties" (click)="refreshFileProperties()">
        <svg class="spot-icon spot-button__icon spot-button__icon--left" aria-labelledby="title">
          <title>Refresh file properties</title>
          <use xlink:href="/assets/spot-library/dist/symbols/spot_symbols.svg#refresh-redo"></use>
        </svg>
        <span class="spot-button__text">Refresh file properties</span>
      </button>

      <button class="spot-button spot-button--link spot-button--with-icon" title="Refresh cached file" (click)="refreshCache()">
        <svg class="spot-icon spot-button__icon spot-button__icon--left" aria-labelledby="title">
          <title>Refresh cached file</title>
          <use xlink:href="/assets/spot-library/dist/symbols/spot_symbols.svg#archive"></use>
        </svg>
        <span class="spot-button__text">Refresh cached file</span>
      </button>
    </section>
    <section class="seismic-link-info">
      <span *ngIf="fileProperties">
        File Name: {{fileProperties.name}}
      </span>
      <span style="margin-left:auto;">File size: {{ formatFileSize(selectedLink.size) }} ({{ selectedLink.format }})</span>
    </section>
  </div>
  <div class="spot-modal__footer">
    <button type="cancel" class="spot-modal__footer-cancel-button" (click)="cancel()">Cancel</button>
    <button type="submit" class="spot-button spot-button--primary" (click)="saveClicked()">Save</button>
  </div>
</div>
