import { Injectable, Injector } from "@angular/core";

import { Observable } from "rxjs";

import { NgbModalRef } from "@ng-bootstrap/ng-bootstrap";

import { AppService } from "../service/app.service";
import { IProductRequest, IProductResponse } from "@shared/model/service/product-service";
import { AuthService } from "../auth/auth.service";
import { AccountService, ConfirmSavePurge } from "../service/account.service";
import { AdminService } from "../service/admin.service";
import { PCCPriceService } from "../service/price.service";
import { CorpPricingAlgorithmService } from "../service/corp-pricing-algorithm.service";
import { PCCSessionService } from "../service/pcc-session.service";
import { DocService } from "../service/doc.service";
import { PCCTranslateService } from "../service/translate.service";

import { IAPIResponseData } from "@shared/model/service/service";
import { IAccountSettings, AccountSettingsCreateRequest } from "@shared/model/account-settings";
import { ICustomPage } from "@shared/model/custom-page";
import { ISeismicLink, SeismicFileProperties, SeismicDocument } from "@shared/model/seismic";
import { IAccount } from "@shared/model/account";
import { IUser } from "@shared/model/user";
import { IAccountResponse, ISessionCount } from "@shared/model/account-response";
import { ICategory } from "@shared/model/category";
import { IBuyingGroup } from "@shared/model/buying-group";
import { IEnrollResponse, ICleanSessionsRequest } from "@shared/model/service/enroll-service";
import { IProfileMatchRequest, IProfileMatchResponse } from "@shared/model/service/profile-match-service";
import { IProfileTemplate } from "@shared/model/profile-template";
import { ISystemSettingsResponse, ISystemSettings } from "../../../shared/model/system-settings";
import { IProfileItem } from "@shared/model/profile-item";
import { PCCSession } from "@shared/model/pcc-session";
import { IAdminSettingsResponse } from "@shared/model/service/admin-service";
import { SettingsFlags, SettingsKey } from "@shared/model/settings-flags";
import { Language } from "@shared/model/language";
import { FileInfo } from "@shared/model/file";

import { IDataResponse } from "@shared/model/data-response";
import { IEnrollmentsRequest, IEnrollmentsResponse } from "@shared/model/service/enrollments-service";
import { IEnrollment } from "@shared/model/enrollment";

@Injectable()
export class AdminFacade {

    private pAppService: AppService;

    private pAuthService: AuthService;

    private pAccountService: AccountService;

    private pAdminService: AdminService;

    private pSessionService: PCCSessionService;

    private pTranslateService: PCCTranslateService;

    private pPriceService: PCCPriceService;

    private pCorpPricingAlgorithmService: CorpPricingAlgorithmService;

    private pDocService: DocService;

    public constructor(
        private injector: Injector
    ) {
    }

    public get accountService(): AccountService {
        if (!this.pAccountService) {
            this.pAccountService = this.injector.get(AccountService);
        }
        return this.pAccountService;
    }

    public get adminService(): AdminService {
        if (!this.pAdminService) {
            this.pAdminService = this.injector.get(AdminService);
        }
        return this.pAdminService;
    }

    public get appService(): AppService {
        if (!this.pAppService) {
            this.pAppService = this.injector.get(AppService);
        }
        return this.pAppService;
    }

    public get docService(): DocService {
        if (!this.pDocService) {
            this.pDocService = this.injector.get(DocService);
        }
        return this.pDocService;
    }

    public get authService(): AuthService {
        if (!this.pAuthService) {
            this.pAuthService = this.injector.get(AuthService);
        }
        return this.pAuthService;
    }

    public get sessionService(): PCCSessionService {
        if (!this.pSessionService) {
            this.pSessionService = this.injector.get(PCCSessionService);
        }
        return this.pSessionService;
    }

    public get translateService(): PCCTranslateService {
        if (!this.pTranslateService) {
            this.pTranslateService = this.injector.get(PCCTranslateService);
        }
        return this.pTranslateService;
    }

    public get priceService(): PCCPriceService {
        if (!this.pPriceService) {
            this.pPriceService = this.injector.get(PCCPriceService);
        }
        return this.pPriceService;
    }

    public get corpPricingAlgorithmService(): CorpPricingAlgorithmService {
        if (!this.pCorpPricingAlgorithmService) {
            this.pCorpPricingAlgorithmService = this.injector.get(CorpPricingAlgorithmService);
        }
        return this.pCorpPricingAlgorithmService;
    }

    public getProfileItems(): Promise<IAdminSettingsResponse> {
        return this.appService.getProfileItems();
    }

    public getProfileItem(profileItemId: number): Promise<IAdminSettingsResponse> {
        return this.appService.getProfileItem(profileItemId);
    }

    public deleteProfileItem(testId: number): Promise<IDataResponse> {
        return this.appService.deleteProfileItem(testId);
    }

    public saveCategory(ctg: ICategory): Promise<IDataResponse> {
        return this.appService.saveCategory(ctg);
    }

    public saveBuyingGroup(bg: IBuyingGroup): Promise<IDataResponse> {
        return this.appService.saveBuyingGroup(bg);
    }

    public findProduct(searchRequest: IProductRequest): Promise<IProductResponse> {
        return this.appService.findProduct(searchRequest);
    }

    public saveProfileItem(test: IProfileItem): Promise<IDataResponse> {
        return this.appService.saveProfileItem(test);
    }

    public saveCustomPage(page: ICustomPage): Promise<IAPIResponseData<ICustomPage>> {
        return this.adminService.saveCustomPage(page);
    }

    public getCustomPages(acctSettingsId: number): Promise<IAPIResponseData<ICustomPage[]>> {
        return this.adminService.getCustomPages(acctSettingsId);
    }

    public saveProfileTemplate(ctg: IProfileTemplate): Promise<IAdminSettingsResponse> {
        return this.appService.saveProfileTemplate(ctg);
    }

    public deleteProfileTemplate(pt: IProfileTemplate): Promise<IAdminSettingsResponse> {
        return this.appService.deleteProfileTemplate(pt);
    }

    public async getSystemSettings(): Promise<ISystemSettings> {
        return await this.appService.getSystemSettings(true);
    }

    public async saveSystemSettings(sysSettings: ISystemSettings): Promise<ISystemSettingsResponse> {
        return await this.appService.saveSystemSettings(sysSettings);
    }

    public getSession(): PCCSession {
        return this.sessionService.getSession();
    }

    public clearSession(): void {
        return this.sessionService.clearSession();
    }

    public async getSystemSettingsCached(): Promise<ISystemSettings> {
        return this.appService.getSystemSettingsCached();
    }

    public async startAdminSession(acctInfo: IAccount, acctSettings: IAccountSettings, sysSettings: ISystemSettings, sessionCount: ISessionCount): Promise<PCCSession> {
        return this.sessionService.startAdminSession(acctInfo, acctSettings, sysSettings, sessionCount);
    }

    public getEnrollment(id: string | number): Observable<IEnrollResponse> {
        return this.sessionService.getEnrollment(id);
    }

    public saveEnrollment(enroll: IEnrollment): Promise<IEnrollResponse> {
        return this.sessionService.saveEnrollment(enroll, true);
    }

    public async findMatchingProfile(profileRequest: IProfileMatchRequest): Promise<IProfileMatchResponse> {
        return this.sessionService.findMatchingProfile(profileRequest);
    }

    public async findAccount(acctSettingsId?: number, isAdmin?: boolean): Promise<IAccountResponse> {
        return this.accountService.findAccount(acctSettingsId, null, isAdmin);
    }

    // Clear out partial sessions for enrollments created from specified account setting
    public async clearOldSessions(acctSettings: IAccountSettings): Promise<IEnrollResponse> {
        console.log("clearOldSessions: ", acctSettings.sap_id);
        const cleanReq: ICleanSessionsRequest = {
            accountSettingsId: acctSettings.account_settings_id,
            sapId: null
        };
        return this.sessionService.cleanupSessions(cleanReq);
    }

    public async confirmPurgeSessions(): Promise<boolean> {
        return this.accountService.confirmPurgeSessions();
    }

    public async confirmSavePurge(acctSettings: IAccountSettings, sessionCount: ISessionCount): Promise<ConfirmSavePurge> {
        return this.accountService.confirmSavePurge(acctSettings, sessionCount);
    }

    public async getAccountSettings(acctSettingsId: number, noCache: boolean = true): Promise<IAccountSettings> {
        const session = this.getSession();
        return this.accountService.getAccountSettings(acctSettingsId, session, noCache);
    }

    public getAllAccountSettings(): Promise<IAccountSettings[]> {
        return this.accountService.getAllAccountSettings();
    }

    public saveAccountSettings(formData: FormData): Promise<IAccountResponse> {
        return this.accountService.saveAccountSettings(formData);
    }

    public async createAccountSettings(createRequest: AccountSettingsCreateRequest): Promise<IAccountResponse> {
        return await this.adminService.createAccountSettings(createRequest);
    }

    public runPriceCalc(priceAlg: string, varValues: Record<string, any>): number {
        return this.corpPricingAlgorithmService.runPriceCalc(priceAlg, varValues);
    }

    public getUsers(): Promise<IAdminSettingsResponse> {
        return this.appService.getUsers();
    }

    public getRoles(): Promise<IAdminSettingsResponse> {
        return this.appService.getRoles();
    }

    public async saveUserRole(user: IUser): Promise<IAdminSettingsResponse> {
        return this.appService.saveUserRole(user);
    }

    public getEnrollments(enrollReq?: IEnrollmentsRequest): Observable<IEnrollmentsResponse> {
        return this.sessionService.getEnrollments(enrollReq);
    }

    public getDefaultLims(session: PCCSession): string[] {
        return this.accountService.getDefaultLims(session);
    }

    public async showPdf(enrollId: number): Promise<NgbModalRef> {
        return await this.docService.showPdf(enrollId);
    }

    public async getCustomPage(customPageId: number): Promise<IAPIResponseData<ICustomPage>> {
        return this.docService.getCustomPage(customPageId, true);
    }

    public async getFileProperties(contentId: string): Promise<IAPIResponseData<SeismicFileProperties>> {
        return await this.docService.getFileProperties(contentId);
    }

    public async refreshFile(contentId: string): Promise<IAPIResponseData<SeismicDocument>> {
        return await this.docService.refreshFile(contentId);
    }

    public async saveSeismicLink(link: ISeismicLink): Promise<IAPIResponseData<ISeismicLink>> {
        return await this.docService.saveSeismicLink(link);
    }

    public async saveSettingsFlags(accountSettingsId: number, flags: SettingsFlags): Promise<IAPIResponseData<number[]>> {
        return await this.adminService.saveSettingsFlags(accountSettingsId, flags);
    }

    public async updateFlag(accountSettingsId: number, flagName: SettingsKey, enabled: boolean): Promise<IAPIResponseData<number[]>> {
        return await this.adminService.updateFlag(accountSettingsId, flagName, enabled);
    }

    public async setProfileItemActive(profileItem: IProfileItem): Promise<IAPIResponseData<IProfileItem>> {
        return await this.appService.setProfileItemActive(profileItem);
    }

    public async saveLanguage(language: Language): Promise<IAPIResponseData<Language>> {
        return await this.adminService.saveLanguage(language);
    }

    public async deleteLanguage(language: Language): Promise<IAPIResponseData<Language>> {
        return await this.adminService.deleteLanguage(language);
    }

    public async getLanguages(): Promise<IAPIResponseData<Language[]>> {
        return await this.adminService.getLanguages();
    }

    public async saveImageFile(file: FileInfo): Promise<IAPIResponseData<FileInfo>> {
        return await this.adminService.saveImageFile(file);
    }

    public translate(key: string, context?: Object): string {
        return this.translateService.instant(key, context);
    }

}
