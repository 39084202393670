export class SessionModeEnum {
    private static ORDINAL_SEQ: number = 0;

    public static readonly FRESH = new SessionModeEnum("Fresh", "FRESH");

    public static readonly ACTIVE = new SessionModeEnum("Active", "ACTIVE");

    public static readonly EXPIRED = new SessionModeEnum("Expired", "EXPIRED");

    public readonly ordinal: number;

    // private to disallow creating other instances of this type
    private constructor(public readonly displayName: string,
        public readonly value: string) {
        this.ordinal = SessionModeEnum.ORDINAL_SEQ++;
    }

    public static forString(value: string): SessionModeEnum {
        if (value === SessionModeEnum.FRESH.value) {
            return SessionModeEnum.FRESH;
        }
        if (value === SessionModeEnum.ACTIVE.value) {
            return SessionModeEnum.ACTIVE;
        }
        if (value === SessionModeEnum.EXPIRED.value) {
            return SessionModeEnum.EXPIRED;
        }
        console.warn("Invalid value type passed in.  Defaulting to FRESH...");
        return SessionModeEnum.FRESH;
    }

    public equals(value: string): boolean {
        return this.value === value;
    }

    public toString(): string {
        return this.displayName;
    }
}
