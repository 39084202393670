import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

import { AppFacade } from "../../../facade/app.facade";

import { PCCAlertService } from "../../../service/alert.service";

import { Enrollment, EnrollUtils } from "@shared/model/enrollment";
import { IAccountResponse } from "@shared/model/account-response";
import { SessionModeEnum } from "@shared/model/session-mode-enum";
import { AppModeEnum } from "@shared/model/app-mode-enum";
import { ErrorUtils, IPCCError } from "../../../../../shared/model/error/";
import { ISearch } from "@shared/model/service/recent-searches-service";
import { PCCTranslateService } from "../../../service/translate.service";
import googleAnalytics from "../../../analytics/googleAnalytics";

@Component({
    selector: "client-sap-search",
    templateUrl: "./sap-search.component.html",
    styleUrls: [
        "./sap-search.component.scss"
    ]
})
export class SAPSearchComponent implements OnInit {
    public locale: string;

    public sapId: string;

    public systemError: IPCCError;

    public searching = false;

    public appMode: AppModeEnum = AppModeEnum.PROD;

    public otherMode: AppModeEnum = AppModeEnum.TRAINING;

    public recentSearches: ISearch[];

    public hasTraining = false;

    public constructor(
        private router: Router,
        private appFacade: AppFacade,
        private alertService: PCCAlertService,
        private activatedRoute: ActivatedRoute,
        private translateService: PCCTranslateService
    ) {
    }

    public async ngOnInit(): Promise<void> {

        this.translateService.localeSubject.subscribe((locale: string): void => {
            this.setLocale(locale);
        });

        // Check if Training mode is turned on.
        const appModeSub = this.appFacade.getAppMode();

        appModeSub.subscribe((appMode: AppModeEnum): void => {
            this.appMode = appMode;
        });

        // If training mode is enabled on the back end, disable training toggle.
        this.hasTraining = !this.appFacade.isEventTrainingMode();

        this.setMode(this.appMode);

        this.activatedRoute.data.subscribe((response: any): void => {
            console.log("RECENT SEARCHES FETCHING", response);
            const searchResp = response.recentSearches;
            if (searchResp && searchResp.success === true) {
                this.recentSearches = searchResp.searches;
            }
            console.log("RECENT SEARCHES FETCHED");
        });
    }

    public async findAccount(): Promise<boolean> {
        console.log(`findAccount: ${this.sapId}`);

        delete this.systemError;
        this.searching = true;

        this.alertService.setBusy(true, this.appFacade.translate("sap-search.Searching"));

        try {
            const resp: IAccountResponse = await this.appFacade.findAccount(this.sapId);

            console.log("Found account: ", resp);

            this.searching = false;
            this.alertService.setBusy(false);

            if (resp.success !== true) {
                console.error(`Error searching for account: ${this.sapId}`, resp.error);
                // TODO: Map error.name to display text?

                this.systemError = ErrorUtils.getError(resp.error);
                console.log("systemError=", this.systemError);

                return false;
            }

            if (resp.account && (!resp.account.limsAccountInfo || !resp.account.limsAccountInfo.length)) {
                console.error("No lims account information for this account!");
            }

            const accountSettings = resp.accountSettings;

            this.translateService.setDataTranslations(resp.accountSettings?.translations);

            console.log("Calling setAccountLanguages");
            this.translateService.setAccountLanguages(accountSettings.languages, accountSettings.defaultLocale);

            this.locale = this.translateService.locale;

            if (resp.existEnroll || resp.oldSession) {
                // Either an existing enrollment (active or expired) or a partial enrollment was found.
                this.gotoSessionConfirm(resp);
            } else {
                // Fresh account with no existing enrollment or partial session data present.
                this.startFreshSession(resp);
            }

            return true;
        } catch (err) {
            console.log("Account not found: ", err);
            const msg = this.appFacade.translate("sap-search.account_not_found", { sapId: this.sapId });
            const code = err.code ?? "ACCOUNT.ERROR_THROWN";
            const errorMessage = err.message ?? ErrorUtils.getErrorMessage(err);
            this.systemError = { name: "Error", message: `${msg} ${errorMessage}`, code };
            this.searching = false;
            this.alertService.setBusy(false);
            return false;
        }
    }

    public sapIdKey(event: any): void {
        if (event.key === "Enter") {
            console.log("Enter key pressed");
            this.findAccount();
        }
    }

    private setLocale(locale: string): void {
        this.locale = locale;
    }

    public setMode(mode: AppModeEnum): void {
        console.log("setMode: ", mode);
        this.appMode = mode;
        this.otherMode = (mode === AppModeEnum.PROD ? AppModeEnum.TRAINING : AppModeEnum.PROD);

        this.appFacade.setAppMode(mode);
    }

    public toggleTraining(): void {
        console.log("toggleTraining");
        googleAnalytics.registerEvent("training_mode_change", "change");
        if (this.appMode === AppModeEnum.PROD) {
            this.setMode(AppModeEnum.TRAINING);
        } else {
            this.setMode(AppModeEnum.PROD);
        }
    }

    public isTrainingMode(): boolean {
        return this.appMode === AppModeEnum.TRAINING;
    }

    private async startFreshSession(resp: IAccountResponse): Promise<void> {
        // Call service to create new session using just account, accountSettings - no partial or submitted enrollment
        const enrollInfo = new Enrollment(resp.account, null, resp.accountSettings);

        enrollInfo.autoAddProfilesIfNeeded(resp.accountSettings, resp.accountSettings.defaultLocale);

        const session = await this.appFacade.createSession(resp.account, resp.accountSettings, this.locale, enrollInfo, true, SessionModeEnum.FRESH);

        if (EnrollUtils.isPricingExpired(session.enrollInfo, session.sessionMode)) {
            // If any new enrollment contains profiles that are auto-selected, we need to run pricing up front here to avoid troubles downstream.
            await this.appFacade.reloadPricing(session);
        }

        // Call service to go to "next" page - either landing page or profiles page.
        // As well as any other tasks like calling test mix, showing corp account info modal, etc.
        this.appFacade.startEnrollmentSession(session);
    }

    private gotoSessionConfirm(resp: IAccountResponse): void {
        console.log("gotoSessionConfirm: ", resp);
        this.router.navigate([
            "account",
            resp.account.sap_id,
            "session-confirm"
        ], {
            state: {
                accountResp: resp
            }
        });
    }

    public recentSearchSelected(search: ISearch): void {
        this.sapId = search.sapId;
        this.findAccount();
    }
}
