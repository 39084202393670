<li class="admin-pcc-category">
  <div class="admin-pcc-category-header">
    {{testCategory.displayName}}
    <div class="admin-pcc-test-none" *ngIf="!isStaticTemplate">
      <pcc-checkbox label="Required" [(checked)]="testCategory.is_required" size="small"></pcc-checkbox>
    </div>
  </div>
  <div class="admin-pcc-category-content">
    <pcc-slot-category-test *ngFor="let test of profileItems" [testComp]="test" [showLeft]="test.display_order > 0" [showRight]="test.display_order < profileItems.length -1" (testClick)="testClicked(test, $event)" (defaultClick)="defaultClicked(test)" (moveLeft)='moveLeft(test)' (moveRight)='moveRight(test)'></pcc-slot-category-test>
  </div>
</li>
