import { IPCCError, PCCError } from "./pcc-error";

export class ErrorUtils {
    public static parseError<T>(err: string | Error | IPCCError<T>): IPCCError<T> {
        if (typeof err === "string") {
            try {
                const jsonObj = JSON.parse(err);
                if (jsonObj.code && jsonObj.name) {
                    // A PCCError that's been converted to string.  Convert it back and return.
                    const madError = new PCCError(jsonObj.code, jsonObj.message, null, jsonObj.context, jsonObj.isSystemError);
                    madError.name = jsonObj.name;
                    return madError;
                }
                // Some other valid json that's not a PCCError.  Wrap it and return.
                return new PCCError<T>("SYS_ERROR", "SYS_ERROR", jsonObj as Error);
            } catch (parseErr) {
                // Just a string error message.  Wrap it and return.
                return new PCCError<T>(err);
            }
        }

        if (err instanceof PCCError) {
            // Don't wrap existing PCCError (or child types)
            return err;
        }

        const msg = err.message || "Unknown System Error";
        if (err instanceof Error) {
            return new PCCError<T>(err.message, msg, <Error>err);
        }

        const errorCode = err.code || "SYS_ERROR_UNKNOWN";

        // Treat as IPCCError
        return new PCCError<T>(errorCode, msg, err.sourceError, err.context);
    }

    public static getErrorCode(err: string | Error | IPCCError): string | null {
        if (!err) {
            return null;
        }

        if (typeof err === "string") {
            return err;
        }

        if (err instanceof PCCError) {
            // Don't wrap existing PCCError (or child types)
            return err.code || err.message;
        }

        if (err instanceof Error) {
            return err.message;
        }

        // Treat as IPCCError
        return err.code || err.message || null;
    }

    /**
     * Return abbreviated string from various error-type messages.
     */
    public static getErrorMessage(err: string | Error | IPCCError | null, includeStack = false): string | null {
        if (!err) {
            return null;
        }

        if (typeof err === "string") {
            return err;
        }

        const msgParts: string[] = [];

        if ("code" in err) {
            msgParts.push(err.code);
        }

        if (err.message) {
            msgParts.push(err.message);
        }

        if (includeStack && err.stack) {
            msgParts.push(err.stack);
        }

        return msgParts.join(" : ");
    }

    /**
     * Returns an predictable error object containing pieces of the error message parsed out into a common config.
     */
    public static getError(err: string | Error | IPCCError | null): IPCCError | null {
        console.log("getError: ", typeof err, err);
        if (!err) {
            return null;
        }

        if (typeof err === "string") {
            return this.parseError(err);
        }
        return err;
    }
}
