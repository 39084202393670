import { Injectable } from "@angular/core";

import { HttpClient } from "@angular/common/http";
import { firstValueFrom, Observable } from "rxjs";

import { ISystemSettings, ISystemSettingsResponse } from "@shared/model/system-settings";

import { ICategory } from "@shared/model/category";
import { IBuyingGroup } from "@shared/model/buying-group";

import { IProduct } from "@shared/model/product";
import { IProfileItem } from "@shared/model/profile-item";
import { IProfileTemplate } from "@shared/model/profile-template";
import { IProfile } from "@shared/model/profile";
import { IProfileResponse } from "@shared/model/service/profile-service";
import { IUser } from "@shared/model/user";

import { IAdminSettingsResponse } from "@shared/model/service/admin-service";
import { IDataResponse } from "@shared/model/data-response";
import { IAPIResponseData } from "@shared/model/service/service";

import { IProductRequest, IProductResponse } from "@shared/model/service/product-service";
import { IAccountResponse } from "@shared/model/account-response";
import { IRecentSearchesResponse } from "@shared/model/service/recent-searches-service";

const enum API {
    searchProduct = "/api/admin/product/search",
    getTests = "/api/admin/tests",
    getTest = "/api/admin/test/",
    deleteProfileItem = "/api/admin/profile-item/",
    getProduct = "/api/admin/product/",
    deleteProduct = "/api/admin/product/",
    saveCategory = "/api/admin/category",
    saveBuyingGroup = "/api/admin/buying-group",
    saveTest = "/api/admin/profile-item",
    setProfileItemActive = "/api/admin/profile-item/active",
    saveProduct = "/api/admin/product",
    saveProfile = "/api/profile",
    saveProfileTemplate = "/api/admin/profileTemplate",
    deleteProfileTemplate = "/api/admin/profileTemplate/",
    getSystemSettings = "/api/admin/settings/",
    saveSystemSettings = "/api/admin/settings",
    getUsers = "/api/admin/users",
    getRoles = "/api/admin/roles",
    saveUserRole = "/api/admin/userrole",
    findAccount = "/api/account/",
    runPricingOutputJob = "/api/admin/pricing-output-job/",
    getRecentSearches = "/api/recent-searches/"
}

@Injectable()
export class ApiService {

    public constructor(
        private http: HttpClient
    ) {
    }

    public async getTests(): Promise<IDataResponse> {
        return await firstValueFrom(
            this.http.get<IDataResponse>(API.getTests)
        );
    }

    public async getTest(testId: number): Promise<IAdminSettingsResponse> {
        return await firstValueFrom(
            this.http.get<IDataResponse>(API.getTest + testId)
        );
    }

    public async deleteProfileItem(testId: number): Promise<IDataResponse> {
        console.log("deleteProfileItem", testId);
        return await firstValueFrom(
            this.http.delete<IDataResponse>(API.deleteProfileItem + testId)
        );
    }

    public async getProduct(productId: string): Promise<IDataResponse> {
        console.log(`getProduct: ${productId}`);
        return await firstValueFrom(
            this.http.get<IDataResponse>(API.getProduct + productId)
        );
    }

    public async findProduct(searchRequest: IProductRequest): Promise<IProductResponse> {
        console.log("findProduct", searchRequest);
        const url = API.searchProduct;

        return await firstValueFrom(
            this.http.post<IDataResponse>(url, searchRequest)
        );
    }

    public async deleteProduct(productId: number): Promise<IDataResponse> {
        console.log("deleteProduct");
        return await firstValueFrom(
            this.http.delete<IDataResponse>(API.deleteProduct + productId)
        );
    }

    public async saveCategory(ctg: ICategory): Promise<IDataResponse> {
        console.log("saveCategory: ", ctg);
        return await firstValueFrom(
            this.http.post<IDataResponse>(API.saveCategory, ctg)
        );
    }

    public async saveBuyingGroup(bg: IBuyingGroup): Promise<IDataResponse> {
        console.log("saveBuyingGroup: ", bg);
        return await firstValueFrom(
            this.http.post<IDataResponse>(API.saveBuyingGroup, bg)
        );
    }

    public async saveTest(test: IProfileItem): Promise<IDataResponse> {
        console.log("saveTest: ", test);
        return await firstValueFrom(
            this.http.post<IDataResponse>(API.saveTest, test)
        );
    }

    public async setProfileItemActive(profileItem: IProfileItem): Promise<IAPIResponseData<IProfileItem>> {
        console.log("saveProfileItemActive: ", profileItem);
        return await firstValueFrom(
            this.http.post<IAPIResponseData<IProfileItem>>(API.setProfileItemActive, profileItem)
        );
    }

    public async saveProduct(product: IProduct): Promise<IDataResponse> {
        console.log("saveProduct: ", product);
        return await firstValueFrom(
            this.http.post<IDataResponse>(API.saveProduct, product)
        );
    }

    public async saveProfile(profile: IProfile): Promise<IProfileResponse> {
        console.log("saveProfile: ", profile);

        return await firstValueFrom(
            this.http.post<IProfileResponse>(API.saveProfile, profile)
        );
    }

    public async saveProfileTemplate(ctg: IProfileTemplate): Promise<IAdminSettingsResponse> {
        console.log("saveProfileTemplate: ", ctg);
        return await firstValueFrom(
            this.http.post<IAdminSettingsResponse>(API.saveProfileTemplate, ctg)
        );
    }

    public async deleteProfileTemplate(pt: IProfileTemplate): Promise<IAdminSettingsResponse> {
        console.log("deleteProfileTemplate", pt);
        console.log("Calling delete to: ", API.deleteProfileTemplate + pt.template_profile_id);
        return await firstValueFrom(
            this.http.delete<IAdminSettingsResponse>(API.deleteProfileTemplate + pt.template_profile_id)
        );
    }

    public getSystemSettings(forAdmin?: boolean): Observable<ISystemSettingsResponse> {
        return this.http.get<ISystemSettingsResponse>(API.getSystemSettings + (forAdmin == true ? "?admin=true" : ""));
    }

    public async getUsers(): Promise<IAdminSettingsResponse> {
        return await firstValueFrom(
            this.http.get<IAdminSettingsResponse>(API.getUsers)
        );
    }

    public async getRoles(): Promise<IAdminSettingsResponse> {
        return await firstValueFrom(
            this.http.get<IAdminSettingsResponse>(API.getRoles)
        );
    }

    public async saveUserRole(user: IUser): Promise<IAdminSettingsResponse> {
        console.log("saveUserRole: ", user);
        return await firstValueFrom(
            this.http.post<IAdminSettingsResponse>(API.saveUserRole, user)
        );
    }

    public async saveSystemSettings(sysSettings: ISystemSettings): Promise<ISystemSettingsResponse> {
        console.log("saveSystemSettings: ", sysSettings);
        return await firstValueFrom(
            this.http.post<ISystemSettingsResponse>(API.saveSystemSettings, sysSettings)
        );
    }

    public findAccount(sapId: string): Observable<IAccountResponse> {
        console.log(`findAccount: ${sapId}`);
        return this.http.get<IAccountResponse>(API.findAccount + sapId);
    }

    public runJob(countryCode?: string) {
        return this.http.get(API.runPricingOutputJob + countryCode);
    }

    public getRecentSearches(userId: number): Observable<IRecentSearchesResponse> {
        console.log(`getRecentSearches: ${userId}`);
        return this.http.get<IRecentSearchesResponse>(API.getRecentSearches + userId);
    }

}
