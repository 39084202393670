import { Component, Input } from "@angular/core";
import { FileInfo } from "@shared/model/file";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";


@Component({
    selector: "pcc-carousel-wrapper",
    templateUrl: "./carousel-wrapper.component.html",
    styleUrls: [
        "./carousel-wrapper.component.scss"
    ]
})
export class CarouselWrapperComponent {
    @Input() public images: FileInfo[] = [];

    @Input() public currentIndex = 0;

    @Input() public title: string;

    @Input() public canExpand = false;

    public constructor(
        public activeModal: NgbActiveModal
    ) { }

    public closeModal(): void {
        this.activeModal.dismiss("Close click");
    }
}
