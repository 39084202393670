import { Component, OnInit, OnChanges } from "@angular/core";
import { Router } from "@angular/router";
import { Sort } from "@angular/material/sort";
import { UtilService } from "@shared/service/util.service";

import { ISystemSettings } from "@shared/model/system-settings";
import { AdminFacade } from "../../../facade/admin.facade";
import { IAccountSettings, AccountSettings, StatusEnum } from "@shared/model/account-settings";
import { PCCAlertService } from "../../../service/alert.service";
import { IAccountResponse, ISessionCount } from "@shared/model/account-response";
import { IAccount } from "@shared/model/account";
import { IBuyingGroup } from "@shared/model/buying-group";
import { Country } from "@shared/model/country";
import { PCCClientError } from "../../../shared/model/pcc-client-error";
import { ErrorUtils } from "@shared/model/error/error-utils";

interface AccountPair {
    account: IAccount,
    accountSettings: IAccountSettings
}

const SETTINGS_TYPES = {
    COUNTRY: "Country",
    BUYING_GROUP: "Buying group",
    CORP_ACCOUNT: "Corporate account"
}

@Component({
    selector: "pcc-account-search",
    templateUrl: "./account-search.component.html",
    styleUrls: [
        "./account-search.component.scss"
    ]
})
export class AccountSearchComponent implements OnInit, OnChanges {

    public errorMsg: string;

    public searching = false;

    public accounts: IAccountResponse[];

    public buyingGroups: IBuyingGroup[] = [];

    public sortedCountryData: IAccountResponse[] = [];

    public sortedBuyingGroupData: IAccountResponse[] = [];

    public sortedCorpData: IAccountResponse[] = [];

    public showSelectConfig = false;

    public availCountries: Country[] = [];

    public systemSettings: ISystemSettings;

    public selectedSettingsType: string = SETTINGS_TYPES.COUNTRY;

    public settingTypes = Object.values(SETTINGS_TYPES);

    public showCountrySettings = false;

    public showBuyingGroupSettings = false;

    public showCorpSettings = false;

    public showDraft = false;

    private acctSettingsList: IAccountSettings[];

    public constructor(
        private adminFacade: AdminFacade,
        private router: Router,
        private alertService: PCCAlertService
    ) {}

    public async ngOnInit(): Promise<void> {
        try {
            this.alertService.setBusy(true, "Loading accounts...");

            this.systemSettings = await this.adminFacade.getSystemSettingsCached();

            // List of all buying groups we have configured.
            this.buyingGroups = this.systemSettings.buyingGroups;

            // List of all existing countries we have configured in account settings.
            this.availCountries = this.systemSettings.countries;

            // Wrap each account settings object with an account response.
            // This is intended to distinguish between when all account
            // settings are being shown and when we already have an account
            // and a fully populated (list) of account settings.
            this.acctSettingsList = await this.adminFacade.getAllAccountSettings();

            this.updateView();

            this.alertService.setBusy(false);
        } catch (err) {
            console.error("Error retrieving all account settings");
            this.alertService.setBusy(false);
        }
    }

    public ngOnChanges(changes: any): void {
        console.log("ngOnChanges: ", changes);
        this.ngOnInit();
    }

    public groupItems(accounts: IAccountResponse[]): void {
        this.sortedCountryData = [];
        this.sortedBuyingGroupData = [];
        this.sortedCorpData = [];
        accounts.forEach((acctResp: IAccountResponse): void => {
            const accountSettings = acctResp.accountSettings;
            if (accountSettings.buyingGroupId) {
                this.sortedBuyingGroupData.push(acctResp);
            } else if (accountSettings.isCorp) {
                this.sortedCorpData.push(acctResp);
            } else {
                this.sortedCountryData.push(acctResp);
            }
        });
    }

    public async searchAccountSettings(accountSettingsId?: number): Promise<IAccountResponse> {
        console.log("searchAccountSettings: ", accountSettingsId);

        this.alertService.setBusy(true, "Searching...");
        if (!accountSettingsId) {
            console.error("No account settings id specified.");
            return {
                success: false,
                error: new PCCClientError("INVALID_ARGS", "No account specified")
            };
        }

        delete this.errorMsg;

        try {
            const resp: IAccountResponse = await this.adminFacade.findAccount(accountSettingsId, true);
            console.log("Search result for account and account settings: ", resp);

            this.alertService.setBusy(false);

            if (!resp.success) {
                const msg = `Error searching for account: ${ErrorUtils.getErrorMessage(resp.error)}`;
                this.errorMsg = msg;
                return resp;
            }

            console.log("Success");

            return resp;
        } catch (err) {
            console.error("Error calling getAccountSettings: ", err);
            this.alertService.setBusy(false);
            return {
                success: false, error: err
            };
        }
    }

    public async startSession(account: IAccount, acctSettings: IAccountSettings, sessionCount: ISessionCount): Promise<boolean> {
        console.log("startSession: ", acctSettings);
        console.log("sessionCount=", sessionCount);

        const newSession = await this.adminFacade.startAdminSession(account, acctSettings as AccountSettings, this.systemSettings, sessionCount);
        console.log("newSession=", newSession);

        this.router.navigate([
            "/admin/config/",
            acctSettings.account_settings_id,
            "general"
        ], {
            state: {
                accountSettings: acctSettings
            }
        });

        return true;
    }

    /**
     * User clicks on row in table.
     * 1) If an account is included in the data, this is data from a previous
     *    search.  User knows what they want, just go there (start session)
     * 2) If an account is not included, this is data from get all account
     *    settings.  Need to run a new search, then select either the ACTIVE or
     *    DRAFT that comes back depending on what was initially clicked by the user.
     */
    public async editAccountSettings(selectedAccount: IAccountResponse): Promise<void> {
        console.log("editAccountSettings: ", selectedAccount);
        const account = selectedAccount.account;
        let acctSettings = selectedAccount.accountSettings;
        const settingsId = acctSettings.account_settings_id;
        const accountSettingsId = acctSettings?.account_settings_id;

        try {
            if (account && acctSettings) {
                await this.startSession(account, acctSettings, selectedAccount.sessionCount);
                return;
            }

            const acctResp = await this.searchAccountSettings(accountSettingsId);
            console.log("acctResp=", acctResp);
            if (!acctResp.success) {
                console.log("Search failed");
                return;
            }

            acctSettings = acctResp.accountSettings;
            if (acctSettings && acctSettings.account_settings_id === settingsId) {
                await this.startSession(acctResp.account, acctSettings, acctResp.sessionCount);
                return;
            }
            console.error("No results back match the selected account settings!", selectedAccount, acctResp);
            this.errorMsg = "No results back match the selected account settings!";

        } catch (err) {
            console.error("Error selecting account settings: ", err);
            this.errorMsg = `Error selecting account settings: ${ErrorUtils.getErrorMessage(err)}`;
        }
    }

    public lastSort: Sort;

    public resort(): void {
        console.log("resort");
        if (this.lastSort) {
            this.sortData(this.lastSort);
        } else {
            this.sortData({
                active: "country", direction: "asc"
            });
        }
        console.log("lastSort not defined, not sorting...");
    }

    public sortData(sort: Sort): void {
        console.log("sortData: ", sort);
        const data = this.accounts.slice();

        this.lastSort = sort;

        if (!sort.active || sort.direction === "") {
            this.groupItems(data);
            return;
        }

        const sortedData = data.sort((a1: IAccountResponse, b1: IAccountResponse): number => {
            const isAsc = sort.direction === "asc";
            const a = a1.accountSettings;
            const b = b1.accountSettings;
            switch (sort.active) {
                case "sap_id":
                    return UtilService.compare(a.sap_id, b.sap_id, isAsc);
                case "customer_name":
                    return UtilService.compare(a.customer_name, b.customer_name, isAsc);
                case "country":
                    return UtilService.compare(a.countryCd, b.countryCd, isAsc);
                case "status":
                    return UtilService.compare(a.status, b.status, isAsc);
                case "visible":
                    return UtilService.compareBool(a.visible, b.visible);
                default:
                    return 0;
            }
        });
        this.groupItems(sortedData);
    }

    // New account settings has been created on service side and returned here to be displayed to user.
    public newAccountSettingsAdded(createResp: IAccountResponse): void {
        console.log("newAccountSettingsAdded: ", createResp);
        this.editAccountSettings(createResp);
    }

    public settingsTypeChanged(): void {
        console.log("settingsTypeChanged: ", this.selectedSettingsType);

        this.showCountrySettings = this.selectedSettingsType === SETTINGS_TYPES.COUNTRY;
        this.showBuyingGroupSettings = this.selectedSettingsType === SETTINGS_TYPES.BUYING_GROUP;
        this.showCorpSettings = this.selectedSettingsType === SETTINGS_TYPES.CORP_ACCOUNT;

    }

    public showDraftChanged(): void {
        console.log("showDraftChanged: ", this.showDraft);
        this.updateView();
    }

    private updateView(): void {

        this.accounts = this.acctSettingsList
            .filter((acctSettings: IAccountSettings): boolean => this.showDraft || acctSettings.status === StatusEnum.ACTIVE.value)
            .map((acctSettings: IAccountSettings): AccountPair => (
                {
                    account: null, accountSettings: acctSettings
                }
            ));

        console.log("accounts=", this.accounts);

        this.resort();

        this.groupItems(this.accounts);

        this.settingsTypeChanged();
    }

    public statusDisplayName(statusStr: string): string {
        const statusEnum = StatusEnum.forString(statusStr);
        if (statusEnum) {
            return statusEnum.displayName;
        }
        return statusStr;
    }
}
