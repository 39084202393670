import { Component, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { IPdfFile } from "@shared/model/pdf";
import { ISeismicLink, SeismicDocument } from "@shared/model/seismic";

/**
 * Modal wrapper to display Seismic documents.
 * Three separate viewers here:
 * PDF - ngx-extended-pdf-viewer - expects an signed S3 bucket url, but could display a downloaded or public pdf as well.
 * MP4 - native-HTML video handling - expects a stream proxied by our services from Seismic download. (Only works in chrome so far)
 */

@Component({
    selector: "pcc-doc-wrapper",
    templateUrl: "./doc-wrapper.component.html",
    styleUrls: [
        "./doc-wrapper.component.scss"
    ]
})
export class DocWrapperComponent {

    @Input() public docUrl?: string;

    @Input() public link?: string;

    @Input() public fileName?: string;

    @Input() public format?: string;

    @Input() public isVideo = false;

    @Input() public isPDF = false;

    @Input() public showSeismic = false;

    public constructor(
        public activeModal: NgbActiveModal
    ) {
    }

    public closeDoc(): void {
        this.activeModal.dismiss("Close click");
    }

    public pageRendered(e: CustomEvent): void {
        console.log("page-rendered", e);
    }

    public pagesInitialized(e: CustomEvent): void {
        console.log("pages-initialized", e);
    }

    public pageChange(pageNum: number): void {
        console.log("pageChange: ", pageNum);
    }

    public pdfError(error: any): void {
        console.error("pdfError: ", error);
    }

    public onEvent(eventName: string, event): void {
        console.log("onEvent: ", eventName, event);
    }

    public showPdf(file: IPdfFile): void {
        this.fileName = file.filename;
        this.docUrl = file.url;
        this.format = "PDF";
        this.isPDF = true;
        this.showSeismic = false;
    }

    public showSeismicDoc(link: ISeismicLink, fileInfo: SeismicDocument): void {
        this.link = link.docCenterUrl ?? link.url;
        this.fileName = fileInfo.fileName;
        this.docUrl = fileInfo.url;
        this.format = fileInfo.format;
        this.isVideo = (fileInfo.format === "MP4");
        this.isPDF = (fileInfo.format === "PDF");
        this.showSeismic = true;
    }
}
