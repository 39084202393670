import { Component, DoCheck, EventEmitter, Input, KeyValueDiffers, OnDestroy, OnInit, Output } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ModalityEnum } from "@shared/model/modality";
import { SettingsFlags } from "@shared/model/settings-flags";
import { PCCSession } from "@shared/model/pcc-session";
import { IProfile } from "@shared/model/profile";
import { TEMPLATE_TYPES } from "@shared/model/profile-template";
import { ItemUtils, IModalityTests } from "@shared/model/profile-item";
import { Subscription, firstValueFrom } from "rxjs";
import { AppFacade } from "../../facade/app.facade";
import { SessionModeEnum } from "@shared/model/session-mode-enum";
import googleAnalytics from "../../analytics/googleAnalytics";
import {envUtils} from "@client/globals/env";

const MODALITY_ICONS = {
    "lab": "/assets/images/test_lab.png",
    "snap": "/assets/images/snap.png",
    "ihd": "/assets/images/ihd_price.png",
    "lab_ihd": "/assets/images/lab_ihd.png",
};

@Component({
    selector: "pcc-slot",
    templateUrl: "./slot.component.html",
    styleUrls: [
        "./slot.component.scss"
    ]
})
export class SlotComponent implements OnInit, OnDestroy, DoCheck {

    @Input() public profile: IProfile;

    @Input() public canSelect = true;

    @Input() public selectedProperty = "selected";

    @Input() public canEdit = true;

    // Determines if accepted practice price shows as a clickable link
    @Input() public canEditPracticePrice = true;

    // Determines if accepted pet owner price shows as a clickable link
    @Input() public canEditPetOwnerPrice = true;

    @Input() public canReset: boolean;

    @Input() public canDelete: boolean;

    @Input() public petOwnerPricePage: boolean = false;

    @Output() public editPriceClick = new EventEmitter<IProfile>();

    @Output() public resetClick = new EventEmitter<IProfile>();

    @Output() public slotClick = new EventEmitter<IProfile>();

    @Output() public deleteClick = new EventEmitter<IProfile>();

    @Output() public selected = new EventEmitter<IProfile>();

    public session: PCCSession;

    public sessionSub: Subscription;

    public profileName: string;

    public selectedTestsByModality: IModalityTests[];

    public differ: any;

    // Used to distinguish checkbox between slots when we don't necessarily have a profile id ot use.
    public uid: string;

    public isDisabled = false;

    public selectDisabled = false;

    public flags: SettingsFlags;

    public isCorp = false;

    public handlingEmail = envUtils.HANDLING_EMAIL;

    public constructor(
        public appFacade: AppFacade,
        private translateService: TranslateService,
        differs: KeyValueDiffers
    ) {
        this.differ = differs.find([]).create();
    }

    public async ngOnInit(): Promise<void> {

        // Locale isn't getting set on this module's translate service.  Explicitly set it here.
        // TODO: Figure out where the new instance is getting created (module imports in shared and client module rather than app.module
        await firstValueFrom(this.translateService.use(this.appFacade.getLocale()));

        this.sessionSub = this.appFacade.getCurrentSession().subscribe(
            (session: PCCSession): void => {
                this.setSession(session);
                this.handlingEmail = session.accountSettings.handling_email;
            }
        );

        this.profileName = this.profile.display_name;

        if (this.isStaticProfile()) {
            this.isDisabled = true;
            this.selectDisabled = this.profile.defaultSelected;
            this.canEdit = false;
        }

        if (this.canReset === undefined || this.canReset === null) {
            this.canReset = true;
        }

        this.reparse();
    }

    public ngOnDestroy(): void {
        this.sessionSub.unsubscribe();
    }

    public async reparse(): Promise<void> {
        this.selectedTestsByModality = ItemUtils.getTestsByModality(this.profile.profileItems);

        this.profileName = this.profile.display_name;

        // Need a unique id.
        // For persisted profiles, the profile id works.
        // But for place-keepers generated from a template, use the template id instead.
        // A single template will never have more than one place-keeper generated from it at a time that
        // hasn't been persisted first.
        this.uid = `${(this.profile.enrollment_profile_id || this.profile.template_profile_id)}`;
    }

    public ngDoCheck(): void {
        const changes = this.differ.diff(this.profile);

        if (changes) {
            changes.forEachChangedItem((elt: any): void => {
                console.log("changes to slot: ", elt);
                this.reparse();
            });
        }
    }

    public setSession(session: PCCSession): void {
        console.log("slot.setSession: ", session);
        this.session = session;

        if (!session || !session.accountSettings) {
            return;
        }

        this.setupSessionMode();

        // When a profile is no longer supported by it's template, show it here, but the only operation allowed is to delete it.
        // If enrollment has closed, then the user can't delete profiles, so this logic doesn't apply.
        if (this.session.sessionMode !== SessionModeEnum.EXPIRED && this.profile.invalid) {
            this.setupInvalidView();
        }

        this.flags = this.session.accountSettings?.flags;
        this.isCorp = this.session.accountSettings.isCorp && !this.session.accountSettings.as_independent;
    }

    public getModalityIcon(modality: string): string {
        if (ModalityEnum.REF_LAB.equals(modality)) {
            return MODALITY_ICONS.lab;
        }
        if (ModalityEnum.SNAP.equals(modality)) {
            return MODALITY_ICONS.snap;
        }
        if (ModalityEnum.IHD.equals(modality)) {
            return MODALITY_ICONS.ihd;
        }
        if (ModalityEnum.REF_LAB_IHD.equals(modality)) {
            return MODALITY_ICONS.lab_ihd;
        }
        console.error("No icon for modality: ", modality);
        return "";
    }

    public editPriceClicked(): void {
        googleAnalytics.registerEvent("edit_price_click", "clickEvent");
        if (!this.profile.error && !this.profile.loading) {
            this.editPriceClick.emit(this.profile);
        } else {
            console.log("editPriceClicked ignored");
        }
    }

    public resetClicked(): void {
        this.resetClick.emit(this.profile);
    }

    public deleteClicked(): void {
        this.deleteClick.emit(this.profile);
    }

    public slotClicked(): void {
        this.slotClick.emit(this.profile);
    }

    public slotPopulated(): boolean {
        return this.profile != null && this.profile.completed === true;
    }

    public showAcceptedPetOwnerPrice(): boolean {
        return this.profile?.acceptedPetOwnerPrice > 0;
    }

    public toggleProfileSelected(): void {
        this.selected.emit(this.profile);
    }

    public selectedChange(event: Event): void {
        console.log("selectedChange: ", event);
        this.profile[this.selectedProperty] = !this.profile[this.selectedProperty];

        this.selected.emit(this.profile);
    }

    /**
     * Panel price (acceptedPracticePrice) is allowed for non-corp accounts only.
     * Return true if allowed and panel is either loading or has acceptedPracticePrice set
     */
    public shouldShowAcceptedPracticePrice(): boolean {
        return this.flags?.show_special_price
            && (
                (!this.isCorp && this.profile.loading)
                || this.profile.acceptedPracticePrice > 0
            );
    }

    /**
     * Practice price is allowed for corp accounts (only) when showPracticePrice is set to true.
     * Return true if allowed and customerPrice is populated.
     */
    public shouldShowPracticePrice(): boolean {
        const isAllowed = this.flags?.profile_show_practice_price;
        return isAllowed && this.profile.customerPrice > 0;
    }

    /**
     * Pet owner price is allowed for individual (non-corp) accounts and for corp accounts when
     *   showPetOwnerPrice is set to true.
     * Return true if allowed and profile is loading or acceptedPetOwnerPrice is populated.
     */
    public shouldShowPetOwnerPrice(): boolean {
        return this.flags?.show_pet_owner_price
            && (this.petOwnerPricePage
                || (this.isCorp && this.profile.loading)
                || this.profile.acceptedPetOwnerPrice > 0);
    }

    public shouldShowPetOwnerPriceButton(): boolean {
        return this.flags?.can_edit_pet_owner_price
            && this.isCorp
            && this.canEditPetOwnerPrice
            && (this.profile.loading
                || this.profile.acceptedPetOwnerPrice !== undefined);
    }

    public shouldShowPracticePriceButton(): boolean {
        return this.flags?.can_edit_special_price
            && this.canEditPracticePrice
            && (this.profile.loading
                || this.profile.acceptedPracticePrice !== undefined);
    }

    public shouldShowPetOwnerPriceEditField(): boolean {
        return this.petOwnerPricePage;
    }

    private setupInvalidView(): void {
        // User must delete the profile before they can continue, so that is the only operation allowed here.
        this.canDelete = true;

        this.canEdit = false;
        this.canEditPracticePrice = false;
        this.canEditPetOwnerPrice = false;
        this.canReset = false;

        // canSelect disabled
        this.isDisabled = true;
        this.selectDisabled = true;
    }

    private setupSessionMode(): void {
        console.log("setupSessionMode: ", this.session.sessionMode);

        if (this.session.sessionMode !== SessionModeEnum.EXPIRED || this.profile.price_check !== true || !this.profile.selected) {
            // All functionality intact for a new or active enrollment.
            return;
        }

        // Editing a submitted, closed enrollment.
        // Cannot modify or delete existing panels (but grey out not hide)
        // show...Price left alone.
        this.canEdit = false;
        this.canReset = false;
        this.canDelete = false;
        this.canEditPracticePrice = false;
        this.canEditPetOwnerPrice = false;
        // canSelect disabled
        this.isDisabled = true;
        this.selectDisabled = true;
    }

    private isStaticProfile(): boolean {
        return this.profile.profileTemplate.templateType === TEMPLATE_TYPES.STATIC;
    }
}
