<mat-form-field appearance="fill">
    <input
            [disabled]="disabled"
            matInput
            [(ngModel)]="dateAsDate"
            [matDatepicker]="$any(datePicker)"
            [min]="minAsDate()"
            (dateChange)="dateChanged($event)"
            class="bold-value"
            name="datePicker"
    >
    <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
    <mat-datepicker
        #datePicker
    ></mat-datepicker>
    <mat-hint></mat-hint>
</mat-form-field>