import { Injectable } from "@angular/core";
import { IAPIResponseData } from "@shared/model/service/service";
import { HttpRequest, HttpClient } from "@angular/common/http";
import { BehaviorSubject } from "rxjs";
import { AuthService } from "frontend/app/auth/auth.service";
import NiceTimer from "../utils/timer";

const timer: NiceTimer = new NiceTimer(window);

@Injectable()
export class VersionService {
    public version: BehaviorSubject<string> = new BehaviorSubject(null);

    constructor(
        private http: HttpClient,
        private authService: AuthService
    ) {
        this.doTimer();
    }

    /**
     * Called from auth.interceptor every time an ajax request is sent.
     * Used to inform the timer that the user is actively using the app.
     */
    httpEvent(req: HttpRequest<any>) {
        console.log("version.httpEvent: ", req);
        timer.userEvent();
    }

    private doTimer() {
        console.log("doTimer");
        timer.setInterval(
            this.checkVersion.bind(this),
            30 * 1000, // check every 30 seconds if the version has changed as long as user is actively using app.
            8 * 60 * 60 * 1000, // check every 8 hours even if no user activity
            1 * 60 * 1000 // User has used app if there has been activity in the past 1 minute
        );
        console.log("After");
    }

    async checkVersion(): Promise<boolean> {
        console.log("checkVersion");
        if (this.authService.isLoggedIn()) {
            try {
                const result = await this._requestVersion();
                if (this.version.getValue() !== result.data) {
                    this.version.next(result.data);
                    return true;
                }
            } catch (error) {
                console.error(error);
            }
        }
        return false;
    }

    _requestVersion(): Promise<IAPIResponseData<string>> {
        return this.http.get("/api/version").toPromise();
    }
}
