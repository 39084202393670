import { Injectable } from "@angular/core";

import { IEnrollResponse } from "@shared/model/service/enroll-service";

import { IEnrollment, EnrollUtils } from "@shared/model/enrollment";
import { ISpecies } from "@shared/model/species";

import { PCCSession } from "@shared/model/pcc-session";
import { PCCPriceService, SamePriceEnum } from "./price.service";
import { PCCSessionService } from "./pcc-session.service";
import { IProfile, ProfileUtils } from "@shared/model/profile";
import { IProfileItem } from "@shared/model/profile-item";

import AwaitLock from "await-lock";
const LOCK = new AwaitLock();

/**
 * Profile Service
 */

@Injectable({
    providedIn: "root"
})
export class ProfileService {

    public constructor(
        private priceService: PCCPriceService,
        private sessionService: PCCSessionService
    ) {
    }

    /**
     * User has clicked save on build profile screen.  Need to lookup prices, save
     *  the enrollment, and update objects here.
     *
     * NOTE: Need to throttle this method so it can only be called once at a time
     * to avoid the user creating profiles faster than the service responds.
    */
    public async submitProfile(profile: IProfile, enrollInfo: IEnrollment, session: PCCSession, runPricing: boolean): Promise<boolean> {
        console.log("========== submitProfile: ", profile, enrollInfo, " ==========");
        console.log("runPricing=", runPricing);

        // Check if this profile contains IAUA.
        // If so, then add an extra "hidden" profile with the non-S code
        // If not, make sure to clean up any existing extra profile that might be left over from a previous selection.
        profile.extraProfile = await this.handleExtraProfile(profile, enrollInfo, session);

        profile.loading = true;
        await LOCK.acquireAsync();
        console.log("========== PROFILE Lock acquired! ==========");
        try {
            return await this.submitProfileInner(profile, enrollInfo, session, runPricing);
        } finally {
            console.log("========== Releasing PROFILE lock ==========");
            LOCK.release();
        }
    }

    private async submitProfileInner(profile: IProfile, enrollInfo: IEnrollment, session: PCCSession, runPricing: boolean): Promise<boolean> {
        console.log("========== submitProfileInner: runPricing=", runPricing, " ==========");
        profile.loading = true;
        profile.error = null;
        try {
            enrollInfo.dirty = true;

            if (runPricing) {
                console.log("Running pricing...");

                // Set price_check to false explicitly.
                // In the case of modify existing enrollment, this flag will have been
                // changed to true to indicate the back-end process has sent the enrollment
                // onwards.  Need to set back to false here so it gets processed again.
                profile.price_check = false;

                const priceRan = await this.priceService.runPricing(profile, session);
                console.log("priceRan=", priceRan, profile);
                if (!priceRan) {
                    console.error("Error running pricing!");
                    if (!profile.error) {
                        profile.error = { messageKey: "profile.PROFILE_PRICING_ERROR" };
                    }
                    return false;
                }

                if (!session.isCorporate) {
                    // Check if multiple profiles exist in this enrollment with the same matched profile test code.
                    // If multiples exist and prices don't match, enforce same price
                    // - either copy old price into this profile, or copy this profile's pricing (default values) to old profiles.
                    await this.samePriceCheck(enrollInfo, profile);
                }
            }

            // TODO: We need this to be saveProfile instead of saveEnrollment.
            // But it needs to be able to both save an update to an existing profile, update an existing profile, insert a new profile, or delete a profile (for extra profiles).
            // And after save, the enrollment should be updated to contain this info...

            const saveResp: IEnrollResponse = await this.sessionService.saveEnrollment(enrollInfo);

            profile.loading = false;

            console.log("saveResp=", saveResp, saveResp?.success);
            if (saveResp.success) {
                enrollInfo.dirty = false;
                console.log("profile.error=", profile.error);
                return true;
            }

            console.error("Error saving session: ", saveResp.error);
            profile.error = { messageKey: "profile.PROFILE_SAVE_ERROR" };
            return false;
        } catch (err) {
            console.error("Error calling submitProfileInner: ", err);
            profile.loading = false;
            return false;
        } finally {
            console.log("========== Done submitProfileInner. ==========");
        }

    }

    private async samePriceCheck(enrollInfo: IEnrollment, profile: IProfile): Promise<boolean> {
        // Check to see if other profiles exist with same matched profile but different price...
        const samePriceResp = await this.priceService.samePriceCheck(enrollInfo, profile);
        console.log("samePriceResp=", samePriceResp);
        let success = true;
        if (samePriceResp === SamePriceEnum.COPY) {
            success = this.priceService.copyPrices(profile, enrollInfo);
            console.log("copyPrices success=", success);
        } else if (samePriceResp === SamePriceEnum.REVERT) {
            success = this.priceService.revertPrices(profile, enrollInfo);
            console.log("revertPrices success=", success);
        }
        return success;
    }

    /**
     * If the current srcProfile contains IDEXX Anywhere (SEDUA or SEDUA/SEDUPC), then create a
     * second "extra" profile with the in-house version.
     */
    private async handleExtraProfile(srcProfile: IProfile, enrollInfo: IEnrollment, session: PCCSession): Promise<IProfile> {
        console.log("handleExtraProfile: ", srcProfile, srcProfile.extraProfile);
        let extraProfile: IProfile = srcProfile.extraProfile;

        const containsIAUA = ProfileUtils.containsIDEXXAnywhereUrinalysis(srcProfile.profileItems)
            || ProfileUtils.containsIDEXXAnywhereUPC(srcProfile.profileItems);

        if (extraProfile && !containsIAUA) {
            if (extraProfile.enrollment_profile_id) {
                console.log("Remove extraProfile from enrollment.  Not needed here");
                this.sessionService.removeProfile(enrollInfo, extraProfile);
            }
            return null;
        }

        if (containsIAUA) {
            console.log("Contains idexx anywhere");
            extraProfile = this.createExtraProfile(srcProfile, extraProfile, session);
            EnrollUtils.addProfile(enrollInfo, extraProfile, srcProfile);
            return extraProfile;
        }
        console.log("extraProfile not relevant");
        return null;
    }

    private createExtraProfile(srcProfile: IProfile, oldExtraProfile: IProfile, session: PCCSession): IProfile {

        // Re-use extra profile already defined.
        const clientId = oldExtraProfile ? oldExtraProfile.clientId : EnrollUtils.nextClientId();

        const extraProfile = ProfileUtils.copyProfile(srcProfile);

        extraProfile.extraProfile = null;
        extraProfile.clientId = clientId;
        extraProfile.isExtra = true;
        extraProfile.enrollment_profile_id = oldExtraProfile?.enrollment_profile_id;
        extraProfile.version = oldExtraProfile?.version;
        extraProfile.goalQuantity = 0;
        extraProfile.goalTotalProfit = 0;
        extraProfile.goalPetOwnerCharge = 0;

        const newProfileItems = this.getUrinalysisProfileItems(srcProfile, session);
        extraProfile.profileItems = newProfileItems;
        extraProfile.products = ProfileUtils.getProductsForMatch(newProfileItems);
        if (srcProfile.matchedProfile) {
            extraProfile.matchedProfile = ProfileUtils.copyProduct(srcProfile.matchedProfile);
            extraProfile.matchedProfile.test_code = srcProfile.matchedProfile.test_code.slice(0, -1);
            // Replace sap material number with non-S code
            let sapMatNum = srcProfile.matchedProfile.sap_material_number || "";
            sapMatNum = sapMatNum.replace(/(.*)S(-.*)/, "$1$2");
            extraProfile.matchedProfile.sap_material_number = sapMatNum;
            extraProfile.profile_test_code = extraProfile.matchedProfile.test_code;
        }

        return extraProfile;
    }

    // Return the same selected profile items as the original profile, but substitute the
    // RefLab Urinalysis code in place of Idexx Anywhere and Reflex UPC in place of SEDUPC
    private getUrinalysisProfileItems(srcProfile: IProfile, session: PCCSession): IProfileItem[] {
        const urProfileItem = this.getUrinalysisProfileItem(srcProfile, session);
        const upcProfileItem = this.getUrinalysisUPCProfileItem(srcProfile, session);
        return srcProfile.profileItems.map((pi: IProfileItem): IProfileItem => {
            // Swap out the SEDUA profile item for the 910 profile item
            if (ProfileUtils.isIDEXXAnywhere(pi)) {
                return urProfileItem;
            }
            // Swap out the SEDUA/SEDUPC profile item with the 910/950 profile item.
            if (ProfileUtils.isIDEXXAnywhereUPC(pi)) {
                return upcProfileItem;
            }
            return pi;
        });
    }

    // Find the RefLab Urinalysis profile item that matches the selected Idexx Anywhere profile item.
    // NOTE: There are multiple RefLab Urinalysis profile items defined in system settings, so we
    // need to filter by the same country, species, and category in order to find the correct item.
    private getUrinalysisProfileItem(profile: IProfile, session: PCCSession): IProfileItem {
        if (!profile) {
            return null;
        }

        const allProfileItems = session.systemSettings.profileItems;
        const iauaProfileItem = ProfileUtils.getIDEXXAnywhereUrinalysis(profile.profileItems);

        // If reflex upc is selected, need to replace with the urinalysis upc (910, 950)
        // if standard SEDUA selected, then just replace with urinalysis (910)
        // SEDUPC can never be selected as a standalone.

        // Find the 910 code that has the same category, species, and country as the original profile item.
        const labItems = allProfileItems
            .filter((profileItem: IProfileItem): boolean => ProfileUtils.isUrinalysis(profileItem));

        return this.findMatchingProfileItem(labItems, iauaProfileItem, profile.species);
    }

    // Find the RefLab Urinalysis profile item that matches the selected Idexx Anywhere profile item.
    // NOTE: There are multiple RefLab Urinalysis profile items defined in system settings, so we
    // need to filter by the same country, species, and category in order to find the correct item.
    private getUrinalysisUPCProfileItem(profile: IProfile, session: PCCSession): IProfileItem {
        if (!profile) {
            return null;
        }

        const allProfileItems = session.systemSettings.profileItems;
        const iauaProfileItem = ProfileUtils.getIDEXXAnywhereUPC(profile.profileItems);

        // If reflex upc is selected, need to replace with the urinalysis upc (910, 950)
        // if standard SEDUA selected, then just replace with urinalysis (910)
        // SEDUPC can never be selected as a standalone.

        // Find the 910/950 code that has the same category, species, and country as the original profile item.
        const labItems = allProfileItems
            .filter((profileItem: IProfileItem): boolean => ProfileUtils.isUrinalysisWithReflexUPC(profileItem));

        return this.findMatchingProfileItem(labItems, iauaProfileItem, profile.species);
    }

    private speciesMatch(profileItem: IProfileItem, species: ISpecies): boolean {
        return !profileItem.speciesList.length
            || profileItem.speciesList.some((profileItemSpecies: ISpecies): boolean => profileItemSpecies.species_id === species.species_id);
    }

    private findMatchingProfileItem(profileItems: IProfileItem[], searchProfileItem?: IProfileItem, species?: ISpecies): IProfileItem {
        if (!searchProfileItem) {
            return null;
        }
        const foundItem = profileItems
            .find((profileItem: IProfileItem): boolean => {
                return profileItem.category.category_id === searchProfileItem.category.category_id
                    && this.speciesMatch(profileItem, species)
                    && profileItem.countryCd === searchProfileItem.countryCd;
            });

        if (!foundItem) {
            console.error("Didn't find matching profileItem: ", searchProfileItem, profileItems);
        }

        return foundItem;
    }
}
