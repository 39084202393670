/**
   * Battery-friendly timer service.
   * Standard ajax polling will kill battery life in mobile device, so this version will only trigger callback if a user is actively using the app.  Using forceDelay, the callback will still trigger, but only once per day or some longer interval
   *
   * Takes four parameters:
   * @param callback: function - Callback to call when timer triggers.
   * @param tryDelay: number - Timer will fire every [tryDelay] ms.  As long as the user is actively using the app, fire the callback.
   * @param forceDelay: number - Even if user isn't actively using the app (on a separate browser tab, away from computer, etc), fire the callback every [forceDelay] ms.
   * @param usageInterval - The user is actively using the site if they've made an ajax call within the past [usageInterval] ms.
   */
class NiceTimer {
    win: Window;

    isFetching: boolean;

    lastTriggerId: number;

    timeouts: any;

    forceTimeouts: any;

    intervals: any;

    forceIntervals: any;

    constructor(win: Window) {
        this.win = win;
        this.isFetching = false;
        this.lastTriggerId = 0;
        this.timeouts = {
        };
        this.forceTimeouts = {
        };
        this.intervals = {
        };
        this.forceIntervals = {
        };
    }

    /**
     * This method will be called (see version.service) whenever the router fires a NavigationStart event.
     * As long as the user is using the app, the timer callback will trigger every [tryDelay] ms
     */
    userEvent() {
        if (this.isFetching) {
            return;
        }
        this.isFetching = true;
        const now = Date.now();
        Object.keys(this.timeouts).forEach((timeoutKey) => {
            const timeout = this.timeouts[timeoutKey];
            timeout.lastUsage = now;
        });
        Object.keys(this.intervals).forEach((intervalKey) => {
            const interval = this.intervals[intervalKey];
            if ((now - interval.lastTrigger) >= interval.tryDelay) {
                interval.lastTrigger = now - ((now - interval.from) % interval.tryDelay);
                interval.callback();
            }
        });
        this.isFetching = false;
    }

    /**
     * @param callback - Function to call when triggered.
     * @param tryDelay - Interval delay to check if should trigger.
     * @param forceDelay - Interval delay to trigger regardless of usage
     * @param usageInterval - Interval last usage must be within to trigger.
     *
     * Note: for setTimeout, only one or the other conditions can be met:
     * Either the user is using the app, and the callback is triggered (once) upon tryDelay ms.
     * Else, the callback will be triggered (once) after forceDelay
     */
    setTimeout(callback, tryDelay: number, forceDelay: number, usageInterval: number): number {
        const lastTriggerId = ++this.lastTriggerId;
        let now = Date.now();
        let timeout = {
            from: Date.now(), lastUsage: now, callback, tryDelay, forceDelay, usageInterval
        };
        this.timeouts[lastTriggerId] = timeout;

        if (tryDelay >= forceDelay) {
            throw new Error("Timer: forceDelay must be a longer interval than tryDelay");
        }
        if (usageInterval < tryDelay) {
            throw new Error("Timer: usageInterval must be longer than tryDelay");
        }

        this.forceTimeouts[lastTriggerId] = this.win.setTimeout(() => {
            now = Date.now();
            if ((now - timeout.from) >= timeout.forceDelay) {
                callback();
            } else if ((now - timeout.lastUsage) < usageInterval) {
                callback();
            }
        }, tryDelay);
        return lastTriggerId;
    }

    clearTimeout(id: string) {
        this.win.clearTimeout(this.forceTimeouts[id]);
        delete this.timeouts[id];
        delete this.forceTimeouts[id];
    }

    /**
     * @param callback - Function to call when triggered.
     * @param tryDelay - Interval delay to check if should trigger.
     * @param forceDelay - Interval delay to trigger regardless of usage
     * @param usageInterval - Interval last usage must be within to trigger.
     */
    setInterval(callback, tryDelay: number, forceDelay: number, usageInterval: number): number {
        const lastTriggerId = ++this.lastTriggerId;
        let now = Date.now();
        const interval = {
            from: now, lastTrigger: now, lastUsage: now, callback, tryDelay, forceDelay, usageInterval
        };
        this.intervals[lastTriggerId] = interval;

        if (tryDelay >= forceDelay) {
            throw new Error("Timer: forceDelay must be a longer interval than tryDelay");
        }
        if (usageInterval < tryDelay) {
            throw new Error("Timer: usageInterval must be longer than tryDelay");
        }

        // Here is the main timer driving the firing of the callback even if user isn't using app.
        // Fires every [tryDelay] ms regardless of activity.
        this.forceIntervals[lastTriggerId] = this.win.setInterval(() => {
            now = Date.now();
            if ((now - interval.lastTrigger) >= interval.forceDelay) {
                interval.lastTrigger = now;
                callback();
            } else if ((now - interval.lastUsage) < usageInterval) {
                interval.lastTrigger = now;
                callback();
            }
        }, tryDelay);

        return lastTriggerId;
    }

    clearInterval(id: number) {
        this.win.clearInterval(this.forceIntervals[id]);
        delete this.intervals[id];
        delete this.forceIntervals[id];
    }
}

export default NiceTimer;
