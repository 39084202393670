import { Injectable } from "@angular/core";
import { EventSourceClient } from "./event-source-client";
import { IAccount } from "@shared/model/account";
import { ManagedCountries } from "@shared/model/country";
import { ITestMixServiceResponse } from "@shared/model/service/test-mix-service";
import { Observable } from "rxjs";
import { shareReplay } from "rxjs/operators";

const CANADIAN_ORG = "CAS7";
const US_ORG = "USS3";

@Injectable()
export class TestMixService {

    public testmixObs: Observable<ITestMixServiceResponse>;

    public constructor(
        private eventSource: EventSourceClient
    ) {}

    public getHistory(account: IAccount): Observable<ITestMixServiceResponse> {
        console.log("getHistory:", account);
        const sapId = account.sap_id;
        let salesOrg: string;
        const countryCd: string = account.country_cd;

        if (ManagedCountries.CA === account.country_cd) {
            salesOrg = CANADIAN_ORG;
        } else {
            salesOrg = US_ORG;
        }

        return this.eventSource.get<ITestMixServiceResponse>(`/api/testmix/event-source`, {
            type: "LONG_REQUEST",
            timeout: 60 * 1000, // 60 second timeout
            params: {
                sapId,
                salesOrg,
                countryCd
            }
        });
    }

    public getTestMix(accountInfo: IAccount): Observable<ITestMixServiceResponse> {
        console.log("getTestMix");
        if (!accountInfo) {
            console.error("no account info");
            return null;
        }
        if (this.testmixObs) {
            return this.testmixObs;
        }

        this.testmixObs = this.getHistory(accountInfo).pipe(
            shareReplay()
        );

        // preload testmix
        this.testmixObs.subscribe(
            (data): void => {
                if (data.success === false) {
                    this.testmixObs = undefined;
                }
            },
            (): void => {
                this.testmixObs = undefined;
            }
        );

        return this.testmixObs;
    }

}
