import { Component, Input, Output, EventEmitter, OnInit, } from "@angular/core";

import { ISystemSettings } from "@shared/model/system-settings";

import { IAPIResponseData } from "@shared/model/service/service";
import { PCCAlertService } from "../../../service/alert.service";
import { CorpPricingAlgorithmService } from "../../../service/corp-pricing-algorithm.service";
import { AdminFacade } from "../../../facade/admin.facade";
import { CustomPageEditComponent } from "../../components/custom-page-edit/custom-page-edit.component";
import { AccountSettingsService } from "../../../service/account-settings.service";
import { PCCTranslateService } from "../../../service/translate.service";
import { ICustomPage } from "@shared/model/custom-page";
import { PCCClientError } from "../../../shared/model/pcc-client-error";

const RANGE_DOLLAR = "dollar";
const RANGE_PERCENT = "percent";

@Component({
    selector: "pcc-page-corp-pricing",
    templateUrl: "./page-corp-pricing.component.html",
    styleUrls: [
        "./page-corp-pricing.component.scss"
    ]
})
export class PageCorpPricingComponent extends CustomPageEditComponent implements OnInit {

    @Input() public selectedPage: ICustomPage;

    @Output() public pricingClicked = new EventEmitter<boolean>();

    public systemSettings: ISystemSettings;

    public priceAlg: string;

    // Used to determine if range is by dollars or percent.
    // If range is percent, convert the number value to 0.percent format.
    // If range is dollar, keep number value as entered...
    public selectedRangeType: string = RANGE_PERCENT;

    public rangeLow: number;

    public rangeHigh: number;

    /** Price range **/
    public minValue: number = 0;

    public maxValue: number = 100; // Stands for twice suggested price.

    public midValue: number = 50; // Stands for suggested price.

    public lowValue: number = 0; // Calculated price below suggested price allowed.

    public hiValue: number = 0; // Calculated price above suggested price allowed.

    public pricingShown = false;

    public constructor(
        protected corpPricingAlgorithmService: CorpPricingAlgorithmService,
        protected adminFacade: AdminFacade,
        protected alertService: PCCAlertService,
        protected accountSettingsService: AccountSettingsService,
        protected translateService: PCCTranslateService
    ) {
        super(adminFacade, alertService, translateService);
    }

    public async ngOnInit(): Promise<void> {
        console.log("corp-pricing.systemSettings=", this.systemSettings);

        console.log("Before systemSettings...");
        this.systemSettings = await this.adminFacade.getSystemSettings();
        console.log("After systemSettings: ", this.systemSettings);

        this.updateView();

        super.ngOnInit();
    }

    public updateView(): void {
        if (this.accountSettings) {
            this.priceAlg = this.convertToDisplayNameVersion(this.accountSettings.pricing_algorithm);
        }

        console.log("this.accountSettings.range_low=", this.accountSettings.range_low);
        console.log("this.accountSettings.range_high=", this.accountSettings.range_high);
        this.rangeLow = this.accountSettings.range_low || 0;
        this.rangeHigh = this.accountSettings.range_high || 0;

        if (this.rangeLow > 1) {
            console.log("dollar");
            this.selectedRangeType = RANGE_DOLLAR;
        } else {
            console.log("percent");
            this.selectedRangeType = RANGE_PERCENT;

            // Convert to integer for UI
            this.rangeLow *= 100;
            this.rangeHigh *= 100;
        }
        console.log("selectedRangeType=", this.selectedRangeType);

        this.recalcPriceRange();

    }

    private convertToDisplayNameVersion(priceAlg: string): string {
        console.log("convertToDisplayNameVersion: ", priceAlg);

        const dispAlg = this.corpPricingAlgorithmService.convertToDisplayNameVersion(priceAlg,
            this.systemSettings.species,
            this.systemSettings.profileItems,
            this.systemSettings);
        console.log("dispAlg=", dispAlg);
        return dispAlg;
    }

    public rangeTypeSelected(rType: string): void {
        console.log("rangeTypeSelected: ", rType);
        this.selectedRangeType = rType;

        this.updateRanges();
    }

    private updateRanges(): void {
        if (this.selectedRangeType === RANGE_PERCENT) {
            this.accountSettings.range_low = this.rangeLow / 100;
            this.accountSettings.range_high = this.rangeHigh / 100;
        } else {
            this.accountSettings.range_low = this.rangeLow;
            this.accountSettings.range_high = this.rangeHigh;
        }
    }

    public recalcPriceRange(): void {
        console.log("recalcPriceRange");
        if (!this.accountSettings) {
            console.error("Can't calculate price ranges, no account settings present.");
            return;
        }

        const lowPct = this.rangeLow;
        const hiPct = this.rangeHigh;
        this.lowValue = (this.midValue - (this.midValue * lowPct / 100));
        this.hiValue = (this.midValue + (this.midValue * hiPct / 100));

        this.updateRanges();
    }

    public lowValueChanged(value: number): void {
        console.log("lowValueChanged: ", value);
        this.lowValue = value;


        this.rangeLow = (this.midValue - this.lowValue) * 100 / this.midValue;
        console.log("range_low=", this.accountSettings.range_low);
    }

    public hiValueChanged(value: number): void {
        console.log("hiValueChanged: ", value);
        this.hiValue = value;
        this.rangeHigh = (this.hiValue - this.midValue) * 100 / this.midValue;
        console.log("range_high=", this.accountSettings.range_high);
    }

    public editPriceAlgClicked(): void {
        this.pricingShown = !this.pricingShown;
    }

    // Overriding custom-page-edit's saveCustomPage so account settings also gets saved for this page.
    protected async saveCustomPage(page: ICustomPage): Promise<IAPIResponseData<ICustomPage>> {
        try {
            // Need to save account settings as well for flags changes.
            // This will be streamlined later as we work out how translations will be managed.
            const acctResp = await this.accountSettingsService.saveAccountSettings(this.accountSettings);
            if (!acctResp.success) {
                console.error("Save account settings failed");
                return {
                    success: false,
                    error: acctResp.error,
                    data: page
                };
            }

            return await super.saveCustomPage(page);
        } catch (err) {
            console.error("Error saving custom page: ", err);
            return {
                success: false,
                error: new PCCClientError("SYSTEM_ERROR", "Error thrown calling adminFacade.saveCustomPage", err)
            };
        }
    }
}
