export class SalesOrgEnum {
    private static ORDINAL_SEQ: number = 0;

    public static readonly US_REF_LAB = new SalesOrgEnum("US_REF_LAB", "US", "USS3", "VS");

    public static readonly US_IHD = new SalesOrgEnum("US_IHD", "US", "USS1", "CP");

    public static readonly US_DIGITAL = new SalesOrgEnum("US_DIGITAL", "US", "USS1", "VR");

    public static readonly CA_REF_LAB = new SalesOrgEnum("CA_REF_LAB", "CA", "CAS7", "VS");

    public static readonly CA_IHD = new SalesOrgEnum("CA_IHD", "CA", "CAS1", "CP");

    public static readonly CA_DIGITAL = new SalesOrgEnum("CA_DIGITAL", "CA", "CAS1", "VR");

    // TODO: Add AU, UK sales orgs

    public readonly ordinal: number = 0;

    public static values: SalesOrgEnum[];

    // private to disallow creating other instances of this type
    private constructor(public readonly developerName: string,
        public readonly countryCd: string,
        public readonly salesOrg: string,
        public readonly division: string) {
        this.ordinal = SalesOrgEnum.ORDINAL_SEQ++;
        SalesOrgEnum.values = SalesOrgEnum.values || [];
        SalesOrgEnum.values.push(this);
    }

    public toString(): string {
        return this.developerName;
    }
}

