import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { Test } from "./../utils/test";

export type EventSourceTypes = typeof EventSourceClient["TYPES"][keyof typeof EventSourceClient["TYPES"]]
export type EventSourceOptions = {
    type: EventSourceTypes;
    timeout?: number;
    params: {
        [key: string]: boolean | string | number;
    }
}

@Injectable({
    providedIn: "root"
})
export class EventSourceClient {
    public static TYPES = {
        LONG_REQUEST: "LONG_REQUEST" as "LONG_REQUEST"
    };

    public doLongRequest<T>(path: string, { params, timeout }: EventSourceOptions): Observable<T> {
        return new Observable<T>((obs): void => {
            let timer: number;

            let wasConnected = false;
            let responseReceived = false;

            if (params) {
                const keys = Object.keys(params);
                if (keys.length) {
                    path += `?${Object.keys(params).map((v: string): string => `${v}=${params[v]}`)
                        .join("&")}`;
                }
            }

            const eventSource = new EventSource(path, {
                withCredentials: true
            });

            if (typeof timeout === "number") {
                timer = setTimeout((): void => {
                    clearTimeout(timer);
                    eventSource.close();
                    obs.error("timeout occurred");
                    obs.complete();
                    timer = undefined;
                }, timeout) as any as number;
            }

            eventSource.addEventListener("error", (e): void => {
                eventSource.close();
                if (!responseReceived) {
                    obs.error(e);
                }
                obs.complete();
            });
            eventSource.addEventListener("message", (msg): void => {
                if (!wasConnected) {
                    wasConnected = true;
                }
                if (msg.data === "ping") {
                    return;
                }
                try {
                    const result = JSON.parse(msg.data);
                    responseReceived = true;
                    obs.next(result);
                } catch (e) {
                    obs.error(e);
                }
                obs.complete();
            });

        });
    }

    public get<T>(path: string, { params, timeout, type = "LONG_REQUEST" }: EventSourceOptions): Observable<T> | null {
        params = params || {
        };
        Test._serviceInject({
            url: path, method: "get", params
        });
        let result: Observable<T>;
        if (type === "LONG_REQUEST") {
            result = this.doLongRequest<T>(path, {
                type, timeout, params
            });
        }
        return result;
    }
}
