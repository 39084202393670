import { Component, OnInit } from "@angular/core";

import { Router, ActivatedRoute } from "@angular/router";

import { IAccountResponse, ISessionCount } from "@shared/model/account-response";
import { Translations } from "@shared/model/language";
import { IAccount } from "@shared/model/account";
import { IAccountSettings } from "@shared/model/account-settings";
import { AdminFacade } from "../../../facade/admin.facade";
import { Message } from "@shared/model/message";
import { INavItem } from "@client/shared/model/nav-item";
import { AccountSettingsService } from "../../../service/account-settings.service";

const ADMIN_LOCALE = "en-US";

@Component({
    selector: "pcc-account-settings",
    templateUrl: "./account-settings.component.html",
    styleUrls: [
        "./account-settings.component.scss"
    ]
})
export class AccountSettingsComponent implements OnInit {

    public activeView: string = "accountSettings";

    public message: Message = new Message();

    public accountSettings: IAccountSettings;

    public account: IAccount;

    public sessionCount: ISessionCount;

    public formStyle: string;

    public constructor(
        private router: Router,
        private route: ActivatedRoute,
        private adminFacade: AdminFacade,
        private accountSettingsService: AccountSettingsService
    ) {
    }

    public async ngOnInit(): Promise<void> {
        this.route.data.subscribe((params): void => {
            console.log("Account resp data here: ", params);
            if (params.accountData) {
                const acctResp: IAccountResponse = params.accountData;
                if (acctResp.success === true) {
                    this.setAccountData(acctResp.account, acctResp.accountSettings, acctResp.sessionCount);
                }
            }
        });
    }

    public cancel(): void {
        console.log("close account settings for account: ", this.accountSettings.sap_id);
        this.adminFacade.clearSession();
        this.router.navigate([
            "/admin/config/search"
        ]);
    }

    public setAccountSettings(acctSettings: IAccountSettings): void {
        console.log("setAccountSettings: ", acctSettings);
        this.accountSettings = acctSettings;

        this.handleLanguage();
    }

    private handleLanguage(): void {
        let defaultLocale = this.adminFacade.translateService.getDefaultLocale(this.accountSettings?.supportedLocales, this.accountSettings?.defaultLocale);

        // Admin tool itself is in English and if English is supported by this account setting, then
        // just use it for UI display text (not currently being edited).
        // If not, then use the translations defined for the default locale specified in account settings,
        // but "fake" them as en-US just for the admin tool.

        let defaultTranslations: Translations;
        if (this.accountSettings.translations && this.accountSettings.translations[ADMIN_LOCALE]) {
            defaultTranslations = this.accountSettings.translations[ADMIN_LOCALE];
        } else {
            defaultTranslations = this.accountSettings.translations[defaultLocale];
        }

        this.adminFacade.translateService.ngxTranslateService.setTranslation(ADMIN_LOCALE,
            defaultTranslations, true);
    }

    public setAccountData(acct: IAccount, acctSettings: IAccountSettings, sessionCount: ISessionCount): void {
        console.log("setAccountData: ", acct, acctSettings, sessionCount);

        this.accountSettings = acctSettings;
        this.account = acct;
        this.sessionCount = sessionCount;

        if (this.accountSettings.status === "DRAFT") {
            this.formStyle = "draft";
        }

        this.setAccountSettings(this.accountSettings);
    }

    public navClicked(item: INavItem): void {
        console.log(`navClicked: ${item}`);
        this.activeView = item.key;
    }

    public async accountSettingsVisibleChange(): Promise<void> {
        await this.accountSettingsService.saveAccountSettings(this.accountSettings, this.sessionCount);
    }
}
