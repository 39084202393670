<div class="pcc-edit-panel">
  <div class="pcc-edit-panel__header custom-page-header">
    <span class="spot-typography__heading--level-3">Page: {{selectedPage?.titleKey | translate}}</span>
    <pcc-confirm-action *ngIf="selectedPage && selectedPage.id" symbol="delete" confirmPosition="spot-popover--bottom-left" [useLink]="true" (okClick)="deleteCustomPageConfirmed(selectedPage)" class="delete-button" confirmMsg="account-custom-pages.confirm_delete" buttonText="Delete Page" ></pcc-confirm-action>
  </div>
  <div class="pcc-edit-panel__content" style="padding: 1em;">
    <form class="spot-form" id="pageForm" #pageForm="ngForm" name="pageForm">
      <div class="spot-form__field-group spot-form__field-group--horizontal" style="margin:0px;">
        <div class="spot-form__field-group">
          <pcc-checkbox [(checked)]="selectedPage.active" label=" Active "></pcc-checkbox>
        </div>
      </div>

      <div class="spot-form__field-group">
        <pcc-localized-text [languages]="accountSettings?.languages" label="Title" key="title" [localizedKey]="selectedPage.localizedKeys.title" (textChanged)="localizedTextChanged($event)"></pcc-localized-text>
      </div>

      <div class="spot-form__field-group">
        <pcc-localized-text [languages]="accountSettings?.languages" label="SubTitle" key="subtitle" [localizedKey]="selectedPage.localizedKeys.subTitle" (textChanged)="localizedTextChanged($event)"></pcc-localized-text>
      </div>

      <span *ngIf="!selectedPage?.id">Please save this page before attaching any Seismic document links or images.</span>
      <div class="spot-tabs" *ngIf="selectedPage?.id">
        <div class="spot-tabs__list-wrap">
          <ul class="spot-tabs__list">
            <li class="spot-tabs__tab">
              <a href="#images" class="spot-tabs__link spot-tabs__link--active">
                Images
              </a>
            </li>
            <li class="spot-tabs__tab">
              <a href="#links" class="spot-tabs__link">
                Documents
              </a>
            </li>
          </ul>
        </div>
        <div class="spot-tabs__content-wrap">
          <div id="images" class="spot-tabs__content">
            <pcc-manage-files [selectedPage]="selectedPage" [accountSettings]="accountSettings" (fileAdded)="fileAdded($event)" (fileDeleted)="fileDeleted($event)" style="padding:10px;"></pcc-manage-files>
          </div>
          <div id="links" class="spot-tabs__content">
            <pcc-seismic-links [selectedPage]="selectedPage" [accountSettings]="accountSettings" [showTag]="false" (linkAdded)="linkAdded($event)" (linkDeleted)="linkDeleted($event)" style="padding:10px;"></pcc-seismic-links>
          </div>
        </div>
      </div>

    </form>

  </div>
  <div class="pcc-edit-panel__footer button-panel">
    <button type="cancel" class="pcc-edit-panel__footer-cancel-button" (click)="cancel()">Cancel</button>
    <button type="submit" class="spot-button spot-button--primary" [disabled]="!saveEnabled && !saving" (click)="onSubmit()">Save</button>
  </div>
</div>
