import { PCCError } from "../model/error/pcc-error";
import { ImageInfo } from "@shared/model/image";
import { ISeismicLink, SeismicDoc } from "@shared/model/seismic";

export class SeismicUtilsService {

    // Note: MP4 files play fine on Chrome, but fail in Safari on ipad.  Disabling for upcoming release on 10/24/23 release
    // TODO: Get this to work in Safari as well.
    public static readonly acceptedFormats = [
        "PDF",
        "MP4"
    ];

    // Take a url copied from Library content in Seismic and parse out contentId.
    public static parseDocUrl(url: string): SeismicDoc {
        if (!url) {
            throw new PCCError("SEISMIC.MISSING_URL", `Missing url: ${url}`);
        }

        if (url.match(/\/doccenter\//)) {
            console.error("Invalid url.  Can't use url from DocCenter", url);
            throw new PCCError("SEISMIC.DOC_CENTER_URL", `Invalid url.  Can't use url from DocCenter: ${url}`);
        }

        const re = /https:\/\/(.*).seismic.com.+\/detail\/([a-z0-9A-Z]+)\/([a-zA-Z0-9-]+).+/;
        const match = url.match(re);
        if (!match) {
            console.error("Invalid url: ", url);
            throw new PCCError("SEISMIC.INVALID_URL", `Bad url: ${url}`);
        }
        return {
            url,
            tenantId: match[1],
            teamSiteId: match[2],
            contentId: match[3]
        };
    }

    public static convertImageToUrl(imageInfo: ImageInfo): string {
        const imageData = imageInfo.data;
        return `data:${imageInfo.contentType};base64,${imageData}`;
    }

    public static validateLink(link: ISeismicLink): { key: string, msg: string }[] {
        if (!link) {
            return [];
        }
        const valResult = [];
        if (!this.hasValue(link.desc)) {
            valResult.push({ key: "desc", msg: "required" });
        }
        if (!this.hasValue(link.contentId)) {
            valResult.push({ key: "contentId", msg: "required" });
        }
        if (!this.hasValue(link.url)) {
            valResult.push({ key: "url", msg: "required" });
        }
        if (!this.hasValue(link.locale)) {
            valResult.push({ key: "locale", msg: "required" });
        }
        if (link.error) {
            valResult.push({ key: "hasError", msg: link.error.message });
        }
        if (valResult.length) {
            console.error("valResult=", link, valResult);
        }

        return valResult;
    }

    public static isLinkValid(link: ISeismicLink): boolean {
        const valResult = this.validateLink(link);
        return valResult.length === 0;
    }

    private static hasValue(value?: string): boolean {
        return value && value.length && value.length > 0;
    }

    public static canHandleFormat(format: string): boolean {
        if (this.acceptedFormats.indexOf(format) !== -1) {
            return true;
        }
        console.log("Can't handle format: ", format);
        return false;
    }

}
