export class EnvironmentEnum {
    private static ORDINAL_SEQ: number = 0;

    public static readonly DEVELOPMENT = new EnvironmentEnum("Development", "DEVELOPMENT");

    public static readonly QA = new EnvironmentEnum("QA", "QA");

    public static readonly STAGE = new EnvironmentEnum("Stage", "STAGE");

    public static readonly TRAINING = new EnvironmentEnum("Training", "TRAINING");

    public static readonly PROD = new EnvironmentEnum("Prod", "PROD", true);

    public static readonly LOCAL = new EnvironmentEnum("Local", "LOCAL");

    public static readonly LOCAL_LIVE = new EnvironmentEnum("Local-Live", "LOCAL_LIVE");

    public readonly ordinal: number = 0;

    // private to disallow creating other instances of this type
    private constructor(public readonly displayName: string,
        public readonly value: string,
        public readonly isProd: boolean = false) {
        this.ordinal = EnvironmentEnum.ORDINAL_SEQ++;
    }

    public static forString(value: string): EnvironmentEnum {
        if (value === EnvironmentEnum.DEVELOPMENT.value) {
            return EnvironmentEnum.DEVELOPMENT;
        }
        if (value === EnvironmentEnum.QA.value) {
            return EnvironmentEnum.QA;
        }
        if (value === EnvironmentEnum.STAGE.value) {
            return EnvironmentEnum.STAGE;
        }
        if (value === EnvironmentEnum.TRAINING.value) {
            return EnvironmentEnum.TRAINING;
        }
        if (value === EnvironmentEnum.PROD.value) {
            return EnvironmentEnum.PROD;
        }
        if (value === EnvironmentEnum.LOCAL.value) {
            return EnvironmentEnum.LOCAL;
        }
        if (value === EnvironmentEnum.LOCAL_LIVE.value) {
            return EnvironmentEnum.LOCAL_LIVE;
        }
        console.trace("Invalid value type passed in.  Defaulting to Development...", value);
        return EnvironmentEnum.DEVELOPMENT;
    }

    public equals(value: string | EnvironmentEnum): boolean {
        return this === value ||
            this.value === value;
    }

    public toString(): string {
        return this.displayName;
    }
}
