<div class="view-body">
  <div class="session-confirm">
    <div class="view-title spot-typography__heading--level-2"> {{ 'session-confirm.title' | translate }} </div>

    <div class="spot-message spot-message--with-icon-tab">
      <div class="spot-message--content">
        <div class="spot-message--body"><span [innerHTML]="sessionMsgKey | translate"> </span></div>
      </div>
      <div class="icon-tab">
        <svg class="spot-icon" aria-labelledby="title">
          <title>Alert</title>
          <use xlink:href="/assets/spot-library/dist/symbols/spot_symbols.svg#info-2"></use>
        </svg>
      </div>
    </div>

    <div class="view-title spot-typography__heading--level-2" [innerHTML]="'session-confirm.choice_prompt' | translate ">  </div>

    <div *ngIf="showConfirmPartial">
      <div class="session-choice">
        <div class="choice">
          <a (click)="partialSessionConfirmed(true)" class="spot-link choice-link"> {{ 'session-confirm.partial_continue_heading' | translate }}</a>
          <div [innerHTML]="continueDescMessage"></div>
        </div>
      </div>
      <div class="session-choice">
        <div class="choice">
          <a (click)="partialSessionConfirmed(false)" class="spot-link choice-link"> {{ 'session-confirm.partial_new_heading' | translate }}</a>
          <div [innerHTML]="newDescMessage"></div>
        </div>
      </div>
    </div>
    <div *ngIf="showConfirmActiveEnrollment">
      <div class="session-choice">
        <div class="choice">
          <a (click)="activeSessionConfirmed()" class="spot-link choice-link"> {{ 'session-confirm.active_continue_heading' | translate }}</a>
          <div class="choice-msg" [innerHTML]="continueDescMessage"></div>
        </div>
        <div class="session-pdf">
          <a class="spot-button--link" (click)="viewPdfClicked()" #downloadButton>
            <svg class="spot-icon" aria-labelledby="title">
              <title>PDF</title>
              <use xlink:href="/assets/spot-library/dist/symbols/spot_symbols.svg#file-pdf"></use>
            </svg>
            {{ 'sessionPrompt.download_pdf' | translate }}
          </a>
        </div>
      </div>

    </div>
    <div *ngIf="showConfirmExpiredEnrollment">
      <div class="session-choice">
        <div class="choice">
          <a (click)="expiredSessionConfirmed(false)" class="spot-link choice-link"> {{ 'session-confirm.expired_new_heading' | translate }}</a>
          <div [innerHTML]="newDescMessage"></div>
        </div>
      </div>
      <div class="session-choice">
        <div class="choice">
          <a (click)="expiredSessionConfirmed(true)" class="spot-link choice-link"> {{ 'session-confirm.expired_continue_heading' | translate }}</a>
          <div class="choice-msg" [innerHTML]="continueDescMessage"></div>
        </div>
        <div class="session-pdf">
          <a class="spot-button--link" (click)="viewPdfClicked()" #downloadButton>
            <svg class="spot-icon" aria-labelledby="title">
              <title>PDF</title>
              <use xlink:href="/assets/spot-library/dist/symbols/spot_symbols.svg#file-pdf"></use>
            </svg>
            {{ 'sessionPrompt.download_pdf' | translate }}
          </a>
        </div>
      </div>
	  <!-- Both a completed enrollment _and_ a partial session were found. -->
      <div class="session-choice" *ngIf="accountSearchResponse?.oldSession">
        <div class="choice">
          <a (click)="partialSessionConfirmed(true)" class="spot-link choice-link"> {{ 'session-confirm.partial_continue_heading' | translate }}</a>
          <div [innerHTML]="continuePartialDescMessage"></div>
        </div>
      </div>
    </div>

    <div class="go-search">
      <a (click)="gotoSearch()" class="spot-link"> {{ 'session-confirm.new_search' | translate }} </a>
    </div>
  </div>
</div>
