import { Component, OnInit, OnChanges, AfterViewInit, Input, Output, ViewChild, EventEmitter } from "@angular/core";

import { NgForm } from "@angular/forms";

import _ from "lodash";

import { IEnrollResponse } from "@shared/model/service/enroll-service";
import { AdminFacade } from "../../../facade/admin.facade";
import { ConfirmSavePurge } from "../../../service/account.service";
import { UtilService } from "@shared/service/util.service";
import { ErrorUtils } from "@shared/model/error/error-utils";

import { ISessionCount } from "@shared/model/account-response";
import { IAccountSettings } from "@shared/model/account-settings";
import { ICategory } from "@shared/model/category";
import { IProduct } from "@shared/model/product";
import { PricingMethods } from "@shared/model/price";
import { IProfileItem, PROFILE_ITEM_TYPES } from "@shared/model/profile-item";
import { IModality, ModalityEnum } from "@shared/model/modality";
import { IProfileCategory } from "@shared/model/profile-category";
import { ProfileTemplate, IProfileTemplate, TemplateType, TEMPLATE_TYPES } from "@shared/model/profile-template";
import { ProfileUtils } from "@shared/model/profile";
import { ISystemSettings } from "@shared/model/system-settings";
import { ISpecies, SpeciesEnum } from "@shared/model/species";
import { IViewProfile } from "./view-profile";
import { PCCAlertService } from "../../../service/alert.service";
import { IProfileMatchRequest } from "@shared/model/service/profile-match-service";
import { LocalizedString } from "@shared/model/language";
import { PCCTranslateService } from "../../../service/translate.service";

@Component({
    selector: "pcc-build-template",
    templateUrl: "./build-template.component.html",
    styleUrls: [
        "./build-template.component.scss"
    ]
})
export class BuildTemplateComponent implements OnInit, OnChanges, AfterViewInit {

    @Input() public slot: IProfileTemplate;

    @Input() public accountSettings: IAccountSettings;

    @Input() public systemSettings: ISystemSettings;

    @Output() public delete = new EventEmitter<IProfileTemplate>();

    @Output() public cancel = new EventEmitter<IProfileTemplate>();

    @Output() public saved = new EventEmitter<IProfileTemplate>();

    @ViewChild("itemForm", {
        static: false
    }) public itemForm: NgForm;

    public countryCd?: string;

    public templateTypes: TemplateType[] = [];

    public sessionCount: ISessionCount;

    public viewModel: IViewProfile;

    public showMatch = false;

    public showStaticFields = false;

    public speciesList: SpeciesEnum[] = [
        SpeciesEnum.CANINE,
        SpeciesEnum.FELINE
    ];

    public modalityList: ModalityEnum[] = [
        ModalityEnum.REF_LAB,
        ModalityEnum.IHD
    ];

    public matching: boolean;

    public noMatch = true;

    private allProfileItems: IProfileItem[];

    public hasChildren = true;

    public saveEnabled = false;

    public constructor(
        private adminFacade: AdminFacade,
        private alertService: PCCAlertService,
        protected translateService: PCCTranslateService
    ) {}

    public async ngOnInit(): Promise<void> {

        console.log("build-template.init: this.slot=", this.slot);

        this.allProfileItems = this.systemSettings.profileItems;

        this.countryCd = this.accountSettings.countryCd;

        delete this.viewModel; // Keep from caching between templates

        this.viewModel = this.initViewModel(this.slot);

        this.showMatch = this.isTemplatePreconfig(this.viewModel.templateType);
        this.showStaticFields = this.isTemplateStatic(this.viewModel.templateType) || this.isTemplatePetCareHeroes(this.viewModel.templateType);
        this.hasChildren = !this.isTemplatePetCareHeroes(this.viewModel.templateType);

        if (this.slot && this.slot.account_settings_id != null) {
            const session = this.adminFacade.getSession();
            this.accountSettings = session.accountSettings;
            this.sessionCount = session.sessionCount;
            this.noMatch = !this.slot.matchedProfile;

            if (this.accountSettings.pricingMethod === PricingMethods.STATIC) {
                this.templateTypes = [
                    <TemplateType>TEMPLATE_TYPES.STATIC
                ];
            } else {
                this.templateTypes = [
                    <TemplateType>TEMPLATE_TYPES.PRECONFIG,
                    <TemplateType>TEMPLATE_TYPES.FLEX,
                    <TemplateType>TEMPLATE_TYPES.PCH
                ];
            }
        }
    }

    public ngOnChanges(changes: unknown): void {
        console.log("Build template changes on inputs: ", changes);
        this.viewModel = this.initViewModel(this.slot);
        this.showMatch = this.isTemplatePreconfig(this.viewModel?.templateType);

        console.log("viewModel after changes: ", this.viewModel);
    }

    public ngAfterViewInit(): void {
        console.log("this.itemForm=", this.itemForm);
        if (this.itemForm) {
            this.itemForm.statusChanges.subscribe((status): void => {
                console.log("itemForm status change: ", status);
                this.updateButtons();
            });

            this.itemForm.valueChanges.subscribe((value): void => {
                console.log("value=", value);
                console.log("itemForm value change: ", value);
                this.findInvalidControls();
            });
        } else {
            console.error("this.itemForm doesn't exist!");
        }
    }

    public findInvalidControls(): string[] {
        const invalid = [];
        const controls = this.itemForm.controls;
        for (const name in controls) {
            if (controls[name].invalid) {
                invalid.push(name);
            }
        }
        console.log("invalid=", invalid);
        return invalid;
    }

    private updateButtons(): void {
        let valid = this.itemForm.form.valid;
        console.log("valid errors: ", this.itemForm.form.errors);
        console.log("this.itemForm.form.status=", this.itemForm.form.status);

        console.log(`form valid: ${valid}`);
        valid = valid && (this.viewModel != null);

        const locales = this.accountSettings.supportedLocales;
        if (!this.translateService.allLocalesPopulated(this.viewModel.localizedKeys.displayName, locales)) {
            console.error("Not all locales are populated: ", this.viewModel.localizedKeys.displayName, locales);
            valid = false;
        }

        this.saveEnabled = valid;
    }

    private initViewModel(oldSlot: IProfileTemplate): IViewProfile {
        console.log("initViewModel");

        if (!this.systemSettings || !this.allProfileItems || !this.slot) {
            console.log("Data not loaded yet");
            return null;
        }

        const newViewModel: IViewProfile = {
            template_profile_id: oldSlot.template_profile_id,
            account_settings_id: oldSlot.account_settings_id,
            species: this.getSpeciesName(oldSlot),
            modality: ModalityEnum.forModality(oldSlot.modality) || ModalityEnum.REF_LAB,
            display_order: oldSlot.display_order,
            templateType: oldSlot.templateType,
            defaultPrice: oldSlot.defaultPrice,
            defaultSelected: oldSlot.defaultSelected,
            localizedKeys: oldSlot.localizedKeys
        };

        newViewModel.categories = this.getFilteredCategories(newViewModel.species, oldSlot.categories, true);

        const oldMatchedProfile = this.viewModel ? this.viewModel.matchedProfile : null;

        newViewModel.matchedProfile = oldSlot.matchedProfile || oldMatchedProfile;

        if (this.isTemplateStatic(oldSlot.templateType) || this.isTemplatePetCareHeroes(oldSlot.templateType)) {
            newViewModel.matchedProfile = newViewModel.matchedProfile || {};
        }

        console.log("viewModel = ", newViewModel);

        return newViewModel;
    }

    public speciesSelected(speciesValue: SpeciesEnum): void {
        console.log("speciesSelected", speciesValue);

        if (!speciesValue) {
            console.warn("Species not selected");
            return;
        }

        this.viewModel.species = speciesValue.value;

        this.updateProfileItems(this.viewModel.species, this.viewModel);

        this.defaultChange();

        console.log("Final viewModel: ", this.viewModel);
    }

    // Filter available profileItems by given species.
    // When profile template is initially created, all profileItems are shown.  But when
    // user sets or changes the species, need to re-filter the available profileItems,
    // without losing any selected values...
    public updateProfileItems(species: string, oldSlot: IViewProfile): void {
        console.log("updateProfileItems: ", species);

        oldSlot.categories = this.getFilteredCategories(species, oldSlot.categories, false);

        console.log("After filtering, oldSlot=", oldSlot);
    }

    /**
     * Assemble list of categories from full list of profile items and maintaining any selected values from old list.
     */
    public getFilteredCategories(species: string, oldCategories: IProfileCategory[], isSlot: boolean): IProfileCategory[] {
        console.log("getFilteredCategories: ", species, oldCategories);
        const filteredProfileItems = this.filterProfileItems(species, this.slot.countryCd, this.allProfileItems);

        // First build up the full model of all available categories and items
        const testMap = UtilService.groupBy(filteredProfileItems, (profileItem: IProfileItem): string => profileItem.category.developerName);

        // Construct lookup map of category name to category
        const ctgMap = _.keyBy(this.systemSettings.categories, (ctg: ICategory): string => (
            ctg.developerName
        ));
        const viewCtgMap: Record<string, IProfileCategory> = {};
        let newCategories: IProfileCategory[] = [];

        // Loop through all categories (not just the ones currently in the profile,
        // because they may have been previously filtered out due to species).
        Object.keys(testMap).forEach((ctgName: string): void => {
            const ctg = ctgMap[ctgName];

            const viewCtg: IProfileCategory = {
                category_id: ctg.category_id,
                displayName: ctg.displayName,
                developerName: ctg.developerName,
                display_order: ctg.display_order,
                isSelected: false,
                is_required: false,
                profileItems: null
            };

            // Clone profile items so don't corrupt system data editing separate templates in same session.
            const fpi = _.cloneDeep(testMap[viewCtg.developerName]);
            viewCtg.profileItems = fpi || [];

            viewCtgMap[ctgName] = viewCtg;
            newCategories.push(viewCtg);
        });

        console.log("viewCtgMap=", viewCtgMap);

        // We now have an updated list of possible profile items and available
        // categories for those items.
        // Now go back and re-select selected tests, categories, is_default, and
        // isRequired.
        oldCategories.forEach((oldCtg: IProfileCategory): void => {
            const viewCtg = viewCtgMap[oldCtg.developerName];
            if (!viewCtg) {
                console.error("No viewCtg found for: ", oldCtg.developerName, oldCtg);
                return;
            }
            viewCtg.is_required = oldCtg.is_required || false;
            viewCtg.isSelected = (Boolean(isSlot));

            viewCtg.profileItems = this.sortProfileItems(viewCtg.profileItems);

            viewCtg.profileItems.forEach((viewItem: IProfileItem): void => {
                const foundItem = oldCtg.profileItems.find((item: IProfileItem): boolean => (
                    item.profile_item_id === viewItem.profile_item_id
                ));
                if (foundItem) {
                    viewItem.isSelected = (isSlot ? true : foundItem.isSelected);
                    viewItem.is_default = foundItem.is_default;
                    viewItem.display_order = foundItem.display_order;
                } else {
                    viewItem.isSelected = false;
                    viewItem.is_default = false;
                    viewItem.display_order = 0;
                }
            });
        });

        newCategories = this.sortCategories(newCategories);

        return newCategories;
    }

    // Sort full set of profile items by test-type, modality
    private sortProfileItems(profileItemList: IProfileItem[]): IProfileItem[] {
        return profileItemList.sort((p1: IProfileItem, p2: IProfileItem): number => {
            if (p1.test_type === p2.test_type) {
                if (p1.modality.display_order === p2.modality.display_order) {
                    return p1.display_order - p2.display_order;
                }
                return p1.modality.display_order - p2.modality.display_order;
            }
            return Object.keys(PROFILE_ITEM_TYPES).indexOf(p1.test_type)
                - Object.keys(PROFILE_ITEM_TYPES).indexOf(p2.test_type);
        });
    }

    // Sort category list by display order;
    private sortCategories(ctgList: IProfileCategory[]): IProfileCategory[] {
        return ctgList.sort((c1, c2): number => (
            c1.display_order > c2.display_order ? 1 : -1
        ));
    }

    /**
     * Filter available profileItems by given species.
     * If a profile item's species list contains the specified species, returns true.
     * If a profile item has no species defined, then it returns true.
     * Else returns false and is not returned in filtered list.
     */
    private filterProfileItems(species: string, countryCd: string, allProfileItems: IProfileItem[]): IProfileItem[] {
        return allProfileItems.filter((profileItem: IProfileItem): boolean => {
            if (countryCd
                && countryCd !== profileItem.countryCd) {
                return false;
            }
            return this.speciesMatch(species, profileItem.speciesList);
        });
    }

    public async save(): Promise<void> {
        console.log("save: viewModel=", this.viewModel);

        try {
            const shouldContinue = await this.purgeBeforeSave();
            if (!shouldContinue) {
                return;
            }

            this.alertService.setBusy(true, "Saving...");

            const saveReq: IProfileTemplate = this.generateSaveRequest();

            console.log("After parsing: ", saveReq);

            const resp = await this.adminFacade.saveProfileTemplate(saveReq);
            if (resp && resp.success === true) {
                this.slot = resp.profileTemplate;
                this.slot.countryCd = this.countryCd;

                await this.refreshAccountSettings();

                this.viewModel = this.initViewModel(this.slot);

                await this.translateService.updateDataTranslations(this.accountSettings.account_settings_id);

                this.alertService.showToast("Save successful");

                this.saved.emit(this.slot);
            } else {
                this.alertService.showError("Save failed", ErrorUtils.getErrorMessage(resp.error));
            }
            this.alertService.setBusy(false);
        } catch (err) {
            console.error("Error saving profile template: ", err);
            this.alertService.setBusy(false);
            this.alertService.showError("Error saving profile template", ErrorUtils.getErrorMessage(err));
        }
    }

    public cancelEdit(): void {
        this.cancel.emit(this.slot);
    }

    public speciesMatch(speciesStr: string, specList: ISpecies[]): boolean {
        if (specList === null || specList.length === 0) {
            return true;
        }

        return specList.some((s: ISpecies): boolean => (
            s && (s.developer_name === speciesStr)
        ));
    }

    public compareModality(m1: IModality, m2: IModality): boolean {
        return m1 && m2 ? m1.modality_id === m2.modality_id : m1 === m2;
    }

    public compareSlot(t1: IProfileTemplate, t2: IProfileTemplate): boolean {
        return t1 && t2 ? t1.template_profile_id === t2.template_profile_id : t1 === t2;
    }

    public setModality(mod: string): void {
        console.log("setModality: ", mod);
        this.viewModel.modality = ModalityEnum.forString(mod);
    }

    public templateNameChanged(textInfo: LocalizedString): void {
        console.log("templateNameChanged: ", textInfo);
        this.translateService.updateLocalizedKey(textInfo, this.viewModel.localizedKeys);
        this.updateButtons();
    }

    private async purge(): Promise<void> {
        let resp: IEnrollResponse;
        if (this.sessionCount.partial_session_count > 0) {
            resp = await this.clearOldSessions();
        }

        if (resp.success) {
            this.alertService.showToast("Partial sessions have been cleared.");
        } else {
            this.alertService.showError("Failed to clear sessions", ErrorUtils.getErrorMessage(resp.error));
        }
    }

    private async clearOldSessions(): Promise<IEnrollResponse> {
        console.log("clearOldSessions");
        this.alertService.setBusy(true, "Clearing sessions...");

        try {
            const resp = await this.adminFacade.clearOldSessions(this.accountSettings);
            console.log("clearOldSessions resp: ", resp);
            if (resp.success === true && resp.sessionCount) {
                this.sessionCount = resp.sessionCount;
                this.alertService.showToast("Session cleanup successful");
            } else {
                this.alertService.showError("Failed to clear sessions", ErrorUtils.getErrorMessage(resp.error));
            }
            this.alertService.setBusy(false);
            return resp;
        } catch (err) {
            console.error("Error clearing old sessions", err);
            this.alertService.showError("Error clearing sessions", ErrorUtils.getErrorMessage(err));
            this.alertService.setBusy(false);
            return {
                success: false,
                error: err
            };
        }
    }

    public defaultChange(): void {
        console.log("defaultChange");

        if (this.showMatch) {
            this.matchDefaults();
        }
    }

    private getDefaultTests(): IProfileItem[] {
        return ProfileTemplate.getDefaultTestsForCategories(this.viewModel.categories);
    }

    private async matchDefaults(): Promise<IProduct> {
        console.log("matchDefaults");

        delete this.viewModel.matchedProfile;
        delete this.noMatch;

        const defaultTests = this.getDefaultTests();
        console.log("defaultTests=", defaultTests);

        if (defaultTests.length === 0) {
            console.log("No tests selected as default");
            return null;
        }

        const profileRequest = this.genMatchRequest(defaultTests);

        try {
            this.matching = true;

            const resp = await this.adminFacade.findMatchingProfile(profileRequest);
            console.log("Response to findMatchingProfile: ", resp);
            if (resp.success === true) {
                this.viewModel.matchedProfile = resp.profile;
                this.noMatch = false;
                console.log("matchedProfile=", this.viewModel.matchedProfile);
                return this.viewModel.matchedProfile;
            }

            this.noMatch = true;

            return null;
        } catch (err) {
            console.error("Error calling findMatchingProfile: ", err);
            return null;
        } finally {
            this.matching = false;
        }
    }

    private getSpeciesName(oldSlot: IProfileTemplate): string {
        return oldSlot.species ? oldSlot.species.developer_name : null;
    }

    private getModality(viewModel: IViewProfile): IModality {
        const modEnum = viewModel.modality || ModalityEnum.REF_LAB;
        return ModalityEnum.getModalityForEnum(modEnum, this.systemSettings.modalities);
    }

    private getSpecies(viewModel: IViewProfile): ISpecies {
        const specEnum = SpeciesEnum.forSpecies(viewModel.species);
        return SpeciesEnum.getSpeciesForEnum(specEnum, this.systemSettings.species);
    }

    // Loop through view model categories to find any selected profile item.
    private getSelectedCategories(): IProfileCategory[] {
        const newCategories: IProfileCategory[] = this.viewModel.categories.reduce((newList: IProfileCategory[], ctg: IProfileCategory): IProfileCategory[] => {
            if (ctg.isSelected !== true) {
                console.log("ctg not selected, skipping... ", ctg);
                return newList;
            }

            ctg = _.cloneDeep(ctg);

            // Now add any new selected tests to the selected category
            ctg.profileItems = ctg.profileItems.filter((item: IProfileItem): boolean => (
                item.isSelected === true
            ));

            ctg.profileItems.forEach((item: IProfileItem): void => {
                item.is_default = item.is_default || false;
            });

            if (!ctg.is_required) {
                ctg.is_required = false;
            }

            if (ctg.profileItems.length > 0) {
                newList.push(ctg);
            }

            return newList;
        }, []);

        return newCategories;
    }

    public templateTypeSelected(): void {
        const templateType = this.viewModel.templateType;
        if (this.isTemplateStatic(templateType) || this.isTemplatePetCareHeroes(templateType)) {
            this.viewModel.matchedProfile = this.viewModel.matchedProfile || {};
            this.viewModel.defaultSelected = true;
        }
        console.log("matchedProfile=", this.viewModel.matchedProfile);

        this.showMatch = this.isTemplatePreconfig(templateType);

        this.showStaticFields = this.isTemplateStatic(templateType) || this.isTemplatePetCareHeroes(templateType);

        this.hasChildren = !this.isTemplatePetCareHeroes(templateType);

        this.defaultChange();
    }

    // Take the temporary view model and convert it to a "real" profile template so it can be persisted.
    private generateSaveRequest(): IProfileTemplate {
        return {
            template_profile_id: this.viewModel.template_profile_id,
            account_settings_id: this.viewModel.account_settings_id,
            display_order: this.viewModel.display_order,
            active: true,
            templateType: this.viewModel.templateType,
            modality: this.getModality(this.viewModel),
            species: this.getSpecies(this.viewModel),
            categories: this.getSelectedCategories(),
            matchedProfile: this.viewModel.matchedProfile,
            defaultPrice: this.viewModel.defaultPrice,
            defaultSelected: this.viewModel.defaultSelected,
            localizedKeys: this.viewModel.localizedKeys
        };
    }

    private async purgeBeforeSave(): Promise<boolean> {
        const confirmSavePurge: ConfirmSavePurge = await this.adminFacade.confirmSavePurge(this.accountSettings, this.sessionCount);
        console.log("confirmSavePurge=", confirmSavePurge);
        if (confirmSavePurge === ConfirmSavePurge.OK) {
            await this.purge();
            return true;
        }
        if (!confirmSavePurge
            || confirmSavePurge === ConfirmSavePurge.CANCEL) {
            console.log("Save canceled");
            return false;
        }
        return true;
    }

    private async refreshAccountSettings(): Promise<IAccountSettings> {
        const session = this.adminFacade.getSession();

        console.log("Refreshing account settings...");
        session.accountSettings = await this.adminFacade.getAccountSettings(this.slot.account_settings_id);
        console.log("refreshed account settings: ", session.accountSettings);

        return session.accountSettings;
    }

    private genMatchRequest(defaultTests: IProfileItem[]): IProfileMatchRequest {
        const limsNames = this.adminFacade.getDefaultLims(this.adminFacade.getSession());

        const selectedProductTestCodes = ProfileUtils.getSelectedProductTestCodes(defaultTests);

        const profileRequest: IProfileMatchRequest = {
            limsNames,
            testCodes: selectedProductTestCodes
        };
        return profileRequest;
    }

    private isTemplatePreconfig(templateType: TemplateType | string): boolean {
        return templateType === TEMPLATE_TYPES.PRECONFIG;
    }

    private isTemplateStatic(templateType: TemplateType | string): boolean {
        return templateType === TEMPLATE_TYPES.STATIC;
    }

    private isTemplatePetCareHeroes(templateType: TemplateType | string): boolean {
        return templateType === TEMPLATE_TYPES.PCH;
    }

    public async deleteTemplateConfirmed(): Promise<void> {
        console.log("deleteTemplate");

        this.delete.emit(this.slot);
    }
}
