<div class="spot-modal">
  <div class="spot-modal__header">
	<span class="pricing-title">{{'edit-pricing.adjust_price_title' | translate}}</span>
	<button type="button" class="spot-modal__header-cancel-button" aria-label="Close" (click)="activeModal.dismiss('Close click')">
      <svg class="spot-icon spot-modal__header-cancel-button-icon" aria-labelledby="title">
        <title>cancel</title>
        <use xlink:href="/assets/spot-library/dist/symbols/spot_symbols.svg#cancel"></use>
      </svg>
	</button>
  </div>
  <div class="spot-modal__content" style="flex:1;display:flex;flex-direction:column;">

	<div class="pricing-pages">
	  <div class="nav-button">
		<button *ngIf="showPrevButton" class="next-button" (click)="prev()"><i class="fas fa-chevron-left"></i></button>
	  </div>
	  <div *ngIf="page==0" class="pricing-page">
		<div class="pricing-slide">
		  <div style="text-align: center;">
			<div class="graph-overlay" *ngIf="priceType == 'Full'">
			  <div class="graph-background" [style.background]="graphGrad"></div>
			</div>

			<div class="price-zones">
			  <input type="range" class="edit-price-range" [style.background]="sliderGrad" [min]="minPrice" [max]="maxPrice" step="1" [(ngModel)]="price" (input)="priceChanged()"/> <!--[value]="price" -->
			</div>

			<div class="price-edit">
			  <label for="price" style="font-weight:bold;">{{'edit-pricing.my_price' | translate }}</label><br/>
			  <input type="text" currencyMask [options]="session?.currencyMaskOptions" name="price" id="price" [(ngModel)]="price" (input)="priceChanged()" style="text-align:center;"/>
			</div>

			<div class="alert alert-secondary price-error" *ngIf="showLowWarning">
			  <h3>{{'edit-pricing.price_alert' | translate}}</h3>
			  <div [innerHTML]="lowWarning"></div>
			</div>
			<div class="alert alert-secondary price-error" *ngIf="showHighWarning">
			  <h3>{{'edit-pricing.price_alert' | translate}}</h3>
			  <div [innerHTML]="highWarning"></div>
			</div>
		  </div>
		  <hr style="color: #2d2e2f">
		  <div>
			<div [innerHTML]="priceSummary" class="priceSummary"></div>
			<!-- <h4>Recommended price</h4> -->
			<!-- This is the price your corporate group recommends in order to make preventive care testing feasible for your clients and improve business outcomes for your practice. -->
		  </div>
		</div>
	  </div>
	  <div *ngIf="page==1" class="pricing-page">
		<h1>{{'edit-pricing.price_strategy' | translate }}</h1>
		{{'edit-pricing.price_strategy_desc' | translate }}
		<br/>
		How it works:
		<br/>
			Preventive care diagnostics are priced lower than similar profiles used for sick patients.
			<br/>
			Why it works:
			<ul>
			  <li>Preventive care workups take less time than those for sick patients, justifying the lower price.</li>
			  <li>Lower prices lead more pet owners to agree to preventive care testing for their pets.</li>
			  <li>Abnormalities found during screenings lead to additional care services and improved health outcomes.</li>
			  <li>Increased screenings and the additional services they bring help offset the lower price of preventive care diagnostics.</li>
			</ul>

	  </div>
	  <div class="nav-button">
		<button *ngIf="showNextButton" class="next-button" (click)="next()"><i class="fas fa-chevron-right"></i></button>
        <br/>
		<a *ngIf="showNextButton" class="spot-link" (click)="next()">See the pricing strategy</a>
	  </div>
	</div>

  </div>
  <div class="spot-modal__footer">
    <button class="spot-modal__footer-cancel-button" (click)="cancelClicked()">{{'edit-pricing.cancel' | translate}}</button>
	<button type="submit" class="spot-button spot-button--primary spot-button--large" (click)="save()">{{ 'edit-pricing.apply-cost' | translate }}</button>
  </div>
</div>
