import { IPartner } from "../partner";
import { IAPIResponseData } from "./service";

export interface IRefLabPriceRequest {
    lims?: string;
    lims_code?: string;
    sap_material_number?: string;
}

export interface IPricedMaterials {
    sapMaterialNumbers: string[];
    soldToPartner: IPartner;
    shipToPartner: IPartner;
}

export interface IPriceRequest {
    sapId?: string;
    materials?: IPricedMaterials[];
    bom_ids?: string[]; // for Reference Price
    countryCd: string;
    matchedProfiles?: IRefLabPriceRequest[]
}

export interface IPriceInfo {
    sapId: string;
    hasErrors: boolean;
    items: ITestPrice2[];
}

export interface IPriceResponse extends IAPIResponseData<IPriceInfo> {
    success?: boolean;
    data?: IPriceInfo;
    error?: Error;
}

export interface IReferencePriceResp extends IAPIResponseData<IReferencePrice[]> {
    success?: boolean;
    error?: Error;
    data?: IReferencePrice[];
}

export interface IReferencePrice {
    bom_id: string;
    reference_price?: number;
}

export interface ITestPrice2 {
    list?: number;
    totalPrice: number; // customer-specific price
    netPrice: number; // list price
    floor?: number;
    recommended?: number;
    sapMaterialNumber: string;
    currencyCd: string;
    error?: string;
}

export class PartnerTypeEnum {
    private static ORDINAL_SEQ: number = 0;

    public static readonly SHIP_TO = new PartnerTypeEnum("SHIP_TO", "WE");

    public static readonly SOLD_TO = new PartnerTypeEnum("SOLD_TO", "AG");

    public readonly ordinal: number = 0;

    public static values: PartnerTypeEnum[];

    // private to disallow creating other instances of this type
    private constructor(public readonly developerName: string,
        public readonly value: string) {
        this.ordinal = PartnerTypeEnum.ORDINAL_SEQ++;
        PartnerTypeEnum.values = PartnerTypeEnum.values || [];
        PartnerTypeEnum.values.push(this);
    }

    public toString(): string {
        return this.developerName;
    }
}
