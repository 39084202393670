import { Component, OnInit, OnChanges, Input } from '@angular/core';

import { Language } from "@shared/model/language";
import { IAccountSettings } from "@shared/model/account-settings";

@Component({
    selector: 'pcc-account-language',
    templateUrl: './account-language.component.html',
    styleUrls: ['./account-language.component.scss']
})
export class AccountLanguageComponent implements OnInit, OnChanges {
    @Input() public accountSettings: IAccountSettings;

    @Input() public allLanguages: Language[] = [];

    public selectedLocale?: string;

    public ngOnInit(): void {
        this.updateLanguages();
    }

    public ngOnChanges(): void {
        this.updateLanguages();
    }

    private updateLanguages(): void {

    }

    public addLanguage() {
        console.log("addLanguage: ", this.selectedLocale);

        if (!this.selectedLocale || this.hasLanguage(this.selectedLocale)) {
            return;
        }

        const language = this.allLanguages.find((lang: Language): boolean => lang.locale === this.selectedLocale);
        if (language) {
            this.accountSettings.languages.push(language);
        }

        this.accountSettings.supportedLocales = this.accountSettings.languages.map((lang: Language): string => lang.locale);

        // If only a single language is defined, set it as the default locale.
        if (this.accountSettings.supportedLocales.length === 1) {
            this.accountSettings.defaultLocale = this.accountSettings.supportedLocales[0];
        }

        this.selectedLocale = null;
    }

    // Returns true if the current list of languages already contains the specified locale.
    public hasLanguage(locale: string): boolean {
        return this.accountSettings.languages.some((lang: Language): boolean => lang.locale === locale);
    }

    public deleteLanguageConfirmed(deletedLanguage: Language): void {
        this.accountSettings.languages = this.accountSettings.languages.filter((lang: Language): boolean => lang.locale !== deletedLanguage.locale);
        this.accountSettings.supportedLocales = this.accountSettings.languages.map((lang: Language): string => lang.locale);
    }
}
