<div class="page-header">
  <span class="spot-typography__heading--level-2">User Settings</span>
</div>

<div class="page-body">

  <as-split unit="percent" direction="vertical" #split="asSplit">
    <as-split-area [size]="50" #area1="asSplitArea">
      <div style="min-height: 100px; max-height: 200px;">
        <table matSort (matSortChange)="sortData($event)" style="width: 100%;" aria-label="Users" class="spot-data-table spot-data-table--clickable">
          <thead>
            <tr>
              <th mat-sort-header="name">Name</th>
              <th mat-sort-header="role">Role</th>
              <th mat-sort-header="sfUsername">SF Username</th>
              <th mat-sort-header="email">Email</th>
              <th mat-sort-header="profile">Profile</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let user of sortedData" (click)="configUser(user)" [ngClass]="'pcc-user ' + (isSelected(user) ? ' spot-data-table__row--clicked' : '')">
              <td>{{user.name}} <ng-container *ngIf="!user.active"> <i>(inactive)</i></ng-container></td>
              <td>{{user.role ? user.role.name : ''}}</td>
              <td>{{user.sfdcUsername}}</td>
              <td>{{user.email}}</td>
              <td>{{user.sfdcProfile ? user.sfdcProfile.name : ''}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </as-split-area>

    <as-split-area [size]="50" #area3="asSplitArea" style="overflow: hidden">
      <div class="pcc-edit-panel" style="display:flex;flex-direction:column;height:100%;" [style]="selectedUser ? '' : 'display: none;'">
        <div class="pcc-edit-panel__header user-header">
          <span class="pcc-level-5-10">{{ selectedUser?.name }}</span>
          <span> {{selectedUser?.sfdcProfile?.name}} </span>
          <span> {{userSfInstance}} </span>
        </div>
        <div class="pcc-edit-panel__content" style="padding: 1em;">
          <form class="spot-form">
            <ng-container *ngIf="selectedUser">

              <div class="spot-form__field-group">
                <label class="spot-form__label" for="role">Role</label>
                <div class="spot-form__select">
                  <select name="role" id="role" class="spot-form__select-input" [(ngModel)]='selectedUser.role' (change)="roleSelected()" required [compareWith]="compareRole">
                    <option class="spot-form__select-option" value=""></option>
                    <option *ngFor="let role of roles" [ngValue]="role" class="spot-form__select-option">{{role.name}}</option>
                  </select>
                  <div class="spot-form__select-inner"></div>
                  <span class="spot-form__select-open-indicator">
                    <svg class="spot-icon spot-form__select-open-icon" aria-labelledby="title">
                      <title>caret-down</title>
                      <use xlink:href="/assets/spot-library/dist/symbols/spot_symbols.svg#caret-down"></use>
                    </svg>
                  </span>
                </div>
              </div>

              <div class="spot-form__field-group">
                <label class="spot-form__label" for="email">Email</label>
                <input type="email" class="spot-form__input" name="email" [(ngModel)]="selectedUser.email" readonly/>
              </div>

              <div class="spot-form__field-group">
                <input type="checkbox" class="spot-toggle" id="activeField" name="activeField" [(ngModel)]="selectedUser.active"/>
                <label class="spot-toggle-label" for="activeField">Active</label>
              </div>

            </ng-container>
          </form>
        </div>
        <div class="pcc-edit-panel__footer button-panel" *ngIf="selectedUser">
          <button type="cancel" [disabled]="saving" class="spot-button spot-button--link" (click)="cancel()">Cancel</button>
          <button type="submit" [disabled]="saving" class="spot-button spot-button--primary" (click)="save()">Save</button>
        </div>
      </div>
    </as-split-area>
  </as-split>
</div>
