import { Component, Input, HostBinding } from "@angular/core";

@Component({
    selector: "pcc-icon",
    templateUrl: "./icon.component.html",
    styleUrls: [
        "./icon.component.scss"
    ]
})
export class IconComponent {
    @Input() public icon: string;

    @Input() public symbol: string;

    @Input() public path: string;

    @Input() public imageSrc: string

    @Input() public iconClass: string = "spot-icon ";

    @Input() public title: string = "";

    @Input() public size: number | string;

    @HostBinding("class") public class: string;

    getStyle() {
        if (this.size) {
            const size = this.size;
            return { width: `${size}px`, height: `${size}px` };
        }
    }
}
