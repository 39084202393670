<div class="page-header">
  <span class="pcc-level-3-10">Buying Groups</span>
  <a class="spot-link add-item" (click)="addBuyingGroup()">
    <svg class="spot-icon spot-link__icon spot-link__icon--left" aria-labelledby="title">
      <title>Add</title>
      <use xlink:href="/assets/spot-library/dist/symbols/spot_symbols.svg#add"></use>
    </svg>
    Add a buying group
  </a>
</div>

<div class="page-body">
  <as-split unit="percent" direction="horizontal" #split="asSplit" style="flex: 1;">
    <as-split-area [size]="30" #area1="asSplitArea">

      <div class="spot-container--light-primary left-bar left-bar">
        <ul class="spot-list-group" style="overflow:auto;height:100%;">
          <li *ngFor="let group of buyingGroups" class="spot-list-group__item list-item" [ngClass]="group == selectedBuyingGroup ? 'spot-list-group__item--active' : ''">
            <a class="spot-list-group__link" (click)="selectBuyingGroup(group)" >
              <div>
                <div>{{group.name}}</div>
                <div>{{group.customer_group_description}}</div>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </as-split-area>

    <as-split-area [size]="70" #area3="asSplitArea" style="overflow:hidden">
      <div class="pcc-edit-panel" style="display:flex;flex-direction:column;height:100%;">
        <div class="pcc-edit-panel__content" [style]="selectedBuyingGroup ? '' : 'display:none;'" style="padding: 1em;">
          <form class="spot-form" #bgForm="ngForm" name="bgForm">
            <ng-container *ngIf="selectedBuyingGroup">
              <div class="spot-form__field-group">
                <label class="spot-form__label" for="name">Buying Group name</label>
                <input type="text" class="spot-form__input" name="name" [(ngModel)]="selectedBuyingGroup.name" id="name" required/>
              </div>
              <div class="spot-form__field-group">
                <label class="spot-form__label" for="customer_group_description" data-toggle="tooltip" data-placement="right" title="Enter a regular expression that matches the Customer Group Description field in the Account table in Salesforce.">Customer Group Description <i class="fa-solid fa-info-circle"></i></label>
                <input type="text" class="spot-form__input" name="customer_group_description" [(ngModel)]="selectedBuyingGroup.customer_group_description" id="customer_group_description"/>

              </div>

              <br/>
              <i style="float:right">Key: {{selectedBuyingGroup.developer_name}}</i>
              <br/>
              <div *ngIf="!testRegex()" style="color: red;background-color:rgba(255,0,0,.05);border:1px solid red;">
                Invalid regular expression.
              </div>

            </ng-container>
          </form>
        </div>

        <div *ngIf="selectedBuyingGroup" class="pcc-edit-panel__footer">
          <button type="cancel" [disabled]="saving" class="pcc-edit-panel__footer-cancel-button" (click)="cancel()">Cancel</button>
          <button type="submit" [disabled]="saving || invalid" class="spot-button spot-button--primary" (click)="save()">Save</button>
        </div>

      </div>
    </as-split-area>
  </as-split>

</div>
