import { Component, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

export interface IButton {
    text: string,
    value: string,
    cls: string
}

@Component({
    selector: "pcc-confirmation",
    templateUrl: "./confirmation.component.html",
    styleUrls: [
        "./confirmation.component.scss"
    ]
})
export class ConfirmationComponent {

    @Input() title = "confirmation.Important";

    @Input() body = "confirmation.Are_you_sure";

    @Input() button1: IButton = {
        text: "confirmation.Yes", value: "yes", cls: "spot-button--primary"
    };

    @Input() button2: IButton;

    @Input() cancelButton: IButton = {
        text: "confirm_action.cancel", value: "cancel", cls: "spot-modal__footer-cancel-button"
    };

    @Input() param: any;

    @Input() closable = false;

    public constructor(
        public activeModal: NgbActiveModal
    ) {
    }

    public onButtonClick(button: "button1" | "button2" | "cancelButton") {
        this.activeModal.close(this[button]);
    }
}
