import { Component, Input, Output, EventEmitter, TemplateRef } from "@angular/core";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";

/**
 * Re-orderable list of items.
 */

@Component({
    selector: "pcc-drag-drop-list",
    templateUrl: "./drag-drop-list.component.html",
    styleUrls: [
        "./drag-drop-list.component.scss"
    ]
})
export class DragDropListComponent<ItemType> {
    @Input() public items: ItemType[];

    @Input() public itemTemplate: TemplateRef<any>;

    @Input() public selectedItem: ItemType;

    @Output() public reordered = new EventEmitter<ItemType[]>();

    @Output() public itemClicked = new EventEmitter<ItemType>();

    public drop(event: CdkDragDrop<ItemType[]>): void {
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        }

        this.itemsReordered();
    }

    public itemsReordered(): void {
        this.reordered.emit(this.items);
    }

    public listItemClicked(item: ItemType): void {
        this.selectedItem = item;
        this.itemClicked.emit(item);
    }
}
