export enum SF_INSTANCE {
    SF_GLOBAL = "SF_GLOBAL",
    SF_AU = "SF_AU"
}

export const utils = {
    forName(val: string): SF_INSTANCE {
        let sfName: SF_INSTANCE;
        if (!val || val === null || val === undefined || val === "0") {
            sfName = SF_INSTANCE.SF_GLOBAL;
        } else if (val === "SF_GLOBAL" || val === "GLOBAL") {
            sfName = SF_INSTANCE.SF_GLOBAL;
        } else if (val === "SF_AU" || val === "AU") {
            sfName = SF_INSTANCE.SF_AU;
        } else {
            console.error("Unknown salesforce instance name: ", val);
            sfName = SF_INSTANCE.SF_GLOBAL;
        }
        // console.log("forName: ", val, typeof val, sfName);
        return sfName;
    },

    // Return default salesforce instance for country code.
    forCountryCd(countryCd: string): SF_INSTANCE {
        let sfName: SF_INSTANCE;
        if (countryCd === "AU") {
            sfName = SF_INSTANCE.SF_AU;
        } else {
            sfName = SF_INSTANCE.SF_GLOBAL;
        }

        console.log("salesforceUtils.forCountryCd: ", countryCd, sfName);
        return sfName;
    }
};
