import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { AuthGuard } from "../auth/auth.guard";
import { AccountGuard } from "../guard/account.guard";
import { EnrollNavGuard } from "../guard/enroll-nav.guard";

import { ProfilesComponent } from "./views/profiles/profiles.component";
import { LandingComponent } from "./views/landing/landing.component";
import { HomeComponent } from "./views/home/home.component";

import { DoneComponent } from "./views/done/done.component";
import { EnrollComponent } from "./views/enroll/enroll.component";
import { EditPricingComponent } from "./views/edit-pricing/edit-pricing.component";
import { ClientLayoutComponent } from "./layout/client-layout/client-layout.component";
import { GoalSettingComponent } from "./views/goal-setting/goal-setting.component";
import { PetOwnerPricingComponent } from "./views/pet-owner-pricing/pet-owner-pricing.component";
import { CustomPageComponent } from "./views/custom-page/custom-page.component";
import { SessionConfirmComponent } from "./views/session-confirm/session-confirm.component";

const routes: Routes = [
    {
        path: "account/:sapId",
        component: ClientLayoutComponent,
        children: [
            {
                path: "session-confirm",
                component: SessionConfirmComponent,
                canActivate: [
                    AuthGuard,
                    EnrollNavGuard
                ]
            },
            {
                path: "landing", component: LandingComponent,
                canActivate: [
                    AuthGuard,
                    AccountGuard,
                    EnrollNavGuard
                ]
            },
            {
                path: "custom-page/:id",
                component: CustomPageComponent,
                canActivate: [
                    AuthGuard,
                    AccountGuard,
                    EnrollNavGuard
                ]
            },
            {
                path: "profiles", component: ProfilesComponent,
                canActivate: [
                    AuthGuard,
                    AccountGuard,
                    EnrollNavGuard
                ]
            },
            {
                path: "done",
                component: DoneComponent,
                canActivate: [
                    AuthGuard,
                    AccountGuard
                ]
            },
            {
                path: "enroll", component: EnrollComponent,
                canActivate: [
                    AuthGuard,
                    AccountGuard,
                    EnrollNavGuard
                ],
                data: { enablePreview: true }
            },
            {
                path: "full-pricing", component: EditPricingComponent,
                canActivate: [
                    AuthGuard,
                    AccountGuard,
                    EnrollNavGuard
                ]
            },
            {
                path: "pet-owner-pricing",
                component: PetOwnerPricingComponent,
                canActivate: [
                    AuthGuard,
                    AccountGuard,
                    EnrollNavGuard
                ],
                data: { enablePreview: true }
            }, {
                path: "goals",
                component: GoalSettingComponent,
                canActivate: [
                    AuthGuard,
                    AccountGuard,
                    EnrollNavGuard
                ],
                data: { enablePreview: true }
            },
            {
                path: "home",
                outlet: "dogScreen",
                component: HomeComponent
            }
        ]
    }
];

@NgModule({
    imports: [
        RouterModule.forChild(routes)
    ],
    exports: [
        RouterModule
    ]
})
export class ClientRoutingModule { }
