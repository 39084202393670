<div class="form-group">
  <label>Pricing algorithm</label>
  <textarea rows=5 class="form-control" id="priceAlg" (input)="formulaChanged()" [(ngModel)]='displayAlgorithm' #pricingAlg name="pricingAlg"></textarea>
  <p>
    <select id="algVar" name="algVar" [(ngModel)]="selectedVar" [compareWith]="compareVar">
      <option value=''></option>
      <optgroup *ngFor="let group of varGroups" [label]="group.name">
        <option *ngFor="let tVar of group.variables" [ngValue]="tVar">{{tVar.displayName}}</option>
      </optgroup>
    </select>
    <button type="button" (click)="addVar()">Add variable</button>
</div>
<div>
  <span id="priceAlgVal" *ngIf="showPriceOK" style="color:green">Valid</span>
  <span id="priceAlgVal" *ngIf="!showPriceOK" style="color:red">{{errorMsg}}</span>
</div>

<button type="button" [disabled]="!showPriceOK" class="spot-button spot-button--secondary pcc-button-space" (click)="toggleSimulate()">Show simulate pricing</button>

<form *ngIf="showSim && simProfile">
  <div id="simPricing" style="margin:10px;border: 1px solid #ccc;border-radius:.5em;padding:5px;">
    <h4>Simulate pricing</h4>
    <span class="form-group" *ngFor="let svar of algorithmVars">
      <div class="form-group" *ngIf="svar.isInputValue">
        <label [for]="svar.name">{{svar.displayName}}</label>
        <input type="text" [id]="svar.name" [name]="svar.name" class="form-control" [(ngModel)]="varValues[svar.name]" (input)='simFormula()' />
      </div>
      <div class="form-check" *ngIf="!svar.isInputValue">
        <pcc-checkbox [label]="svar.displayName" [(checked)]="varValues[svar.name]" (checkedChange)="simFormula()"></pcc-checkbox>
      </div>
    </span>

    <div id="simResult">
      Result:
      <div id="priceAlgSimVal" style="border: 1px solid blue;border-radius:.5em;padding:5px;">{{simResult}}</div>
    </div>
  </div>
</form>
