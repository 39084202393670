import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { AppModule } from "./app/app.module";
import { ConfigService } from "./app/service/config.service";
import googleAnalytics from "./app/analytics/googleAnalytics";

import '@angular/localize/init';

async function main() {
    try {
        // Initialize config service
        const configService = new ConfigService();
        await configService.fetchConfigOnAppInit();

        // Init google analytics, but don't wait for it.
        googleAnalytics.initGoogle();

        // Once config is complete, bootstrap the Angular application
        platformBrowserDynamic().bootstrapModule(AppModule)
            .catch(err => console.error(err));
    } catch (error) {
        console.error("Failed to init app.", error);
    }
}

if (process.env.NODE_ENV === "production") {
    console.log("Calling enableProdMode...");
    enableProdMode();
}

// Start the application
main();
