<div class="pcc-edit-panel">
  <div class="pcc-edit-panel__content" style="padding: 1em;">
    <div *ngIf="accountSettings?.status=='DRAFT'" style="background-color:#88d8df;text-align:center;">
      <h1>DRAFT Version</h1>
    </div>

    <div style="display:flex;flex-direction:row;">
      <form #acctForm="ngForm" (ngSubmit)="onSubmit()" enctype="multipart/form-data" [style]="formStyle" style="flex:1" class="spot-form general-settings">
        <div *ngIf="accountSettings" class="form-horizontal">

          <div class="general-info">
            <div class="spot-form__field-group">
              <label class="spot-form__label" for="countryCd">
                <span class="spot-form__label-required-indicator">*</span>
                Country
              </label>
              <div class="spot-form__select">
                <select name="countryCd" class="spot-form__select-input" aria-label="Country Code" [(ngModel)]="accountSettings.countryCd" required>
                  <option class="spot-form__select-option" value=""></option>
                  <option *ngFor="let country of countryList" [value]="country.code" class="spot-form__select-option">{{country.code}} - {{country.name}}</option>
                </select>
                <div class="spot-form__select-inner"></div>
                <span class="spot-form__select-open-indicator">
                  <svg class="spot-icon spot-form__select-open-icon" aria-labelledby="title">
                    <title>caret-down</title>
                    <use xlink:href="/assets/spot-library/dist/symbols/spot_symbols.svg#caret-down"></use>
                  </svg>
                </span>
              </div>
            </div>

            <ng-container *ngIf="isCountrySettings">
              <div class="spot-form__field-group">
                <label class="spot-form__label" for="handling-email">Handling Email</label>
                <input type="email" class="spot-form__input" name="handling-email" [(ngModel)]="accountSettings.handling_email">
              </div>

              <div class="spot-form__field-group">
                <label class="spot-form__label" for="pricingoutputemail">Pricing Output Email</label>
                <input type="email" class="spot-form__input" name="pricingoutputemail" [(ngModel)]="accountSettings.pricingOutputEmail"/>
              </div>
            </ng-container>

            <ng-container *ngIf="!isCountrySettings">
              <div class="spot-form__field-group">
                <label class="spot-form__label" for="handling-email">Handling Email</label>
                {{ accountSettings?.handling_email }}
              </div>

              <div class="spot-form__field-group">
                <input type="checkbox" class="spot-toggle" id="handleAsInd" name="handleAsInd" [(ngModel)]="accountSettings.as_independent">
                <label class="spot-toggle-label" for="handleAsInd">Handle as Independent</label>
              </div>

              <div class="spot-form__field-group">
                <pcc-rich-text label="Special Handling Instructions" [editMode]="false" [(richText)]="accountSettings.special_instructions"></pcc-rich-text>
              </div>
            </ng-container>
          </div>
          <pcc-account-language *ngIf="!accountSettings.isCorp" [accountSettings]="accountSettings" [allLanguages]="systemSettings?.languages" class="lang-info"></pcc-account-language>
        </div>
      </form>
      <div class="right-info">
        <ng-container *ngIf="accountSettings">
          <pcc-corp-logo [accountSettings]="accountSettings" *ngIf="accountSettings.isCorp"></pcc-corp-logo>
          <section id="enroll-activity" class="enroll-activity">
            <span class="spot-typography__heading--level-3 enroll-activity-heading">Enrollment Activity</span>
            <div class="session-count-item">
              <div class="session-count-label">
                Submitted enrollments
              </div>
              <div class="spot-typography__heading--level-1">
                {{sessionCount.enrollments_count}}
              </div>
            </div>
            <div class="session-count-item">
              <div class="session-count-label">
                Active enrollments
              </div>
              <div class="spot-typography__heading--level-1">
                {{sessionCount.active_enrollments_count}}
              </div>
            </div>
            <div class="session-count-item">
              <div class="session-count-label">
                Unsubmitted sessions
              </div>
              <div class="spot-typography__heading--level-1">
                {{sessionCount.partial_session_count}}<br/>
              </div>
            </div>
          </section>
        </ng-container>
      </div>
    </div>

    <hr>
    <div>
      <div class="collapse-header">
        <a class="spot-link" data-bs-toggle="collapse" data-bs-target="#advancedSettings" aria-expanded="false" aria-controls="advancedSettings" (click)="advancedSettingsClicked($event)" style="width:100%;">
          <svg *ngIf="advancedCollapsed" class="spot-icon spot-link__icon spot-link__icon--left" aria-labelledby="title">
            <title>expand</title>
            <use xlink:href="/assets/spot-library/dist/symbols/spot_symbols.svg#expand"></use>
          </svg>
          <svg *ngIf="!advancedCollapsed" class="spot-icon spot-link__icon spot-link__icon--left" aria-labelledby="title">
            <title>expand</title>
            <use xlink:href="/assets/spot-library/dist/symbols/spot_symbols.svg#collapse"></use>
          </svg>
          Advanced settings
        </a>
      </div>
      <div class="collapse" id="advancedSettings" style="padding: 10px;">

        &nbsp;<button class="spot-button spot-button--sales" *ngIf="sessionCount && sessionCount.partial_session_count > 0" (click)="clearClicked()">Clear partial sessions</button>
        <i class="fa-solid fa-check-circle fa-lg" style="color:green" *ngIf="!sessionCount || sessionCount.partial_session_count == 0"></i>
        <hr>

        <div class="promote-buttons">
          <button *ngIf="accountSettings?.account_settings_id && accountSettings?.status=='DRAFT'" type="button" class="spot-button spot-button--secondary" (click)="promoteActive()">Promote to Active</button>
          <button *ngIf="accountSettings?.account_settings_id && accountSettings?.status=='DRAFT'" type="button" class="spot-button spot-button--sales" (click)="deleteDraft()">Delete Draft</button>
          <button *ngIf="accountSettings?.status=='ACTIVE'" type="button" class="spot-button spot-button--secondary" (click)="saveAsDraft()">Save as Draft</button>
        </div>
      </div>
    </div>
    <br/>
  </div>
  <div class="pcc-edit-panel__footer">
    <button type="cancel" class="pcc-edit-panel__footer-cancel-button" (click)="cancel()">Cancel</button>
    <button type="submit" class="spot-button spot-button--primary" (click)="onSubmit()">Save</button>
  </div>
</div>
