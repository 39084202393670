import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

//import { AuthCallbackComponent } from './auth-callback/auth-callback.component';
import { LoginFailedComponent } from "./login-failed/login-failed.component";
import { MsalRedirectComponent } from "@azure/msal-angular"; // Redirect component imported from msal-angular

const routes: Routes = [
    {
        path: "auth",
        children: [
            {
                path: "callback",
                component: MsalRedirectComponent
                //		component: AuthCallbackComponent
            }

            // {
            //     path: '**', redirectTo: 'login'
            // }
        ]
    },
    {
        path: "login-failed",
        component: LoginFailedComponent
    }
];

@NgModule({
    imports: [
        RouterModule.forChild(routes)
    ],
    exports: [
        RouterModule
    ]
})
export class AuthRoutingModule { }
