import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";

import { NavService } from "../../../service/nav.service";
import { ErrorUtils } from "@shared/model/error/error-utils";
import { Subscription, Observable } from "rxjs";
import { IProfile, ProfileUtils } from "@shared/model/profile";
import { PCCSession } from "@shared/model/pcc-session";
import { AppModeEnum } from "@shared/model/app-mode-enum";
import { IAccountRep } from "@shared/model/account";
import { AppFacade } from "../../../facade/app.facade";
import { delay } from "rxjs/operators";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ConfirmationComponent } from "../../components/confirmation/confirmation.component";
import googleAnalytics from "../../../analytics/googleAnalytics";
import { IAPIResponseData } from "@shared/model/service/service";
import { IEnrollment, EnrollUtils, EnrollmentStatus } from "@shared/model/enrollment";
import { IEnrollResponse } from "@shared/model/service/enroll-service";

declare const SVGMorpheus: any;

type StepState = "none" | "complete" | "inprogress" | "error";

interface IStepState {
    s3: StepState;
    mail: StepState;
    send: StepState;
}

const MORPHEUS_CONSTANTS = {
    CIRCLE: "circle",
    CLOCK: "clock",
    COUNTER_CLOCK: "counterclock",
    EASING_IN_OUT: "expo-in-out"
}

@Component({
    selector: "pcc-done",
    templateUrl: "./done.component.html",
    styleUrls: [
        "./done.component.scss"
    ]
})
export class DoneComponent implements OnInit, OnDestroy, AfterViewInit {

    private sessionSub: Subscription;

    private submitObs: Observable<IEnrollResponse>;

    private checkObs: Observable<IAPIResponseData<EnrollmentStatus>>;

    @ViewChild("sendIcons", {
        static: false, read: ElementRef
    }) public sendIcons: ElementRef;

    @ViewChild("sendIconsBG", {
        static: false, read: ElementRef
    }) public sendIconsBG: ElementRef;

    @ViewChild("s3Icons", {
        static: false, read: ElementRef
    }) public s3Icons: ElementRef;

    @ViewChild("s3IconsBG", {
        static: false, read: ElementRef
    }) public s3IconsBG: ElementRef;

    @ViewChild("emailIcons", {
        static: false, read: ElementRef
    }) public emailIcons: ElementRef;

    @ViewChild("emailIconsBG", {
        static: false, read: ElementRef
    }) public emailIconsBG: ElementRef;

    private svgS3Icons: any;

    private svgS3IconsBG: any;

    private svgSendIcons: any;

    private svgSendIconsBG: any;

    private svgEmailIcons: any;

    private svgEmailIconsBG: any;

    private enrollTimer: number;

    public session: PCCSession;

    public enrollInfo: IEnrollment;

    public retrying: boolean;

    public submitting = true;

    public stepState: IStepState = {
        s3: null,
        mail: null,
        send: null
    };

    public errorMsg: string;

    public currentId: string;

    public hasResources = false;

    public vdcUser: IAccountRep;

    // In the view, all created profiles are sorted into columns by species, then rows by order of creation.
    // The order of species displayed should be part of the species definition...
    public profileCards: IProfile[] = [];

    public launchAsDate: Date;

    public integrationAsDate: Date;

    public enrollmentEndAsDate: Date;

    public constructor(
        private navService: NavService,
        private appFacade: AppFacade,
        private modalService: NgbModal
    ) {
        this.sessionSub = this.appFacade.getCurrentSession().subscribe((session): void => {
            this.setSession(session);
        });
    }

    private setSession(session: PCCSession): void {
        this.session = session;
        this.hasResources = session?.enrollInfo?.selectedDocuments?.length > 0;
        this.enrollInfo = session?.enrollInfo;

        this.launchAsDate = new Date(this.enrollInfo.launchDate);
        this.integrationAsDate = new Date(this.enrollInfo.integrationDate);
        this.enrollmentEndAsDate = new Date(this.enrollInfo.enrollmentEndDate);

        this.vdcUser = EnrollUtils.getVdcUser(this.enrollInfo);

        if (session?.enrollInfo?.profiles) {
            this.profileCards = ProfileUtils.filterSelectedProfiles(session?.enrollInfo.profiles);
        }
    }

    // Hide the progress bar, next button.
    public ngOnInit(): void {
        this.navService.setCurrentState({
            name: "navigation.Done",
            link: "/done",
            enabled: false,
            hideProgress: true
        });
    }

    public ngAfterViewInit(): void {
        this.svgSendIcons = new SVGMorpheus(this.sendIcons.nativeElement);
        this.svgSendIconsBG = new SVGMorpheus(this.sendIconsBG.nativeElement);
        this.svgS3Icons = new SVGMorpheus(this.s3Icons.nativeElement);
        this.svgS3IconsBG = new SVGMorpheus(this.s3IconsBG.nativeElement);
        this.svgEmailIcons = new SVGMorpheus(this.emailIcons.nativeElement);
        this.svgEmailIconsBG = new SVGMorpheus(this.emailIconsBG.nativeElement);

        setTimeout((): void => {
            this.updateStepState({
                send: "none",
                s3: "none",
                mail: "none"
            });
            this.submitEnrollment();
        });
    }

    public submitEnrollment(): Observable<IEnrollResponse> {
        console.log("done.submitEnrollment");
        this.submitting = true;

        const result = this.appFacade.submitEnrollment();
        this.submitObs = result;

        this.updateStepState({
            ...this.stepState,
            send: "inprogress"
        });

        result.pipe(
            delay(500)
        ).subscribe((v): void => {
            console.log("submit v=", v);
            if (v && v.success && this.submitObs === result) {
                this.startCheckEnrollmentStatus({
                    id: v.enrollInfo.enrollment_id
                });
                this.updateStepState({
                    ...this.stepState,
                    send: "complete"
                });
            } else if (!v || !v.success) {
                console.error("Error submitting enrollment: ", v.error);
                let errorMsg = ErrorUtils.getErrorMessage(v.error);
                if (errorMsg === "") {
                    errorMsg = "Unknown system error";
                }
                this.updateStepState({
                    ...this.stepState,
                    send: "error"
                }, errorMsg);
            }
        });
        return result;
    }

    private transitionIcon(icons: any, iconsBG: any, value: StepState): void {
        console.log("transitionIcon: ", icons, iconsBG, value);
        switch (value) {
            case "none":
                icons.to(MORPHEUS_CONSTANTS.CIRCLE, {
                    rotation: MORPHEUS_CONSTANTS.CLOCK, easing: MORPHEUS_CONSTANTS.EASING_IN_OUT
                });
                iconsBG.to(MORPHEUS_CONSTANTS.CIRCLE, {
                    rotation: MORPHEUS_CONSTANTS.CLOCK, easing: MORPHEUS_CONSTANTS.EASING_IN_OUT
                });
                break;
            case "error":
                icons.to("error", {
                    rotation: MORPHEUS_CONSTANTS.COUNTER_CLOCK, easing: MORPHEUS_CONSTANTS.EASING_IN_OUT
                });
                iconsBG.to("error", {
                    rotation: MORPHEUS_CONSTANTS.CLOCK, easing: MORPHEUS_CONSTANTS.EASING_IN_OUT
                });
                break;
            case "complete":
                icons.to("start", {
                    rotation: MORPHEUS_CONSTANTS.CLOCK, easing: MORPHEUS_CONSTANTS.EASING_IN_OUT
                });
                iconsBG.to(MORPHEUS_CONSTANTS.CIRCLE, {
                    rotation: MORPHEUS_CONSTANTS.CLOCK, easing: MORPHEUS_CONSTANTS.EASING_IN_OUT
                });
                break;
            case "inprogress":
                icons.to("start", {
                    rotation: MORPHEUS_CONSTANTS.CLOCK, easing: MORPHEUS_CONSTANTS.EASING_IN_OUT
                });
                iconsBG.to("gear", {
                    rotation: MORPHEUS_CONSTANTS.CLOCK, easing: MORPHEUS_CONSTANTS.EASING_IN_OUT
                });
                break;
            default:
                break;
        }
    }

    public hasError(state: any): boolean {
        let retVal = false;
        if (!state) {
            retVal = false;
        } else {
            for (const key of Object.keys(state)) {
                if (state[key] === "error") {
                    retVal = true;
                    break;
                }
            }
        }
        console.log("hasError: ", retVal);
        return retVal;
    }

    public isComplete(state: any): boolean {
        let retVal = true;
        if (!state) {
            retVal = true;
        } else {
            for (const key of Object.keys(state)) {
                if (state[key] !== "complete") {
                    retVal = false;
                    break;
                }
            }
        }
        console.log("isComplete: ", retVal);
        return retVal;
    }

    public updateStepState(state: IStepState, error?: string): void {
        console.log("updateStepState: ", state, error);
        this.errorMsg = error;

        if (state.send !== this.stepState.send) {
            console.log("send state changed: ", this.stepState.send, "->", state.send);
            this.currentId = "send";
            this.stepState.send = state.send;
            this.transitionIcon(this.svgSendIcons, this.svgSendIconsBG, this.stepState.send);
        }
        if (state.s3 !== this.stepState.s3) {
            this.currentId = "s3";
            console.log("s3 state changed: ", this.stepState.s3, "->", state.s3);
            this.stepState.s3 = state.s3;
            this.transitionIcon(this.svgS3Icons, this.svgS3IconsBG, this.stepState.s3);
        }
        if (state.mail !== this.stepState.mail) {
            console.log("mail state changed: ", this.stepState.mail, "->", state.mail);
            this.currentId = "mail";
            this.stepState.mail = state.mail;
            this.transitionIcon(this.svgEmailIcons, this.svgEmailIconsBG, this.stepState.mail);
        }

        // submitting means no error, no complete
        // If anything has error, we're done.
        // If all have complete, we're done.
        console.log("submit state=", state);
        if (this.hasError(state)) {
            this.submitting = false;
        } else if (this.isComplete(state)) {
            this.submitting = false;

            this.submitComplete();
        } else {
            this.submitting = true;
        }
    }

    public async submitComplete(): Promise<void> {
        console.log("submitComplete");
        if (this.appFacade.getAppMode().getValue() === AppModeEnum.TRAINING) {
            console.log("confirmPurgeSessions");
            const ref = this.modalService.open(ConfirmationComponent, {
                size: "med",
                windowClass: "confirmation-leaving",
                backdrop: "static",
                keyboard: false
            });
            ref.componentInstance.title = "training.title";
            ref.componentInstance.body = "training.msg";
            ref.componentInstance.button1 = {
                text: "training.button1", value: "ok", cls: "pcc-primary-button"
            };
            ref.componentInstance.cancelButton = null; // Hide cancel button
            const value = await ref.result;
            console.log("Confirm value=", value);
        }
    }

    private async startCheckEnrollmentStatus({ id }: { id: number }): Promise<void> {
        console.log("startCheckEnrollmentStatus: ", id);
        if (this.enrollTimer) {
            return;
        }

        this.enrollTimer = new Date() as any;

        const check = async (): Promise<void> => {
            const start = new Date() as any;
            const result = await this.checkEnrollmentStatus({
                id
            }).toPromise();
            const next = new Date() as any;
            const diff = Math.max(750 - (next - start), 0);

            if (
                next - this.enrollTimer >= 30000 ||
                (!this.retrying && (result.data && (result.data.s3.error || result.data.mail.error))) ||
                (result.data && result.data.s3.complete && result.data.mail.complete)
            ) {
                this.enrollTimer = undefined;
                return;
            }
            setTimeout((): void => {
                check();
            }, diff);
        };
        check();
    }

    public checkEnrollmentStatus({ id }: { id: number }): any {
        console.log("checkEnrollmentStatus: ", id);
        let state: string;
        if (this.stepState.s3 !== "complete") {
            if (this.stepState.s3 !== "error") {
                this.updateStepState({
                    ...this.stepState,
                    s3: "inprogress"
                });
            }
            state = "s3";
        } else if (this.stepState.mail !== "complete") {
            if (this.stepState.mail !== "error") {
                this.updateStepState({
                    ...this.stepState,
                    mail: "inprogress"
                });
            }
            state = "mail";
        }

        const result = this.appFacade.checkEnrollmentStatus({
            id
        });
        this.checkObs = result;
        result.subscribe((v: IAPIResponseData<EnrollmentStatus>): void => {
            console.log("v=", v);
            if (v.success && this.checkObs === result) {
                if (!this.retrying && v.data && v.data.s3.error) {
                    this.updateStepState({
                        ...this.stepState,
                        [state]: "error"
                    }, v.data.s3.error);
                } else if (v.data && v.data.s3.complete) {
                    this.updateStepState({
                        ...this.stepState,
                        s3: "complete"
                    });
                }
                if (!this.retrying && v.data && v.data.mail.error) {
                    this.updateStepState({
                        ...this.stepState,
                        [state]: "error"
                    }, v.data.mail.error);
                } else if (v.data && v.data.mail.complete) {
                    this.updateStepState({
                        ...this.stepState,
                        mail: "complete"
                    });
                }
            } else {
                this.updateStepState({
                    ...this.stepState,
                    [state]: "error"
                });
            }
        });
        return result;
    }

    public sendEmail(): void {
        this.updateStepState({
            ...this.stepState,
            s3: this.stepState.s3 === "error" ? "inprogress" : this.stepState.s3,
            mail: this.stepState.mail === "error" ? "inprogress" : this.stepState.mail,
        });
        this.retrying = true;
        this.appFacade.sendEmail().subscribe(
            (): void => {
                this.retrying = false;
            },
            (): void => {
                this.retrying = false;
            });
        this.startCheckEnrollmentStatus({
            id: this.session.enrollInfo.enrollment_id
        });
    }

    public getStepClass(id: keyof DoneComponent["stepState"]): string {
        return `step ${id} ${this.stepState[id]}`;
    }

    public onClick(id: keyof DoneComponent["stepState"]): void {
        console.log("clicked", id, this.stepState);
        if (id === "send" && this.stepState.send === "error") {
            this.submitEnrollment();
        } else if (id === "s3" && this.stepState.s3 === "error") {
            this.sendEmail();
        } else if (id === "mail" && this.stepState.mail === "error") {
            this.sendEmail();
        }
    }

    public downloadClick(): void {

        googleAnalytics.registerEvent("enrollment_pdf_download_click", "clickEvent");

        const enrollId = this.session.enrollInfo.enrollment_id;

        this.appFacade.showPdf(enrollId);
    }

    public ngOnDestroy(): void {
        this.sessionSub.unsubscribe();
    }

    public homeClick(): void {
        console.log("homeClick");

        googleAnalytics.registerEvent("go_home_from_end_started", "clickEvent");
        const result = this.navService.goHome();

        console.log("result=", result);
    }

    public formatDate(date?: Date): string {
        if (!date) {
            return "";
        }
        return date.toLocaleDateString(this.enrollInfo.locale);
    }
}
