<label class="spot-form__label" for="title" style="align-items: center;display:flex;">
  <span *ngIf="required" class="spot-form__label-required-indicator">*</span>
  {{ label }}
  <a class="spot-link" (click)="editClicked()" style="margin-left: 20px;">
    <svg *ngIf="useEditButton" class="spot-icon" aria-labelledby="title">
      <title>Edit</title>
      <use xlink:href="/assets/spot-library/dist/symbols/spot_symbols.svg#edit"></use>
    </svg>
    <span *ngIf="!useEditButton">Edit</span>
  </a>
</label>
<ng-container *ngFor="let lang of languages">
  <pcc-edit-text-locale [locale]="lang.locale" [editText]="localizedKey[lang.locale]" [showEditButton]="false" [editMode]="editMode" (editTextChange)="fieldTextChanged(lang.locale,$event)" [hasRichText]="hasRichText" [required]="required"></pcc-edit-text-locale>
</ng-container>
