
/**
 * Enum class representing LIMS systems and mapping between country code, list
 * of context codes, and list of context names
 */
export class LimsEnum {
    static ORDINAL_SEQ: number = 0;
    // display name

    static readonly LYNXX_US = new LimsEnum([
        "LYNXX US"
    ], [
        "MEM"
    ], "US");

    static readonly LYNXX_CA = new LimsEnum([
        "LYNXX CA East",
        "LYNXX CA West"
    ], [
        "VAN",
        "TOR"
    ], "CA");

    static readonly LYNXX_AU = new LimsEnum([
        "LYNXX AU"
    ], [
        "BRI"
    ], "AU");

    static readonly LYNXX_UK = new LimsEnum([
        "LYNXX UK"
    ], [
        "WBY"
    ], "UK");

    readonly ordinal: number = 0;

    static values: LimsEnum[];

    // private to disallow creating other instances of this type
    private constructor(public readonly limsNames: string[], public readonly limsContexts: string[], public readonly countryCd: string) {
        this.ordinal = LimsEnum.ORDINAL_SEQ++;
        LimsEnum.values = LimsEnum.values || [];
        LimsEnum.values.push(this);
    }

    /* Given a string (either context code or lims name), return the enum value that matches*/
    static forString(value: string): LimsEnum | null {
        for (let le of LimsEnum.values) {
            for (let limsName of le.limsNames) {
                if (limsName == value) {
                    return le;
                }
            }
            for (let contextCd of le.limsContexts) {
                if (contextCd == value) {
                    return le;
                }
            }
        }
        console.error("No value found for value: ", value);
        return null;
    }

    static forCountry(countryCd: string): LimsEnum {
        for (let me of LimsEnum.values) {
            if (me.countryCd == countryCd) {
                return me;
            }
        }
        console.warn("No Lims Enum match for country: ", countryCd);
        return null;
    }

    equals(value: string): boolean {
        var otherVal = LimsEnum.forString(value);
        return (otherVal && otherVal == this);
    }

    toString() {
        return this.limsNames.concat(",");
    }
}
/*
Need to be able to map from country
*/
