import { Injectable } from "@angular/core";

import { Router } from "@angular/router";
import { ErrorUtils } from "@shared/model/error/error-utils";
import { AdminFacade } from "../facade/admin.facade";

import { PCCAlertService } from "./alert.service";
import { ConfirmSavePurge } from "./account.service";

import { IEnrollResponse } from "@shared/model/service/enroll-service";
import { IAccountResponse, ISessionCount } from "@shared/model/account-response";
import { IAccountSettings, StatusEnum } from "@shared/model/account-settings";

@Injectable({
    providedIn: "root"
})
export class AccountSettingsService {

    public constructor(
        public router: Router,
        private adminFacade: AdminFacade,
        public alertService: PCCAlertService
    ) {}

    public async saveAccountSettings(accountSettings: IAccountSettings, sessionCount?: ISessionCount, status?: StatusEnum, fileData?: File): Promise<IAccountResponse> {
        console.log("saveAccountSettings");

        const confirmSavePurge = await this.adminFacade.confirmSavePurge(accountSettings, sessionCount);

        if (confirmSavePurge === ConfirmSavePurge.CANCEL) {
            return null;
        }

        if (confirmSavePurge === ConfirmSavePurge.OK) {
            await this.purgePartialEnrollments(accountSettings, sessionCount);
        }

        const saveResp = await this.saveAccountSettings2(accountSettings, status, fileData);
        console.log(`saveResp.success = ${saveResp.success}`);

        return saveResp;
    }

    public async saveAccountSettings2(acctSettings: IAccountSettings, status?: StatusEnum, fileData?: File): Promise<IAccountResponse> {
        console.log("saveAccountSettings2: ", status, acctSettings);

        this.alertService.setBusy(true, "Saving...");

        const formData = this.getFormData(acctSettings, status, fileData);

        try {
            const saveResp: IAccountResponse = await this.adminFacade.saveAccountSettings(formData);
            console.log("saveAccountSettings saveResp: ", saveResp);

            this.alertService.setBusy(false);

            if (saveResp && saveResp.success === true) {
                this.alertService.showToast("Save successful");
            } else {
                const msg = `Error saving account settings: ${ErrorUtils.getErrorMessage(saveResp.error)}`;
                this.alertService.showError(msg);
            }
            return saveResp;
        } catch (err) {
            console.error("Error: ", err);
            const msg2 = `System Error saving account settings: ${ErrorUtils.getErrorMessage(err)}`;
            this.alertService.setBusy(false);
            this.alertService.showError(msg2);

            return {
                success: false, error: err
            };
        }
    }

    private async purgePartialEnrollments(accountSettings: IAccountSettings, sessionCount: ISessionCount): Promise<void> {
        let resp: IEnrollResponse;
        if (sessionCount.partial_session_count > 0) {
            resp = await this.clearOldSessions(accountSettings);
        }

        if (resp.success) {
            this.alertService.showToast("Partial sessions have been cleared.");
        } else {
            this.alertService.showError("Failed to clear sessions", ErrorUtils.getErrorMessage(resp.error));
        }
    }

    private async clearOldSessions(accountSettings: IAccountSettings): Promise<IEnrollResponse> {
        console.log("clearOldSessions");
        this.alertService.setBusy(true, "Clearing sessions...");

        try {
            const resp = await this.adminFacade.clearOldSessions(accountSettings);
            console.log("clearOldSessions resp: ", resp);
            if (resp.success === true && resp.sessionCount) {
                this.alertService.showToast("Session cleanup successful");
            } else {
                this.alertService.showError("Failed to clear sessions", ErrorUtils.getErrorMessage(resp.error));
            }
            this.alertService.setBusy(false);
            return resp;
        } catch (err) {
            console.error("Error clearing old sessions", err);
            this.alertService.setBusy(false);
            this.alertService.showError("Error clearing sessions", ErrorUtils.getErrorMessage(err));
            return {
                success: false,
                error: err
            };
        }
    }

    protected getFormData(acctSettings: IAccountSettings, status?: StatusEnum, fileData?: File): FormData {
        const formData = new FormData();
        formData.append("accountSettings", JSON.stringify(acctSettings));
        if (status) {
            formData.append("status", status.value);
        }
        if (fileData) {
            formData.append("file", fileData);
        }
        return formData;
    }

    public cancel(): void {
        console.log("account-settings: cancel");
        this.adminFacade.clearSession();
        this.router.navigate([
            "/admin/account/search"
        ]);
    }
}
