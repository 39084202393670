import { Injectable } from "@angular/core";
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from "@angular/router";
import { AuthService } from "./auth.service";
import { AuthGuard } from "./auth.guard";

@Injectable()
export class RoleGuardService implements CanActivate {
    constructor(
        public authService: AuthService,
        public authGuard: AuthGuard,
        public router: Router
    ) { }

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
        try {
            const result = await this.authGuard.canActivate(route, state);
            if (result !== true) {
                console.log("User not logged in.  Returning canActivate=false");
                return result;
            }

            // this will be passed from the route config
            // on the data property
            const expectedRole = route.data.expectedRole;
            const currentUser = this.authService.getUserDetails();
            // decode the token to get its payload
            if (!this.authService.isLoggedIn() ||
                !currentUser.role ||
                currentUser.role.developer_name !== expectedRole) {
                this.router.navigate([
                    "/search"
                ]);

                console.log("role-guard: Returning false");
                return false;
            }
            return true;
        } catch (err) {
            console.error("Error checking route: ", err);
            return false;
        }
    }
}
