export class AppModeEnum {
    private static ORDINAL_SEQ: number = 0;

    public static readonly PROD = new AppModeEnum("Production", "PRODUCTION");

    public static readonly TRAINING = new AppModeEnum("Training", "TRAINING");

    public readonly ordinal: number;

    // private to disallow creating other instances of this type
    private constructor(public readonly displayName: string,
        public readonly value: string) {
        this.ordinal = AppModeEnum.ORDINAL_SEQ++;
    }

    public static forString(value: string): AppModeEnum {
        if (value === AppModeEnum.PROD.value) {
            return AppModeEnum.PROD;
        }
        if (value === AppModeEnum.TRAINING.value) {
            return AppModeEnum.TRAINING;
        }
        console.warn("Invalid value type passed in.  Defaulting to PROD...");
        return AppModeEnum.PROD;
    }

    public equals(value: string): boolean {
        return this.value === value;
    }

    public toString(): string {
        return this.displayName;
    }
}
