<div class="spot-modal testmix-modal">
    <div class="testmix-loading-overlay" *ngIf="loading">
      <div id='ajax_loader' class="loading-indicator" style="z-index: 400; background-color: rgba(175, 217, 238,.9); text-align: center;" id="loading-indicator">
        <i class="fa-solid fa-4x fa-circle-notch fa-spin"></i>
        <div style="background-color: rgba(250,250,250,.05);border-radius: 10px;padding:5px;margin-top: 5px;font-size: larger;">
          <strong>{{'testmix.Loading' | translate}}</strong>
        </div>
      </div>
    </div>
    <div class="spot-modal__header testmix-header">
      <div class="spot-modal__titles">
        <span class="testmix-header-title">
          {{'testmix.Most_frequently_used_test_codes' | translate}}
        </span>
      </div>
      <button type="button" class="spot-modal__header-cancel-button" aria-label="Close" (click)="activeModal.dismiss('Close click')">
        <svg class="spot-icon spot-modal__header-cancel-button-icon" aria-labelledby="title">
          <title>cancel</title>
          <use xlink:href="/assets/spot-library/dist/symbols/spot_symbols.svg#cancel"></use>
        </svg>
      </button>
    </div>
    <div class="testmix-modal-body">
        <span class="testmix-table-body">
            <span class="testmix-table-header">
                <span class="testmix-table-header-testcode">{{'testmix.Test_code' | translate}}</span>
                <span class="testmix-table-header-description">{{'testmix.Description' | translate}}</span>
                <span class="testmix-table-header-qty">{{'testmix.Qty' | translate}}</span>
                <span class="testmix-table-header-price" *ngIf="session?.accountSettings?.flags.test_mix_show_price">{{'testmix.Price' | translate}}</span>
                <span class="testmix-table-header-specialprice" *ngIf="session?.accountSettings?.flags.test_mix_show_special_price">{{'testmix.Special_price' | translate}}</span>
            </span>
            <span *ngFor="let item of testMix" class="testmix-table-row">
                <span class="testmix-table-cell-testcode">{{item.NativeTestCode || item.Label}}</span>
                <span class="testmix-table-cell-description bold">{{item.MaterialDesc}}</span>
                <span class="testmix-table-cell-qty">{{item.Qty12Months}}</span>
                <span class="testmix-table-cell-price" *ngIf="session?.accountSettings?.flags.test_mix_show_price">{{item.ListPrice | currency : session?.currency : 'symbol-narrow' : '1.2-2' : session?.locale}}</span>
                <span class="testmix-table-cell-specialprice bold" *ngIf="session?.accountSettings?.flags.test_mix_show_special_price">{{item.CustPrice | currency : session?.currency : 'symbol-narrow' : '1.2-2' : session?.locale}}</span>
            </span>
        </span>
    </div>
    <div class="spot-modal__footer">
        <span class="testmix-footer-sapid">
            <div class="testmix-footer-label">{{'testmix.SAP_ID' | translate}}:</div>
            <div class="testmix-footer-value">{{session?.accountInfo?.sap_id}}</div>
        </span>
        <span class="testmix-footer-practicename">
            <div class="testmix-footer-label">{{'testmix.Practice_name' | translate}}:</div>
            <div class="testmix-footer-value">{{session?.accountInfo?.name}}</div>
        </span>
        <span class="testmix-footer-location">
            <div class="testmix-footer-label">{{'testmix.Location' | translate}}:</div>
            <div class="testmix-footer-value">{{session?.accountInfo?.city}}<ng-container *ngIf="session && session.accountInfo && (session.accountInfo.city && session.accountInfo.state)">,</ng-container>{{session?.accountInfo?.state}}</div>
        </span>
    </div>
</div>
