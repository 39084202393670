import { Component, OnInit, Input } from "@angular/core";
import { DomSanitizer, SafeStyle, SafeHtml } from "@angular/platform-browser";

import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

import { AppFacade } from "../../../facade/app.facade";

import { Profile, IProfile } from "@shared/model/profile";
import { PCCAlertService } from "../../../service/alert.service";
import { PCCSession } from "@shared/model/pcc-session";
import { ErrorUtils } from "@shared/model/error/error-utils";

const SLIDER_BACKGROUND: string = "linear-gradient(to right,rgb(128,128,128),rgb(128,128,128) x%,#d7f0a2 x%,#d7f0a2 y%,rgb(128,128,128) y%)";
const GRAPH_BACKGROUND: string = "linear-gradient(to right,transparent,transparent x%,#d7f0a2 x%,#d7f0a2 y%,transparent y%)";

const PRICE_SUMMARY = "<h4>Recommended price</h4> This is the price your corporate group recommends in order to make preventive care testing feasible for your clients and improve business outcomes for your practice.";
const LOW_WARNING: string = "The price you selected is outside of the price range your corporate group recommends.";
const HIGH_WARNING: string = "The price you selected is outside of the price range your corporate group recommends.";

const RANGE_PERCENT = "percent",
    RANGE_DOLLAR = "dollar";

@Component({
    selector: "pcc-edit-pricing",
    templateUrl: "./edit-pricing.component.html",
    styleUrls: [
        "./edit-pricing.component.scss"
    ]
})
export class EditPricingComponent implements OnInit {

    @Input() public profile: IProfile;

    public session: PCCSession;

    private recommendedPrice: number;

    public price: number;

    public minPrice: number = 0;

    public maxPrice: number = 100;

    public page: number = 0;

    public showNextButton: boolean;

    public showPrevButton: boolean;

    public saving: boolean = false;

    public priceType: string = "Full";

    public rangeType: string = RANGE_PERCENT;

    public rangeHigh: number;

    public rangeLow: number;

    public lowWarning: SafeHtml;

    public highWarning: SafeHtml;

    public priceSummary: SafeHtml;

    public showLowWarning: boolean;

    public showHighWarning: boolean;

    public lowPrice: number;

    public highPrice: number;

    public sliderGrad: SafeStyle;

    public graphGrad: SafeStyle;

    public constructor(
        private appFacade: AppFacade,
        public activeModal: NgbActiveModal,
        private alertService: PCCAlertService,
        private sanitizer: DomSanitizer
    ) {
    }

    public ngOnInit(): void {
        this.session = this.appFacade.getSession();
        this.setPrices();
    }

    private setPrices(): void {
        console.log("setPrices");
        if (!this.profile || !this.session) {
            console.log("Profile or session not here yet");
            return;
        }

        let accountSettings = this.session.accountSettings;
        this.priceType = accountSettings.price_type;
        this.rangeLow = accountSettings.range_low || 1;
        this.rangeHigh = Number(accountSettings.range_high) || 100;
        console.log("price_type=", accountSettings.price_type);
        console.log("range_low=", accountSettings.range_low);
        console.log("range_high=", accountSettings.range_high);

        if (this.rangeLow < 1) {
            this.rangeType = RANGE_PERCENT;
            this.rangeLow = this.rangeLow * 100;
            this.rangeHigh = Number(this.rangeHigh) * 100;
        } else {
            this.rangeType = RANGE_DOLLAR;
        }

        console.log("rangeType=", this.rangeType);
        console.log("rangeLow=", this.rangeLow);
        console.log("rangeHigh=", this.rangeHigh);

        this.lowWarning = this.sanitizer.bypassSecurityTrustHtml(accountSettings.low_warning || LOW_WARNING);
        this.highWarning = this.sanitizer.bypassSecurityTrustHtml(accountSettings.high_warning || HIGH_WARNING);

        const ps = accountSettings.price_summary || PRICE_SUMMARY;
        this.priceSummary = this.sanitizer.bypassSecurityTrustHtml(ps);

        this.price = this.profile.acceptedPetOwnerPrice || this.profile.recommendedPetOwnerPrice || this.profile.customerPrice || this.profile.listPrice;

        this.recommendedPrice = this.profile.recommendedPetOwnerPrice || this.profile.customerPrice || this.profile.listPrice;

        this.minPrice = this.profile.customerPrice;
        this.maxPrice = Math.abs(this.recommendedPrice - this.profile.customerPrice) * 2 + this.profile.customerPrice;

        console.log("this.price=", this.price);
        console.log("this.minPrice=", this.minPrice);
        console.log("this.maxPrice=", this.maxPrice);
        console.log("this.suggestedPrice=", this.recommendedPrice);
        console.log("customerPrice=", this.profile.customerPrice);

        if (this.rangeType == RANGE_PERCENT) {
            this.lowPrice = this.recommendedPrice - (this.recommendedPrice * this.rangeLow / 100);
            this.highPrice = Number(this.recommendedPrice) + Number(this.recommendedPrice * this.rangeHigh / 100);
        } else {
            this.lowPrice = this.recommendedPrice - this.rangeLow;
            this.highPrice = Number(this.recommendedPrice) + Number(this.rangeHigh);
        }
        console.log("lowPrice=", this.lowPrice);
        console.log(`Before adjustment: highPrice=${this.highPrice}`);

        const fullPriceRange = this.maxPrice - this.minPrice;
        let lowRange: number;
        let highRange: number;

        if (this.minPrice > this.lowPrice) {
            this.lowPrice = this.minPrice;
            lowRange = 0;
        } else {
            lowRange = (this.lowPrice - this.minPrice) / fullPriceRange * 100;
        }
        if (this.highPrice > this.maxPrice) {
            this.highPrice = this.maxPrice;
            highRange = 100;
        } else {
            highRange = (this.highPrice - this.minPrice) / fullPriceRange * 100;
            console.log(`Adjusting highRange...${highRange}`);
        }

        console.log("After adjustment: this.lowPrice=", this.lowPrice);
        console.log("After adjustment: this.highPrice=", this.highPrice);
        console.log("After adjustment: lowRange=", lowRange);
        console.log("After adjustment: highRange=", highRange);

        let sliderStyle = SLIDER_BACKGROUND.replace(/x/g, `${lowRange}`);
        sliderStyle = sliderStyle.replace(/y/g, `${highRange}`);
        console.log("sliderStyle=", sliderStyle);

        this.sliderGrad = this.sanitizer.bypassSecurityTrustStyle(sliderStyle);

        let graphStyle = GRAPH_BACKGROUND.replace(/x/g, `${lowRange}`);
        graphStyle = graphStyle.replace(/y/g, `${highRange}`);
        console.log("graphStyle=", graphStyle);

        this.graphGrad = this.sanitizer.bypassSecurityTrustStyle(graphStyle);

        this.updateButtons();
    }

    public setProfile(p: Profile): void {
        this.profile = p;

        this.setPrices();
    }

    public async save(): Promise<void> {
        console.log("save: price=", this.price);
        this.profile.acceptedPetOwnerPrice = this.price;

        this.setBusy(true, "Saving...");
        this.profile.error = null;

        try {
            const saveResp = await this.appFacade.saveProfile(this.profile);
            console.log("saveResp=", saveResp);
            if (saveResp.success == true) {

                // Keep adjusted price in sync between original and extra profiles
                // for Idexx Anywhere.
                if (this.profile.extraProfile) {
                    this.profile.extraProfile.acceptedPetOwnerPrice = this.profile.acceptedPetOwnerPrice;
                    const saveExtraResp = await this.appFacade.saveProfile(this.profile.extraProfile);
                    console.log("saveExtraResp=", saveExtraResp);

                    this.setBusy(false);
                    if (saveExtraResp.success == true) {
                        this.activeModal.close(this.profile);
                        return;
                    }
                    console.error("Error saving extra profile: ", ErrorUtils.getErrorMessage(saveExtraResp.error));
                }

                this.activeModal.close(this.profile);
            }
            this.setBusy(false);
        } catch (err) {
            console.error("Error saving profile: ", err);
            this.setBusy(false);
        }
    }

    public next(): void {
        this.page += 1;
        this.updateButtons();
    }

    public prev(): void {
        this.page -= 1;
        this.updateButtons();
    }

    private updateButtons(): void {
        this.showNextButton = (this.priceType == "Full" && this.page == 0);
        this.showPrevButton = (this.priceType == "Full" && this.page == 1);
    }

    public priceChanged(): void {
        delete this.showLowWarning;
        delete this.showHighWarning;
        if (this.price < this.lowPrice) {
            this.showLowWarning = true;
        } else if (this.price > this.highPrice) {
            this.showHighWarning = true;
        }
    }

    private setBusy(isBusy: boolean, msg?: string): void {
        this.alertService.setBusy(isBusy, msg);
        this.profile.loading = isBusy;
        this.profile.error = null;
        this.saving = isBusy;
    }

    public cancelClicked(): void {
        this.activeModal.close(this.profile);
    }

}
