<div class="view-body" style="flex: 1; margin-left: 1em; display:flex;flex-direction:row;height: 100%;">
  <div class="landing-links">

	<div class="heading spot-typography__heading--level-2"> {{ 'landing.heading' | translate }} </div>
	<div class="sub-heading spot-typography__heading--level-5"> {{ 'landing.sub-heading' | translate }} </div>

	<div style="display:flex;flex-direction:column;margin: 0 auto;">

	  <div class="custom-page-link" *ngFor="let cp of customPages">
		<div class="custom-page-info">
		  <div class="custom-page-topic spot-typography__heading--level-3">{{cp.titleKey | customTranslate}}</div>
		  <div class="custom-page-blurb spot-typography__heading--level-5">{{cp.subTitleKey | customTranslate}}</div>
		</div>
		<div class="custom-page-button">
		  <button class="spot-button spot-button--secondary" (click)="pageClicked(cp)" > {{ "landing.go" | translate}} </button>
		</div>
	  </div>

	</div>
  </div>
  <div class="landing-sidebar">
	<div class="start-panel" *ngIf="session?.accountSettings?.flags?.enrollment_enabled">
	  <button (click)="startEnrollment()" class="spot-button spot-button--primary spot-button--large next-button" title="Next"> {{ "landing.startEnroll" | translate}} </button>
	</div>
	<div class="landing-background-image">&nbsp;</div>
  </div>
</div>
