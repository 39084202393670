<div class="pcc-edit-panel">
  <div class="pcc-edit-panel__header">
    <span class="spot-typography__heading--level-3">Page: Pricing</span>
  </div>
  <div class="pcc-edit-panel__content" style="padding: 1em;">
    <form class="spot-form" id="pageForm" #pageForm="ngForm" name="pageForm">
      <div class="spot-form__field-group" style="display:flex;flex-direction:column;">
        <input type="checkbox" class="spot-toggle" id="show_floor_price" name="show_floor_price" [(ngModel)]="accountSettings.flags.show_floor_price" (change)="flagToggled($event)">
        <label class="spot-toggle-label" for="show_floor_price">Show Floor Price</label>
      </div>
      <div class="spot-form__field-group" style="display:flex;flex-direction:column;">
        <input type="checkbox" class="spot-toggle" id="show_list_price" name="show_list_price" [(ngModel)]="accountSettings.flags.show_list_price" (change)="flagToggled($event)">
        <label class="spot-toggle-label" for="show_list_price">Show List Price</label>
      </div>
      <div class="spot-form__field-group" style="display:flex;flex-direction:column;">
        <input type="checkbox" class="spot-toggle" id="show_practice_price" name="show_practice_price" [(ngModel)]="accountSettings.flags.show_practice_price" (change)="flagToggled($event)">
        <label class="spot-toggle-label" for="show_practice_price">Show Customer Price</label>
      </div>

      <div class="spot-form__field-group" style="display:flex;flex-direction:column;">
        <input type="checkbox" class="spot-toggle" id="show_recommended_price" name="show_recommended_price" [(ngModel)]="accountSettings.flags.show_recommended_price" (change)="flagToggled($event)">
        <label class="spot-toggle-label" for="show_recommended_price">Show Recommended Price</label>
      </div>

      <div class="spot-form__field-group">
        <pcc-localized-text [languages]="accountSettings?.languages" [label]="'edit-pricing-ca.pricing_detail' | translate" key="pricingDetail" [localizedKey]="selectedPage.localizedKeys.pricingDetail" (textChanged)="localizedTextChanged($event)"></pcc-localized-text>
      </div>

      <div class="spot-form__field-group">
        <pcc-localized-text [languages]="accountSettings?.languages" [label]="'edit-pricing-ca.List_price' | translate" key="pricingListPrice" [localizedKey]="selectedPage.localizedKeys.pricingListPrice" (textChanged)="localizedTextChanged($event)"></pcc-localized-text>
      </div>

      <div class="spot-form__field-group">
        <pcc-localized-text [languages]="accountSettings?.languages" [label]="'edit-pricing-ca.Current_price' | translate" key="pricingCurrentPrice" [localizedKey]="selectedPage.localizedKeys.pricingCurrentPrice" (textChanged)="localizedTextChanged($event)"></pcc-localized-text>
      </div>

      <div class="spot-form__field-group">
        <pcc-localized-text [languages]="accountSettings?.languages" [label]="'edit-pricing-ca.Recommended_price' | translate" key="pricingRecommendedPrice" [localizedKey]="selectedPage.localizedKeys.pricingRecommendedPrice" (textChanged)="localizedTextChanged($event)"></pcc-localized-text>
      </div>

      <div class="spot-form__field-group">
        <pcc-localized-text [languages]="accountSettings?.languages" [label]="'edit-pricing-ca.Floor_price' | translate" key="pricingFloorPrice" [localizedKey]="selectedPage.localizedKeys.pricingFloorPrice" (textChanged)="localizedTextChanged($event)"></pcc-localized-text>
      </div>

      <div class="spot-form__field-group">
        <pcc-localized-text [languages]="accountSettings?.languages" [label]="'edit-pricing-ca.Special_price' | translate" key="pricingSpecialPrice" [localizedKey]="selectedPage.localizedKeys.pricingSpecialPrice" (textChanged)="localizedTextChanged($event)"></pcc-localized-text>
      </div>

      <div class="spot-form__field-group">
          <pcc-localized-text [languages]="accountSettings?.languages" [label]="'edit-pricing-ca.In_house_price' | translate" key="pricingInHousePrice" [localizedKey]="selectedPage.localizedKeys.pricingInHousePrice" (textChanged)="localizedTextChanged($event)"></pcc-localized-text>
      </div>

      <div class="spot-form__field-group">
        <pcc-localized-text [languages]="accountSettings?.languages" [label]="'edit-pricing-ca.Panel_price' | translate" key="pricingPanelPrice" [localizedKey]="selectedPage.localizedKeys.pricingPanelPrice" (textChanged)="localizedTextChanged($event)"></pcc-localized-text>
      </div>

    </form>
  </div>
</div>
