import { Component, OnInit } from "@angular/core";
import { ErrorUtils } from "@shared/model/error/error-utils";
import { AuthService } from "../auth.service";

@Component({
    selector: "pcc-login-failed",
    templateUrl: "./login-failed.component.html",
    styleUrls: [
        "./login-failed.component.scss"
    ]
})
export class LoginFailedComponent implements OnInit {
    errorMsg?: string;

    constructor(
        private authService: AuthService
    ) {
    }

    ngOnInit(): void {
        if (this.authService.loginFailure) {
            this.errorMsg = ErrorUtils.getErrorMessage(this.authService.loginFailure);
        } else {
            console.log("No error found");
        }
    }
}
