import { Component, Input, Output, EventEmitter, AfterViewInit, ViewChild, OnInit, OnChanges } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { NgForm } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

import { UtilService } from "@shared/service/util.service";
import { AdminFacade } from "../../../facade/admin.facade";
import { PCCAlertService } from "../../../service/alert.service";
import { PCCTranslateService } from "@client/service/translate.service";

import { FileInfo } from "@shared/model/file";
import { IAccountSettings } from "@shared/model/account-settings";
import { Language } from "@shared/model/language";
import { ErrorUtils } from "@shared/model/error/error-utils";

const MAX_FILE_SIZE = 1024 * 1024; // 1 MB

export interface ISafeFileInfo extends FileInfo {
    safeThumbnailUrl?: SafeResourceUrl;
}

export const MODAL_RESULTS = {
    SAVED: "saved",
    DELETED: "deleted",
    CANCELLED: "cancelled"
}

@Component({
    selector: "pcc-managed-file-edit",
    templateUrl: "./managed-file-edit.component.html",
    styleUrls: [
        "./managed-file-edit.component.scss"
    ]
})
export class ManagedFileEditComponent implements AfterViewInit, OnChanges, OnInit {
    @ViewChild("fileForm", {
        static: false
    }) public fileForm: NgForm;

    @Input() public selected: boolean;

    @Input() public selectedFile?: ISafeFileInfo;

    @Input() public accountSettings: IAccountSettings;

    @Output() public valid = new EventEmitter<boolean>();

    @Output() public fileChanged = new EventEmitter<ISafeFileInfo>();

    @Output() public fileDeleted = new EventEmitter<ISafeFileInfo>();

    public imageFileName: string;

    public imagePreview: string;

    public collapsed = true;

    public languages: Language[] = [];

    public isLoading = false;

    public loadingMessage = "Loading...";

    public constructor(
        private adminFacade: AdminFacade,
        public domSanitizer: DomSanitizer,
        private alertService: PCCAlertService,
        public activeModal: NgbActiveModal,
        private translateService: PCCTranslateService
    ) {}

    public ngOnInit(): void {
        this.imagePreview = this.selectedFile.url;

        if (this.accountSettings) {
            this.languages = this.accountSettings.languages;
            this.selectedFile.locale = this.translateService.getDefaultLocale(this.accountSettings.supportedLocales, this.selectedFile.locale, false);
        }
    }

    public ngOnChanges(): void {
        console.log("ngOnChanges");
        this.fileChanged.emit(this.selectedFile);
    }

    public ngAfterViewInit(): void {
        if (this.fileForm) {
            this.fileForm.statusChanges.subscribe((): void => {
                this.validate();
            });
        } else {
            console.error("this.fileForm doesn't exist!");
        }
    }

    public validate(): boolean {
        const isValid = this.fileForm?.valid;
        this.valid.emit(isValid);
        return isValid;
    }

    public async deleteFileConfirmed(oldFile: FileInfo): Promise<void> {
        this.setBusy(true, "Deleting...");

        try {
            oldFile.active = false;

            const saveResp = await this.adminFacade.saveImageFile(oldFile);

            if (saveResp && saveResp.success === true) {

                this.alertService.showToast("Save successful");

                this.fileDeleted.emit(oldFile);

                this.activeModal.close(MODAL_RESULTS.DELETED);
                return;
            }
            this.alertService.showError("Error deleting image file");
        } catch (err) {
            console.error("Error deleting image file: ", err);
            const msg = `System Error deleting image file: ${ErrorUtils.getErrorMessage(err)}`;

            this.alertService.showError(msg);
        } finally {
            this.setBusy(false);
        }
    }

    public onImageFileSelect(fileInput: any): void {
        console.log("onImageFileSelect: ", fileInput);
        if (fileInput.target.files.length === 0) {
            console.warn("No image selected");
            return;
        }

        const fileData = <File>fileInput.target.files[0];
        console.log("fileData=", fileData);

        if (fileData.size > MAX_FILE_SIZE) {
            console.error("File size too large: ", fileData);
            this.alertService.showError("File size exceeds maximum allowed (1MB)");
            return;
        }

        const reader = new FileReader();
        reader.onload = (): void => {
            this.imagePreview = reader.result as string;
        };
        reader.readAsDataURL(fileData);

        this.selectedFile.uploadData = fileData;
        this.selectedFile.name = fileData.name;
        this.selectedFile.contentType = fileData.type;
        this.selectedFile.developer_name = UtilService.cleanName(this.selectedFile.name);

        this.fileChanged.emit(this.selectedFile);
    }

    public toggleCollapse(): void {
        this.collapsed = !this.collapsed;
        console.log("collapsed=", this.collapsed);
    }

    public onLanguageSelected(lang: Language): void {
        console.log("onLanguageSelected: ", lang);
        this.selectedFile.locale = lang?.locale;
    }

    public cancel(): void {
        console.log("cancel");
        this.activeModal.close(MODAL_RESULTS.CANCELLED);
    }

    public saveClicked(): void {
        this.save();
    }

    public async save(): Promise<void> {
        console.log("save");

        this.setBusy(true, "Saving...");

        try {
            const saveResp = await this.adminFacade.saveImageFile(this.selectedFile);
            console.log("saveSystemSettings saveResp:", saveResp);

            if (saveResp && saveResp.success === true) {

                this.alertService.showToast("Save successful");

                this.activeModal.close(MODAL_RESULTS.SAVED);
            }
        } catch (err) {
            console.error("Error saving image file: ", err);
            const msg = `System Error saving image file: ${ErrorUtils.getErrorMessage(err)}`;

            this.alertService.showError(msg);

        } finally {
            this.setBusy(false);
        }
    }

    private setBusy(isBusy: boolean, msg?: string): void {
        this.isLoading = isBusy;
        this.loadingMessage = msg;
    }

}
