<img *ngIf="imageSrc" [class]="iconClass" [style]="getStyle()" src="imageSrc">
<svg *ngIf="!imageSrc"
	 [class]="iconClass"
     [style]="getStyle()"
     aria-labelledby="title">
  <title>{{title}}</title>
  <use *ngIf="icon" [attr.xlink:href]="'/assets/spot-library/dist/icons/spot_icons.svg#' + icon"></use>
  <use *ngIf="symbol" [attr.xlink:href]="'/assets/spot-library/dist/symbols/spot_symbols.svg#' + symbol"></use>
  <use *ngIf="path" [attr.xlink:href]="path"></use>
</svg>
