import { Component, OnInit, OnDestroy } from "@angular/core";

import { Subscription } from "rxjs";

import { AppFacade } from "../../../facade/app.facade";

import { AccountSettings } from "@shared/model/account-settings";
import { IProgressItem, NavService } from "../../../service/nav.service";
import { IProfile, ProfileUtils } from "@shared/model/profile";
import { PCCAlertService } from "../../../service/alert.service";
import { PCCSession } from "@shared/model/pcc-session";
import { ICustomPage, CustomPageTypes } from "@shared/model/custom-page";
import { ISeismicLink } from "@shared/model/seismic";

@Component({
    selector: "pet-owner-pricing",
    templateUrl: "./pet-owner-pricing.component.html",
    styleUrls: [
        "./pet-owner-pricing.component.scss"
    ]
})
export class PetOwnerPricingComponent implements OnInit, OnDestroy {

    private nextPage: IProgressItem = {
        name: "pet-owner-pricing.Next",
        link: "goals",
        onNext: this.nextClicked.bind(this),
        enabled: true
    };

    private localeSub: Subscription;

    public session: PCCSession;

    public customContent: ICustomPage;

    public docs: ISeismicLink[];

    public loadingDocs = false;

    public constructor(
        public appFacade: AppFacade,
        private navService: NavService,
        private alertService: PCCAlertService
    ) {
        this.navService.setNextState(this.nextPage);
    }

    public ngOnInit(): void {

        this.setSession(this.appFacade.getSession());

        this.initNextPage();

        this.localeSub = this.appFacade.getLocaleSubject().subscribe(
            (locale: string): void => {
                this.setLocale(locale);
            }
        );
    }

    public ngOnDestroy(): void {
        this.localeSub.unsubscribe();
    }

    private setSession(session: PCCSession): void {
        this.session = session;

        const customPages = AccountSettings.getCustomPages(this.session.accountSettings, CustomPageTypes.PET_OWNER_PRICE);
        this.customContent = customPages.length > 0 ? customPages[0] : null;
        if (!this.customContent) {
            this.alertService.showError(this.appFacade.translate("pet-owner-pricing.no_custom_page"));
            this.loadingDocs = false;
            return;
        }

        this.pullCustomPageContent(this.customContent.id);
    }

    private async pullCustomPageContent(customPageId: number): Promise<void> {
        try {
            this.loadingDocs = true;

            const resp = await this.appFacade.getCustomPage(customPageId);
            this.setCustomPage(resp.data);
        } catch (err) {
            console.error("Error pulling custom page: ", err);
        } finally {
            this.loadingDocs = false;
        }
    }

    private setCustomPage(page: ICustomPage): void {
        this.customContent = page;
        this.docs = this.customContent?.links;

        this.updateDocs(this.session?.locale);
    }

    private setLocale(locale: string): void {
        console.log("setLocale: ", locale);

        this.updateDocs(locale);
    }

    private updateDocs(locale: string): void {
        if (!this.customContent || !locale) {
            return;
        }
        console.log("updateDocs: ", locale);
        this.docs = this.customContent.links.filter((link: ISeismicLink): boolean => link.locale === locale);
    }

    public async nextClicked(): Promise<void> {
        console.log("Next clicked from PetOwnerPricing view");
        this.alertService.setBusy(true, this.appFacade.translate("pet-owner-pricing.saving"));
        await this.appFacade.saveSession();
        this.alertService.setBusy(false);
    }

    public getActiveProfiles(): IProfile[] {
        return ProfileUtils.filterSelectedProfiles(this.session.enrollInfo.profiles);
    }

    private initNextPage(): void {
        if (!this.session.accountSettings.flags.page_enabled_goals) {
            this.nextPage.link = "enroll";
        }
    }
}
