import { Component, OnInit } from "@angular/core";
import { CustomPageEditComponent } from "../../components/custom-page-edit/custom-page-edit.component";

@Component({
    selector: "pcc-page-pdf",
    templateUrl: "./page-pdf.component.html",
    styleUrls: ["./page-pdf.component.scss"]
})
export class PagePdfComponent extends CustomPageEditComponent implements OnInit {
}
