<div class="pcc-edit-panel">
  <div class="pcc-edit-panel__header">
    <span class="spot-typography__heading--level-3">Page: Enrollment</span>
  </div>
  <div class="pcc-edit-panel__content" style="padding: 1em;">
    <form class="spot-form" id="pageForm" #pageForm="ngForm" name="pageForm">
      <div class="spot-form__field-group" style="display:flex;flex-direction:column;">
        <input type="checkbox" class="spot-toggle" id="show_launch_calendar" name="show_launch_calendar" [(ngModel)]="accountSettings.flags.show_launch_calendar" (change)="flagToggled($event)">
        <label class="spot-toggle-label" for="show_launch_calendar">Show Launch Calendar</label>
      </div>

      <div class="spot-form__field-group" style="display:flex;flex-direction:column;">
        <input type="checkbox" class="spot-toggle" id="pch_enabled" name="pch_enabled" [(ngModel)]="accountSettings.flags.pch_enabled" (change)="flagToggled($event)">
        <label class="spot-toggle-label" for="pch_enabled">Pet Care Heroes Enabled</label>
      </div>

      <div class="spot-form__field-group">
        <pcc-localized-text [languages]="accountSettings?.languages" [label]="'enroll.Date_Title' | translate" key="importantDates" [localizedKey]="selectedPage.localizedKeys.importantDates" (textChanged)="localizedTextChanged($event)"></pcc-localized-text>
      </div>

      <div class="spot-form__field-group">
        <pcc-localized-text [languages]="accountSettings?.languages" [label]="'enroll.Practice_integration_appointment' | translate" key="calendarName" [localizedKey]="selectedPage.localizedKeys.calendarName" (textChanged)="localizedTextChanged($event)"></pcc-localized-text>
      </div>

      <div class="spot-form__field-group">
        <pcc-localized-text [languages]="accountSettings?.languages" [label]="'enroll.Launch_training_appointment' | translate" key="launchCalendarName" [localizedKey]="selectedPage.localizedKeys.launchCalendarName" (textChanged)="localizedTextChanged($event)"></pcc-localized-text>
      </div>

      <div class="spot-form__field-group">
        <pcc-localized-text [languages]="accountSettings?.languages" label="Program Resources" key="programResources" [localizedKey]="selectedPage.localizedKeys.programResources" (textChanged)="localizedTextChanged($event)"></pcc-localized-text>
      </div>

      <pcc-seismic-links [selectedPage]="selectedPage" [accountSettings]="accountSettings" (linkAdded)="linkAdded($event)" (linkDeleted)="linkDeleted($event)" style="padding:10px;"></pcc-seismic-links>

    </form>
  </div>

  <div class="pcc-edit-panel__footer button-panel">
    <button type="cancel" class="pcc-edit-panel__footer-cancel-button" (click)="cancel()">Cancel</button>
    <button type="submit" class="spot-button spot-button--primary" [disabled]="!saveEnabled && !saving" (click)="onSubmit()">Save</button>
  </div>
</div>
