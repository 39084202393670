import { IPCCError, PCCError } from "./pcc-error";

export class PCCServiceError<T = any> extends PCCError<T> implements IPCCError {
    public static readonly NAME: string = "PCCServiceError";

    public constructor(code: string, msg?: string, err?: Error | null, context?: T | null, isSystemError?: boolean) {
        super(code, msg, err, context, isSystemError);
        this.name = PCCServiceError.NAME;
    }
}
