import { Injectable } from "@angular/core";
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";

import { PCCSession } from "@shared/model/pcc-session";
import { IProfile } from "@shared/model/profile";

import { AppFacade } from "../facade/app.facade";

@Injectable({
    providedIn: "root"
})
export class EnrollNavGuard implements CanActivate {
    public constructor(
        public appFacade: AppFacade
    ) {
    }

    public canActivate(route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): boolean {
        console.log("canActivate", route, state);

        const dest = this.getDest(state.url);

        const session = this.appFacade.getSession();

        if (session
            && session.enrollInfo
            && session.enrollInfo.enrollmentDate
            && session.enrollInfo.submitted) {
            console.log("Enrolled, can't go back");
            return false;
        }

        if (dest === "session-confirm" && session?.enrollInfo) {
            console.warn("Can't navigate back to session-confirm once you've started enrollment");
            return false;
        }

        return !(dest !== "profiles" && this.isSessionBusy(session));
    }

    private isSessionBusy(session: PCCSession): boolean {
        if (session?.enrollInfo?.profiles) {
            return session.enrollInfo.profiles.some((profile: IProfile): boolean => profile.loading);
        }
        return false;
    }

    private getDest(url: string): string {
        if (!url || url.indexOf("/") === -1) {
            return url;
        }

        // Extract the path and get the last segment
        const pathSegments = url.split("/");
        return pathSegments[pathSegments.length - 1];
    }
}
