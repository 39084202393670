<input *ngIf="editMode && !hasRichText" type="text" class="spot-form__input" name="displayName" [(ngModel)]="editText" [required]="required" (input)="textChanged()"/>
<pcc-rich-text *ngIf="editMode && hasRichText" [(richText)]="editText" (richTextChange)="textChanged()"></pcc-rich-text>

<span *ngIf="!editMode && !hasRichText">{{editText || label}}</span>
<div *ngIf="!editMode && hasRichText && editText" [innerHtml]="editText" class="rich-text-readonly"></div>

<button *ngIf="showEditButton" type="button" class="spot-button spot-button--icon-only round-button edit-text-button" (click)="editClicked()">
  <svg class="spot-icon" aria-labelledby="title">
    <title>Edit</title>
    <use xlink:href="/assets/spot-library/dist/symbols/spot_symbols.svg#edit"></use>
  </svg>
</button>
