import { IUser } from "./user";
import _ from "lodash";

export interface IModality {
    modality_id: number; // modality_id
    name: string;
    icon_name?: string
    developer_name: string;
    display_order?: number

    created_on?: Date;
    createdBy?: IUser;

    updated_on?: Date;
    updatedBy?: IUser;

}

export class ModalityEnum {
    static ORDINAL_SEQ: number = 0;

    static readonly REF_LAB = new ModalityEnum("Reference Labs", "REF_LAB");

    static readonly IHD = new ModalityEnum("IHD", "IHD");

    static readonly SNAP = new ModalityEnum("Snap", "SNAP");

    static readonly REF_LAB_IHD = new ModalityEnum("Reference Labs IHD", "REF_LAB_IHD");

    readonly ordinal: number = 0;

    static values: ModalityEnum[];

    // private to disallow creating other instances of this type
    private constructor(public readonly displayName: string, public readonly value: string) {
        this.ordinal = ModalityEnum.ORDINAL_SEQ++;
        ModalityEnum.values = ModalityEnum.values || [];
        ModalityEnum.values.push(this);
    }

    static forString(value: string): ModalityEnum {
        if (value == ModalityEnum.REF_LAB.value) {
            return ModalityEnum.REF_LAB;
        } else if (value == ModalityEnum.IHD.value) {
            return ModalityEnum.IHD;
        } else if (value == ModalityEnum.REF_LAB_IHD.value) {
            return ModalityEnum.REF_LAB_IHD;
        } else if (value == ModalityEnum.SNAP.value) {
            return ModalityEnum.SNAP;
        }
        console.warn("Invalid value type passed in.  Defaulting to REF_LAB Modality...");
        return ModalityEnum.REF_LAB;
    }

    static forModality(mod: IModality): ModalityEnum {
        if (!mod) {
            console.error("mod is null, no match found");
            return null;
        }
        for (let me of ModalityEnum.values) {
            if (me.equals(mod)) {
                return me;
            }
        }
        console.error("No enum found for modality: ", mod);
        return null;
    }

    public equals(value: string | IModality): boolean {
        if (typeof value == "string") {
            return this.value == value;
        }
        if (value && value.developer_name) {
            return this.value == value.developer_name;
        }
        return false;
    }

    public getModality(modList: IModality[]): IModality {
        return ModalityEnum.getModalityForEnum(this, modList);
    }

    public toString(): string {
        return this.displayName;
    }

    public static getModalityForEnum(mod: ModalityEnum, modList: IModality[]): IModality {
        if (!modList) {
            return null;
        }

        mod = mod || ModalityEnum.REF_LAB;

        const found = modList.find((modality: IModality): boolean => (
            mod.equals(modality)
        ));

        return found;
    }
}
