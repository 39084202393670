import { Component, OnInit, OnChanges } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

import { AdminFacade } from "../../../facade/admin.facade";
import { PCCAlertService } from "../../../service/alert.service";
import { IAccountResponse } from "@shared/model/account-response";

import { IAccountSettings } from "@shared/model/account-settings";
import { ICustomPage, CustomPageType, CustomPageTypes } from "@shared/model/custom-page";
import { SettingsKey } from "@shared/model/settings-flags";

@Component({
    selector: "pcc-account-settings-enrollment-pages",
    templateUrl: "./account-settings-enrollment-pages.component.html",
    styleUrls: [
        "./account-settings-enrollment-pages.component.scss"
    ]
})
export class AccountSettingsEnrollmentPagesComponent implements OnInit, OnChanges {
    public accountSettings: IAccountSettings;

    public enrollPages: ICustomPage[] = [];

    public enrollPageTypes: CustomPageType[] = [];

    public selectedPage?: ICustomPage;

    public selectedType: CustomPageType;

    public constructor(
        public route: ActivatedRoute,
        private adminFacade: AdminFacade,
        private alertService: PCCAlertService
    ) {}

    public ngOnInit(): void {
        console.log("account-custom-pages.onInit");

        this.route.parent.data.subscribe((data): void => {
            console.log("Account resp data here: ", data);
            if (data.accountData) {
                const acctResp: IAccountResponse = data.accountData;
                if (acctResp.success === true) {
                    this.setAccountSettings(acctResp.accountSettings);
                }
            }
        });
    }

    public ngOnChanges(changes: any): void {
        console.log("ngOnChanges: ", changes);
        this.ngOnInit();
    }

    public setAccountSettings(acctSettings: IAccountSettings): void {
        this.accountSettings = acctSettings;
        this.enrollPages = this.accountSettings.customPages.filter((page: ICustomPage): boolean => page.pageType !== CustomPageTypes.LAUNCH);
        this.enrollPageTypes = this.getRelevantPageTypes()
    }

    public pageDeleted(page: ICustomPage): void {
        console.log("pageDeleted", page);
        this.refreshCustomPages();
    }

    public pageCanceled(page: ICustomPage): void {
        console.log("pageCanceled: ", page);
        this.refreshCustomPages();
    }

    private async getSelectedPageDetails(): Promise<void> {
        this.alertService.setBusy(true, "Loading page");
        try {
            const resp = await this.adminFacade.getCustomPage(this.selectedPage.id);
            console.log("customPageDetails resp: ", resp);
            if (resp.success) {
                this.selectedPage = resp.data;
            } else {
                console.error("Error pulling custom page details: ", resp);
            }
        } catch (err) {
            console.error("Error pulling custom page details: ", err);
        } finally {
            this.alertService.setBusy(false);
        }
    }

    public pageClicked(page: ICustomPage): void {
        console.log("existingPageClicked");
        this.setSelectedPage(page);
    }

    public enrollPageClicked(pageType: CustomPageType): void {
        console.log("enrollPageClicked: ", pageType);
        this.selectedType = pageType;
        let page = this.enrollPages.find((epage: ICustomPage): boolean => epage.pageType === pageType);
        if (!page) {
            console.log("Page not yet configured: ", pageType);
            page = {
                pageType,
                accountSettingsId: this.accountSettings.account_settings_id,
                links: [],
                displayOrder: 0,
                localizedKeys: {},
                active: true
            };
            this.accountSettings.customPages.push(page);
        }
        this.setSelectedPage(page);
    }

    private async refreshCustomPages(): Promise<boolean> {
        try {
            // Note: service only returns active pages.
            const resp = await this.adminFacade.getCustomPages(this.accountSettings.account_settings_id);
            if (resp.success) {
                this.accountSettings.customPages = resp.data;
                this.selectedPage = null;
            }
            return true;
        } catch (err) {
            console.error("Error calling getCustomPages: ", err);
            return false;
        }
    }

    public setSelectedPage(page: ICustomPage): void {
        console.log("setSelectedPage: ", page);
        this.selectedPage = page;

        if (this.selectedPage?.id) {
            this.getSelectedPageDetails();
        }
    }

    private getRelevantPageTypes(): CustomPageType[] {
        return Object.values(CustomPageTypes).filter((pt: CustomPageType): boolean => {
            if (pt === CustomPageTypes.LAUNCH) {
                return false;
            }
            if (pt === CustomPageTypes.CORP_PRICING) {
                return this.accountSettings.isCorp;
            }
            if (pt === CustomPageTypes.PRICING) {
                return !this.accountSettings.isCorp;
            }
            return true;
        });
    }

    public isPageEnabled(pt: CustomPageType): boolean {
        if (pt === CustomPageTypes.PROFILES) {
            return true;
        }
        if (pt === CustomPageTypes.PET_OWNER_PRICE) {
            return this.accountSettings.flags.page_enabled_pet_owner_pricing;
        }
        if (pt === CustomPageTypes.GOAL) {
            return this.accountSettings.flags.page_enabled_goals;
        }
        if (pt === CustomPageTypes.ENROLL) {
            return true;
        }
        if (pt === CustomPageTypes.DONE) {
            return true;
        }
        if (pt === CustomPageTypes.CORP_PRICING) {
            return true;
        }
        if (pt === CustomPageTypes.PRICING) {
            return true;
        }
        if (pt === CustomPageTypes.PDF) {
            return true;
        }
        if (pt === CustomPageTypes.TEST_MIX) {
            return this.accountSettings.flags.use_test_mix;
        }
        return true;
    }

    public async enrollmentEnabledChange(event: Event): Promise<void> {
        console.log("enrollmentEnabledChange: ", this.accountSettings.flags.enrollment_enabled);

        const checkbox = event.target as HTMLInputElement;
        const isChecked = checkbox.checked;
        const name = checkbox.name;

        await this.adminFacade.updateFlag(this.accountSettings.account_settings_id, name as SettingsKey, isChecked);
    }
}
