<div class="pcc-edit-panel">
  <div class="pcc-edit-panel__header">
    <span class="spot-typography__heading--level-3">Page: Goals</span>

    <input type="checkbox" class="spot-toggle" id="page_enabled_goals" name="page_enabled_goals" [(ngModel)]="accountSettings.flags.page_enabled_goals" (change)="flagToggled($event)">
    <label class="spot-toggle-label" for="page_enabled_goals">Enabled</label>
  </div>
  <div class="pcc-edit-panel__content" style="padding: 1em;">
    <form class="spot-form" id="pageForm" #pageForm="ngForm" name="pageForm">
      <div class="spot-form__field-group" style="display:flex;flex-direction:column;">
        <input type="checkbox" class="spot-toggle" id="show_goal_calculator" name="show_goal_calculator" [(ngModel)]="accountSettings.flags.show_goal_calculator" (change)="flagToggled($event)">
        <label class="spot-toggle-label" for="show_goal_calculator">Show Goal Calculator</label>
      </div>

      <div class="spot-form__field-group">
        <pcc-localized-text [languages]="accountSettings?.languages" label="Title" key="title" [localizedKey]="selectedPage.localizedKeys.title" (textChanged)="localizedTextChanged($event)"></pcc-localized-text>
      </div>

      <div class="spot-form__field-group">
        <pcc-localized-text [languages]="accountSettings?.languages" label="SubTitle" key="subtitle" [localizedKey]="selectedPage.localizedKeys.subTitle" (textChanged)="localizedTextChanged($event)"></pcc-localized-text>
      </div>
    </form>
  </div>
  <div class="pcc-edit-panel__footer button-panel">
    <button type="cancel" class="pcc-edit-panel__footer-cancel-button" (click)="cancel()">Cancel</button>
    <button type="submit" class="spot-button spot-button--primary" [disabled]="!saveEnabled && !saving" (click)="onSubmit()">Save</button>
  </div>
</div>
