<div>
  <div style="display:flex;align-items:baseline;">
    <span class="spot-typography__heading--level-5">Documents</span>
    <div class="list-controls">
	  <a class="spot-link add-item" (click)="addLink();">
        <svg class="spot-icon spot-link__icon spot-link__icon--left" aria-labelledby="title">
          <title>Add</title>
          <use xlink:href="/assets/spot-library/dist/symbols/spot_symbols.svg#add"></use>
        </svg>
        Add new document
      </a>
    </div>
  </div>


  <pcc-drag-drop-list [items]="activeLinks" (reordered)="linksReordered($event);" (itemClicked)="linkClicked($event)" style="height: 100%;" [itemTemplate]="linkTemplate"></pcc-drag-drop-list>
</div>

<ng-template #linkTemplate let-item="item" let-selected="selected">
  <div class="order-item" >
    <div class="link-content">
	  <pcc-icon icon="document" iconClass="spot-icon" title="Document"></pcc-icon>
      <div class="link-title">{{item.desc}}</div>
      <svg class="spot-icon error-icon" aria-labelledby="title" *ngIf="item.error">
        <title>Error</title>
        <use xlink:href="/assets/spot-library/dist/symbols/spot_symbols.svg#alert-notification"></use>
      </svg>
      <button type="button" class="spot-button spot-button--icon-only round-button edit-text-button" (click)="editLinkClicked(item)">
        <svg class="spot-icon" aria-labelledby="title">
          <title>Edit</title>
          <use xlink:href="/assets/spot-library/dist/symbols/spot_symbols.svg#edit"></use>
        </svg>
      </button>
    </div>
    <div class="link-footer">
      <div class="link-tag">{{item.tag}}</div>
      <div class="link-locale">{{item.locale}}</div>
    </div>
  </div>
</ng-template>


