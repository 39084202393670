import { MissingTranslationHandler, MissingTranslationHandlerParams } from "@ngx-translate/core";
import { Observable } from "rxjs";

export class PCCMissingTranslationHandler implements MissingTranslationHandler {
    private handledMissing: string[] = [];
    public handle(params: MissingTranslationHandlerParams): string | Observable<string> {
        const localeKey = `${params.translateService.currentLang}.${params.key}`;
        if (!this.handledMissing.includes(localeKey)) {
            console.trace("missing translation: ", params.key);
            this.handledMissing.push(localeKey);
        }
        return params.key;
    }
}
