import { Component, Input } from "@angular/core";

@Component({
    selector: "pcc-test-pill",
    templateUrl: "./test-pill.component.html",
    styleUrls: [
        "./test-pill.component.scss"
    ]
})
export class TestPillComponent {

    @Input() public name: string;

    // Extra class to apply to span
    @Input() public pillClass = "";
}
