<pcc-busy></pcc-busy>

<a *ngIf="updateRequired" href="javascript:void(0)" (click)="onUpdateClick()" class="update-required-header">{{ "site.site_updated" | translate }}</a>

<div class="main-layout">

  <pcc-app-header></pcc-app-header>
  <pcc-env-header></pcc-env-header>

  <div class="router-outlet-wrapper">
	<router-outlet *ngIf="!isIframe"></router-outlet>
  </div>
  <pcc-app-footer></pcc-app-footer>
</div>

