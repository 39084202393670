
export enum LEVEL {
    ERROR,
    SUCCESS,
    INFO
}

export interface IMessage {
    title?: string;
    message?: string;
    level?: LEVEL;
}

export class Message implements IMessage {

    constructor(public title?: string, public message?: string, public level: LEVEL = LEVEL.INFO) {
    }

    clear() {
        delete this.title;
        delete this.message;
        this.level = LEVEL.INFO;
    }

    setMessage(title?: string, msg?: string, level: LEVEL = LEVEL.INFO) {
        console.log("setMessage: ", title, msg, level);
        this.title = title;
        this.message = msg;
        this.level = level;
    }

    setSuccess(msg?: string, title?: string) {
        console.log("setSuccess: ", title, msg);
        this.setMessage(title, msg, LEVEL.SUCCESS);
    }

    setError(msg?: string, title?: string) {
        this.setMessage(title, msg, LEVEL.ERROR);
    }

    setInfo(msg?: string, title?: string) {
        this.setMessage(title, msg, LEVEL.INFO);
    }
}
