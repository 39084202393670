<div class="pcc-edit-panel">
  <div class="pcc-edit-panel__header">
    <span class="spot-typography__heading--level-3">Page: Profiles</span>
  </div>
  <div class="pcc-edit-panel__content" style="padding: 1em;">
    <form class="spot-form" id="pageForm" #pageForm="ngForm" name="pageForm">
      <div class="spot-form__field-group" style="display:flex;flex-direction:column;">
        <input type="checkbox" class="spot-toggle" id="can_edit_pet_owner_price" name="can_edit_pet_owner_price" [(ngModel)]="accountSettings.flags.can_edit_pet_owner_price" (change)="flagToggled($event)">
        <label class="spot-toggle-label" for="can_edit_pet_owner_price">Can Edit Pet Owner Price</label>
      </div>

      <div class="spot-form__field-group" style="display:flex;flex-direction:column;">
        <input type="checkbox" class="spot-toggle" id="can_edit_profile_name" name="can_edit_profile_name" [(ngModel)]="accountSettings.flags.can_edit_profile_name" (change)="flagToggled($event)">
        <label class="spot-toggle-label" for="can_edit_profile_name">Can Edit Profile Name</label>
      </div>

      <div class="spot-form__field-group" style="display:flex;flex-direction:column;">
        <input type="checkbox" class="spot-toggle" id="can_edit_special_price" name="can_edit_special_price" [(ngModel)]="accountSettings.flags.can_edit_special_price" (change)="flagToggled($event)">
        <label class="spot-toggle-label" for="can_edit_special_price">Can Edit Pet Owner Price</label>
      </div>

      <div class="spot-form__field-group" style="display:flex;flex-direction:column;">
        <input type="checkbox" class="spot-toggle" id="profile_show_practice_price" name="profile_show_practice_price" [(ngModel)]="accountSettings.flags.profile_show_practice_price" (change)="flagToggled($event)">
        <label class="spot-toggle-label" for="profile_show_practice_price">Show Practice Price</label>
      </div>

      <div class="spot-form__field-group" style="display:flex;flex-direction:column;">
        <input type="checkbox" class="spot-toggle" id="show_pet_owner_price" name="show_pet_owner_price" [(ngModel)]="accountSettings.flags.show_pet_owner_price" (change)="flagToggled($event)">
        <label class="spot-toggle-label" for="profile_show_pet_owner_price">Show Pet Owner Price</label>
      </div>

      <div class="spot-form__field-group" style="display:flex;flex-direction:column;">
        <input type="checkbox" class="spot-toggle" id="show_recommended_price" name="show_recommended_price" [(ngModel)]="accountSettings.flags.show_recommended_price" (change)="flagToggled($event)">
        <label class="spot-toggle-label" for="show_recommended_price">Show Recommended Price</label>
      </div>

      <div class="spot-form__field-group" style="display:flex;flex-direction:column;">
        <input type="checkbox" class="spot-toggle" id="show_special_price" name="show_special_price" [(ngModel)]="accountSettings.flags.show_special_price" (change)="flagToggled($event)">
        <label class="spot-toggle-label" for="profile_show_special_price">Show Special Price</label>
      </div>

      <div class="spot-form__field-group">
        <pcc-localized-text [languages]="accountSettings?.languages" [label]="'profiles.title' | translate" key="selectPanelInstruction" [localizedKey]="selectedPage.localizedKeys.selectPanelInstruction" (textChanged)="localizedTextChanged($event)"></pcc-localized-text>
      </div>

    </form>
  </div>
  <div class="pcc-edit-panel__footer button-panel">
    <button type="cancel" class="pcc-edit-panel__footer-cancel-button" (click)="cancel()">Cancel</button>
    <button type="submit" class="spot-button spot-button--primary" [disabled]="!saveEnabled && !saving" (click)="onSubmit()">Save</button>
  </div>
</div>
