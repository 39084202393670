<div [ngClass]="'admin-pcc-test ' + modality + ' ' + testType">
  <input class="admin-pcc-test-select" [id]="'name_'+testComp.profile_item_id" (change)="testClicked()" type="checkbox" [name]="'name_'+testComp.profile_item_id" [value]="testComp.displayNameKey | translate" [(ngModel)]="testComp.isSelected" [checked]="testComp.isSelected"/>
  <label [for]="'name_'+testComp.profile_item_id" style="display:flex;flex-direction:row;">
	<button *ngIf="showLeft" class="btn pcc-order" title="Left" (click)="moveLeftClicked($event)"><i class="fa-solid fa-chevron-left" style="float:left;"></i></button>
	<span style="display:block;padding: 5px;">
	  {{testComp.displayNameKey | translate}}
	  <div class="admin-pcc-test-default" *ngIf="enableDefault">
		<label class="spot-form__checkbox spot-form__checkbox--small" [for]="'isDefault_' + testComp.profile_item_id">
		  <input type="checkbox" (change)="defaultClicked()" [name]="'isDefault_' + testComp.profile_item_id" [id]="'isDefault_' + testComp.profile_item_id" [(ngModel)]="testComp.is_default" class="spot-form__checkbox-input"/>
		  <span class="spot-form__checkbox-inner">
			<span class="spot-form__checkbox-visual">
			  <svg class="spot-icon spot-form__checkbox-checked-icon" aria-labelledby="title">
				<title>checkmark</title>
				<use xlink:href="/assets/spot-library/dist/icons/spot_icons.svg#checkmark"></use>
			  </svg>
			  <svg class="spot-icon spot-form__checkbox-indeterminate-icon" aria-labelledby="title">
				<title>checkbox-indeterminate</title>
				<use xlink:href="/assets/spot-library/dist/icons/spot_icons.svg#checkbox-indeterminate"></use>
			  </svg>
			</span>
			<span class="spot-form__checkbox-label">
			  &nbsp;Default?&nbsp;
			</span>
		  </span>
		</label>
	  </div>
	</span>
	<button *ngIf="showRight" class="btn pcc-order" title="Right" (click)="moveRightClicked($event)"><i class="fa-solid fa-chevron-right" style="float:right;"></i></button>
  </label>
</div>
