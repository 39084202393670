<div class="sap-content">
  <div class="sap-search">
	<span class="idexx-title spot-typography__heading--level-2">
	  {{ 'sap-search.PCC_A' | translate }}
	</span>
	<div class="sap-input">
	  <input id="sapId" name="sapId" [(ngModel)]="sapId" [placeholder]="'sap-search.SAP_ID'| translate" class="form-control sapInput" (keydown)="sapIdKey($event)" autofocus required/>
	</div>
	<button (click)="findAccount()" class="spot-button spot-button--primary spot-button--large" [disabled]="searching || !sapId">{{'sap-search.Submit' | translate}}</button>
	<pcc-recent-searches class="recent-searches" [recentSearches]="recentSearches" (selected)="recentSearchSelected($event)"></pcc-recent-searches>
  </div>

  <div *ngIf="systemError" class="spot-message priority-medium--danger error-message">
	<div class="message--content">
      <div class="message--body">{{systemError.message}}</div>
	  <div *ngIf="systemError?.code" class="error-code" >{{systemError.code}}</div>
	</div>
	<div class="icon-tab">
      <svg class="spot-icon" aria-labelledby="title">
        <title>error</title>
        <use xlink:href="/assets/spot-library/dist/symbols/spot_symbols.svg#alert-notification"></use>
      </svg>
	</div>
  </div>
</div>
<div class="dog-panel">
  <div class="spacer"> </div>
  <div class="bottom-panel">
	<div class="outside-link" role="alert" id="outsideLink">
	  <a href="https://evcpc.idexx.com" target="new"><img src="/assets/images/apps/evc.png" alt="EVC"></a>
	  <span>
		<a href="https://evcpc.idexx.com" target="new">{{ 'sap-search.GO_TO_EVC_NEW' | translate}}</a>
	  </span>
	</div>
	<div class="training-panel" role="alert" id="outsideLink" *ngIf="hasTraining">
	  <input type="checkbox" class="spot-toggle" [checked]="isTrainingMode()" (change)="toggleTraining()" id="--29511">
	  <label class="spot-toggle-label" for="--29511"> {{ 'sap-search.training_mode_toggle' | translate }}</label>
	</div>
  </div>
</div>
<div class="bottom-line">
</div>
