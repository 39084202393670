import { Component, Input, Output, OnInit, EventEmitter } from "@angular/core";

import { SessionModeEnum } from "@shared/model/session-mode-enum";
import { IEnrollment } from "@shared/model/enrollment";
import { UtilService } from "@shared/service/util.service";

const PCH_ENROLLMENT_DATE_CHECK = "enroll-pet-care-heroes.pch_enrollment_date_check";

@Component({
    selector: "pcc-enroll-pet-care-heroes",
    templateUrl: "./enroll-pet-care-heroes.component.html",
    styleUrls: [
        "./enroll-pet-care-heroes.component.scss"
    ]
})
export class EnrollPetCareHeroesComponent implements OnInit {
    @Input() public enrollInfo: IEnrollment;

    @Input() public sessionMode: SessionModeEnum;

    @Input() public handlingEmail: string;

    @Output() public enrollChanged = new EventEmitter<boolean>();

    public pchDateMessageKey?: string;

    public pchMessageParam: any;

    public pchEnabled: boolean = true;

    public ngOnInit(): void {
        this.handlePetCareHeroes();
    }

    // Set up pet care heroes rules.
    // User can only enroll in pch program if this is a new enrollment.
    // For active or completed enrollments, they must go through support (via Admin tool)
    // If account has been enrolled in pch within the past 14 months, then disable pch checkbox
    // and show a message explaining why.
    private handlePetCareHeroes(): void {
        delete this.pchDateMessageKey;

        if (this.sessionMode !== SessionModeEnum.FRESH) {
            this.pchEnabled = false;
            return;
        }

        if (this.passesPetCareHeroesDateCheck()) {
            this.pchEnabled = true;
            return;
        }

        this.pchDateMessageKey = PCH_ENROLLMENT_DATE_CHECK;
        this.pchMessageParam = { email: this.handlingEmail };

        this.pchEnabled = false;
    }

    // If this account has already enrolled in PCH within the past 14 months,
    // return false so a message can be shown to the user.
    // Returns true if pch date check passes
    private passesPetCareHeroesDateCheck(): boolean {
        if (!this.enrollInfo || !this.enrollInfo.petCareHeroesLastEnrollmentDate) {
            // Account hasn't enrolled in PCH in the past.
            return true;
        }

        const checkDate = new Date();
        checkDate.setMonth(checkDate.getMonth() - 14);

        // Check if this account's last pch enrollment falls within the past 14 months
        return UtilService.compareDatesIgnoreTime(this.enrollInfo.petCareHeroesLastEnrollmentDate, checkDate) < 0;
    }

    public pchEnrolledChange(): void {
        // Triggering later to allow binding on required rule to update
        setTimeout((): void => {
            this.enrollChanged.emit(this.enrollInfo.petCareHeroesEnrolled);
        });
    }
}
