<div class="pcc-edit-panel">
  <div class="pcc-edit-panel__header">
    <span class="spot-typography__heading--level-3">Page: Corporate Pricing</span>
  </div>
  <div class="pcc-edit-panel__content" style="padding: 1em;">
    <form class="spot-form" id="pageForm" #pageForm="ngForm" name="pageForm">
      <div class="spot-form__field-group">
        <div class="corp-price-edit">
          <label class="spot-form__label" for="simplePriceAlgorithmField">Pricing Algorithm</label>
          <button type="button" class="spot-button spot-button--icon-only round-button" (click)="editPriceAlgClicked()">
            <svg class="spot-icon" aria-labelledby="title">
              <title>Edit</title>
              <use xlink:href="/assets/spot-library/dist/symbols/spot_symbols.svg#edit"></use>
            </svg>
          </button>
        </div>
        <input *ngIf="!pricingShown" type="text" class="spot-form__input" name="simplePriceAlgorithmField" [(ngModel)]="priceAlg" readonly/>
        <pcc-account-pricing #accountPricing [accountSettings]="accountSettings" [hidden]="!pricingShown"></pcc-account-pricing>
      </div>

      <fieldset class="practice-price-visibility">
        <legend>Practice Price visibility</legend>
        <div class="spot-form__field-group">
          <input type="checkbox" class="spot-toggle" name="show_practice_price" id="show_practice_price" [(ngModel)]="accountSettings.flags.show_practice_price" (change)="flagToggled($event)">
          <label class="spot-toggle-label" for="show_practice_price">General user interface</label>
        </div>

        <div class="spot-form__field-group">
          <input type="checkbox" class="spot-toggle" id="show_practice_price_pdf" name="show_practice_price_pdf" [(ngModel)]="accountSettings.flags.show_practice_price_pdf" (change)="flagToggled($event)">
          <label class="spot-toggle-label" for="show_practice_price_pdf">Output PDF</label>
        </div>
      </fieldset>

      <div class="spot-form__field-group">
        <input type="checkbox" class="spot-toggle" name="show_pet_owner_price" id="show_pet_owner_price" [(ngModel)]="accountSettings.flags.show_pet_owner_price" (change)="flagToggled($event)">
        <label class="spot-toggle-label" for="show_pet_owner_price">Show Pet Owner Price</label>
      </div>

      <div class="spot-form__field-group spot-form__field-group--horizontal">
        <fieldset class="spot-form__radio-group" role="radiogroup">
          <legend class="spot-form__radio-group-label">Price Range</legend>
          <div class="spot-form__radio-group-inner">
            <label class="spot-form__radio" for="percent">
              <input id="percent" name="priceRange" value="percent" type="radio" class="spot-form__radio-input" (change)="rangeTypeSelected('percent')" [(ngModel)]="selectedRangeType" required >
              <span class="spot-form__radio-inner">
                <span class="spot-form__radio-visual"></span>
                <span class="spot-form__radio-label ">
                  Percent
                </span>
              </span>
            </label>
            <label class="spot-form__radio" for="dollar">
              <input id="dollar" name="priceRange" value="dollar" type="radio" class="spot-form__radio-input" (change)="rangeTypeSelected('dollar')" [(ngModel)]="selectedRangeType" required >
              <span class="spot-form__radio-inner">
                <span class="spot-form__radio-visual"></span>
                <span class="spot-form__radio-label ">
                  Dollar
                </span>
              </span>
            </label>
          </div>
        </fieldset>
      </div>

      <pcc-range-slider *ngIf="selectedRangeType=='percent'" [minValue]="minValue" [maxValue]="maxValue" [lowValue]="lowValue" [midValue]="midValue" [hiValue]="hiValue" [midReadOnly]="true" (lowValueChange)="lowValueChanged($event)" (hiValueChange)="hiValueChanged($event)"></pcc-range-slider>

      <div class="spot-form__field-group">
        <label class="spot-form__label">Low-end price difference</label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">-</span>
          </div>

          <input type="number" min="0" [(ngModel)]="rangeLow" name="lowRangeTxt" columns="3" (change)="recalcPriceRange()"/>
          <div class="input-group-append">
            <span class="input-group-text" *ngIf="selectedRangeType=='percent'">%</span>
            <span class="input-group-text" *ngIf="selectedRangeType=='dollar'">$</span>
          </div>
        </div>
      </div>

      <div class="spot-form__field-group">
        <label class="spot-form__label">High-end price difference</label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">+</span>
          </div>
          <input type="number" min="0" [(ngModel)]="rangeHigh" name="hiRangeTxt" columns="3" (change)="recalcPriceRange"/>
          <div class="input-group-append">
            <span class="input-group-text" *ngIf="selectedRangeType=='percent'">%</span>
            <span class="input-group-text" *ngIf="selectedRangeType=='dollar'">$</span>
          </div>
        </div>
      </div>

      <div class="spot-form__field-group">
        <pcc-rich-text label="Price Summary" [editMode]="false" [(richText)]="accountSettings.price_summary"></pcc-rich-text>
      </div>

      <div class="spot-form__field-group">
        <pcc-rich-text label="Low Boundary Warning" [editMode]="false" [(richText)]="accountSettings.low_warning"></pcc-rich-text>
      </div>

      <div class="spot-form__field-group">
        <pcc-rich-text label="High Boundary Warning" [editMode]="false" [(richText)]="accountSettings.high_warning"></pcc-rich-text>
      </div>

      <div class="spot-form__field-group spot-form__field-group--horizontal">
        <fieldset class="spot-form__radio-group" role="radiogroup">
          <legend class="spot-form__radio-group-label">
            Compliance-based Pricing Education Visibility
          </legend>
          <div class="spot-form__radio-group-inner">
            <label class="spot-form__radio" for="full">
              <input id="full" name="priceEduTypeCB" value="Full" [(ngModel)]="accountSettings.price_type" type="radio" class="spot-form__radio-input"/>
              <span class="spot-form__radio-inner">
                <span class="spot-form__radio-visual"></span>
                <span class="spot-form__radio-label ">
                  Full
                </span>
              </span>
            </label>
            <label class="spot-form__radio" for="simple">
              <input id="simple" name="priceEduTypeCB" value="Simplified" [(ngModel)]="accountSettings.price_type" type="radio" class="spot-form__radio-input"/>
              <span class="spot-form__radio-inner">
                <span class="spot-form__radio-visual"></span>
                <span class="spot-form__radio-label ">
                  Simplified
                </span>
              </span>
            </label>
          </div>
        </fieldset>
      </div>

    </form>
  </div>
  <div class="pcc-edit-panel__footer button-panel">
    <button type="cancel" class="pcc-edit-panel__footer-cancel-button" (click)="cancel()">Cancel</button>
    <button type="submit" class="spot-button spot-button--primary" [disabled]="!saveEnabled && !saving" (click)="onSubmit()">Save</button>
  </div>
</div>
