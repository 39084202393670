import { IProfileTemplate, ProfileTemplate, TemplateType } from "./profile-template";
import { ISpecies, SpeciesUtils } from "./species";
import { ModalityEnum } from "./modality";
import { IUser } from "./user";
import { ICustomPage, CustomPageTypes, CustomPageType } from "./custom-page";
import { StatusEnum } from "./account-settings-status";
export { StatusEnum } from "./account-settings-status";
import { SettingsFlags } from "./settings-flags";
import { PricingMethod } from "./price";
import { Language, Translations } from "./language";

export interface IAccountSettings {
    account_settings_id?: number;

    pricingOutputEmail?: string;

    isCorp?: boolean;

    sap_id?: string;
    customer_name?: string;
    special_instructions?: string;
    price_type?: string;
    pricing_algorithm?: string;
    range_low?: number;
    range_high?: number;
    low_warning?: string;
    high_warning?: string;
    price_summary?: string;

    as_independent?: boolean;

    pricingMethod?: PricingMethod;

    status?: string;
    visible?: boolean;

    logo?: any;

    logoUrl?: any;

    profileTemplates?: IProfileTemplate[];

    supportedLocales?: string[];

    defaultLocale?: string;

    languages?: Language[];

    translations?: Record<string, Translations>;

    handling_email?: string;
    countryCd?: string;

    buyingGroupId?: number;

    customPages: ICustomPage[];

    created_on?: Date;
    createdBy?: IUser;

    updated_on?: Date;
    updatedBy?: IUser;

    flags?: SettingsFlags;
}

export class AccountSettings implements IAccountSettings {
    public account_settings_id?: number;

    public isCorp?: boolean;

    public sap_id?: string;

    public customer_name?: string;

    public special_instructions?: string;

    public price_type?: string;

    public pricing_algorithm?: string = "v_CUSTOMER_PRICE";

    public range_low?: number;

    public range_high?: number;

    public low_warning?: string;

    public high_warning?: string;

    public price_summary?: string;

    public as_independent?: boolean;

    public pricingMethod?: PricingMethod;

    public status: string = StatusEnum.DRAFT.value;

    public visible: boolean = true;

    public logo: any;

    public logoUrl: any;

    public supportedLocales?: string[] = [];

    public languages?: Language[] = [];

    public translations?: Record<string, Translations> = {};

    public profileTemplates: ProfileTemplate[] = [];

    public handling_email?: string;

    public pricingOutputEmail?: string;

    public countryCd?: string;

    public buyingGroupId?: number;

    public customPages: ICustomPage[] = [];

    public created_on?: Date;

    public createdBy?: IUser;

    public updated_on?: Date;

    public updatedBy?: IUser;

    public flags: SettingsFlags = {};

    public constructor(apiResp?: IAccountSettings) {
        this.parseApiResp(apiResp);
    }

    private parseApiResp(apiResp?: IAccountSettings): void {
        console.log("parseApiResp: ", apiResp);
        if (!apiResp) {
            return;
        }

        Object.assign(this, apiResp);

        if (apiResp.profileTemplates) {
            this.profileTemplates = [];
            apiResp.profileTemplates.forEach((pt: IProfileTemplate): void => {
                this.profileTemplates.push(new ProfileTemplate(pt));
            });
        }
    }

    /**
     * Return true if the account settings contains at least one template with the
     * specified species and/or modality.
     */
    public hasTemplates(species: ISpecies, templateType: TemplateType, modality: ModalityEnum): boolean {
        const matches = AccountSettings.getTemplates(this, species, templateType, modality);
        return matches && matches.length > 0;
    }

    public static getCustomPages(acctSettings: IAccountSettings, type: CustomPageType = CustomPageTypes.LAUNCH): ICustomPage[] {
        return acctSettings.customPages.filter((cp: ICustomPage): boolean => cp.pageType === type);
    }

    public static getTemplates(accountSettings: IAccountSettings, species: ISpecies, templateType: TemplateType, modality?: ModalityEnum): IProfileTemplate[] {
        return accountSettings.profileTemplates.filter((pt: IProfileTemplate): boolean => (
            (!species || SpeciesUtils.equals(species, pt.species))
            && (templateType === pt.templateType)
            && (!modality || (modality.equals(pt.modality)))
        ));
    }

    // NOTE: Buying group member will be handled as corporate account until new
    // requirements say differently.
    public static isCorporate(accountSettings: IAccountSettings): boolean {
        return accountSettings && accountSettings.isCorp && !accountSettings.as_independent;
    }

    // NOTE: Buying group member will be handled as corporate account until new
    // requirements say differently.
    // But some code needs to know when this is just a buying group so data can be managed correctly
    public static isBuyingGroup(accountSettings: IAccountSettings): boolean {
        return accountSettings.buyingGroupId !== null && accountSettings.buyingGroupId !== undefined;
    }

    public static isCountry(accountSettings: IAccountSettings): boolean {
        return !accountSettings.isCorp;
    }
}

export interface AccountSettingsCreateRequest {
    countryCd?: string;
    corpParentSapId?: string;
    buyingGroupId?: number;
}
