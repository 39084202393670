import { IUser } from "./user";
import { ICategory } from "./category";
import { ISpecies } from "./species";
import { IProfileItem } from "./profile-item";
import { IProfileCategory } from "./profile-category";
import { IModality } from "./modality";
import { IProduct } from "./product";
import { LocalizedResource, LocalizedContent } from "./language";

/**
 * Profile Templates represent the admin-configured definition of a single profile's allowed options.
 * Used to filter down from the known data universe to what will be allowed by
 * the template for the user to see when using the actual PCC tool.
 *
 * During use in the UI, this will contain all categories and all profileItems within those categories, but only a subset may be "selected".
 * Challenge is to fill in the missing blanks...
 *
 * We have four types of template concepts:
 * Preconfigured
 *   - Set up to only match against a single (or limited alternatives) profile.
 *   - Can have canned price for future global.
 *   - Can only be added to an enrollment one time in a session.
 *   - Similar to old corporate template concept
 * Flex
 *   - Set up with large range of options to match against hundreds of possible outcomes.
 *   - Can be used multiple times in a single enrollment to configure different profiles.
 *   - No canned price - must come from pricing service.
 * Static
 *   - Can only match a single profile (no options).
 *   - Have a set price
 * Pet Care Heroes
 *   - Used to define PCH profiles added to the enrollment when user enrolls in program.  (Not shown in client or pdf)
 */

export type TTemplateType = "FLEX" | "PRECONFIG" | "STATIC" | "PCH";
export const TEMPLATE_TYPES: { [key: string]: TTemplateType } = {
    FLEX: "FLEX",
    PRECONFIG: "PRECONFIG",
    STATIC: "STATIC",
    PCH: "PCH"
};

export type TemplateType = typeof TEMPLATE_TYPES[keyof typeof TEMPLATE_TYPES];

export interface IProfileTemplate extends LocalizedResource {
    template_profile_id?: number;
    account_settings_id?: number;

    // Used when consumed either by the client to pull translations from "active" locale
    displayNameKey?: string;

    species?: ISpecies;
    display_order?: number;
    active?: boolean;
    countryCd?: string;

    // To support reference labs vs ihd modality toggle for US
    modality: IModality; // modality_id

    categories?: IProfileCategory[];

    templateType: TemplateType;

    matchedProfile?: IProduct;

    defaultPrice?: number;

    defaultSelected?: boolean;

    // Used to contain localized display text for this object
    localizedKeys: LocalizedContent;

    created_on?: Date;
    createdBy?: IUser;

    updated_on?: Date;
    updatedBy?: IUser;
}

export class ProfileTemplate implements IProfileTemplate {
    public template_profile_id: number;

    // The account settings record id this template corresponds to.
    // Will be null in the case of a "system profile template".
    public account_settings_id: number;

    // Used when consumed by the client to pull translations from "active" locale
    public displayNameKey?: string;

    // A profile template can only be associated with a single species.
    public species: ISpecies;

    // The order to display this profile template in the tiles (todo)
    public display_order: number = 0;

    public active: boolean = true;

    public countryCd?: string;

    public modality: IModality; // modality_id

    // A subset of all categories selected by corporate parent.
    // Each category in turn contains only a subset of all valid profileItems, chosen by
    // corporate parent.
    public categories: IProfileCategory[] = [];

    public templateType: TemplateType;

    public matchedProfile?: IProduct;

    public defaultPrice?: number;

    public defaultSelected?: boolean;

    // Used to contain localized display text for this object
    public localizedKeys: LocalizedContent;

    public created_on?: Date;

    public createdBy?: IUser;

    public updated_on?: Date;

    public updatedBy?: IUser;

    public constructor(tpl?: IProfileTemplate) {
        this.parseApiResp(tpl);
    }

    private parseApiResp(tpl: IProfileTemplate): void {
        console.log("parseApiResp: ", tpl);
        if (!tpl) {
            return;
        }

        this.template_profile_id = tpl.template_profile_id;
        this.account_settings_id = tpl.account_settings_id;
        this.displayNameKey = tpl.displayNameKey;

        this.species = tpl.species;
        this.modality = tpl.modality;
        this.display_order = tpl.display_order;
        this.active = tpl.active;
        this.countryCd = tpl.countryCd;
        this.templateType = tpl.templateType;

        this.matchedProfile = tpl.matchedProfile;

        this.defaultPrice = tpl.defaultPrice;

        this.defaultSelected = tpl.defaultSelected;

        this.categories = [];
        this.categories = tpl.categories;

        this.localizedKeys = tpl.localizedKeys;

        ProfileTemplate.sortCategories(this);

        this.created_on = tpl.created_on;
        this.createdBy = tpl.createdBy;
        this.updated_on = tpl.updated_on;
        this.updatedBy = tpl.updatedBy;
    }

    public static newInstance(slot?: IProfileTemplate): ProfileTemplate {
        console.log("newInstance: slot=", slot);

        return new ProfileTemplate(slot);
    }

    public static sortCategories(profileTemplate: IProfileTemplate): ICategory[] {
        profileTemplate.categories = profileTemplate.categories || [];
        return profileTemplate.categories.sort((a: ICategory, b: ICategory): number => (
            a.display_order - b.display_order || a.category_id - b.category_id
        ));
    }

    public static removeCategory(profileTemplate: IProfileTemplate, ctg: ICategory): void {
        if (!profileTemplate || !ctg || !profileTemplate.categories) {
            return;
        }
        profileTemplate.categories = profileTemplate.categories.filter((c: ICategory): boolean => (
            c.developerName !== ctg.developerName
        ));
    }

    public static clear(profileTemplate: IProfileTemplate): void {
        profileTemplate.categories = [];
        delete profileTemplate.species;
    }

    public static findCategory(profileTemplate: IProfileTemplate, ctgName: string | number): ICategory {
        if (profileTemplate && ctgName) {
            return profileTemplate.categories.find((c: ICategory): boolean => (
                c.category_id === ctgName || c.developerName === ctgName
            ));
        }
        return null;
    }

    public static getDefaultTestsForCategories(categories: IProfileCategory[]): IProfileItem[] {
        if (!categories) {
            return [];
        }

        return categories.reduce((defaults: IProfileItem[], profileCategory: IProfileCategory): IProfileItem[] => {
            const selectedItem = profileCategory.profileItems.find((profileItem: IProfileItem): boolean => (
                profileItem.is_default
            ));
            if (selectedItem) {
                defaults.push(selectedItem);
            }
            return defaults;
        }, []);
    }

    public static getDefaultTests(profileTemplate: IProfileTemplate): IProfileItem[] {
        if (!profileTemplate || !profileTemplate.categories) {
            return [];
        }

        return this.getDefaultTestsForCategories(profileTemplate.categories);
    }
}
