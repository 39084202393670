<div class="spot-modal doc-wrapper">
  <div class="spot-modal__header" style="height: 60px;">
	<a *ngIf="link && showSeismic" [href]="link" target="_new"> {{ 'doc-wrapper.open_seismic' | translate }} &nbsp;<i class="fa-solid fa-arrow-up-right-from-square"></i></a>
	<button type="button" class="spot-modal__header-cancel-button" aria-label="Close" (click)="closeDoc()">
      <svg class="spot-icon spot-modal__header-cancel-button-icon" aria-labelledby="title">
        <title>cancel</title>
        <use xlink:href="/assets/spot-library/dist/symbols/spot_symbols.svg#cancel"></use>
      </svg>
	</button>
  </div>
  <div class="spot-modal__content">

	<ngx-extended-pdf-viewer
	  *ngIf="isPDF && docUrl"
	  [src]="docUrl"
	  [useBrowserLocale]="true"
	  [textLayer]="true"
	  [filenameForDownload]="fileName"
	  [showOpenFileButton]="false"
	  [showPropertiesButton]="false"
	  [showSidebarButton]="false"
	  (pageChange)="onEvent('pageChange', $event)"
	  (afterPrint)="onEvent('afterPrint', $event)"
	  (pageRendered)="onEvent('pageRendered', $event)"
	  (pdfDownloaded)="onEvent('pdfDownloaded', $event)"
	  (pdfLoaded)="onEvent('pdfLoaded', $event)"
	  class="doc-pdf-viewer"></ngx-extended-pdf-viewer>

	<video *ngIf="isVideo && docUrl" controls autoplay="true" class="doc-video" preload="metadata">
	  <source [src]="docUrl" type="video/mp4">
	</video>
  </div>
</div>
