import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from "@angular/router";
import { inject } from "@angular/core";
import { IAccountResponse } from "@shared/model/account-response";
import { AccountSettings } from "@shared/model/account-settings";
import { AdminFacade } from "../facade/admin.facade";

export const accountSettingsResolver: ResolveFn<IAccountResponse> = async (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
    adminFacade: AdminFacade = inject(AdminFacade)
): Promise<IAccountResponse> => {
    console.log("Account Settings resolve: ", route.params, state);
    try {

        const accountSettingsId = route.params.id ? Number(route.params.id) : undefined;
        if (accountSettingsId) {
            const session = adminFacade.getSession();
            if (session && session.accountSettings && session.accountSettings.account_settings_id === accountSettingsId) {
                // Just return cached version from currently active session.
                console.log("Returning cached version of account from session...", session);
                return {
                    success: true,
                    account: session.accountInfo,
                    accountSettings: session.accountSettings,
                    sessionCount: session.sessionCount
                };
            }
            console.log("Something didn't match: ", session?.accountSettings, session?.accountSettings?.account_settings_id === accountSettingsId, typeof accountSettingsId, typeof session?.accountSettings?.account_settings_id);

            const accountResp = await adminFacade.findAccount(accountSettingsId, true);
            console.log("accountResp here=", accountResp);
            if (accountResp.success) {
                const systemSettings = await adminFacade.getSystemSettingsCached();
                const accountSettings = accountResp.accountSettings;

                await adminFacade.startAdminSession(accountResp.account,
                    <AccountSettings>accountSettings,
                    systemSettings,
                    accountResp.sessionCount);
            }
            return accountResp;
        }
    } catch (err) {
        console.error(`Error finding account settings: ${route.params}`, err);
        return {
            success: false
        };
    }

    return {
        success: false
    };

}

