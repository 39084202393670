import { Component, OnInit, OnDestroy, DoCheck, KeyValueDiffers } from "@angular/core";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { Subscription } from "rxjs";

import { Profile, IProfile, ProfileUtils } from "@shared/model/profile";
import { IProfileTemplate, TEMPLATE_TYPES } from "@shared/model/profile-template";
import { IProduct } from "@shared/model/product";
import { ICustomPage, CustomPageTypes } from "@shared/model/custom-page";
import { AccountSettings } from "@shared/model/account-settings";

import { PricingMethods } from "@shared/model/price";

import { EnrollUtils } from "@shared/model/enrollment";
import { PCCSession } from "@shared/model/pcc-session";
import { SessionModeEnum } from "@shared/model/session-mode-enum";

import { IProfileMatchRequest } from "@shared/model/service/profile-match-service";

import { AppFacade } from "../../../facade/app.facade";
import { NavService, IProgressItem } from "../../../service/nav.service";
import { PCCSessionService } from "../../../service/pcc-session.service";

import { BuildProfileComponent } from "../build-profile/build-profile.component";
import { EditPricingComponent } from "../edit-pricing/edit-pricing.component";
import { EditPricingCAComponent } from "../edit-pricing-ca/edit-pricing-ca.component";
import googleAnalytics from "../../../analytics/googleAnalytics";
import { envUtils } from "@client/globals/env";

@Component({
    selector: "pcc-profiles",
    templateUrl: "./profiles.component.html",
    styleUrls: [
        "./profiles.component.scss"
    ]
})
export class ProfilesComponent implements OnInit, OnDestroy, DoCheck {

    public session: PCCSession;

    private sessionSub: Subscription;

    // Contains join of PRECONFIG templates with configured Profiles currently in the session,
    // grouped by species.
    public profileCards: IProfile[] = [];

    // Contains any FLEX templates to be shown at bottom of the screen.  "Build new X profile" link style
    public flexProfileCards: IProfile[];

    public nextPage: IProgressItem = {
        name: "profiles.Next",
        link: "pet-owner-pricing", // Link is modified based on account country or corporate status
        enabled: false
    };

    private modalRef: NgbModalRef;

    private differ: any;

    public lockedEnrollment = false;

    public lockedMsg: string;

    public customContent: ICustomPage;

    public customPrefix: string;

    public constructor(
        private modalService: NgbModal,
        private appFacade: AppFacade,
        private navService: NavService,
        public sessionService: PCCSessionService,
        differs: KeyValueDiffers
    ) {
        this.differ = differs.find([]).create();
    }

    public ngOnInit(): void {
        this.sessionSub = this.appFacade.getCurrentSession().subscribe(
            (session: PCCSession): void => {
                this.setSession(session);
            }
        );
    }

    public ngOnDestroy(): void {
        this.sessionSub.unsubscribe();
    }

    private setSession(session: PCCSession): void {
        this.session = session;

        if (!session || !session.accountSettings) {
            return;
        }

        // TODO: Move this logic to nav service.
        // If account is corporate, then "enroll".
        // Otherwise, next page is "pet-owner-pricing"
        if (session.accountSettings.flags.page_enabled_pet_owner_pricing) {
            this.nextPage.link = "pet-owner-pricing";
        } else if (session.accountSettings.flags.page_enabled_goals) {
            this.nextPage.link = "goals";
        } else {
            this.nextPage.link = "enroll";
        }
        this.navService.setNextState(this.nextPage);

        if (session.enrollInfo) {
            this.updateLayout();
        }

        if (this.session.sessionMode !== SessionModeEnum.FRESH) {
            this.lockedEnrollment = true;
            const key = this.session.sessionMode === SessionModeEnum.ACTIVE ? "locked-enrollment.active" : "locked-enrollment.expired";
            this.lockedMsg = this.appFacade.translate(key, { email: this.getHandlingEmail() });
        }

        const customPages = AccountSettings.getCustomPages(this.session.accountSettings, CustomPageTypes.PROFILES);
        this.customContent = customPages.length > 0 ? customPages[0] : null;

        this.customPrefix = `CUSTOM_PAGE.${this.customContent?.id}.`;
    }

    /**
     * Maintaining two lists of profiles/templates:
     * - A join of "PRECONFIG" templates defined in account settings admin with populated profiles
     *   defined in the enrollment.
     *   This will allow keeping the view static as profiles are built, keep things in the proper order,
     *   and only allow the user to create one profile per defined template.
     *
     * - A list of profiles for every "FLEX" template configured in account settings.
     *   Each FLEX template will result in one extra "new" profile added at the end to allow user to
     *   add more instances of the same FLEX template.
     */
    private updateLayout(): void {
        console.log("updateLayout");

        let profiles = this.session.enrollInfo?.profiles;
        const templates = this.session.accountSettings?.profileTemplates;

        profiles = profiles.filter((profile: IProfile): boolean => ProfileUtils.isVisible(profile));

        const { profileCards, flexCards } = this.groupProfiles(templates, profiles);

        this.profileCards = profileCards;
        this.flexProfileCards = flexCards;

        this.updateButtons();
    }

    /*
     * Given a list of templates and a list of built profiles, returns two lists:
     * profileCards - join of PRECONFIG templates with configured Profiles currently in the session
     * flexCards - any FLEX templates to be shown at bottom of the screen.  "Build new X profile" link style
     */
    private groupProfiles(allTemplates: IProfileTemplate[], allProfiles: IProfile[]): { profileCards: IProfile[], flexCards: IProfile[] } {
        let profileCards: IProfile[] = [];
        const flexCards: IProfile[] = [];

        const templates = this.getTemplates(allTemplates);

        templates.forEach((template: IProfileTemplate): void => {
            // Add any existing (or PRECONFIG placeholder) profiles to list.
            const profiles = this.getProfileCardsForTemplate(template, allProfiles);
            profileCards = profileCards.concat(profiles);

            // Always add "NEW" profile for FLEX template
            if (template.templateType === TEMPLATE_TYPES.FLEX) {
                const newProfile = Profile.fromTemplate(template);
                newProfile.display_name = this.appFacade.translate(template.displayNameKey);
                flexCards.push(newProfile);
            }
        });

        const missed = allProfiles.filter((profile1: IProfile): boolean => {
            return !profile1.isExtra
                && ProfileUtils.isVisible(profile1) // Pet care heroes profiles shouldn't be counted here.
                && !profileCards.some((profile2: IProfile): boolean => profile1.enrollment_profile_id === profile2.enrollment_profile_id)
                && !flexCards.some((profile3: IProfile): boolean => profile1.enrollment_profile_id === profile3.enrollment_profile_id);
        });

        if (missed.length) {
            console.warn("Template(s) must have been deleted.  Missed profiles=", missed);
            profileCards = profileCards.concat(missed);
        }

        return { profileCards, flexCards };
    }

    /**
     * Take a profile (either one already populated by the user or created from templates for
     * presentation) and open build-profile.
     * When the user saves the profile, it will be added to the enrollment if not already present.
     */
    public cardClicked(profile: IProfile): void {
        console.log("cardClicked: ", profile);

        if (profile.loading === true) {
            console.log("Profile still saving.  Can't edit until complete");
            return;
        }

        googleAnalytics.registerEvent("new_profile_click", "click", {
            display_name: profile.display_name,
            species: profile.species.display_name
        });

        this.openBuildProfile(profile);
    }

    private async openBuildProfile(profile: IProfile): Promise<void> {
        console.log("openBuildProfile: ", profile);

        await this.showBuildProfile(profile);
        this.updateLayout();
    }

    private async showBuildProfile(profile: IProfile): Promise<boolean> {
        if (this.modalRef && this.modalService.hasOpenModals()) {
            console.error("Modal dialog already open.");
            return false;
        }

        this.modalRef = this.modalService.open(BuildProfileComponent, {
            windowClass: "fixed", size: "xl"
        });
        this.modalRef.componentInstance.setProfile(profile);

        try {
            const result: { saved: boolean; profile: IProfile } = await this.modalRef.result;

            console.log("Build Profile modal closed: ", result);

            delete this.modalRef;

            return result && result.saved === true;
        } catch (err) {
            console.log("Build Profile modal dismissed", err);
            delete this.modalRef;

            return false;
        }
    }

    public canReset(profile: IProfile): boolean {
        return profile.templateType === TEMPLATE_TYPES.PRECONFIG;
    }

    public canDelete(profile: IProfile): boolean {
        return profile.templateType === TEMPLATE_TYPES.FLEX;
    }

    /**
     * Next button is disabled by default.
     * Enable it when at least one profile has been built from a template, priced out, and selected for
     * enrollment.
     */
    private updateButtons(): boolean {
        console.log("updateButtons");

        let profiles = this.session.enrollInfo?.profiles || [];

        profiles = profiles.filter((profile: IProfile): boolean => ProfileUtils.isVisible(profile));

        const hasSelectedProfiles = ProfileUtils.filterSelectedProfiles(profiles).length;
        const hasError = profiles.some((p: IProfile): boolean => (p.error != null || p.loading));
        const hasIncompleteProfile = profiles.some((p: IProfile): boolean => {
            if (p.selected && p.completed === true && !p.acceptedPracticePrice && !p.acceptedPetOwnerPrice) {
                console.warn("No accepted price for profile: ", p);
                return true;
            }
            return false;
        });

        // Invalid profiles must be deleted from session even if they're not selected.
        const hasInvalidProfile = profiles.some((p: IProfile): boolean => p.invalid);

        const isValid = hasSelectedProfiles && !hasIncompleteProfile && !hasInvalidProfile && !hasError && this.session.enrollInfo?.dirty !== true;
        this.nextPage.enabled = isValid;

        return isValid;
    }

    public editPrice(slot: IProfile): void {
        console.log("editPrice: ", slot);

        googleAnalytics.registerEvent("edit_price_clicked", "click", {
            panel_name: slot.display_name,
            profile_code: slot.profile_test_code,
            panel_current_price: slot.specialPrice
        });

        if (this.modalRef && this.modalService.hasOpenModals()) {
            console.error("Modal dialog already open.");
            return;
        }

        if (this.session.accountSettings.pricingMethod === PricingMethods.CORP) {
            this.modalRef = this.modalService.open(EditPricingComponent, {
                windowClass: "pricing-window"
            });
        } else {
            this.modalRef = this.modalService.open(EditPricingCAComponent, {
                windowClass: "pricing-window-ca fixed", size: "xl"
            });
        }

        this.modalRef.componentInstance.setProfile(slot);

        this.modalRef.result.then((result): void => {
            console.log("Edit Price modal closed: ", result);
            delete this.modalRef;
        }, (reason): void => {
            console.log("Edit Price modal dismissed", reason);
            delete this.modalRef;
        });

    }

    // Deleting a profile is only valid for a flex template that the user has added to the enrollment.
    // - Removes it from enrollment.
    // - Removes any "extra" profile from IAUA
    // - Saves enrollment.
    // - Then shows the available preconfig profile provided when no equivalent profile is found in enrollment.
    public async deleteClicked(slot: IProfile): Promise<boolean> {
        console.log("deleteClicked: ", slot);

        googleAnalytics.registerEvent("profile_delete_click", "click");

        const success = await this.appFacade.deleteProfile(slot, this.session.enrollInfo, false);
        if (success) {
            this.updateLayout();
        }

        return success;
    }

    // From the profiles screen, resetting a profile should do the same thing as deleting it
    // - Removes it from enrollment.
    // - Removes any "extra" profile from IAUA
    // - Saves enrollment.
    // - Then shows the available preconfig profile provided when no equivalent profile is found in enrollment.
    public async resetClicked(slot: IProfile): Promise<boolean> {
        console.log("resetClicked: ", slot);

        googleAnalytics.registerEvent("profile_reset_click", "click");

        const success = await this.appFacade.deleteProfile(slot, this.session.enrollInfo, true);
        if (success) {
            this.updateLayout();
        }

        return success;
    }

    public ngDoCheck(): void {
        const changes = this.differ.diff(this.session.enrollInfo);
        if (changes && this.session?.enrollInfo) {
            // Enable next button if at least one profile has been completed and the enrollment itself isn't in the process of being saved...
            this.updateButtons();

            this.updateLayout();
        }
    }

    /**
     * When user clicks "selected" checkbox, the specified profile gets marked as selected=true and
     * we make sure the profile is added to current session.
     * When a user unchecks "selected", the specified profile gets marked as selected=false, which
     * means it's not part of the current enrollment, but is still saved as part of the current session.
     * Need to determine if a deselected profile hasn't been modified, should it still be saved to
     * session or should it be removed.  How to determine if has been configured?
    */
    public async profileSelected(profile: IProfile): Promise<void> {
        console.log("profileSelected: ", profile.enrollment_profile_id, profile);
        if (profile.selected) {

            if (!profile.matchedProfile || (!profile.matchedProfile.listPrice && profile.templateType !== TEMPLATE_TYPES.STATIC && profile.templateType !== TEMPLATE_TYPES.PCH)) {
                console.log("Matched profile missing list price.  Calling matchProfile service...", profile.matchedProfile);
                profile.matchedProfile = await this.matchProfile(profile);
            }

            if (profile.enrollment_id) {
                console.log("Already present in session");
            } else {
                profile.completed = true;
                EnrollUtils.addProfile(this.session.enrollInfo, profile);
            }
        } else {
            console.log("Deselected profile: ", profile);
        }

        const resp = await this.appFacade.submitProfile(profile, this.session.enrollInfo, this.session, profile.selected);
        console.log("submitProfile resp=", resp);

        this.updateLayout();
    }

    private findBuiltProfiles(template: IProfileTemplate, allProfiles: IProfile[]): IProfile[] {
        return allProfiles.filter((profile: IProfile): boolean => (
            profile.template_profile_id === template.template_profile_id
        ));
    }

    // For specified template, find any existing profiles built from this template.
    // Return that list along with "placekeepers" for any PRECONFIG templates that haven't had a
    // profile built yet.
    // If any STATIC templates exist, add them here as well.
    private getProfileCardsForTemplate(template: IProfileTemplate, allProfiles: IProfile[]): IProfile[] {
        let builtProfiles = this.findBuiltProfiles(template, allProfiles);

        // Add any existing profiles to list.
        let cards: IProfile[] = [
            ...builtProfiles
        ];

        // Remove any "extra" profiles that should be hidden on this page.
        cards = cards.filter((profile: IProfile): boolean =>
            !profile.isExtra
        );

        // If a profile is invalid (template has changed since profile was last saved),
        // show both the invalid and the "clean" new version.
        builtProfiles = builtProfiles.filter((profile: IProfile): boolean =>
            !profile.invalid
        );

        // If no valid profiles have been created for this template, and the template type is PRECONFIG,
        // then add a place-keeper Profile for this template to the list.
        if ((template.templateType === TEMPLATE_TYPES.PRECONFIG || template.templateType === TEMPLATE_TYPES.STATIC)
            && !builtProfiles.length) {
            // If no profile has been configured yet for a PRECONFIG template, add "NEW" profile to list.
            const profile = Profile.fromTemplate(template);
            profile.display_name = this.appFacade.translate(template.displayNameKey);
            profile.selected = template.defaultSelected;
            cards.push(profile);
        }

        return cards;
    }

    /**
     * Return sorted list of templates
     * Sorted in order by:
     * - template type
     * - display_order
    */
    private getTemplates(allTemplates?: IProfileTemplate[]): IProfileTemplate[] {
        let templates = allTemplates;

        if (!allTemplates) {
            return [];
        }

        // Don't show Pet Care Heroes profile templates in UI
        templates = templates.filter((tpl: IProfileTemplate): boolean => tpl.templateType != TEMPLATE_TYPES.PCH);

        // Sort list of templates.
        templates = templates.sort((tpl1: IProfileTemplate, tpl2: IProfileTemplate): number => {
            if (tpl1.templateType !== tpl2.templateType) {
                // Preconfig templates should be first in list
                return (tpl1.templateType === TEMPLATE_TYPES.PRECONFIG) ? -1 : 1;
            }
            if (tpl1.display_order !== tpl2.display_order) {
                return tpl1.display_order - tpl2.display_order;
            }
            return 0;
        });

        return templates;
    }

    private async matchProfile(profile: IProfile): Promise<IProduct> {
        try {
            const profileRequest = this.genMatchRequest(profile);

            const resp = await this.appFacade.findMatchingProfile(profileRequest);
            if (resp.success && resp.profile) {
                return resp.profile;
            }

            console.error("Error matching profile.  No match found!", resp);

            return null;

        } catch (error) {
            console.error("Error matching profile: ", error);
            return null;
        }
    }

    private genMatchRequest(profile: IProfile): IProfileMatchRequest {
        const limsNames = this.appFacade.getDefaultLims(this.session);

        const selectedProductTestCodes = ProfileUtils.getSelectedProductTestCodes(profile.profileItems);

        const profileRequest: IProfileMatchRequest = {
            limsNames,
            testCodes: selectedProductTestCodes
        };
        return profileRequest;
    }

    private getHandlingEmail(): string {
        return this.session.accountSettings.handling_email || envUtils.HANDLING_EMAIL;
    }

}

/*
Need to monitor the list of configured profiles.  When a profile is completed (price and enrollment_profile_id is set and no error present), then allow next button to be active.
*/
