<div *ngIf="canSelect" class="pcc-select-checkpanel">
  <div class="slot-added">{{ profile[selectedProperty] ? "ADDED":" " }}</div>
  <label class="spot-form__checkbox" [for]="'selected' + uid">
    <input [id]="'selected' + uid" type="checkbox" class="spot-form__checkbox-input" [(ngModel)]="profile[selectedProperty]" [disabled]="selectDisabled" name="isSelected" (change)="toggleProfileSelected()"/>
    <span class="spot-form__checkbox-inner">
      <span class="spot-form__checkbox-visual">
        <svg class="spot-icon spot-form__checkbox-checked-icon" aria-labelledby="title">
          <title>select</title>
          <use xlink:href="/assets/spot-library/dist/symbols/spot_symbols.svg#checkmark"></use>
        </svg>
        <svg class="spot-icon spot-form__checkbox-indeterminate-icon" aria-labelledby="title">
          <title>checkbox-indeterminate</title>
          <use xlink:href="/assets/spot-library/dist/symbols/spot_symbols.svg#checkbox-indeterminate"></use>
        </svg>
      </span>
    </span>
  </label>
</div>
<div [ngClass]="'pcc-slot ' + (profile.loading ? 'slot-loading' : '') + (profile.selected ? ' pcc-slot-selected' : '') + (profile.invalid ? ' pcc-slot-invalid' : '')">
  <div class="pcc-slot-header">
    <a *ngIf="canEdit" (click)="slotClicked()" class="spot-link pcc-profile-title">{{profileName}}</a>
    <span *ngIf="!canEdit" class="pcc-profile-title pcc-profile-title-readonly">{{profileName}}</span>
    <span style="flex:1;"> </span>
    <pcc-confirm-action *ngIf="canReset" okKey="confirm_action.reset" (okClick)="resetClicked()" buttonTitle="slot.reset"></pcc-confirm-action>
    <pcc-confirm-action *ngIf="canDelete" okKey="confirm_action.delete" buttonTitle="confirm_action.delete" symbol="delete" (okClick)="deleteClicked()"></pcc-confirm-action>
    <button *ngIf="canEdit" class="spot-button spot-button--secondary spot-button--large pcc-configure-button" [title]="'slot.configure' | translate" (click)='slotClicked()'>
      <i class="fa-solid fa-wrench fa-lg"></i> <!-- TODO: SPOT or custom version of wrench -->
    </button>

    <span *ngIf="profile.error" class="slot-error">

      <ng-template #slotError>
        <div class="spot-message priority-medium--danger">
          <div class="message--content">
            <div class="message--body" [innerHTML]="profile.error.messageKey | translate:{email: handlingEmail}"></div>
          </div>
          <div class="icon-tab">
            <svg class="spot-icon" aria-labelledby="title">
              <title>Error</title>
              <use *ngIf="!profile.error.hasOrderBlock" xlink:href="/assets/spot-library/dist/symbols/spot_symbols.svg#alert-notification"></use>
              <use *ngIf="profile.error.hasOrderBlock" xlink:href="/assets/spot-library/dist/symbols/spot_symbols.svg#blocked"></use>
            </svg>
          </div>
        </div>
      </ng-template>

      <svg
        class="spot-icon slot-error-icon"
        aria-labelledby="title"
        [ngbTooltip]="slotError"
        tooltipClass= "slot-error-popup"
        placement="start"
        >
        <use *ngIf="!profile.error.hasOrderBlock" xlink:href="/assets/spot-library/dist/symbols/spot_symbols.svg#alert-notification"></use>
        <use *ngIf="profile.error.hasOrderBlock" xlink:href="/assets/spot-library/dist/symbols/spot_symbols.svg#blocked"></use>
      </svg>
    </span>
  </div>

  <div class="pcc-slot-content" *ngIf="profile.profile_test_code">
    <div class="pcc-profile-id">{{'slot.Test_Code' | translate}} {{profile.profile_test_code}}</div>

    <div class="pcc-inline-testsbycategory-wrapper">
      <div class="pcc-inline-testsbycategory" *ngFor="let category of selectedTestsByModality">
        <img [src]="getModalityIcon(category.modality)" class="modality-icon" [alt]="category.modality">
        <span class="modality-tests">
          <pcc-test-pill *ngFor="let tc of category.tests;" [name]="tc.displayNameKey | translate"></pcc-test-pill>
        </span>
      </div>
    </div>

    <div *ngIf="profile.invalid && profile.error" class="spot-message priority-medium--danger">
      <div class="message--content">
        <div class="message--body" [innerHTML]="profile.error.messageKey | translate:{email: handlingEmail}"></div>
      </div>
      <div class="icon-tab">
        <svg class="spot-icon" aria-labelledby="title">
          <title>Error</title>
          <use *ngIf="!profile.error.hasOrderBlock" xlink:href="/assets/spot-library/dist/symbols/spot_symbols.svg#alert-notification"></use>
          <use *ngIf="profile.error.hasOrderBlock" xlink:href="/assets/spot-library/dist/symbols/spot_symbols.svg#blocked"></use>
        </svg>
      </div>
    </div>

  </div>
  <div class="price-panel">
    <div *ngIf="shouldShowAcceptedPracticePrice()" class="pcc-profile-price">
      <span class="price-label">{{'slot.Panel_price'| translate}}:</span>
      <ng-container *ngIf="shouldShowPracticePriceButton()">
        <div class="lds-ellipsis" [ngClass]="(profile.loading) ? '' : 'transparent'" class="transition-transparency loader lds-ellipsis"><div></div><div></div><div></div><div></div></div>
        <a class="transition-transparency spot-link panel-price-button" (click)="editPriceClicked()">{{profile.acceptedPracticePrice | currency : session.currency : 'symbol-narrow' : '1.2-2' : session.locale}}
          <svg class="spot-icon spot-button__icon" aria-labelledby="title">
            <title>{{ 'slot.edit' | translate }}</title>
            <use xlink:href="/assets/spot-library/dist/symbols/spot_symbols.svg#edit"></use>
          </svg>
        </a>
      </ng-container>
      <ng-container *ngIf="!shouldShowPracticePriceButton()">
        <span class="price-value">{{profile.acceptedPracticePrice | currency : session.currency : 'symbol-narrow' : '1.2-2' : session.locale}}</span>
      </ng-container>
    </div>
    <div *ngIf="shouldShowPetOwnerPrice()" class="pcc-profile-price">
      <span class="price-label">{{'slot.pet_owner_price' | translate}}:</span>

      <ng-container *ngIf="!shouldShowPetOwnerPriceButton() && !shouldShowPetOwnerPriceEditField()">
        <span class="price-value">{{profile.acceptedPetOwnerPrice | currency : session.currency : 'symbol-narrow' : '1.2-2' : session.locale}}</span>
      </ng-container>
      <ng-container *ngIf="shouldShowPetOwnerPriceButton()">
        <div class="lds-ellipsis" [ngClass]="(profile.loading) ? '' : 'transparent'" class="transition-transparency loader lds-ellipsis"><div></div><div></div><div></div><div></div></div>
        <a class="transition-transparency spot-link panel-price-button" (click)="editPriceClicked()">{{profile.acceptedPetOwnerPrice | currency : session.currency : 'symbol-narrow' : '1.2-2' : session.locale}}
          <svg class="spot-icon spot-button__icon" aria-labelledby="title">
            <title>{{ 'slot.edit' | translate }}</title>
            <use xlink:href="/assets/spot-library/dist/symbols/spot_symbols.svg#edit"></use>
          </svg>
        </a>
      </ng-container>
      <ng-container *ngIf="shouldShowPetOwnerPriceEditField()">
        <input class="pcc-pet-owner-price-input" [disabled]="isDisabled" [(ngModel)]="profile.acceptedPetOwnerPrice" type="text" currencyMask [options]="session?.currencyMaskOptions">
      </ng-container>
    </div>
    <div *ngIf="shouldShowPracticePrice()" class="pcc-profile-price">
      <div>
        <span class="price-label">{{'slot.practice_cost' | translate}}:</span>
        <span class="price-value">{{profile.customerPrice | currency : session.currency : 'symbol-narrow' : '1.2-2' : session.locale}}</span>
      </div>
    </div>
  </div>
</div>
